import { ReactElement, useState } from 'react';
import { Button } from '../../atoms/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import Tabs from '../../molecules/Tabs';
import OverviewTab from './Tabs/Overview';
import SlotsTab from './Tabs/Slots';
import { gql } from '@monorepo/graphql';
import AddPartner from '../../molecules/Modals/AddPartner';

const Partners = (): ReactElement => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <>
      <div className="flex flex-col overflow-hidden p-5 h-full">
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-h1-small font-bold font-nunito">Partners</h1>
          <Button
            bText="Add"
            reverse
            onClick={() => setShowCreateModal(true)}
            Icon={<PlusIcon className="size-6 text-white" />}
          />
        </div>
        <Tabs
          tabs={[
            {
              name: 'Overview',
              value: 'overview',
              component: <OverviewTab />,
            },
            {
              name: 'Slots',
              value: 'slots',
              component: <SlotsTab />,
            },
          ]}
        />
      </div>
      <AddPartner 
        open={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
    </>
  );
};
export default Partners;
