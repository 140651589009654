import { ReactElement, useCallback, useMemo, useState } from 'react';
import Table from '../../../../molecules/Table';
import { Button } from '../../../../atoms/Button';
import { CircleIcon } from '../../../../icons/Circle';
import { DropdownWithBorder, Option } from '../../../../atoms/Dropdown';
import { AllEnum } from '../../../../../utility/calendarContext';
import { contractorUtility } from '../../../../../utility/contractors';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import {
  BoltIcon,
  BuildingOffice2Icon,
  FunnelIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Drawer from '../../../../molecules/Drawer';
import PartnerDropdown from '../../../../molecules/PartnerDropdown';
import {
  ContractorCompletionRate,
  ContractorTrade,
  gql,
  IndexUsersForContractorsScreenQuery,
  UserContractorProfile,
  UserProfile,
} from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import AvatarStack from '../../../../atoms/AvatarStack';
import { RooferIcon } from '../../../../icons/Roofer';
import CompletionRateIndicator from '../../../../atoms/CompletionRateIndicator';
import AddContractorModal from '../../../../molecules/Modals/AddUserWithProfile';

const GET_USERS = gql(`
  query IndexUsersForContractorsScreen ($filters: IndexUsersFilterInput!, $pagination: PaginationInput) {
	indexUsers (filters: $filters, pagination: $pagination) {
		items {
			uuid
			firstName
      lastName
			avatarSrc
      lastLogin
      profile {
        __typename 
        ... on UserContractorProfile {
          isElectrician
          isRoofer
          completionRate
          jobCount
          organisation {
            name
          }
        }
      }
		}
    pagination {
      lastPage
    }
	}
}
`);

type UserWithContractorProfileType =
  IndexUsersForContractorsScreenQuery['indexUsers']['items'][0] & {
    profile: UserContractorProfile;
  };

const completionRateFilters = (rate: ContractorCompletionRate) => {
  if (rate === ContractorCompletionRate.aboveEighty) {
    return {
      userContractorProfileCompletionRateGte: 80,
    };
  }

  if (rate === ContractorCompletionRate.twentyToEighty) {
    return {
      userContractorProfileCompletionRateGte: 20,
      userContractorProfileCompletionRateLte: 80,
    };
  }

  return {
    userContractorProfileCompletionRateLte: 20,
  };
};

const ContractorTab = (): ReactElement => {
  const [filters, setFilters] = useState(contractorUtility.defaultFilters);
  const [organisationUuid, setOrganisationUuid] = useState('all');
  const [showAddContractorModal, setShowAddContractorModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [users, setUsers] = useState<Array<UserWithContractorProfileType>>([]);
  const flatFilters = useMemo(
    () => [
      ...Object.keys(filters).reduce((prev, k) => {
        const options = filters[k as keyof typeof filters];
        if (!Array.isArray(options))
          return options.value !== AllEnum.all ? [...prev, options] : prev;
        return [...prev, ...options.filter((k) => k.value !== AllEnum.all)];
      }, [] as Option<string>[]),
      ...(organisationUuid !== 'all'
        ? [
            {
              name: organisationUuid,
              value: organisationUuid,
            },
          ]
        : []),
    ],
    [filters, organisationUuid]
  );

  const addOrRemove = useCallback(
    (opt: Option<any>, key: keyof typeof filters) => {
      setFilters((ft) => {
        const item = ft[key];
        if (!Array.isArray(item)) return ft;
        let options = item.filter((opt) => opt.value !== AllEnum.all);

        if (opt.value === 'all') {
          options = [opt];
        } else {
          const indexOfOption = options.indexOf(opt);
          if (indexOfOption > -1) {
            options.splice(indexOfOption, 1);
          } else {
            options = [...options, opt];
          }
        }

        return {
          ...ft,
          [key]:
            options.length > 0
              ? options
              : [{ name: 'All', value: AllEnum.all }],
        };
      });
    },
    []
  );

  const { loading } = useQuery(GET_USERS, {
    variables: {
      filters: {
        userProfile: UserProfile.contractor,
        organisationUuid: organisationUuid === 'all' ? undefined : organisationUuid,
        userContractorProfileIsRoofer: filters.trade.some(
          ({ value }) => value === ContractorTrade.roofer
        )
          ? true
          : undefined,
        userContractorProfileIsElectrician: filters.trade.some(
          ({ value }) => value === ContractorTrade.electrician
        )
          ? true
          : undefined,
        ...(filters.completion.value !== AllEnum.all
          ? completionRateFilters(filters.completion.value)
          : {}),
      },
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
    skip: showFilters,
    onCompleted: (data) => {
      setUsers(
        data.indexUsers.items.filter(
          (i): i is UserWithContractorProfileType =>
            i.profile.__typename === 'UserContractorProfile'
        )
      );
      setTotalPages(data.indexUsers.pagination.lastPage);
    },
  });

  return (
    <>
      <Table
        loading={loading}
        toolbar={
          <>
            <div className="relative mr-2">
              <Button
                onClick={() => setShowFilters((ft) => !ft)}
                bStyle="light"
                className='h-11 w-11 !p-0 justify-center'
                Icon={<FunnelIcon className="size-6" />}
              />
              {!!flatFilters.length && (
                <CircleIcon
                  className="text-primary absolute top-0 right-0 translate-x-1/4 transform -translate-y-1/4"
                  multiplier={6}
                />
              )}
            </div>
            <Button
              bText="Add"
              Icon={<PlusIcon className="text-white size-6" />}
              onClick={() => setShowAddContractorModal(true)}
              reverse
            />
          </>
        }
        columns={[
          {
            heading: 'name',
            width: 25,
          },
          {
            heading: 'organisation',
            width: 25,
          },
          {
            heading: 'trade',
            width: 20,
          },
          {
            heading: 'completion rate',
            width: 15,
          },
          {
            heading: 'jobs',
            width: 5,
          },
          {
            width: 10,
          },
        ]}
        widthType="pc"
        rows={users.map((u) => ({
          uuid: u.uuid,
          cells: [
            {
              width: 25,
              content: (
                <div className="flex items-center space-x-2">
                  <AvatarStack
                    avatars={[
                      {
                        firstName: u.firstName,
                        lastName: u.lastName,
                        avatarSrc: u.avatarSrc ?? undefined,
                      },
                    ]}
                  />
                  <span className="text-body-small">
                    {u.firstName} {u.lastName}
                  </span>
                </div>
              ),
            },
            {
              width: 25,
              content: (
                <div className="flex items-center space-x-2">
                  <BuildingOffice2Icon className="size-5 text-grey-400" />
                  <span className="text-body-small">{u.profile.organisation.name}</span>
                </div>
              ),
            },
            {
              width: 20,
              content: (
                <div className="flex items-center space-x-2">
                  {u.profile.isElectrician ? (
                    <>
                      <BoltIcon className="size-5 text-grey-400" />
                      <span className="text-body-small">Electrician</span>
                    </>
                  ) : u.profile.isRoofer ? (
                    <>
                      <RooferIcon className="text-grey-400" />
                      <span className="text-body-small">Roofer</span>
                    </>
                  ) : (
                    '-'
                  )}
                </div>
              ),
            },
            {
              width: 15,
              content:
                u.profile.completionRate > 0 ? (
                  <div className="flex items-center w-full space-x-2">
                    <CompletionRateIndicator
                      number={u.profile.completionRate}
                    />
                    <span className="text-body-small">
                      {u.profile.completionRate}%
                    </span>
                  </div>
                ) : (
                  '-'
                ),
            },
            {
              width: 5,
              content: u.profile.jobCount > 0 ? u.profile.jobCount : '-',
            },
            {
              width: 10,
              content: (
                <div className="flex justify-end w-full">
                  <Button
                    href={`/contacts/${u.uuid}`}
                    bText="View"
                    bStyle="outline"
                    className="h-9"
                  />
                </div>
              ),
            },
          ],
        }))}
        title="Contractors"
      />
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
      <Drawer setShow={setShowFilters} show={showFilters} title="Filters">
        <div className="w-72 flex flex-col">
          <div className="mb-5 flex gap-3 flex-wrap">
            {Object.keys(filters).map((f) => {
              const key = f as keyof typeof filters;
              const item = filters[key];
              if (Array.isArray(item)) {
                return item
                  .filter((f) => f.value !== 'all')
                  .map((opt) => (
                    <button
                      key={opt.value}
                      onClick={() =>
                        addOrRemove(opt, f as keyof typeof filters)
                      }
                      className="h-8 px-2 flex items-center bg-grey-900 border border-grey-500 rounded space-x-1.5"
                    >
                      <span className="text-body-small">{opt.name}</span>
                      <XMarkIcon className="size-5" />
                    </button>
                  ));
              }
              if (item.value !== AllEnum.all)
                return (
                  <button
                    key={item.value}
                    onClick={() =>
                      setFilters((f) => ({
                        ...f,
                        [key]: contractorUtility.defaultFilters[key],
                      }))
                    }
                    className="h-8 px-2 flex items-center bg-grey-900 border border-grey-500 rounded space-x-1.5"
                  >
                    <span className="text-body-small">{item.name}</span>
                    <XMarkIcon className="size-5" />
                  </button>
                );
            })}
          </div>
          <DropdownWithBorder
            selected={filters.trade}
            options={contractorUtility.tradesOptions}
            onOptionSelect={(opt) => addOrRemove(opt, 'trade')}
            label="Trades"
            buttonClassname="justify-between w-50 mb-5"
            bubble
            buttonText={
              filters.trade.length > 1
                ? `Trades (${filters.trade.length})`
                : filters.trade[0].name
            }
          />
          <DropdownWithBorder
            selected={filters.completion}
            options={contractorUtility.completionRateOptions}
            onOptionSelect={(opt) =>
              setFilters((f) => ({
                ...f,
                completion: opt,
              }))
            }
            label="Completion rate"
            buttonClassname="justify-between mb-5"
            bubble
            buttonText={filters.completion.name}
          />
          {/* CHANGE PARTNER DROPDOWN */}
          <PartnerDropdown
            all
            partnerUuid={organisationUuid}
            setPartnerUuid={setOrganisationUuid}
          />
        </div>
        <div className="flex flex-col space-y-3">
          <Button
            bText="Apply filters"
            className="w-full justify-center"
            onClick={() => setShowFilters(false)}
          />
          <Button
            disabled={flatFilters.length === 0}
            bText="Clear all"
            className="w-full justify-center"
            bStyle="clean"
            onClick={() => {
              setFilters(contractorUtility.defaultFilters);
              setOrganisationUuid('all');
              setShowFilters(false);
            }}
          />
        </div>
      </Drawer>
      <AddContractorModal
        open={showAddContractorModal}
        onClose={setShowAddContractorModal}
      />
    </>
  );
};
export default ContractorTab;
