import { ReactElement } from 'react';
import { gql } from '@monorepo/graphql';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input } from '../../../atoms/Input';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import PartnerDropdown from '../../PartnerDropdown';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';
import OrganisationDropdown from '../../OrganisationDropdown';

type ContractorProfileData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  trades: {
    isElectrician: boolean;
    isRoofer: boolean;
  };
  organisationUuid: string;
};

type Props = {
  contractorProfilleUuid: string;
  open: boolean;
  organisationName: string;
  onClose: (success: boolean, data?: ContractorProfileData & {
    organisationName: string;
  }) => void;
} & ContractorProfileData & Omit<ModalWrapperPropsExtends, 'onClose'>;

const UPDATE_USER_CONTRACTOR_PROFILE = gql(`
  mutation UpdateUserContractorProfileModal ($input: UpdateUserContractorProfileInput!) {
    updateUserContractorProfile(input: $input) {
      uuid
      userUuid
      organisation {
        name
      }
    }
  }
`);

const EditContractor = ({
  open,
  onClose,
  ...rest
}: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <EditContractorChild {...rest} onClose={onClose} />
  </ModalWrapper>
)

const EditContractorChild = ({
  contractorProfilleUuid,
  firstName,
  lastName,
  email,
  phoneNumber,
  trades,
  organisationUuid,
  organisationName,
  onClose,
}: Omit<Props, 'open'>): ReactElement => {
  const [update, { loading, error }] = useMutation(
    UPDATE_USER_CONTRACTOR_PROFILE
  );

  const form = useForm({
    onSubmit: ({ value }) => {
      update({
        variables: {
          input: {
            uuid: contractorProfilleUuid,
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            phoneNumber: value.phoneNumber,
            isElectrician: value.trades.isElectrician,
            isRoofer: value.trades.isRoofer,
            organisationUuid: value.organisationUuid,
          },
        },
        onCompleted: (data) => {
          if (data) {
            notify.success('Succesfully saved contractor');
            onClose(true, {
              ...value,
              organisationName: data.updateUserContractorProfile.organisation.name
            });
          }
        },
      });
    },
    defaultValues: {
      firstName,
      lastName,
      email,
      phoneNumber,
      trades,
      organisationUuid,
    },
  });

  return (
    <Modal
      onClose={(success) => onClose(success, undefined)}
      title="Edit contractor"
      confirmText="Save changes"
      confirmCallback={form.handleSubmit}
      loading={loading}
    >
      <div className="p-5">
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <form.Field
            name="firstName"
            validators={{
              onBlur: ({ value }) =>
                value.length ? undefined : 'Please enter the first name',
            }}
            children={({ state, handleChange, handleBlur }) => (
              <Input
                error={state.meta.errors.join(', ')}
                value={state.value}
                onBlur={handleBlur}
                onChange={(e) => handleChange(e.target.value)}
                label="First name"
              />
            )}
          />
          <form.Field
            name="lastName"
            validators={{
              onBlur: ({ value }) =>
                value.length ? undefined : 'Please enter the last name',
            }}
            children={({ state, handleChange, handleBlur }) => (
              <Input
                error={state.meta.errors.join(', ')}
                value={state.value}
                onBlur={handleBlur}
                onChange={(e) => handleChange(e.target.value)}
                label="Last name"
              />
            )}
          />
          <form.Field
            name="email"
            validators={{
              onBlur: ({ value }) =>
                value.length ? undefined : 'Please enter the email',
            }}
            children={({ state, handleChange, handleBlur }) => (
              <Input
                error={state.meta.errors.join(', ')}
                type="email"
                value={state.value}
                className='w-140'
                onBlur={handleBlur}
                onChange={(e) => handleChange(e.target.value)}
                label="First name"
                Icon={<EnvelopeIcon className="size-6 text-normal" />}
              />
            )}
          />
          <form.Field
            name="phoneNumber"
            children={({ state, handleChange, handleBlur }) => (
              <Input
                value={state.value ?? ''}
                onBlur={handleBlur}
                Icon={<PhoneIcon className='size-6 text-text-normal' />}
                onChange={(e) => handleChange(e.target.value)}
                label="Phone"
              />
            )}
          />
          <div className="mb-5">
            <span className="font-semibold text-input-label block mb-2 text-text-normal">
              Trade
            </span>
            <form.Field
              name="trades"
              children={({ state, handleChange }) => (
                <div className="space-y-2.5">
                  <SimpleCheckbox
                    label="Electrician"
                    checked={state.value.isElectrician}
                    setChecked={(checked) =>
                      handleChange((f) => ({
                        ...f,
                        isElectrician: checked,
                      }))
                    }
                  />
                  <SimpleCheckbox
                    label="Roofer"
                    checked={state.value.isRoofer}
                    setChecked={(checked) =>
                      handleChange((f) => ({
                        ...f,
                        isRoofer: checked,
                      }))
                    }
                  />
                </div>
              )}
            />
          </div>
          <form.Field
            name="organisationUuid"
            validators={{
              onSubmit: ({ value }) =>
                value.length ? undefined : 'Please select a partner.',
            }}
            children={({ state, handleChange }) => (
              <OrganisationDropdown
                error={state.meta.errors.join(', ')}
                setOrganisationUuid={handleChange}
                organisationUuid={state.value}
                organisationName={organisationName}
              />
            )}
          />
        </form>
        {error && (
          <div className="mt-5">
            <Alert alertType="error" text={error.message} />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default EditContractor;
