import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import Table from '../Table';
import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  CalendarIcon,
  EllipsisVerticalIcon,
  ListBulletIcon,
  Squares2X2Icon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import AvatarStack from '../../atoms/AvatarStack';
import { Button } from '../../atoms/Button';
import ButtonSelector from '../../atoms/ButtonSelector';
import Dropdown, { Option } from '../../atoms/Dropdown';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import { format } from 'date-fns';
import { CircleIcon } from '../../icons/Circle';
import { RowsPerPage, TablePagination } from '../Pagination';
import UploadModal from '../Modals/UploadModal';
import { filesUtility } from '../../../utility/files';
import {
  FileTargetType,
  gql,
  IndexFilesQuery,
  TargetType,
} from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import { client } from '../../../main';
import ExpandedFileViewer from '../Modals/ExpandedFileViewer';
import SimpleModal from '../Modals/Simple';
import { notify } from '../../../utility/notify';
import { AuditEntryTargetType } from '@prisma/client';

const fileDropdownOptions: Option<string>[] = [
  {
    name: 'All Files',
    value: 'all',
  },
  {
    name: 'Images',
    value: 'image/',
  },
  {
    name: 'PDF',
    value: 'application/pdf',
  },
  {
    name: 'Word documents',
    value:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    name: 'CSV',
    value: 'text/csv',
  },
  {
    name: 'Excel Document',
    value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
];

interface Props {
  userUuid: string;
  customerProfileUuid: string;
}

const INDEX_FILES = gql(`
  query IndexFilesForCustomer ($filters: IndexFilesFilter!, $pagination: PaginationInput) {
    indexFiles(filters: $filters, pagination: $pagination) {
      items {
        uuid
        key
        name
        mimeType
        size
        src
        downloadSrc
        createdAt
        targetType
        targetUuid
        owner {
          firstName
          lastName
          avatarSrc
        }
      }
      pagination {
        total
        perPage
        lastPage
      }
    }
  }
`);

const FileViewByCustomer = ({
  userUuid,
  customerProfileUuid,
}: Props): ReactElement => {
  const [viewer, setViewer] = useState<'gallery' | 'table'>('table');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [mimeType, setMimeType] = useState<string>();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.ten);

  const [file, setFile] = useState<{
    src: string;
    mimeType: string;
    name: string;
  }>();

  const { deleteFile, deleteFileLoading } = filesUtility.useFileUploader();

  const { data: files, loading } = useQuery(INDEX_FILES, {
    variables: {
      filters: {
        customerProfileUuid,
        mimeType,
      },
      pagination: {
        page,
        perPage: rowsPerPage,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const [fileToDelete, setFileToDelete] =
    useState<IndexFilesQuery['indexFiles']['items'][0]>();

  return (
    <>
      <div className="bg-white overflow-hidden h-full flex flex-col">
        <div className="flex space-x-3 p-5 items-center border-b border-grey-700">
          <div className="flex-grow">
            <h2 className="text-h2 font-bold font-nunito">Files</h2>
          </div>
          <Dropdown
            buttonText={
              mimeType
                ? fileDropdownOptions.find(({ value }) => value === mimeType)
                    ?.name
                : 'All files'
            }
            ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
            buttonClassname="border px-3 h-11 w-50 justify-between items-center space-x-4 border-grey-500 rounded flex"
            selected={fileDropdownOptions[0]}
            options={fileDropdownOptions}
            onOptionSelect={(opt) =>
              setMimeType(opt.value === 'all' ? undefined : opt.value)
            }
          />
          <ButtonSelector<'table' | 'gallery'>
            selectedValue={viewer}
            setSelectedValue={setViewer}
            options={[
              {
                name: <ListBulletIcon className="size-6" />,
                value: 'table',
              },
              {
                name: <Squares2X2Icon className="size-6" />,
                value: 'gallery',
              },
            ]}
          />
          <Button
            Icon={<ArrowUpTrayIcon className="size-6" />}
            bText="Upload"
            reverse
            bStyle="outline"
            onClick={() => setShowUploadModal(true)}
          />
        </div>
        {viewer === 'table' ? (
          <Table
            loading={loading}
            widthType="pc"
            rows={
              files?.indexFiles.items.map((f) => ({
                uuid: f.uuid,
                cells: [
                  {
                    content: (
                      <button
                        onClick={() =>
                          setFile({
                            name: f.name,
                            src: f.src,
                            mimeType: f.mimeType,
                          })
                        }
                        className="flex items-center space-x-2 font-nunito overflow-hidden"
                      >
                        <div className="w-9 h-9 overflow-hidden mr-2">
                          {filesUtility.getMimeTypeComponent({
                            type: f.mimeType,
                            src: f.src,
                            name: f.name,
                          })}
                        </div>
                        <span className="text-body-small underline truncate">
                          {f.name}
                        </span>
                      </button>
                    ),
                    width: 30,
                  },
                  {
                    content:
                      f.targetType && f.targetUuid
                        ? filesUtility.fileTargetComponentMap(f.targetUuid)[
                            f.targetType
                          ]
                        : '-',
                    width: 16,
                  },
                  {
                    content: (
                      <div className="flex items-center space-x-2">
                        <CalendarIcon className="h-5 text-grey-400" />
                        <span className="text-body-small">
                          {format(new Date(f.createdAt), 'd MMMM yyyy')}
                        </span>
                      </div>
                    ),
                    width: 16,
                  },
                  {
                    content: (
                      <div className="flex items-center space-x-2">
                        {f.owner ? (
                          <>
                            <AvatarStack
                              avatars={[
                                {
                                  firstName: f.owner.firstName,
                                  lastName: f.owner.lastName,
                                  avatarSrc: f.owner.avatarSrc,
                                },
                              ]}
                              width="w-9"
                              height="h-9"
                            />
                            <span className="text-body-small">
                              {f.owner.firstName} {f.owner.lastName}
                            </span>
                          </>
                        ) : (
                          <span>-</span>
                        )}
                      </div>
                    ),
                    width: 16,
                  },
                  {
                    content: (
                      <span className="text-sm">
                        {filesUtility.getSize(f.size)}
                      </span>
                    ),
                    width: 10,
                  },
                  {
                    content: (
                      <div className="flex items-center justify-end space-x-2 w-full">
                        <Button
                          className="!h-9 !px-2"
                          bStyle="light"
                          onClick={() => setFileToDelete(f)}
                          Icon={<TrashIcon className="text-red size-5" />}
                        />
                        <Button
                          href={f.downloadSrc}
                          className="!h-9 !px-2"
                          bStyle="light"
                          Icon={<ArrowDownTrayIcon className="size-5" />}
                        />
                      </div>
                    ),
                    width: 12,
                  },
                ],
              })) ?? []
            }
            columns={[
              {
                heading: 'Name',
                width: 30,
              },
              {
                heading: 'Relation',
                width: 16,
              },
              {
                heading: 'Date added',
                width: 16,
              },
              {
                heading: 'Added by',
                width: 16,
              },
              {
                heading: 'Size',
                width: 10,
              },
              {
                width: 12,
              },
            ]}
          />
        ) : (
          <div className="grid-cols-4 grid px-4 pb-4 bg-white gap-2">
            {files?.indexFiles.items.map((f) => (
              <div className="bg-grey-900 rounded-md h-52 flex justify-center flex-col">
                <div className="px-2 py-2 flex items-center justify-between">
                  <span className="block ml-px">{f.name}</span>
                  <Dropdown
                    onOptionSelect={(opt) => {
                      if (opt.value === 'delete') {
                        setFileToDelete(f);
                      }
                    }}
                    options={[
                      {
                        value: 'download',
                        name: 'Download',
                        href: f.downloadSrc,
                        Icon: <ArrowDownTrayIcon className="size-5" />,
                      },
                      {
                        itemClassname: 'text-red',
                        value: 'delete',
                        name: 'Delete',
                        Icon: <TrashIcon className="text-red size-5" />,
                      },
                    ]}
                    ButtonIcon={<EllipsisVerticalIcon className="size-6" />}
                  />
                </div>
                <button
                  onClick={() =>
                    setFile({
                      name: f.name,
                      src: f.src,
                      mimeType: f.mimeType,
                    })
                  }
                  className="mx-2 rounded bg-white flex items-center justify-center h-full overflow-hidden"
                >
                  {filesUtility.getMimeTypeComponent({
                    type: f.mimeType,
                    src: f.src,
                    name: f.name,
                  })}
                </button>
                <div className="px-3 py-2 text-text-low-priority flex items-center text-button-label space-x-2">
                  {f.owner && (
                    <>
                      <AvatarStack
                        avatars={[
                          {
                            firstName: f.owner.firstName,
                            lastName: f.owner.lastName,
                            avatarSrc: f.owner.avatarSrc,
                          },
                        ]}
                        width="w-6"
                        height="h-6"
                      />

                      <span>
                        {f.owner.firstName} {f.owner.lastName}
                      </span>
                      <CircleIcon />
                    </>
                  )}
                  <span>{format(f.createdAt, 'dd MMM yyyy')}</span>
                </div>
              </div>
            ))}
          </div>
        )}
        <TablePagination
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          totalPages={files?.indexFiles.pagination?.lastPage}
        />
      </div>
      <UploadModal
        open={showUploadModal}
        targetType={FileTargetType.customer}
        targetUuid={customerProfileUuid}
        onClose={(success) => {
          if (success) {
            client.graphqlClient().refetchQueries({
              include: [INDEX_FILES],
            });
          }
          setShowUploadModal(false);
        }}
        customerProfileUuid={customerProfileUuid}
      />
      <ExpandedFileViewer
        open={!!file}
        onClose={() => setFile(undefined)}
        file={file}
      />
      <SimpleModal
        text={`Are you sure you want to delete ${fileToDelete?.name}?`}
        title="Delete file"
        loading={deleteFileLoading}
        onConfirm={() => {
          if (!fileToDelete) return;
          deleteFile({
            variables: {
              input: {
                uuid: fileToDelete.uuid,
                auditTargetType: TargetType.user,
                auditTargetUuid: userUuid,
              },
            },
            refetchQueries: ['IndexFiles'],
            onCompleted: () => {
              notify.success('Deleted file');
              setFileToDelete(undefined);
            },
          });
        }}
        icon="warning"
        onConfirmText="Delete"
        open={!!fileToDelete}
        onClose={() => setFileToDelete(undefined)}
      />
    </>
  );
};
export default FileViewByCustomer;
