import { ReactElement, useState } from 'react';
import Table from '../../molecules/Table';
import { useQuery } from '@apollo/client';
import { gql, SchemeStatus, SchemeType } from '@monorepo/graphql';
import { RowsPerPage, TablePagination } from '../../molecules/Pagination';
import { notify } from '../../../utility/notify';
import {
  BuildingOffice2Icon,
  CalendarIcon,
  DocumentCheckIcon,
  PlusIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline';
import { CircleIcon } from '../../icons/Circle';
import { styleUtility } from '../../../utility/styleUtility';
import ToolBoxSmall from '../../../assets/icons/ToolBoxSmall.svg';
import { Button } from '../../atoms/Button';
import UpsertScheme from '../../molecules/Modals/UpsertScheme';

const IndexSchemes = gql(`
    query IndexSchemesForSchemesPage ($pagination: PaginationInput) {
      indexSchemes(pagination: $pagination) {
        items {
          uuid
          name
          partnerUuid
          startDate
          endDate 
          schemeType
          formattedStartDate
          formattedEndDate 
          status
          partnerName
        }
        pagination {
          lastPage
        }
      }
    }
`);

const Schemes = (): ReactElement => {
  const [perPage, setPerPage] = useState(RowsPerPage.twentyfive);
  const [page, setPage] = useState(1);

  const [showUpsertModal, setShowUpsertModal] = useState(false);

  const { data, loading } = useQuery(IndexSchemes, {
    variables: {
      pagination: {
        perPage,
        page,
      },
    },
    onError: (err) => {
      notify.error(`Unable to fetch Schemes \n ${err.message}`);
    },
  });

  return (
    <>
      <div className="flex flex-col overflow-hidden p-5 h-full">
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-h1-small font-bold font-nunito">Schemes</h1>
          <Button
            bText="Add"
            reverse
            onClick={() => setShowUpsertModal(true)}
            Icon={<PlusIcon className="size-6 text-white" />}
          />
        </div>
        <div className="h-full flex flex-col overflow-hidden rounded-lg">
          <Table
            loading={loading}
            columns={[
              {
                heading: 'Name',
                width: 18,
              },
              {
                heading: 'status',
                width: 13,
              },
              {
                heading: 'type',
                width: 13,
              },
              {
                heading: 'partner',
                width: 18,
              },
              {
                heading: 'start date',
                width: 13,
              },
              {
                heading: 'end date',
                width: 13,
              },
              {
                width: 12,
              },
            ]}
            widthType="pc"
            rows={
              data?.indexSchemes.items.map((i) => ({
                uuid: i.uuid,
                cells: [
                  {
                    content: (
                      <>
                        <RectangleStackIcon className="size-5 text-grey-400 mr-2" />
                        <span className="text-sm">{i.name}</span>
                      </>
                    ),
                    width: 18,
                  },
                  {
                    content: (
                      <>
                        <CircleIcon
                          multiplier={4}
                          className="mr-2"
                          colour={
                            i.status === SchemeStatus.current
                              ? styleUtility.colours.primary['DEFAULT']
                              : i.status === SchemeStatus.upcoming
                              ? styleUtility.colours.amber['DEFAULT']
                              : styleUtility.colours.red['DEFAULT']
                          }
                        />
                        <span className="text-sm">
                          {styleUtility.capitalise(i.status)}
                        </span>
                      </>
                    ),
                    width: 13,
                  },
                  {
                    content: (
                      <>
                        {i.schemeType === SchemeType.fullService ? (
                          <DocumentCheckIcon className="size-5 text-grey-400 mr-2" />
                        ) : (
                          <img
                            src={ToolBoxSmall}
                            alt="Toolbox"
                            className="mr-2"
                          />
                        )}
                        <span className="text-sm">
                          {i.schemeType === SchemeType.fullService
                            ? 'Full service'
                            : 'Installation'}
                        </span>
                      </>
                    ),
                    width: 13,
                  },
                  {
                    content: (
                      <>
                        <BuildingOffice2Icon className="size-5 text-grey-400 mr-2" />
                        <span className="text-sm">{i.partnerName}</span>
                      </>
                    ),
                    width: 18,
                  },
                  {
                    content: (
                      <>
                        <CalendarIcon className="size-5 text-grey-400 mr-2" />
                        <span className="text-sm">{i.formattedStartDate}</span>
                      </>
                    ),
                    width: 13,
                  },
                  {
                    content: (
                      <>
                        <CalendarIcon className="size-5 text-grey-400 mr-2" />
                        <span className="text-sm">{i.formattedEndDate}</span>
                      </>
                    ),
                    width: 13,
                  },
                  {
                    content: (
                      <div className="flex justify-end w-full">
                        <Button
                          href={`/schemes/${i.uuid}`}
                          bText="View"
                          bStyle="outline"
                          className="h-9"
                        />
                      </div>
                    ),
                    width: 12,
                  },
                ],
              })) ?? []
            }
          />
          <TablePagination
            rowsPerPage={perPage}
            setRowsPerPage={setPerPage}
            page={page}
            setPage={setPage}
            totalPages={data?.indexSchemes.pagination.lastPage}
          />
        </div>
      </div>
      <UpsertScheme
        open={showUpsertModal}
        onClose={() => {
          setShowUpsertModal(false);
        }}
      />
    </>
  );
};
export default Schemes;
