import { ReactElement, useState } from 'react';
import Table from '../../../../molecules/Table';
import { Button } from '../../../../atoms/Button';
import { RooferIcon } from '../../../../icons/Roofer';
import { styleUtility } from '../../../../../utility/styleUtility';
import {
  AcademicCapIcon,
  BoltIcon,
  BookOpenIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import SkillsModal from '../../../../molecules/Modals/ContractorProfileSkiilsModal';
import { SkillLevel, ContractorTrade, gql } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import { notify } from '../../../../../utility/notify';

const INDEX_SKILLS = gql(`
  query IndexSkillsForContractors ($uuid: String!) {
    indexSkillsForContractors (contractorProfileUuid: $uuid) {
      uuid
      name
      levelRequired
      trade
    }
  }  
`);

interface Props {
  contractorProfileUuid: string;
}

const SkillsTab = ({ contractorProfileUuid }: Props): ReactElement => {
  const [showModal, setShowModal] = useState(false);

  const { data, loading } = useQuery(INDEX_SKILLS, {
    variables: {
      uuid: contractorProfileUuid,
    },
    onError: (msg) => notify.error(msg.message),
  });

  return (
    <>
      <div className="rounded-b-lg overflow-hidden flex">
        <Table
          loading={loading}
          widthType="pc"
          columns={[
            {
              width: 30,
              heading: 'trade',
            },
            {
              width: 30,
              heading: 'skill',
            },
            {
              width: 30,
              heading: 'min level',
            },
            {
              width: 10,
            },
          ]}
          rows={
            data?.indexSkillsForContractors.map((s) => ({
              uuid: s.uuid,
              cells: [
                {
                  content:
                    s.trade === ContractorTrade.roofer ? (
                      <>
                        <RooferIcon
                          className="shrink-0"
                          colour={styleUtility.colours.grey['400']}
                        />
                        <span className="text-body-small block ml-2">
                          Roofer
                        </span>
                      </>
                    ) : (
                      <>
                        <BoltIcon className="size-5 text-grey-400" />
                        <span className="text-body-small block ml-2">
                          Electrician
                        </span>
                      </>
                    ),
                  width: 30,
                },
                {
                  content: s.name,
                  width: 30,
                },
                {
                  content:
                    s.levelRequired === SkillLevel.inTraining ? (
                      <>
                        <BookOpenIcon className="size-5 text-grey-400" />
                        <span className="text-body-small block ml-2">
                          In training
                        </span>
                      </>
                    ) : s.levelRequired === SkillLevel.partQualified ? (
                      <>
                        <AcademicCapIcon className="size-5 text-grey-400" />
                        <span className="text-body-small block ml-2">
                          Part qualified
                        </span>
                      </>
                    ) : (
                      <>
                        <StarIcon className="size-5 text-grey-400" />
                        <span className="text-body-small block ml-2">
                          Fully qualified
                        </span>
                      </>
                    ),
                  width: 40,
                },
              ],
            })) ?? []
          }
          title="Required skills"
          toolbar={
            <Button
              onClick={() => setShowModal(true)}
              bText="Edit skills"
              bStyle="outline"
            />
          }
        />
      </div>
      <SkillsModal
        skills={data?.indexSkillsForContractors ?? []}
        contractorProfileUuid={contractorProfileUuid}
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};
export default SkillsTab;
