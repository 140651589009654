import { ReactElement } from 'react';
import Tabs from '../../../molecules/Tabs';
import { gql } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import Loader from '../../../icons/Loader';
import UnassignedTasks from './Tabs/UnassignedTasks';
import AllTasks from './Tabs/AllTasks';
import NoAction from '../../../atoms/NoAction';

interface Props {
  myAssignment: boolean;
}

const TASK_WIDGET_COUNTS = gql(`
  query TaskWidgetCounts ($myAssignment: Boolean!) {
    allTaskCount (myAssignment: $myAssignment)
    unassignedTaskCount (myAssignment: $myAssignment)
  }
`);

const TasksWidget = ({ myAssignment }: Props): ReactElement => {
  const { data, loading } = useQuery(TASK_WIDGET_COUNTS, {
    variables: {
      myAssignment,
    },
  });

  return (
    <div className="bg-white rounded-lg flex flex-col overflow-hidden">
      <div className="p-5">
        <h2 className="text-h2 font-bold font-nunito">Tasks</h2>
      </div>
      {loading ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : !data?.allTaskCount && !data?.unassignedTaskCount ? (
        <NoAction />
      ) : (
        <Tabs
          ignoreSearch
          tabs={[
            ...(data?.allTaskCount
              ? [
                  {
                    name: (
                      <div className="p-3 flex flex-col items-center">
                        <h1 className="font-bold font-nunito text-display text-primary">
                          {data.allTaskCount}
                        </h1>
                        <p>Current Tasks</p>
                      </div>
                    ),
                    value: 'current',
                    component: <AllTasks myAssignment={myAssignment} />,
                  },
                ]
              : []),
            ...(data?.unassignedTaskCount
              ? [
                  {
                    name: (
                      <div className="p-3 flex flex-col items-center">
                        <h1 className="font-bold font-nunito text-display text-primary">
                          {data.unassignedTaskCount}
                        </h1>
                        <p>Unassigned Tasks</p>
                      </div>
                    ),
                    value: 'unassigned',
                    component: <UnassignedTasks myAssignment={myAssignment} />,
                  },
                ]
              : []),
          ]}
        />
      )}
    </div>
  );
};
export default TasksWidget;
