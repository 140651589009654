import { ReactElement, useEffect, useState } from 'react';
import { getFragmentData, gql, NotificationFragment } from '@monorepo/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { useAuth } from '../../../../utility/authentication';
import { RowsPerPage } from '../../Pagination';
import Loader from '../../../icons/Loader';
import { BellIcon } from '@heroicons/react/24/outline';
import { TagLight } from '../../../atoms/Tag';
import { format } from 'date-fns';
import { CircleIcon } from '../../../icons/Circle';
import { Button } from '../../../atoms/Button';
import Alert from '../../../atoms/Alerts';
import { notificationsUtiltiy } from '../../../../utility/notifications';
import { useRouter } from '@tanstack/react-router';
import { client } from '../../../../main';
import EmptyState from '../../EmptyState';
import { InView, useInView } from 'react-intersection-observer';

interface Props {
  type: 'current' | 'dismissed';
}

const INDEX_NOTIFICATIONS = gql(`
  query IndexNotificationsForNotificationsFeed ($filters: IndexNotificationsFilterInput, $pagination: PaginationInput) {
    indexNotifications (filters: $filters, pagination: $pagination) {
      items {
        ... Notification
      }
      pagination {
        lastPage
      }
    }
  }
`);

const VIEW_NOTIFICATION = gql(`
  mutation ViewNotificationForNotificationsFeed ($uuid: String!) {
    viewNotification (uuid: $uuid) {
      ... Notification
    }
  }
`);

const DISMISS_NOTIFICATION = gql(`
  mutation DismissNotificationForNotificationsFeed ($uuid: String!) {
    dismissNotification (uuid: $uuid) {
      ... Notification
    }
  }
`);

const DISMISS_ALL_NOTIFICATIONS = gql(`
  mutation DismissAllNotificationForNotificationsFeed {
    dismissAllNotifications
  }
`);

const Notifications = ({ type }: Props): ReactElement => {
  const [page, setPage] = useState(1);
  const { ref } = useInView();
  const { setUser } = useAuth();

  const { data, loading, error } = useQuery(INDEX_NOTIFICATIONS, {
    variables: {
      filters: {
        isDismissed: type === 'dismissed',
      },
      pagination: {
        perPage: RowsPerPage.twentyfive,
        page,
      },
    },
    onCompleted: (data) => {
      setNotifications((notifs) => [
        ...notifs,
        ...(data?.indexNotifications.items.map((n) =>
          getFragmentData(notificationsUtiltiy.queries.NOTIFICATION_FRAGMENT, n)
        ) ?? []),
      ]);
    },
  });

  useEffect(() => {
    setNotifications([]);
  }, [type]);

  const [notifications, setNotifications] = useState<NotificationFragment[]>(
    []
  );

  const [view] = useMutation(VIEW_NOTIFICATION);
  const [dismissAll] = useMutation(DISMISS_ALL_NOTIFICATIONS, {
    onCompleted: () => {
      client.graphqlClient().refetchQueries({
        include: [INDEX_NOTIFICATIONS],
      });
    },
  });
  const [dismiss] = useMutation(DISMISS_NOTIFICATION, {
    onCompleted: () => {
      client.graphqlClient().refetchQueries({
        include: [INDEX_NOTIFICATIONS],
      });
    },
  });

  const router = useRouter();

  return loading && !notifications.length ? (
    <div className="flex w-full h-full p-5 items-center justify-center">
      <Loader />
    </div>
  ) : (
    <div
      key={type}
      className="flex items-center flex-col overflow-hidden h-full"
    >
      <div className="flex flex-col h-full overflow-scroll w-full">
        {error && (
          <div className="p-5">
            <Alert text={error.message} alertType="error" />
          </div>
        )}
        {notifications.length ? (
          <>
            {notifications.map((n) => (
              <div
                key={n.uuid}
                className="border-b border-grey-700 last:border-none"
              >
                <div className="p-5">
                  <div className="flex items-center">
                    <BellIcon className="size-6 text-primary" />
                    <div className="flex-grow mx-2">
                      <h4 className="text-h4 font-bold font-nunito">
                        {n.title}
                      </h4>
                    </div>
                    {n.isViewed ? (
                      <TagLight colour={'purple'} text="Seen" />
                    ) : (
                      <TagLight text="New" colour="unassigned" />
                    )}
                  </div>
                  <p className="text-sm my-4">{n.description}</p>
                  <div className="flex items-center">
                    <div className="flex-grow flex items-center space-x-2">
                      <span className="text-text-low-priority text-xs">
                        {format(n.createdAt, 'do MMMM yyyy')}
                      </span>
                      <CircleIcon multiplier={1} />
                      <span className="text-text-low-priority text-xs">
                        {format(n.createdAt, 'H:mm')}
                      </span>
                    </div>
                    {type === 'current' && (
                      <Button
                        bStyle="clean"
                        bText="Dismiss"
                        className="mr-2"
                        onClick={() => {
                          setNotifications((notifs) =>
                            notifs.filter(({ uuid }) => uuid !== n.uuid)
                          );
                          if (!n.isViewed) {
                            setUser((user) => ({
                              ...user!,
                              unreadNotificationCount:
                                user!.unreadNotificationCount - 1,
                            }));
                          }
                          dismiss({
                            variables: {
                              uuid: n.uuid,
                            },
                          });
                        }}
                      />
                    )}
                    <Button
                      bText={`View ${n.targetType}`}
                      onClick={() => {
                        if (!n.isViewed) {
                          if (!n.isViewed) {
                            setUser((user) => ({
                              ...user!,
                              unreadNotificationCount:
                                user!.unreadNotificationCount - 1,
                            }));
                          }
                          view({
                            variables: {
                              uuid: n.uuid,
                            },
                          });
                        }
                        router.navigate({
                          to: notificationsUtiltiy.actionUrl({
                            targetUuid: n.targetUuid,
                            targetType: n.targetType,
                            targetUserUuid: n.targetUserUuid,
                          }),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
            {loading && (
              <div className="flex w-full justify-center my-10">
                <Loader />
              </div>
            )}
            <InView
              as="div"
              onChange={(inView) => {
                if (
                  inView &&
                  !loading &&
                  (data?.indexNotifications.pagination.lastPage ?? 0) > page
                ) {
                  setPage((pg) => pg + 1);
                }
              }}
            >
              <div className="h-1 w-full" ref={ref} />
            </InView>
          </>
        ) : (
          <EmptyState
            title="Notifications Clear 🎉"
            description="Nothing to see here"
          />
        )}
      </div>

      {notifications.length && type === 'current' && (
        <div className="p-5 w-full border-t border-grey-700">
          <Button
            onClick={() => {
              setNotifications([]);
              dismissAll();
              setUser((user) => ({
                ...user!,
                unreadNotificationCount: 0,
              }));
            }}
            bText="Dismiss all"
            bStyle="outline"
            className="w-full justify-center"
          />
        </div>
      )}
    </div>
  );
};
export default Notifications;
