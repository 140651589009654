import { ReactElement, useState } from 'react';
import Table from '../../../molecules/Table';
import { DropdownWithBorder } from '../../../atoms/Dropdown';
import { AllEnum } from '../../../../utility/calendarContext';
import { gql, JobDifficulty, JobStatus, TicketStatus } from '@monorepo/graphql';
import { TagLight } from '../../../atoms/Tag';
import { CalendarIcon, LifebuoyIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../atoms/Button';
import { RowsPerPage, TablePagination } from '../../../molecules/Pagination';
import AvatarStack from '../../../atoms/AvatarStack';
import { Link } from '@tanstack/react-router';
import { tasksUtility } from '../../../../utility/tasks';
import { useQuery } from '@apollo/client';
import { ticketsUtility } from '../../../../utility/ticketsUtility';
import { format, formatDate } from 'date-fns';

interface Props {
  userProfileUuid: string;
}

const ticketStatusOptions = [
  {
    name: 'All Statuses',
    value: AllEnum.all,
  },
  ...ticketsUtility.ticketStatusOptions,
];

const TICKET_QUERY = gql(`
  query IndexTicketsForUser($filters: IndexTicketsFilterInput, $pagination: PaginationInput) {
    indexTickets(filters: $filters, pagination: $pagination) {
      items {
        uuid
        title
        createdAt
        lastInteraction
        status
        customer {
          firstName
          lastName
        }
      }
      pagination {
        lastPage
      }
    }
  }
  `);

const Tickets = ({ userProfileUuid }: Props): ReactElement => {
  const [taskStatus, setTicketStatus] = useState<AllEnum | TicketStatus>(
    AllEnum.all
  );

  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { data, loading } = useQuery(TICKET_QUERY, {
    variables: {
      pagination: {
        perPage: rowsPerPage,
        page,
      },
      filters: {
        creatorUuid: userProfileUuid,
        status: taskStatus === AllEnum.all ? undefined : taskStatus,
      },
    },
    onCompleted: (data) => setTotalPages(data?.indexTickets.pagination.lastPage),
  });

  return (
    <>
      <Table
        title="Tickets"
        loading={loading}
        toolbar={
          <div className="flex justify-center space-x-2">
            <DropdownWithBorder<AllEnum | TicketStatus>
              buttonText={
                ticketStatusOptions.find(({ value }) => value === taskStatus)
                  ?.name
              }
              options={ticketStatusOptions}
              buttonClassname="!w-60 justify-between whitespace-nowrap"
              onOptionSelect={(opt) => setTicketStatus(opt.value)}
              respectButtonWidth
            />
          </div>
        }
        columns={[
          {
            width: 30,
            heading: 'title',
          },
          {
            width: 17,
            heading: 'customer',
          },
          {
            width: 17,
            heading: 'opened',
          },
          {
            width: 17,
            heading: 'last interaction',
          },
          {
            width: 10,
            heading: 'state',
          },
          {
            width: 13,
          },
        ]}
        widthType="pc"
        rows={
          data?.indexTickets.items.map((i) => ({
            uuid: i.uuid,
            cells: [
              {
                width: 30,
                content: (
                  <>
                    <LifebuoyIcon className="size-5" />
                    <span className="text-body-small block ml-2">
                      {i.title}
                    </span>
                  </>
                ),
              },
              {
                width: 17,
                content: (
                  <>
                    <AvatarStack
                      height="h-9"
                      width="w-9"
                      avatars={[
                        {
                          firstName: i.customer.firstName,
                          lastName: i.customer.lastName,
                        },
                      ]}
                    />
                    <Link to="/contacts/$uuid" params={{ uuid: '' }}>
                      <span className="underline text-body-small block ml-2">
                        {i.customer.firstName} {i.customer.lastName}
                      </span>
                    </Link>
                  </>
                ),
              },
              {
                width: 17,
                content: (
                  <>
                    <CalendarIcon className="size-5" />
                    <span className="text-body-small block ml-2">
                      {format(new Date(parseInt(i.createdAt)), 'do MMMM yyyy')}
                    </span>
                  </>
                ),
              },
              {
                width: 17,
                content: i.lastInteraction ? (
                  <>
                    <CalendarIcon className="size-5" />
                    <span className="text-body-small block ml-2">
                      {formatDate(new Date(i.lastInteraction), 'do MMMM yyyy')}
                    </span>
                  </>
                ) : '-',
              },
              {
                width: 10,
                content: <TagLight colour={i.status} text={ticketsUtility.ticketStatusNiceMap[i.status]} />,
              },
              {
                width: 13,
                content: (
                  <div className="flex justify-end w-full">
                    <Button
                      href={`/tickets/${i.uuid}`}
                      bText="View ticket"
                      bStyle="light"
                      className="h-9 text-button-small"
                    />
                  </div>
                ),
              },
            ],
          })) ?? []
        }
      />
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </>
  );
};
export default Tickets;
