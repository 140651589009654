import { ReactElement, useState } from 'react';
import { Button } from '../../../atoms/Button';
import { useRouter } from '@tanstack/react-router';
import { gql, IndexSkillsForConfigurationQuery } from '@monorepo/graphql';
import {
  ArrowLeftIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@apollo/client';
import { RowsPerPage, TablePagination } from '../../../molecules/Pagination';
import Table from '../../../molecules/Table';
import { notify } from '../../../../utility/notify';
import UpsertSkill from '../../../molecules/Modals/UpsertSkill';
import { client } from '../../../../main';
import SimpleModal from '../../../molecules/Modals/Simple';

const INDEX_SKILLS = gql(`
  query IndexSkillsForConfiguration ($pagination: PaginationInput) {
    indexSkills (pagination: $pagination) {
      items {
        uuid
        name 
        jobCount
        partnerCount
        contractorCount
      }
      pagination {
        lastPage
      }
    }
  }
`);

const DELETE_SKILL = gql(`
  mutation DeleteSkill ($uuid: String!) {
    deleteSkill (uuid: $uuid)
  }  
`);

const Skills = (): ReactElement => {
  const router = useRouter();

  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);

  const { data, loading } = useQuery(INDEX_SKILLS, {
    variables: {
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
    onError: (err) => notify.error(`Unable to fetch skills \n${err.message}`),
  });

  const [deleteSkill, { loading: deleteLoading }] = useMutation(DELETE_SKILL, {
    onError: (err) =>
      notify.error(`Unable to delete orgnisation \n ${err.message}`),
    onCompleted: () => notify.success('Successfully deleted skill.')
  });

  const [skill, setSkill] =
    useState<IndexSkillsForConfigurationQuery['indexSkills']['items'][0]>();

  const [showDeleteSkill, setShowDeleteSkill] = useState(false);
  const [showUpsertSkill, setShowUpsertSkill] = useState(false);

  return (
    <>
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col flex-grow p-5">
          <div className="flex items-center mb-5">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <h1 className="text-h1 font-nunito font-bold">Skills</h1>
            </div>
            <Button
              reverse
              Icon={<PlusIcon className="size-6 text-white" />}
              onClick={() => setShowUpsertSkill(true)}
              bText="Add"
            />
          </div>
          <Table
            loading={loading}
            widthType="pc"
            columns={[
              {
                heading: 'name',
                width: 30,
              },
              {
                heading: 'job count',
                width: 20,
              },
              {
                heading: 'partner count',
                width: 20,
              },
              {
                heading: 'contractor count',
                width: 20,
              },
              {
                width: 10,
              },
            ]}
            rows={
              data?.indexSkills.items.map((s) => ({
                uuid: s.uuid,
                cells: [
                  {
                    content: s.name,
                    width: 30,
                  },
                  {
                    content: s.jobCount,
                    width: 20,
                  },
                  {
                    content: s.partnerCount,
                    width: 20,
                  },
                  {
                    content: s.contractorCount,
                    width: 20,
                  },
                  {
                    content: (
                      <div className="flex space-x-2 justify-end w-full">
                        <Button
                          bStyle="light"
                          className="w-9 h-9 justify-center !p-0"
                          Icon={<TrashIcon className="text-red size-5" />}
                          onClick={() => {
                            setSkill(s);
                            setShowDeleteSkill(true);
                          }}
                        />
                        <Button
                          bStyle="light"
                          className="w-9 h-9 justify-center !p-0"
                          Icon={
                            <PencilSquareIcon
                              onClick={() => {
                                setSkill(s);
                                setShowUpsertSkill(true);
                              }}
                              className="size-5"
                            />
                          }
                        />
                      </div>
                    ),
                    width: 10,
                  },
                ],
              })) ?? []
            }
          />
          <TablePagination
            rowsPerPage={rowsPerPage}
            page={page}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            totalPages={data?.indexSkills.pagination.lastPage}
          />
        </div>
      </div>
      <UpsertSkill
        open={showUpsertSkill}
        onClose={(success) => {
          if (success) {
            client.graphqlClient().refetchQueries({
              include: [INDEX_SKILLS],
            });
          }
          setSkill(undefined);
          setShowUpsertSkill(false);
        }}
        skill={
          skill
            ? {
                name: skill.name,
                uuid: skill.uuid,
              }
            : undefined
        }
      />
      <SimpleModal
        loading={deleteLoading}
        text="Are you sure you want to delete this organisation?"
        title="Delete Organisation"
        onConfirm={async () => {
          if (skill) {
            await deleteSkill({
              variables: {
                uuid: skill.uuid,
              },
            });
          }
          setSkill(undefined);
          setShowDeleteSkill(false);
          client.graphqlClient().refetchQueries({
            include: [INDEX_SKILLS],
          });
        }}
        onConfirmText="Delete"
        icon="critical"
        open={showDeleteSkill}
        onClose={() => {
          setShowDeleteSkill(false);
          setSkill(undefined);
        }}
      />
    </>
  );
};
export default Skills;
