import { ReactElement, useState } from 'react';
import AvatarStack from '../../atoms/AvatarStack';
import {
  CalendarIcon,
  DocumentTextIcon,
  EllipsisVerticalIcon,
  PhotoIcon,
} from '@heroicons/react/24/outline';
import { CircleIcon } from '../../icons/Circle';
import { Button } from '../../atoms/Button';
import {
  CommentFragment,
  CommentTargetType,
  getFragmentData,
  gql,
  UserRole,
} from '@monorepo/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import ExpandedFileViewer from '../Modals/ExpandedFileViewer';
import { Link } from '@tanstack/react-router';
import Dropdown from '../../atoms/Dropdown';
import Upsert from './Upsert';
import { commentsUtility } from '../../../utility/comments';
import SimpleModal from '../Modals/Simple';
import { notify } from '../../../utility/notify';
import { useAuth } from '../../../utility/authentication';
import { profileUtility } from '../../../utility/profile';
import AddTask from '../Modals/AddTask';
import { LocalFile } from '../../../utility/files';
import Pagination, { RowsPerPage } from '../Pagination';
import Loader from '../../icons/Loader';
import Alert from '../../atoms/Alerts';
import FileArray from '../../atoms/FileArray';

interface Props {
  targetType: CommentTargetType;
  targetUuid: string;
  title?: string;
  customerProfileUuid?: string;
  jobUuid?: string;
  hideRaiseTask?: boolean;
}

const INDEX_COMMENTS = gql(`
    query IndexComments ($filter: IndexCommentsFilter!, $pagination: PaginationInput) {
      indexComments(filter: $filter, pagination: $pagination) {
        items {
          ...Comment
        }
        pagination {
          lastPage
        }
      }
    }
`);

const DELETE_COMMENT = gql(`
  mutation DeleteComment ($uuid: String!) {
    deleteComment(uuid: $uuid)
  }
`);

const CommentThread = ({
  title = 'Comments',
  targetType,
  targetUuid,
  customerProfileUuid,
  jobUuid,
  hideRaiseTask
}: Props): ReactElement => {
  const [commentToDeleteUuid, setCommentToDeleteUuid] = useState<string>();
  const { user } = useAuth();
  profileUtility.assertProfile(user, 'UserUserProfile');

  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.ten);
  const [page, setPage] = useState(1);

  const [task, setTask] = useState<{
    description: string;
  }>();

  const { data, error } = useQuery(INDEX_COMMENTS, {
    variables: {
      filter: {
        targetType,
        targetUuid,
      },
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
  });

  const [deleteComment, { loading: deleteCommentLoading }] =
    useMutation(DELETE_COMMENT);

  const [editingUuid, setEditingUuid] = useState<string>();

  return (
    <div className="bg-white h-full">
      <div className="w-170 m-auto p-5 flex h-full flex-col overflow-hidden">
        <h2 className="text-h2 font-bold font-nunito">{title}</h2>
        <div className="mt-5 flex-grow flex flex-col overflow-hidden justify-between space-y-5">
          <div className="flex flex-col overflow-scroll space-y-5">
            {data ? (
              <>
                {data.indexComments.items
                  .map((c) =>
                    getFragmentData(commentsUtility.queries.COMMENT_FRAGMENT, c)
                  )
                  .map((c) => (
                    <div className="p-5 border rounded border-grey-700 flex flex-col">
                      <div className="flex items-center mb-3 space-x-3">
                        <AvatarStack
                          avatars={[
                            {
                              firstName: c.user.firstName,
                              lastName: c.user.lastName,
                              avatarSrc: c.user.avatarSrc,
                            },
                          ]}
                          height="h-12"
                          width="w-12"
                        />
                        <div>
                          <h4 className="text-h4 font-nunito font-semibold mb-2">
                            {c.user.firstName} {c.user.lastName}
                          </h4>
                          <div className="flex items-center">
                            <CalendarIcon className="size-5 text-grey-400 mr-1.5" />
                            <div className="flex items-center text-body-small space-x-2">
                              <span>{format(c.createdAt, 'MMMM, yyyy')}</span>
                              <CircleIcon multiplier={2} />
                              <span>{format(c.createdAt, 'H:m')}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {editingUuid === c.uuid ? (
                        <Upsert
                          customerProfileUuid={customerProfileUuid}
                          jobUuid={jobUuid}
                          targetType={targetType}
                          targetUuid={targetUuid}
                          comment={{
                            ...c,
                            files: c.files ?? [],
                          }}
                          onSaveCallback={() => setEditingUuid(undefined)}
                        />
                      ) : (
                        <>
                          <div className="mb-3">
                            <span className="text-body-small">{c.text}</span>
                          </div>
                          {c.files && <FileArray files={c.files} />}
                          <div className="flex space-between space-x-2.5">
                            <div className="flex items-center text-sm space-x-1 flex-grow">
                              {c.job && (
                                <>
                                  <span>Related job:</span>
                                  <Link
                                    to="/job/$uuid"
                                    params={{ uuid: c.job.uuid }}
                                    className="font-semibold text-primary underline"
                                  >
                                    {c.job.displayLink}
                                  </Link>
                                </>
                              )}
                            </div>
                            {!hideRaiseTask && <Button
                              bText="Raise task"
                              className="h-9 text-sm"
                              bStyle="outline"
                              onClick={() =>
                                setTask({
                                  description: c.text,
                                })
                              }
                            />}
                            {(c.user.uuid === user.uuid ||
                              user.profile.role ===
                                UserRole.superAdministrator) && (
                              <Dropdown<'edit' | 'delete'>
                                buttonClassname="bg-white h-9 w-9 items-center justify-center flex rounded border border-grey-500"
                                ButtonIcon={
                                  <EllipsisVerticalIcon className="size-6" />
                                }
                                onOptionSelect={(opt) => {
                                  if (opt.value === 'edit') {
                                    setEditingUuid(c.uuid);
                                  } else if (opt.value === 'delete') {
                                    setCommentToDeleteUuid(c.uuid);
                                  }
                                }}
                                options={[
                                  // {
                                  //   value: 'Share',
                                  //   name: 'Share',
                                  // },
                                  {
                                    value: 'edit',
                                    name: 'Edit',
                                  },
                                  {
                                    value: 'delete',
                                    name: 'Delete',
                                    itemClassname: 'text-red',
                                  },
                                ]}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </>
            ) : (
              <div className="flex items-center justify-center">
                {error ? (
                  <Alert alertType="error" text={error.message} />
                ) : (
                  <Loader />
                )}
              </div>
            )}
          </div>
          <Pagination
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            totalPages={data?.indexComments.pagination.lastPage}
            removePadding
          />
        </div>
        <Upsert
          customerProfileUuid={customerProfileUuid}
          jobUuid={jobUuid}
          targetType={targetType}
          targetUuid={targetUuid}
        />
      </div>
      <AddTask
        open={!!task}
        description={task?.description}
        onClose={() => setTask(undefined)}
        customerProfileUuid={customerProfileUuid}
        jobUuid={jobUuid}
      />
      {commentToDeleteUuid && (
        <SimpleModal
          text="Are you sure you want to delete this comment?"
          title="Delete comment"
          loading={deleteCommentLoading}
          onConfirm={() => {
            deleteComment({
              variables: {
                uuid: commentToDeleteUuid,
              },
              onCompleted: () => {
                notify.success('Deleted comment!');
                setCommentToDeleteUuid(undefined);
              },
              refetchQueries: ['IndexComments'],
            }).catch((e) => {
              notify.error(e);
            });
          }}
          icon="warning"
          onConfirmText="Delete"
          open={!!commentToDeleteUuid}
          onClose={() => setCommentToDeleteUuid(undefined)}
        />
      )}
    </div>
  );
};
export default CommentThread;
