import { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  gql,
  IndexTasksForTasksQuery,
  TaskStatus,
  UserProfile,
} from '@monorepo/graphql';
import Table from '../../../molecules/Table';
import { CalendarIcon, ClipboardIcon } from '@heroicons/react/24/outline';
import { TagLight } from '../../../atoms/Tag';
import { tasksUtility } from '../../../../utility/tasks';
import UserDropdown from '../../../molecules/UserDropown';
import { useMutation, useQuery } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import { Button } from '../../../atoms/Button';
import { format } from 'date-fns';
import { RowsPerPage, TablePagination } from '../../../molecules/Pagination';
import { DropdownWithBorder, Option } from '../../../atoms/Dropdown';
import { AllEnum } from '../../../../utility/calendarContext';

interface Props {
  type: 'open' | 'closed';
}

const SAVE_TASK = gql(`
  mutation UpsertTask ($input: TaskUpsertInput!) {
    upsertTask(input: $input) {
      uuid
    }
  }  
`);

const INDEX_TASKS = gql(`
  query IndexTasksForTasks ($filters: IndexTasksFilterInput, $pagination: PaginationInput) {
    indexTasks (filters: $filters, pagination: $pagination) {
      items {
        uuid
        title
        assignee {
          uuid
          firstName
          lastName
        }
        createdAt
        dueDate
        status
      }
      pagination {
        lastPage
        total
      }
    }
  }
`);

const INDEX_USERS = gql(`
  query IndexUsersForTasksFilters ($filters: IndexUsersFilterInput, $pagination: PaginationInput) {
    indexUsers(filters: $filters, pagination: $pagination) {
      items {
        uuid
        firstName
        lastName
        profile {
          ... on UserUserProfile {
            uuid
          }
        }
      }
    }
  }
`);

const baseOptions = [
  {
    value: AllEnum.all,
    name: 'All operators',
  },
];

const TasksTable = ({ type }: Props): ReactElement => {
  const [taskStatus, setTaskStatus] = useState<TaskStatus[]>([]);

  const [assignedOperators, setAssignedOperators] = useState<Option<string>[]>([
    ...baseOptions,
  ]);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);

  const [term, setTerm] = useState<string>();

  const { loading, data } = useQuery(INDEX_TASKS, {
    variables: {
      filters: {
        status: taskStatus,
        assignedOperators:
          assignedOperators.length === 1 &&
          assignedOperators[0].value === AllEnum.all
            ? undefined
            : assignedOperators.map(({ value }) => value),
      },
      pagination: {
        page,
        perPage: rowsPerPage,
      },
    },
    skip: taskStatus.length === 0,
    notifyOnNetworkStatusChange: true,
  });

  const { data: userData } = useQuery(INDEX_USERS, {
    variables: {
      filters: {
        term,
        userProfile: UserProfile.user,
      },
      pagination: {
        perPage: 10,
        page: 1,
      },
    },
  });

  const contractorOptions = useMemo(() => {
    return [
      ...baseOptions,
      ...(userData?.indexUsers.items.map(
        ({ firstName, lastName, profile }) => ({
          name: `${firstName} ${lastName}`,
          // lazy coding
          value: (profile as { uuid: string }).uuid,
        })
      ) ?? []),
    ];
  }, [userData]);

  useEffect(() => {
    if (type === 'open') {
      setTaskStatus([
        TaskStatus.inProgress,
        TaskStatus.unassigned,
        TaskStatus.todo,
      ]);
    } else {
      setTaskStatus([TaskStatus.done]);
    }
  }, [type]);

  const [tasks, setTasks] = useState<
    IndexTasksForTasksQuery['indexTasks']['items']
  >(data?.indexTasks.items ?? []);

  const [saveTask] = useMutation(SAVE_TASK, {
    onError: (err) => notify.error(`Unable to save task ${err.message}`),
    onCompleted: () => notify.success(`Successfully saved task`),
  });

  useEffect(() => {
    setTasks(data?.indexTasks.items ?? []);
  }, [data]);

  return (
    <>
      <Table
        loading={loading}
        title={`${type === 'open' ? 'Open tasks' : 'Closed tasks'} (${
          data?.indexTasks.pagination.total ?? 0
        })`}
        toolbar={
          <>
            {type === 'open' && (
              <DropdownWithBorder
                options={tasksUtility.taskStatusOptions.filter(
                  ({ value }) => value !== TaskStatus.done
                )}
                selected={tasksUtility.taskStatusOptions.filter(({ value }) =>
                  taskStatus.includes(value)
                )}
                buttonClassname="w-60 justify-between"
                buttonText={
                  taskStatus.length === 0
                    ? '-- Select --'
                    : `${tasksUtility.taskStatusNiceMap[taskStatus[0]]} ${
                        taskStatus.length > 1
                          ? `+ ${taskStatus.length - 1} more`
                          : ''
                      }`
                }
                onOptionSelect={(opt) => {
                  if (taskStatus.includes(opt.value)) {
                    setTaskStatus((ts) => ts.filter((v) => v !== opt.value));
                  } else {
                    setTaskStatus((ts) => [...ts, opt.value]);
                  }
                }}
              />
            )}
            <div className="w-60 ml-2">
              <DropdownWithBorder
                selected={assignedOperators}
                options={contractorOptions}
                onOptionSelect={(opt) => {
                  if (opt.value === AllEnum.all) {
                    setAssignedOperators([opt]);
                  } else {
                    if (assignedOperators.includes(opt)) {
                      setAssignedOperators((ao) =>
                        ao.filter(
                          ({ value }) =>
                            value !== opt.value && value !== AllEnum.all
                        )
                      );
                    } else {
                      setAssignedOperators((ao) =>
                        [...ao, opt].filter((v) => v.value !== AllEnum.all)
                      );
                    }
                  }
                }}
                buttonClassname="justify-between w-60"
                bubble
                handlesSearch
                searchFunction={async (str) => {
                  if (str.length) {
                    setTerm(str);
                  } else {
                    setTerm(undefined);
                  }
                }}
                buttonText={
                  assignedOperators.length > 1
                    ? `Operators (${assignedOperators.length})`
                    : assignedOperators[0].name
                }
              />
            </div>
          </>
        }
        columns={[
          {
            heading: 'name',
            width: 28,
          },
          {
            heading: 'assigned operator',
            width: 18,
          },
          {
            heading: 'raised',
            width: 18,
          },
          {
            heading: 'due',
            width: 18,
          },
          {
            heading: 'status',
            width: 10,
          },
          {
            width: 10,
          },
        ]}
        rows={tasks.map((t) => ({
          uuid: t.uuid,
          cells: [
            {
              content: (
                <>
                  <ClipboardIcon className="size-5 mr-2 text-grey-400" />
                  <span className="text-sm">{t.title}</span>
                </>
              ),
              width: 28,
            },
            {
              content: (
                <div>
                  <UserDropdown
                    simple
                    disabled={t.status === TaskStatus.done}
                    userUuid={t.assignee?.uuid}
                    setUserUuid={(_, profileUuid) =>
                      setTasks((ts) =>
                        ts.map((task) => {
                          if (t.uuid === task.uuid) {
                            saveTask({
                              variables: {
                                input: {
                                  uuid: task.uuid,
                                  title: task.title,
                                  status: TaskStatus.todo,
                                  operatorUuid: profileUuid,
                                },
                              },
                            });
                            return {
                              ...task,
                              status: TaskStatus.todo,
                              assignee: {
                                firstName: '',
                                lastName: '',
                                uuid: profileUuid,
                              },
                            };
                          }
                          return task;
                        })
                      )
                    }
                  />
                </div>
              ),
              width: 18,
            },
            {
              content: (
                <>
                  <CalendarIcon className="size-5 mr-2 text-grey-400" />
                  <span className="text-sm">
                    {format(t.createdAt, 'do MMMM yyyy')}
                  </span>
                </>
              ),
              width: 18,
            },
            {
              content: (
                <>
                  <CalendarIcon className="size-5 mr-2 text-grey-400" />
                  <span className="text-sm">
                    {t.dueDate ? format(t.dueDate, 'do MMMM yyyy') : '-'}
                  </span>
                </>
              ),
              width: 18,
            },
            {
              content: (
                <TagLight
                  colour={t.status}
                  text={tasksUtility.taskStatusNiceMap[t.status]}
                />
              ),
              width: 10,
            },
            {
              width: 10,
              content: (
                <div className="flex justify-end w-full">
                  <Button
                    href={`/tasks/${t.uuid}`}
                    bText="View"
                    bStyle="outline"
                    className="h-9"
                  />
                </div>
              ),
            },
          ],
        }))}
        widthType="pc"
      />
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={data?.indexTasks.pagination.lastPage}
      />
    </>
  );
};
export default TasksTable;
