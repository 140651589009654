import { ReactElement, useState } from 'react';
import { Button } from '../../../../atoms/Button';
import {
  CalendarIcon,
  ClipboardIcon,
  PlusIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { TagLight } from '../../../../atoms/Tag';
import UserDropdown from '../../../../molecules/UserDropown';
import { gql, IndexTasksQuery, TaskStatus } from '@monorepo/graphql';
import { useMutation, useQuery } from '@apollo/client';
import Pagination, { RowsPerPage } from '../../../../molecules/Pagination';
import Alert from '../../../../atoms/Alerts';
import Loader from '../../../../icons/Loader';
import { format } from 'date-fns';
import { tasksUtility } from '../../../../../utility/tasks';
import { notify } from '../../../../../utility/notify';
import FileArray from '../../../../atoms/FileArray';
import AddTask from '../../../../molecules/Modals/AddTask';
import EmptyState from '../../../../molecules/EmptyState';
import { client } from '../../../../../main';
import Robot from '../../../../icons/Robot';
import { styleUtility } from '../../../../../utility/styleUtility';

const IndexTasksForCustomer = gql(`
    query IndexTasks ($filters: IndexTasksFilterInput, $pagination: PaginationInput) {
      indexTasks (filters: $filters, pagination: $pagination) {
        items {
          uuid
          title
          description
          dueDate
          status
          creator {
            uuid
            firstName
            lastName
          }
          assignee {
            uuid
            firstName
            lastName
          }
          files {
            uuid
            src
            type
            name
          }
        }
        pagination {
          lastPage
        }
      }
    }
`);

interface Props {
  customerProfileUuid: string;
  partnerUuid: string;
}

const UpdateTasksOnJob = gql(`
  mutation UpdateTaskOnJob ($input: TaskUpsertInput!) {
    upsertTask(input: $input) {
      uuid
    }
  }  
`);

const TasksTab = ({
  customerProfileUuid,
  partnerUuid,
}: Props): ReactElement => {
  const [perPage, setPerPage] = useState(RowsPerPage.ten);
  const [page, setPage] = useState(1);

  const [showAddTaskModal, setShowAddTaskModal] = useState(false);

  const { data, error, loading } = useQuery(IndexTasksForCustomer, {
    variables: {
      filters: {
        customerProfileUuid,
      },
      pagination: {
        perPage,
        page,
      },
    },
  });

  const [taskUpdating, setTaskUpdating] = useState<string>();

  const [saveTask, { loading: saving }] = useMutation(UpdateTasksOnJob, {
    onError: (err) => notify.error(`Unable to save task ${err.message}`),
    onCompleted: () => notify.success(`Successfully saved task`),
  });

  return (
    <>
      <div className="bg-white p-5 flex h-full flex-col rounded-b-lg overflow-hidden">
        <div className="flex space-x-3 mb-5 items-center justify-between">
          <h2 className="text-h2 font-bold font-nunito">Tasks</h2>
          <Button
            bText="Add"
            bStyle="outline"
            reverse
            onClick={() => setShowAddTaskModal(true)}
            Icon={<PlusIcon className="text-primary size-6" />}
          />
        </div>
        <div className="flex items-center flex-col overflow-scroll">
          <div className="w-170 space-y-5">
            {data ? (
              <>
                {data.indexTasks.items.map((t) => (
                  <div className="border border-grey-700 rounded-md p-5 m-auto max-w-170">
                    <div className="flex items-center">
                      <div className="rounded-full h-12 w-12 bg-primary/10 flex items-center justify-center mr-3">
                        <ClipboardIcon className="size-6 text-primary" />
                      </div>
                      <div className="w-full">
                        <div className="flex items-center justify-between mb-2">
                          <h4 className="text-h4 font-nunito font-semibold">
                            {t.title}
                          </h4>
                          <TagLight
                            colour={t.status}
                            text={tasksUtility.taskStatusNiceMap[t.status]}
                          />
                        </div>
                        <div className="flex items-center">
                          {t.creator ? (
                            <>
                              <UserIcon className="size-4.5 text-grey-400 mr-1.5" />
                              <div className="flex items-center text-body-small space-x-2 mr-5">
                                <span>
                                  Created by: {t.creator.firstName}{' '}
                                  {t.creator.lastName}
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <Robot
                                multiplier={0.8}
                                colour={styleUtility.colours.grey['400']}
                              />
                              <div className="flex items-center text-body-small space-x-2 mr-5">
                                <span>Created by: System</span>
                              </div>
                            </>
                          )}
                          {t.dueDate && (
                            <div className="flex items-center text-body-small space-x-2">
                              <span>
                                Due: {format(t.dueDate, 'd, MMMM yyyy')}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="py-3">
                      <span className="text-body-small">{t.description}</span>
                    </div>
                    {t.files && <FileArray files={t.files} />}
                    <div className="flex items-center justify-between w-full mt-5">
                      <div className="flex items-center flex-grow">
                        <span className="block mr-3 text-text-low-priority text-body-small">
                          Assigned to:
                        </span>
                        <div className="w-fit">
                          <UserDropdown
                            simple
                            disabled={
                              t.status === TaskStatus.done ||
                              taskUpdating === t.uuid
                            }
                            partnerUuid={partnerUuid}
                            userUuid={t.assignee?.uuid}
                            setUserUuid={(_, profileUuid) => {
                              setTaskUpdating(t.uuid);
                              saveTask({
                                variables: {
                                  input: {
                                    uuid: t.uuid,
                                    title: t.title,
                                    status: t.status,
                                    operatorUuid: profileUuid,
                                  },
                                },
                                onCompleted: () => {
                                  client.graphqlClient().refetchQueries({
                                    include: [IndexTasksForCustomer],
                                  });
                                  setTaskUpdating(undefined);
                                  notify.success('Saved task');
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <Button
                        bText="View task"
                        bStyle="light"
                        bSize="sm"
                        className="h-9"
                      />
                    </div>
                  </div>
                ))}
                <Pagination
                  removePadding
                  rowsPerPage={perPage}
                  setRowsPerPage={setPerPage}
                  page={page}
                  setPage={setPage}
                  totalPages={data?.indexTasks.pagination.lastPage}
                />
              </>
            ) : loading ? (
              <div className="flex items-center justify-center">
                <Loader />
              </div>
            ) : (
              <EmptyState
                title="No tasks assigned"
                description="Currently there are no tasks assigned to this job."
              />
            )}
            <div className="flex items-center justify-center">
              {error && <Alert alertType="error" text={error.message} />}
            </div>
          </div>
        </div>
      </div>
      <AddTask
        open={showAddTaskModal}
        customerProfileUuid={customerProfileUuid}
        partnerUuid={partnerUuid}
        onClose={(success) => {
          setShowAddTaskModal(false);
          if (success) {
            client.graphqlClient().refetchQueries({
              include: [IndexTasksForCustomer],
            });
          }
        }}
      />
    </>
  );
};
export default TasksTab;
