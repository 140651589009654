import { ReactElement } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import SkillsTable, { SkillRow } from '../../SkillsTable';
import { gql } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';

type Props = {
  skills: SkillRow[];
  contractorProfileUuid: string;
} & ModalWrapperPropsExtends;

const UPDATE_CONTRACTOR_PROFILE_SKILLS = gql(`
  mutation UpdateUserContractorProfileSkills ($input: UpdateUserContractorProfileInput!) {
    updateUserContractorProfile (input: $input) {
      uuid
    }
  }
`);

const ContractorProfileSkiilsModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper dialogPanelClassname="min-w-140" open={open} onClose={onClose}>
    <ContractorProfileSkiilsModalChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const ContractorProfileSkiilsModalChild = ({
  onClose,
  skills,
  contractorProfileUuid,
}: Omit<Props, 'open'>): ReactElement => {
  const [update, { loading, error }] = useMutation(
    UPDATE_CONTRACTOR_PROFILE_SKILLS,
    {
      onCompleted: () => {
        notify.success('Succesfully saved skills.');
        onClose(true);
      },
      refetchQueries: ['IndexSkillsForContractors'],
    }
  );

  const form = useForm({
    defaultValues: {
      skills,
    },
    onSubmit: ({ value }) => {
      update({
        variables: {
          input: {
            uuid: contractorProfileUuid,
            skills: value.skills.map((sk) => ({
              skillUuid: sk.uuid,
              level: sk.levelRequired,
              trade: sk.trade,
            })),
          },
        },
      });
    },
  });

  const skillValue = form.useField({
    name: 'skills',
  });

  return (
    <Modal
      loading={loading}
      title="Contactor's skills"
      onClose={onClose}
      confirmText="Save changes"
      confirmCallback={() => {
        form.handleSubmit();
      }}
      hideTopHr={skillValue.getValue().length > 0}
    >
      <div className={skillValue.getValue().length ? 'px-5' : 'p-5'}>
        <SkillsTable wrapped form={form} />
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
export default ContractorProfileSkiilsModal;
