import { ReactElement, useState } from 'react';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import {
  ArrowRightStartOnRectangleIcon,
  BellIcon,
  Cog6ToothIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Link } from '@tanstack/react-router';
import { useAuth } from '../../../utility/authentication';
import Drawer from '../Drawer';
import Tabs from '../Tabs';
import Notifications from './Notifications';
import NotificationSettings from './NotificationSettings';

const NavigationProfile = (): ReactElement => {
  const { logout, user } = useAuth();

  const unreadNotificationCount = user!.unreadNotificationCount;
  console.log(user)
  const [showNotifications, setShowNotifications] = useState(false);

  return (
    <>
      <nav>
        <ul className="flex items-center space-x-5">
          <li className="relative">
            {!!unreadNotificationCount && (
              <div
                className={`bg-red absolute flex items-center justify-center px-2 py-1 rounded-full right-full transform ${
                  unreadNotificationCount > 99
                    ? 'translate-x-1/2'
                    : 'translate-x-5'
                }`}
              >
                <span className="text-xs leading-none text-white font-semibold">
                  {unreadNotificationCount > 99
                    ? `99+`
                    : unreadNotificationCount}
                </span>
              </div>
            )}
            <button
              type="button"
              onClick={() => setShowNotifications(true)}
              className="flex items-center space-x-2 font-semibold py-2.5 px-3 hover:bg-grey-900/70 rounded"
            >
              <BellIcon className="size-6 text-primary" />
              <span>Notifications</span>
            </button>
          </li>
          <li>
            <Menu>
              <MenuButton className="flex items-center h-full space-x-2 font-semibold py-2.5 px-3 hover:bg-grey-900/70 rounded">
                <UserCircleIcon className="size-6 text-primary" />
                <span>Profile</span>
                <img src={ExpandMoreDark} alt="Expand menu" />
              </MenuButton>
              <MenuItems
                anchor="bottom start"
                transition
                className="bg-white shadow-regular rounded overflow-hidden origin-top transition duration-100 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
              >
                <ul className="space-y-4 p-2">
                  <MenuItem as="li">
                    {({ close }) => (
                      <Link
                        to="/contacts/$uuid"
                        params={{
                          uuid: user!.uuid,
                        }}
                        className={`cursor-pointer hover:bg-grey-900/70 rounded flex text-body flex-row items-center w-full leading-none px-3 py-2.5`}
                        onClick={() => close()}
                      >
                        <UserCircleIcon className="size-5 text-text-normal mr-2" />
                        <span className="text-body">My Profile</span>
                      </Link>
                    )}
                  </MenuItem>
                  <MenuItem as="li">
                    <button
                      className={`cursor-pointer hover:bg-grey-900/70 rounded flex text-body flex-row items-center w-full leading-none px-3 py-2.5`}
                      onClick={() => logout()}
                    >
                      <ArrowRightStartOnRectangleIcon className="size-5 text-text-normal mr-2" />
                      <span className="text-body">Logout</span>
                    </button>
                  </MenuItem>
                </ul>
              </MenuItems>
            </Menu>
          </li>
        </ul>
      </nav>
      <Drawer
        show={showNotifications}
        setShow={setShowNotifications}
        title="Notifications"
        hidePadding
      >
        <div className="w-140 overflow-hidden flex h-full flex-col">
          <Tabs
            ignoreSearch
            padding={20}
            tabs={[
              {
                name: 'Current',
                value: 'current',
                component: <Notifications type="current" />,
              },
              {
                name: 'Dismissed',
                value: 'dismissed',
                grow: true,
                component: <Notifications type="dismissed" />,
              },
              {
                name: (
                  <div className="flex items-center group-data-[status=active]:text-primary">
                    <Cog6ToothIcon className="size-6 mr-2" />
                    <span>Settings</span>
                  </div>
                ),
                value: 'settings',
                component: <NotificationSettings />,
              },
            ]}
          />
        </div>
      </Drawer>
    </>
  );
};
export default NavigationProfile;
