import {
  ContractorCompletionRate,
  SkillLevel,
  ContractorTrade,
} from '@monorepo/graphql';
import { AllEnum } from './calendarContext';
import { Option } from '../components/atoms/Dropdown';

const skillLevelNiceMap: Record<SkillLevel, string> = {
  [SkillLevel.fullyQualified]: 'Fully Qualified',
  [SkillLevel.inTraining]: 'In Training',
  [SkillLevel.partQualified]: 'Part Qualified',
};
const skillTradeNiceMap: Record<ContractorTrade, string> = {
  [ContractorTrade.electrician]: 'Electrician',
  [ContractorTrade.roofer]: 'Roofer',
};
const completionRateNiceMap: Record<ContractorCompletionRate, string> = {
  [ContractorCompletionRate.aboveEighty]: 'Above 80%',
  [ContractorCompletionRate.twentyToEighty]: 'Between 20-80%',
  [ContractorCompletionRate.belowTwenty]: 'Less than 20%',
};

const completionRateOptions: Option<ContractorCompletionRate | AllEnum>[] = [
  {
    value: AllEnum.all,
    name: 'All completion rates',
  },
  {
    value: ContractorCompletionRate.aboveEighty,
    name: completionRateNiceMap[ContractorCompletionRate.aboveEighty],
  },
  {
    value: ContractorCompletionRate.twentyToEighty,
    name: completionRateNiceMap[ContractorCompletionRate.twentyToEighty],
  },
  {
    value: ContractorCompletionRate.belowTwenty,
    name: completionRateNiceMap[ContractorCompletionRate.belowTwenty],
  },
];

const tradesOptions: Option<ContractorTrade | AllEnum>[] = [
  {
    value: AllEnum.all,
    name: 'All trades',
  },
  {
    value: ContractorTrade.electrician,
    name: skillTradeNiceMap[ContractorTrade.electrician],
  },
  {
    value: ContractorTrade.roofer,
    name: skillTradeNiceMap[ContractorTrade.roofer],
  },
];

const defaultFilters = {
  completion: completionRateOptions[0],
  trade: [tradesOptions[0]],
};

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const getAvailability = (availability: string) => {
  if (availability.length === 1) {
    return days[availability.length - 1];
  }
  if (availability.length <= 3) {
    return availability
      .split('')
      .map((d) => days[parseInt(d) - 1])
      .join(', ');
  }
  if (availability === '12345') return 'Monday to Friday (5 days)';
  return `${availability.length} days`;
};

export const contractorUtility = {
  skillTradeNiceMap,
  skillLevelNiceMap,
  tradesOptions,
  completionRateOptions,
  defaultFilters,
  getAvailability,
};
