import { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { useForm } from '@tanstack/react-form';
import RadioSelector from '../../../atoms/RadioSelector';
import { AuditType, gql, JobDifficulty, UserRole } from '@monorepo/graphql';
import { Input } from '../../../atoms/Input';
import SkillsTable, { SkillRow } from '../../SkillsTable';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import Alert from '../../../atoms/Alerts';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import { useJobContext } from '../../../organisms/Job';
import { useAuth } from '../../../../utility/authentication';
import { profileUtility } from '../../../../utility/profile';

const issueOptions = [
  {
    name: 'Yes (pass audit)',
    value: true,
  },
  {
    name: 'No (fail audit)',
    value: false,
  },
];

const diffcultyOptions = [
  {
    name: 'Easy',
    value: JobDifficulty.easy,
  },
  {
    name: 'Normal',
    value: JobDifficulty.normal,
  },
  {
    name: 'Difficult',
    value: JobDifficulty.difficult,
  },
];

const UPDATE_JOB_AUDIT = gql(`
  mutation UpdateJobAudit ($input: UpdateJobAuditInput!) {
    updateJobAudit (input: $input) {
      uuid
      notes
      hasPassed
      auditType 
      submittedAt
      auditor {
        uuid
        firstName
        lastName
        avatarSrc
      }
    }
  }  
`);

const PreAuditModal = ({ open, onClose }: ModalWrapperPropsExtends) => (
  <ModalWrapper dialogPanelClassname="w-[920px]" open={open} onClose={onClose}>
    <PreAuditModalChild onClose={onClose} />
  </ModalWrapper>
);

const PreAuditModalChild = ({ onClose }: ModalPropsExtends): ReactElement => {
  const { user } = useAuth();
  profileUtility.assertProfile(user, 'UserUserProfile');
  const { audits, setAudits, job, setJob, skills, setSkills } = useJobContext();
  const [createTask, setCreateTask] = useState(false);

  const [updateJob, { loading, error }] = useMutation(UPDATE_JOB_AUDIT);
  const [canEdit, setCanEdit] = useState(true);

  const audit = useMemo(
    () => audits.find(({ auditType }) => auditType === AuditType.pre),
    [audits]
  );

  const form = useForm<{
    pass: boolean;
    jobDifficulty: JobDifficulty;
    notes?: string;
    skills: SkillRow[];
  }>({
    onSubmit: ({ value }) => {
      if (!audit) return;
      updateJob({
        variables: {
          input: {
            uuid: audit.uuid,
            notes: value.notes,
            difficulty: value.jobDifficulty,
            hasPassed: value.pass,
            skills: value.skills.map((s) => ({
              uuid: s.uuid,
              levelRequired: s.levelRequired,
              trade: s.trade,
            })),
          },
        },
      }).then((data) => {
        if (data.data) {
          const { __typename, ...attributes } = data.data.updateJobAudit;
          setAudits((aud) =>
            aud.map((a) => {
              if (a.auditType === AuditType.pre) {
                return attributes;
              }
              return a;
            })
          );
          setSkills(
            value.skills.map((s) => ({
              uuid: s.uuid,
              name: s.name ?? '-',
              levelRequired: s.levelRequired,
              trade: s.trade,
            }))
          );
          setJob((j) => ({
            ...j,
            difficulty: value.jobDifficulty,
          }));
          onClose(true);
          notify.success('Audit submitted.');
        }
      });
    },
    defaultValues: {
      skills,
      pass: audit?.hasPassed ?? true,
      jobDifficulty: job.difficulty,
      notes: audit?.notes ?? undefined,
    },
  });

  useEffect(() => {
    setCanEdit(!audit?.submittedAt);
  }, [audit]);

  const isAuthorised =
    user.profile.role === UserRole.superAdministrator ||
    audit?.auditor?.uuid === user.profile.uuid;

  return (
    <Modal
      onClose={onClose}
      closeText={canEdit ? 'Cancel' : 'Close'}
      title="Job audit"
      confirmText={canEdit ? 'Submit audit' : undefined}
      confirmCallback={() => form.handleSubmit()}
      loading={loading}
    >
      <form className="flex flex-col p-5 space-y-5">
        {audit?.submittedAt && isAuthorised && !canEdit && (
          <Alert
            alertType={audit.hasPassed ? 'success' : 'error'}
            text={
              <div className="flex flex-col items-start">
                <span className="text-body-small break-words">
                  {audit.hasPassed
                    ? 'This audit has already been submitted as “passed”. To edit it, click the button below.'
                    : 'This audit has already been submitted as “failed”. To edit it, click the button below.'}
                </span>
                <button
                  onClick={() => setCanEdit(true)}
                  type="button"
                  className="underline text-body-small mt-5 font-bold font-nunito"
                >
                  Edit audit
                </button>
              </div>
            }
          />
        )}
        <div className="flex flex-col space-y-5">
          <form.Field
            name="pass"
            children={({ state, handleChange }) => (
              <RadioSelector<boolean>
                title="Is everything in order for this job?"
                options={issueOptions.map((opt) => ({
                  ...opt,
                  disabled: !canEdit,
                }))}
                onSelectedOption={(value) => handleChange(value)}
                selectedOption={state.value}
              />
            )}
          />
          <form.Field
            name="notes"
            children={({ state, handleChange }) => (
              <Input
                disabled={!canEdit}
                className="!w-120"
                label="Auditor notes (optional)"
                value={state.value ?? ''}
                onChange={(e) => handleChange(e.target.value)}
                max={500}
                type="textarea"
              />
            )}
          />
          <form.Field
            name="jobDifficulty"
            children={({ state, handleChange }) => (
              <RadioSelector
                title="Job Difficulty"
                options={diffcultyOptions.map((opt) => ({
                  ...opt,
                  disabled: !canEdit,
                }))}
                onSelectedOption={(value) => handleChange(value)}
                selectedOption={state.value}
              />
            )}
          />
        </div>
        <h3 className="text-h3 font-bold font-nunito">Required Skills</h3>
        {/* 
// @ts-expect-error The from skills table expects only has skills: SkillRow[] as an attribute, we on the otherhand have the rest of the pre-audit attributes. In order to not have to define another row we are ignoring the error. If you remove form.skills it will break */}
        <SkillsTable wrapped disabled={!canEdit} form={form} />
        {canEdit && (
          <>
            <SimpleCheckbox
              label="I want to raise a task"
              checked={createTask}
              setChecked={setCreateTask}
            />
            {createTask && (
              <div className="mt-5">
                <Alert
                  alertType="info"
                  text="You will be able to input task details on the next screen."
                />
              </div>
            )}
          </>
        )}
        {error && (
          <div className="m-5">
            <Alert alertType="error" text={error.message} />
          </div>
        )}
      </form>
    </Modal>
  );
};
export default PreAuditModal;
