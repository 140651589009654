import ErrorScreen from '../components/organisms/ErrorScreen';
import logoSrc from './../assets/images/logo.svg';
import solarSrc from './../assets/images/top.png';
import {
  CatchBoundary,
  createFileRoute,
  Outlet,
  redirect,
} from '@tanstack/react-router';
import * as Sentry from '@sentry/react';
import Loader from '../components/icons/Loader';
import WithLoader from '../components/molecules/WithLoader';

export const Route = createFileRoute('/_unauthenticated')({
  component: LayoutComponent,
  pendingComponent: () => <div className="flex h-full w-full items-center justify-center"><Loader /></div>,
  beforeLoad: () => {
    if (localStorage.getItem('__ir_logged__')) {
      throw redirect({
        to: '/',
      });
    }
  },
});

function LayoutComponent() {
  return (
    <CatchBoundary
    onCatch={(error) => Sentry.captureException(error)}
      getResetKey={() => 'unauthenticated'}
      errorComponent={ErrorScreen}
    >
      <div className="w-full h-full flex items-center justify-center overflow-hidden bg-white">
        <div className="p-14 flex flex-col h-full">
          <div className="flex flex-col h-full justify-center flex-grow items-center">
            <img src={logoSrc} alt="Infinity Logo" className="mb-5" />
            <Outlet />
          </div>
          <div>
            <p className="text-body">
              If you are experiencing any technical issues, please contact{' '}
              <button className="underline text-primary">Tech support.</button>
            </p>
          </div>
        </div>
        <div className="w-full h-full">
          <img
            src={solarSrc}
            className="h-full w-full object-cover"
            alt="Solar"
          />
        </div>
      </div>
    </CatchBoundary>
  );
}
