import { gql, ReadUserQuery, UserProfile } from '@monorepo/graphql';
import env from '../environment';
import { client } from '../main';
import { skipToken, useLazyQuery, useSuspenseQuery } from '@apollo/client';
import { useEffect } from 'react';

const GET_USER = gql(`
  query ReadUser($input: ReadUserInput!) {
    readUser (input: $input) {
      uuid
      firstName
      lastName
      email
      avatarSrc
      phoneNumber
      createdAt
      schemeName
      address {
        uuid
        line1
        line2
        city
        county
        postcode
        latitude
        longitude
      }
      profile {
        __typename
        ... on UserUserProfile {
          uuid
          role
          status
          organisation {
            uuid
            name
          }
        }
        ... on UserCustomerProfile {
          uuid
          schemeUuid
          jobStatus
          source
          partner {
            uuid
            name
          }
        }
        ... on UserContractorProfile {
          uuid
          status
          startDate
          availability
          isElectrician
          isRoofer
          completionRate
          servicingRadius
          organisation {
            uuid
            name
          }
        }
      }
    }
  }
`);

export const SignInGql = gql(`
  query SignIn($input: SignInInput!) {
    signIn(input: $input) {
      accessToken
      refreshToken
    }
  }
`);

export const ResetPasswordGql = gql(`
  query ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`);

export const CompleteResetPasswordGql = gql(`
  query CompleteResetPassword($input: CompleteResetPasswordInput!) {
    completeResetPassword(input: $input)
  }
`);

export const RefreshGql = gql(`
  query Refresh($input: RefreshTokenInput) {
    refresh(input: $input) {
      accessToken
    }
  }
`);

const LogoutGql = gql(`
  query Logout {
    logout
  }
`);

const MeGql = gql(`
  query Me {
    user: findUniqueUser {
      uuid
      firstName
      lastName
      email
      avatarSrc
      unreadNotificationCount
      profile {
        ... on UserContractorProfile {
          uuid
        }
        ... on UserCustomerProfile {
          uuid
        }
        ... on UserUserProfile {
          uuid
          role
        }
      }
    }
  }
`);

export enum Device {
  mobile = 'mobile',
  desktop = 'desktop',
}

const useLogin = (
  { device = Device.desktop }: { device?: Device } | undefined = {
    device: Device.desktop,
  }
) => {
  const [login, { error, loading }] = useLazyQuery(SignInGql, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.signIn) {
        if (device === Device.mobile) {
          localStorage.setItem(
            '__cognito_refresh__',
            data.signIn.refreshToken ?? ''
          );
        }
        localStorage.setItem('__ir_logged__', `${new Date().getTime()}`);
        sessionStorage.setItem('__cognito_token__', data.signIn.accessToken);
      }
      fetchUser();
    },
  });

  const [fetchUser, { data: user, loading: userFetching, error: userError }] =
    useLazyQuery(MeGql);

  return {
    error: error || userError,
    isFetching: loading || userFetching,
    login,
    user: user?.user,
  };
};

function useRefresh() {
  const refreshToken = localStorage.getItem('__cognito_refresh__');
  const {
    data,
    error,
    refetch: refresh,
  } = useSuspenseQuery(RefreshGql, {
    skip: !localStorage.getItem('__ir_logged__'),
    ...(refreshToken
      ? {
          variables: {
            input: {
              refreshToken,
            },
          },
        }
      : {}),
  });

  const { data: user, error: userError } = useSuspenseQuery(
    MeGql,
    data ? {} : skipToken
  );

  useEffect(() => {
    if (data?.refresh) {
      sessionStorage.setItem('__cognito_token__', data.refresh.accessToken);
    }
  }, [data]);

  return {
    user: user?.user,
    loading: !user,
    refresh,
    error: error ?? userError,
  };
}

export const usersUtility = {
  useLogin,
  useRefresh,
  queries: {
    ResetPasswordGql,
    CompleteResetPasswordGql
  },
  formatAddress: (address: ReadUserQuery['readUser']['address']): string =>
    address
      ? [
          address.line1,
          address.line2,
          address.city,
          address.county,
          address.postcode,
        ]
          .filter((v): v is string => !!v)
          .join(', ')
      : '',
  fetch: ({ uuid, userProfile }: { uuid: string; userProfile?: UserProfile }) =>
    client
      .graphqlClient({ apiUrl: env.apiUrl })
      .query({
        query: GET_USER,
        variables: {
          input: {
            uuid,
            userProfile,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then((d) => d.data.readUser),
};
