import { ReactElement, useMemo, useState } from 'react';
import { DropdownWithBorder } from '../../atoms/Dropdown';
import { gql, IndexJobsForDropdownQuery } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';

interface Props {
  jobUuid?: string;
  error?: string;
  customerProfileUuid?: string;
  setJobUuid: (uuid: string) => void;
  ignorePartnerUuid?: boolean;
}

const INDEX_JOBS = gql(`
  query IndexJobsForDropdown($filters: IndexJobsFilterInput) {
    indexJobs (filters: $filters) {
      items {
        uuid
        displayLink
      }
    }
  }  
`);

const GET_ACTIVE_JOB = gql(`
  query ReadJobForSearch($uuid: String!) {
    readJob (uuid: $uuid) {
      uuid
      displayLink
    }
  }  
`);

const JobDropdown = ({
  error,
  jobUuid,
  setJobUuid,
  customerProfileUuid,
}: Props): ReactElement => {
  const [job, setJob] =
    useState<IndexJobsForDropdownQuery['indexJobs']['items'][0]>();

  const { data, refetch } = useQuery(INDEX_JOBS, {
    variables: {
      filters: {
        customerProfileUuid,
      },
    },
  });

  const jobOptions = useMemo(() => {
    return (
      data?.indexJobs.items.map(({ uuid, displayLink }) => ({
        value: uuid,
        name: displayLink,
      })) ?? []
    );
  }, [data]);

  const selected = useMemo(() => {
    const option = jobOptions.find(({ value }) => value === jobUuid);
    if (option) return option;
    if (job) {
      return {
        value: job.uuid,
        name: job.displayLink,
      };
    }
    return undefined;
  }, [jobUuid, jobOptions, job]);

  const { loading } = useQuery(GET_ACTIVE_JOB, {
    variables: {
      uuid: jobUuid ?? '',
    },
    skip: !!(!jobUuid || selected),
    onCompleted: (u) => {
      if (u.readJob) {
        setJob(u.readJob);
      }
    },
  });

  return (
    <DropdownWithBorder
      respectButtonWidth
      label="Related job"
      error={error}
      options={jobOptions}
      isFetchingSelected={loading}
      buttonText={selected?.name ?? '-- Select --'}
      selected={selected}
      ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
      buttonClassname="flex space-x-2 text-body-small justify-between items-center w-580 w-full"
      handlesSearch
      searchFunction={async (term) => {
        if (term.length) {
          refetch({
            filters: {
              term,
            },
          });
        }
      }}
      onOptionSelect={(opt) => {
        const job = data?.indexJobs.items.find(
          ({ uuid }) => uuid === opt.value
        );
        if (!job) return;
        setJobUuid(opt.value);
        setJob(job);
      }}
    />
  );
};
export default JobDropdown;
