import { ReactElement, useState } from 'react';
import {
  gql,
  ReadUserQuery,
  TargetType,
  UserProfile,
  UserProfileStatus,
} from '@monorepo/graphql';
import { Button } from '../../atoms/Button';
import { useRouter } from '@tanstack/react-router';
import {
  ArrowLeftIcon,
  BoltIcon,
  BuildingOffice2Icon,
  CalendarIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  MapPinIcon,
  PhoneIcon,
  PowerIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import SimpleModal from '../../molecules/Modals/Simple';
import { notify } from '../../../utility/notify';
import { usersUtility } from '../../../utility/users';
import { profileUtility } from '../../../utility/profile';
import { useMutation } from '@apollo/client';
import Tag from '../../atoms/Tag';
import AvatarStack from '../../atoms/AvatarStack';
import TwoLineText from '../../atoms/TwoLineText';
import { format } from 'date-fns';
import { contractorUtility } from '../../../utility/contractors';
import { RooferIcon } from '../../icons/Roofer';

import { styleUtility } from '../../../utility/styleUtility';
import Tabs from '../../molecules/Tabs';
import JobsTab from './Tabs/Jobs';
import SkillsTab from './Tabs/Skills';
import CommentsTab from './Tabs/Comments';
import EditContractor from '../../molecules/Modals/EditContractor';
import EditAddress from '../../molecules/Modals/EditAddress';
import ExtrasTab from './Tabs/Extras';
import AvailabilityTab from './Tabs/Availability';
import AuditModal from '../../molecules/Modals/Audit';

interface Props {
  data: ReadUserQuery['readUser'] & {
    profile: {
      __typename: 'UserContractorProfile';
    };
  };
}

const UPDATE_USER_CONTRACTOR_PROFILE = gql(`
  mutation UpdateUserContractorProfileStatus($input: UpdateUserContractorProfileInput!) {
    updateUserContractorProfile(input: $input) {
      userUuid
    }
  }
`);

const UserContractorProfile = ({ data }: Props): ReactElement => {
  const router = useRouter();

  const [user, setUser] = useState(data);

  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [amendUser, { error, loading }] = useMutation(
    UPDATE_USER_CONTRACTOR_PROFILE
  );
  const userIsActive = user.profile.status === UserProfileStatus.active;

  return (
    <>
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col flex-grow p-5">
          <div className="flex items-center mb-5">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <p className="font-semibold">Contractor #{user.profile.uuid}</p>
            </div>
            <Button
              className="mr-3"
              onClick={() => setShowAuditTrail(true)}
              bText="Audit trail"
              bSize="sm"
              bStyle="light"
            />
            <Button
              className=" enabled:!text-red"
              onClick={() => setShowStatusModal(true)}
              bText={
                user.profile.status === UserProfileStatus.active
                  ? 'Deactivate'
                  : 'Activate'
              }
              bSize="sm"
              Icon={
                user.profile.status === UserProfileStatus.active ? (
                  <ExclamationCircleIcon className="size-5 text-red" />
                ) : (
                  <PowerIcon className="size-5" />
                )
              }
              bStyle="light"
            />
          </div>
          <div className="bg-white rounded p-5 flex flex-col mb-5">
            <div className="flex justify-between items-center">
              <Tag
                size="lg"
                text={userIsActive ? 'Active' : 'Inactive'}
                colour={userIsActive ? 'completed' : 'notSigned'}
              />
              <Button
                bStyle="outline"
                onClick={() => setShowEditModal(true)}
                bText="Edit"
              />
            </div>
            <div className="flex">
              <div className="flex items-center flex-col w-1/3 p-5">
                <AvatarStack
                  heading
                  height="h-20"
                  width="w-20"
                  avatars={[
                    {
                      firstName: user.firstName,
                      lastName: user.lastName,
                      avatarSrc: user.avatarSrc ?? undefined,
                    },
                  ]}
                />
                <h1 className="text-h1-small font-nunito font-bold mt-4 mb-2">
                  {user.firstName} {user.lastName}
                </h1>
                <div className="flex items-start">
                  <MapPinIcon className="size-5 text-grey-400" />
                  <div className="ml-2">
                    <span>{usersUtility.formatAddress(user.address)} •</span>{' '}
                    <button
                      onClick={() => setShowEditAddressModal(true)}
                      className="text-primary underline font-semibold"
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <div className="flex w-full space-between py-5">
                  <div className="flex flex-col h-full items-center w-1/2 space-between">
                    {user.profile.isElectrician ? (
                      <>
                        <BoltIcon className="size-9 text-primary" />
                        <span className="text-body small mt-2 block">
                          Electrician
                        </span>
                      </>
                    ) : user.profile.isRoofer ? (
                      <>
                        <RooferIcon
                          multiplier={1.5}
                          colour={styleUtility.colours.primary['DEFAULT']}
                        />
                        <span className="text-body small mt-2 block">
                          Roofer
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="flex flex-col h-full items-center w-1/2 space-between">
                    <h1 className="text-h1 font-nunito  font-semibold text-primary">
                      {user.profile.completionRate}%
                    </h1>
                    <span className="text-body small mt-2 block">
                      Completion rate
                    </span>
                  </div>
                </div>
              </div>
              <hr className="border-none w-px h-full bg-grey-700" />
              <div className="grid grid-cols-2 gap-5 p-5 flex-grow">
                <div className="basis-1/2">
                  <TwoLineText
                    label="Email"
                    text={user.email}
                    Icon={<EnvelopeIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Phone"
                    text={user.phoneNumber ?? '-'}
                    Icon={<PhoneIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Organisation"
                    text={user.profile.organisation.name}
                    Icon={
                      <BuildingOffice2Icon className="size-5 text-grey-400" />
                    }
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Type"
                    text="Individual"
                    Icon={<UserIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Availability"
                    text={`${contractorUtility.getAvailability(
                      user.profile.availability
                    )}`}
                    Icon={<CalendarIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Start date"
                    text={format(
                      new Date(parseInt(user.profile.startDate)),
                      'do MMMM yyyy'
                    )}
                    Icon={<CalendarIcon className="size-5 text-grey-400" />}
                  />
                </div>
              </div>
            </div>
          </div>
          <Tabs
            tabs={[
              {
                name: 'Jobs',
                value: 'jobs',
                component: (
                  <JobsTab contractorProfileUuid={user.profile.uuid} />
                ),
              },
              {
                name: 'Skills',
                value: 'skills',
                component: (
                  <SkillsTab contractorProfileUuid={user.profile.uuid} />
                ),
              },
              {
                name: 'Availability',
                value: 'availability',
                component: (
                  <AvailabilityTab
                    contractorProfileUuid={user.profile.uuid}
                    availability={user.profile.availability}
                  />
                ),
              },
              {
                name: 'Comments',
                value: 'comments',
                component: (
                  <CommentsTab contractorProfileUuid={user.profile.uuid} />
                ),
              },
              {
                name: 'Extras',
                value: 'extras',
                component: (
                  <ExtrasTab contractorProfileUuid={user.profile.uuid} />
                ),
              },
            ]}
          />
        </div>
      </div>

      <EditContractor
        contractorProfilleUuid={user.profile.uuid}
        open={showEditModal}
        organisationName={user.profile.organisation.name}
        onClose={(success, data) => {
          if (data) {
            setUser((u) => ({
              ...u,
              firstName: data.firstName,
              lastName: data.lastName,
              phoneNumber: data.phoneNumber ?? null,
              email: data.email,
              profile: {
                ...u.profile,
                isElectrician: data.trades.isElectrician,
                isRoofer: data.trades.isRoofer,
                organisation: {
                  uuid: data.organisationUuid,
                  name: data.organisationName
                }
              },
            }));
          }
          setShowEditModal(false);
        }}
        firstName={user.firstName}
        lastName={user.lastName}
        email={user.email}
        trades={{
          isElectrician: user.profile.isElectrician,
          isRoofer: user.profile.isRoofer,
        }}
        organisationUuid={user.profile.organisation.uuid}
      />

      {user.address && (
        <EditAddress
          uuid={user.address.uuid}
          open={showEditAddressModal}
          onClose={(success, data) => {
            if (data) {
              setUser((u) => ({
                ...u,
                address: u.address
                  ? {
                      ...u.address,
                      ...data,
                    }
                  : null,
              }));
            }
            setShowEditAddressModal(false);
          }}
          line1={user.address.line1}
          line2={user.address.line2 ?? undefined}
          city={user.address.city ?? undefined}
          county={user.address.county ?? undefined}
          postcode={user.address.postcode}
        />
      )}

      <AuditModal
        targetUuid={user.uuid}
        targetType={TargetType.user}
        open={showAuditTrail}
        onClose={() => setShowAuditTrail(false)}
      />

      <SimpleModal
        icon="warning"
        text="Are you sure you want to update the users access?"
        title={`${userIsActive ? 'Deactivate' : 'Activate'} user`}
        onConfirm={() => {
          amendUser({
            variables: {
              input: {
                uuid: user.profile.uuid,
                status: userIsActive
                  ? UserProfileStatus.inactive
                  : UserProfileStatus.active,
              },
            },
          })
            .then(() => {
              notify.success('Saved user');
              usersUtility.fetch({ uuid: user.uuid }).then((d) => {
                if (
                  profileUtility.assertProfileSafe(d, 'UserContractorProfile')
                ) {
                  setUser(d);
                }
              });
            })
            .catch((e) => notify.error(`Unable to save user.`));
          setShowStatusModal(false);
        }}
        onConfirmText={`${userIsActive ? 'Deactivate' : 'Activate'} user`}
        open={showStatusModal}
        onClose={() => setShowStatusModal(false)}
      />
    </>
  );
};

export default UserContractorProfile;
