import { ReactElement, useEffect } from 'react';
import { Input } from '../../atoms/Input';
import { useForm } from '@tanstack/react-form';
import { Button } from '../../atoms/Button';
import { Device, usersUtility } from '../../../utility/users';
import { useAuth } from '../../../utility/authentication';
import {
  ArrowRightIcon,
  EnvelopeIcon,
  LockClosedIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

const Login = (): ReactElement => {
  const { error, isFetching, login, user } = usersUtility.useLogin({
    device: Device.mobile,
  });
  const { setUser } = useAuth();
  const form = useForm<{ email: string; password: string }>({
    onSubmit: (vals) => {
      login({
        variables: {
          input: {
            email: vals.value.email,
            password: vals.value.password,
            isMobile: true,
          },
        },
      });
    },
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (user) {
      setUser(user);
    }
  }, [user, setUser]);

  return (
    <>
      <h1 className="text-h1-small font-nunito mb-4">Log in</h1>
      {error?.message && (
        <div className="mb-5 w-full bg-red-600/50 p-2 rounded border justify-between border-red--100 flex items-center text-red--100 max-w-[430px]">
          <p className="text-body-small p-2 break-words max-w-[90%]">{error.message}</p>
          <button className="p-2">
            <XMarkIcon className="size-5" />
          </button>
        </div>
      )}
      <form
        className="w-[430px]"
        onSubmit={(e) => {
          e.preventDefault();
          form.handleSubmit();
        }}
      >
        <form.Field
          name="email"
          children={({ state, handleChange }) => (
            <Input
              value={state.value ?? ''}
              onChange={(e) => handleChange(e.target.value)}
              label="Your email"
              type="email"
              required
              Icon={<EnvelopeIcon className="size-6" />}
            />
          )}
        />
        <form.Field
          name="password"
          children={({ state, handleChange }) => (
            <Input
              value={state.value ?? ''}
              onChange={(e) => handleChange(e.target.value)}
              label="Password"
              required
              Icon={<LockClosedIcon className="size-6" />}
              type="password"
            />
          )}
        />
        <Button
          className="w-full flex items-center justify-center"
          type="submit"
          bText="Log in"
          Icon={<ArrowRightIcon className="size-6" />}
          loading={isFetching}
          reverse
        />
        <Button
          href="/reset-password"
          className="w-full mt-2 flex items-center justify-center"
          type="button"
          bText="Forgot password?"
          bStyle="clean"
        />
      </form>
    </>
  );
};
export default Login;
