import { gql, NotificationTargetType } from '@monorepo/graphql';

const NOTIFICATION_FRAGMENT = gql(`
  fragment Notification on Notification {
    uuid
    title
    description
    actionUrl
    isDismissed 
    isViewed 
    createdAt
    targetType
    targetUuid
    targetUserUuid
  }  
`)

export const notificationsUtiltiy = {
  queries: {
    NOTIFICATION_FRAGMENT,
  },
  actionUrl: ({
    targetUuid,
    targetType,
    targetUserUuid
  }: {
    targetUuid: string;
    targetType: NotificationTargetType;
    targetUserUuid: string; 
  }): string => {

    switch (targetType) {
      case NotificationTargetType.holiday: 
        return `/contacts/${targetUserUuid}?tab=holidays&holiday=${targetUuid}`
      case NotificationTargetType.job:
        return `/job/${targetUuid}`
    }

  },
};
