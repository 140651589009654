import { Option } from '../components/atoms/Dropdown';
import { JobStatus, TaskStatus } from '@monorepo/graphql';

const taskStatusNiceMap: Record<TaskStatus, string> = {
  [TaskStatus.unassigned]: 'Unassigned',
  [TaskStatus.todo]: 'To do',
  [TaskStatus.inProgress]: 'In progress',
  [TaskStatus.done]: 'Done',
};

const taskStatusOptions: Option<TaskStatus>[] = Object.keys(TaskStatus)
  .sort((a, b) =>
    Object.keys(taskStatusNiceMap).indexOf(a) >
    Object.keys(taskStatusNiceMap).indexOf(b)
      ? 1
      : -1
  )
  .map((s) => ({
    name: taskStatusNiceMap[s as TaskStatus],
    value: s as TaskStatus,
  }));

export const tasksUtility = {
  taskStatusOptions,
  taskStatusNiceMap,
};
