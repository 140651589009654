import { ReactElement, useCallback, useMemo, useState } from 'react';
import Dropdown from '../../atoms/Dropdown';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import { RectangleStackIcon } from '@heroicons/react/24/outline';
import { gql } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import { useDebounce } from 'use-debounce';

const GET_SCHEMES = gql(`
  query IndexSchemes($filters: IndexSchemesFilterInput) {
    indexSchemes(filters: $filters) {
      items {
        name
        uuid
      }
    }
  }
`);

interface Props {
  schemeUuid: string;
  setSchemeUuid: (uuid: string) => void;
  error?: string;
  isOptional?: boolean;
  partnerUuid?: string;
}
const SchemeDropdown = ({
  schemeUuid,
  setSchemeUuid,
  partnerUuid,
  isOptional,
  error
}: Props): ReactElement => {

  const { data, refetch } = useQuery(GET_SCHEMES, {
    variables: {
      filters: {
        partnerUuid
      }
    }
  });

  const schemeOptions = useMemo(() => {
    return (
      data?.indexSchemes.items.map(({ uuid, name }) => ({
        value: uuid,
        name,
      })) ?? []
    );
  }, [data]);

  const selected = useMemo(
    () => schemeOptions.find(({ value }) => value === schemeUuid),
    [schemeOptions, schemeUuid]
  );

  return (
    <Dropdown
      options={schemeOptions}
      respectButtonWidth={true}
      innerButtonFullWidth={true}
      buttonText={selected?.name ?? '-- Select --'}
      buttonClassname="py-2.5 px-3 border border-grey-500 rounded flex items-center focus-within:outline-primary focus-within:outline focus-within:outline-2 w-580 mb-5 w-full"
      selected={selected}
      ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
      onOptionSelect={(opt) => setSchemeUuid(opt.value)}
      label={`Scheme ${isOptional ? '(optional)' : ''}`}
      ButtonLeadIcon={<RectangleStackIcon className="size-6 mr-2" />}
      textClassname="flex-grow text-start"
      handlesSearch
      searchFunction={async (search) => {
        await refetch({
          filters: {
            term: search,
            partnerUuid
          },
        });
      }}
      error={error}
    />
  );
};
export default SchemeDropdown;
