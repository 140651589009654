import { ReactElement, useState } from 'react';
import Table from '../../../../molecules/Table';
import { useQuery } from '@apollo/client';
import { gql } from '@monorepo/graphql';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import { notify } from '../../../../../utility/notify';
import ExpandedFileViewer from '../../../../molecules/Modals/ExpandedFileViewer';
import AvatarStack from '../../../../atoms/AvatarStack';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { currency } from '../../../../../utility/currency';
import { Button } from '../../../../atoms/Button';
import { Link } from '@tanstack/react-router';

interface Props {
  contractorProfileUuid: string;
}

const INDEX_INSTALLED_EXTRAS = gql(`
    query IndexInstalledProductsForContractor ($contractorProfileUuid: String!, $pagination: PaginationInput) {
      indexInstalledProductsForContractor (contractorProfileUuid: $contractorProfileUuid, pagination: $pagination) {
        meta {
          allTimeTotal
        }
        items {
          uuid
          name
          customerFirstName
          customerLastName
          customerUserUuid
          customerAvatarSrc
          installationDate
          totalCost
          jobUuid
          image {
            src
            mimeType
            name
          }
        }
        pagination {
          lastPage
        }
      }
    }
`);

const ExtrasTab = ({ contractorProfileUuid }: Props): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);

  const { data, loading } = useQuery(INDEX_INSTALLED_EXTRAS, {
    variables: {
      contractorProfileUuid,
      pagination: {
        page,
        perPage: rowsPerPage,
      },
    },
    onError: (err) =>
      notify.error(`Unable to fetch extras for contractor.\n${err.message}`),
  });

  const [image, setImage] = useState<{
    name: string;
    src: string;
    mimeType: string;
  }>();

  return (
    <>
      <div className="flex overflow-hidden flex-col h-full">
        <Table
          loading={loading}
          columns={[
            {
              heading: 'name',
              width: 28,
            },
            {
              heading: 'customer',
              width: 28,
            },
            {
              heading: 'date installed',
              width: 28,
            },
            {
              heading: 'Cost',
              width: 8,
            },
            {
              width: 8,
            },
          ]}
          toolbar={
            <div className="font-bold space-x-3">
              <span>All time total:</span>
              <span className="text-tertiary">
                {currency.format(
                  data?.indexInstalledProductsForContractor.meta.allTimeTotal ??
                    0
                )}
              </span>
            </div>
          }
          title="Extras"
          widthType="pc"
          rows={
            data?.indexInstalledProductsForContractor.items.map((i) => ({
              uuid: i.uuid,
              cells: [
                {
                  width: 28,
                  content: (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          if (i.image) {
                            setImage({
                              src: i.image.src,
                              name: i.image.name,
                              mimeType: i.image.mimeType,
                            });
                          }
                        }}
                        className="h-9 w-9 bg-grey-800 rounded overflow-hidden mr-2"
                      >
                        {i.image && (
                          <img
                            className="object-cover h-full w-full"
                            alt={i.image.name}
                            src={i.image.src}
                          />
                        )}
                      </button>
                      <span className="text-body-small">{i.name}</span>
                    </>
                  ),
                },
                {
                  width: 28,
                  content: (
                    <Link
                      to="/contacts/$uuid"
                      params={{
                        uuid: i.customerUserUuid,
                      }}
                      className="flex underline items-center space-x-2"
                    >
                      <AvatarStack
                        avatars={[
                          {
                            firstName: i.customerFirstName,
                            lastName: i.customerLastName,
                            avatarSrc: i.customerAvatarSrc,
                          },
                        ]}
                      />
                      <span className="text-body-small">
                        {i.customerFirstName} {i.customerLastName}
                      </span>
                    </Link>
                  ),
                },
                {
                  width: 28,
                  content: (
                    <div className="flex items-center space-x-2">
                      <CalendarIcon className="size-5 text-grey-400" />
                      <span className="text-body-small">
                        {format(i.installationDate, 'd MMMM yyyy')}
                      </span>
                    </div>
                  ),
                },
                {
                  width: 8,
                  content: (
                    <div className="flex items-end">
                      <span className="text-body-small text-tertiary font-semibold">
                        {currency.format(i.totalCost)}
                      </span>
                    </div>
                  ),
                },
                {
                  width: 8,
                  content: (
                    <div className="flex items-end">
                      <Button
                        href={`/job/${i.jobUuid}`}
                        bStyle="light"
                        className="h-9"
                        bText="View job"
                      />
                    </div>
                  ),
                },
              ],
            })) ?? []
          }
        />
        <TablePagination
          totalPages={
            data?.indexInstalledProductsForContractor.pagination.lastPage
          }
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </div>
      <ExpandedFileViewer
        open={!!image}
        onClose={() => setImage(undefined)}
        file={image}
      />
    </>
  );
};
export default ExtrasTab;
