import { ReactElement } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { gql, SchemeType } from '@monorepo/graphql';
import { addDays } from 'date-fns';
import { Input } from '../../../atoms/Input';
import { RectangleStackIcon } from '@heroicons/react/24/outline';
import PartnerDropdown from '../../PartnerDropdown';
import RadioSelector from '../../../atoms/RadioSelector';
import DateSelector from '../../../atoms/DateSelector';
import UserDropdown from '../../UserDropown';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';

type Props = {
  uuid?: string;
  partnerUuid?: string;
  name?: string;
  schemeType?: SchemeType;
  startDate?: Date;
  endDate?: Date;
  operatorUuid?: string;
  preAuditorUuid?: string;
  postAuditorUuid?: string;
  electricalAuditorUuid?: string;
  roofingAuditorUuid?: string;
  partnerName?: string;
} & ModalWrapperPropsExtends;

const UPSERT_SCHEME = gql(`
  mutation UpsertScheme ($input: UpsertSchemeInput!) {
    upsertScheme (input: $input) {
      uuid
      name
      partnerUuid
      startDate
      endDate 
      schemeType
      formattedStartDate
      formattedEndDate 
      status
      partnerName
    }
  }  
`);

const UpsertScheme = ({
  open,
  onClose,
  ...rest
}: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <UpsertSchemeChild onClose={onClose} {...rest} />
  </ModalWrapper>
)

const UpsertSchemeChild = ({
  uuid,
  partnerUuid = '',
  name = '',
  schemeType = SchemeType.fullService,
  startDate = new Date(),
  endDate = addDays(new Date(), 30),
  operatorUuid = '',
  preAuditorUuid = '',
  postAuditorUuid = '',
  electricalAuditorUuid = '',
  roofingAuditorUuid = '',
  partnerName,
  onClose,
}: Omit<Props, 'open'>): ReactElement => {
  const [mutate, { error, loading }] = useMutation(UPSERT_SCHEME, {
    onCompleted: (data) => {
      if (data) {
        notify.success('Successfully added scheme.');
      }
      onClose(!!data);
    },
    refetchQueries: ['IndexSchemesForSchemesPage'],
  });

  const form = useForm({
    onSubmit: ({ value }) => {
      const { startDate, endDate, ...input } = value;
      mutate({
        variables: {
          input: {
            startDate: startDate,
            endDate: endDate,
            ...input,
          },
        },
      });
    },
    defaultValues: {
      uuid,
      partnerUuid,
      name,
      schemeType,
      startDate,
      endDate,
      operatorUuid,
      preAuditorUuid,
      postAuditorUuid,
      electricalAuditorUuid,
      roofingAuditorUuid,
    },
  });

  const selectedPartnerUuid = form.useField({
    name: 'partnerUuid',
  });

  return (
    <Modal
      title={uuid ? 'Update Scheme' : 'Add Scheme'}
      onClose={onClose}
      confirmCallback={form.handleSubmit}
      confirmText={uuid ? 'Save scheme' : 'Add scheme'}
      loading={loading}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="w-150 max-w-150 p-5 space-y-5"
      >
        <form.Field
          name="name"
          validators={{
            onBlur: ({ value }) =>
              value.length > 0
                ? undefined
                : 'Please enter a name for the scheme.',
          }}
          children={({ state, handleChange, handleBlur }) => (
            <Input
              onBlur={handleBlur}
              error={state.meta.errors.join(', ')}
              value={state.value}
              label="Scheme name"
              onChange={(e) => handleChange(e.target.value)}
              Icon={<RectangleStackIcon className="size-5 text-text-normal" />}
            />
          )}
        />
        <form.Field
          name="partnerUuid"
          validators={{
            onSubmit: ({ value }) =>
              value.length > 0
                ? undefined
                : 'Please select a partner for the scheme.',
          }}
          children={({ state, handleChange }) => (
            <div>
              <PartnerDropdown
                error={state.meta.errors.join(', ')}
                partnerUuid={state.value}
                setPartnerUuid={handleChange}
                partnerName={partnerName}
              />
            </div>
          )}
        />
        <form.Field
          name="schemeType"
          children={({ state, handleChange }) => (
            <RadioSelector
              title="Type of service"
              options={[
                {
                  name: 'Full service',
                  value: SchemeType.fullService,
                },
                {
                  name: 'Installation',
                  value: SchemeType.installation,
                },
              ]}
              selectedOption={state.value}
              onSelectedOption={handleChange}
            />
          )}
        />

        <div className="flex items-center space-x-2">
          <form.Field
            name="startDate"
            children={({ state, handleChange }) => (
              <div className="flex flex-col w-full">
                <span className="mb-2 text-input-label block font-semibold">
                  Start Date
                </span>
                <DateSelector
                  selectedDate={state.value}
                  setSelectedDate={handleChange}
                  showLabel
                />
              </div>
            )}
          />
          <form.Field
            name="endDate"
            children={({ state, handleChange }) => (
              <div className="flex flex-col w-full">
                <span className="mb-2 text-input-label block font-semibold">
                  End Date
                </span>
                <DateSelector
                  selectedDate={state.value}
                  setSelectedDate={handleChange}
                  showLabel
                />
              </div>
            )}
          />
        </div>

        <form.Field
          name="operatorUuid"
          validators={{
            onSubmit: ({ value }) =>
              value.length > 0 ? undefined : 'Please select a default auditor.',
          }}
          children={(field) =>
            selectedPartnerUuid.getValue() && (
              <div>
                <UserDropdown
                  label="Auditor"
                  helperText="Pre job"
                  userUuid={field.state.value}
                  error={field.state.meta.errors.join(', ')}
                  setUserUuid={(_, profileUuid) =>
                    field.handleChange(profileUuid)
                  }
                  partnerUuid={form.getFieldValue('partnerUuid')}
                />
              </div>
            )
          }
        />

        <form.Field
          name="preAuditorUuid"
          validators={{
            onSubmit: ({ value }) =>
              value.length > 0 ? undefined : 'Please select a default auditor.',
          }}
          children={(field) =>
            selectedPartnerUuid.getValue() && (
              <div>
                <UserDropdown
                  label="Auditor"
                  helperText="Pre job"
                  error={field.state.meta.errors.join(', ')}
                  userUuid={field.state.value}
                  setUserUuid={(_, profileUuid) =>
                    field.handleChange(profileUuid)
                  }
                  partnerUuid={form.getFieldValue('partnerUuid')}
                />
              </div>
            )
          }
        />
        <form.Field
          name="postAuditorUuid"
          validators={{
            onSubmit: ({ value }) =>
              value.length > 0 ? undefined : 'Please select a default auditor.',
          }}
          children={(field) =>
            selectedPartnerUuid.getValue() && (
              <div>
                <UserDropdown
                  label="General Auditor"
                  helperText="Post job"
                  error={field.state.meta.errors.join(', ')}
                  userUuid={field.state.value}
                  setUserUuid={(_, profileUuid) =>
                    field.handleChange(profileUuid)
                  }
                  partnerUuid={form.getFieldValue('partnerUuid')}
                />
              </div>
            )
          }
        />
        <form.Field
          name="electricalAuditorUuid"
          validators={{
            onSubmit: ({ value }) =>
              value.length > 0 ? undefined : 'Please select a default auditor.',
          }}
          children={(field) =>
            selectedPartnerUuid.getValue() && (
              <div>
                <UserDropdown
                  label="Electrical Auditor"
                  helperText="Post job"
                  error={field.state.meta.errors.join(', ')}
                  userUuid={field.state.value}
                  setUserUuid={(_, profileUuid) =>
                    field.handleChange(profileUuid)
                  }
                  partnerUuid={form.getFieldValue('partnerUuid')}
                />
              </div>
            )
          }
        />
        <form.Field
          name="roofingAuditorUuid"
          validators={{
            onSubmit: ({ value }) =>
              value.length > 0 ? undefined : 'Please select a default auditor.',
          }}
          children={(field) =>
            selectedPartnerUuid.getValue() && (
              <div>
                <UserDropdown
                  label="Roofing Auditor"
                  helperText="Post job"
                  error={field.state.meta.errors.join(', ')}
                  userUuid={field.state.value}
                  setUserUuid={(_, profileUuid) =>
                    field.handleChange(profileUuid)
                  }
                  partnerUuid={form.getFieldValue('partnerUuid')}
                />
              </div>
            )
          }
        />

        {error && <Alert alertType="error" text={error.message} />}
      </form>
    </Modal>
  );
};
export default UpsertScheme;
