import { ReactElement, useState } from 'react';
import Table from '../../../../molecules/Table';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import { gql } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { Button } from '../../../../atoms/Button';

const INDEX_PARTNERS = gql(`
  query IndexPartnersForOverview {
    indexPartners {
      items {
        uuid
        name 
        jobCount
        schemeCount
        ticketCount
        userCount
        customerCount
      }
    }
  }
`);

const OverviewTab = (): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twentyfive);
  const [page, setPage] = useState(1);

  const { data, loading } = useQuery(INDEX_PARTNERS);

  return (
    <>
      <Table
        loading={loading}
        title="Overview"
        columns={[
          {
            heading: 'name',
            width: 18,
          },
          {
            heading: 'schemes',
            width: 18,
          },
          {
            heading: 'jobs',
            width: 18,
          },
          {
            heading: 'tickets',
            width: 18,
          },
          {
            heading: 'users',
            width: 18,
          },
          {
            heading: 'customers',
            width: 18,
          },
          {
            width: 10,
          },
        ]}
        widthType="pc"
        rows={
          data?.indexPartners.items.map((p) => ({
            uuid: p.uuid,
            cells: [
              {
                content: (
                  <div className="flex items-center space-x-2">
                    <BuildingOffice2Icon className="size-5 text-grey-400" />
                    <span className="text-sm">{p.name}</span>
                  </div>
                ),
                width: 18,
              },
              {
                content: p.schemeCount,
                width: 18,
              },
              {
                content: p.jobCount,
                width: 18,
              },
              {
                content: p.ticketCount,
                width: 18,
              },
              {
                content: p.userCount,
                width: 18,
              },
              {
                content: p.customerCount,
                width: 18,
              },
              {
                content: (
                  <div className="flex justify-end w-full">
                    <Button
                      className="h-9"
                      href={`/partners/${p.uuid}`}
                      bText="View"
                      bStyle="outline"
                    />
                  </div>
                ),
                width: 10,
              },
            ],
          })) ?? []
        }
      />
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
      />
    </>
  );
};
export default OverviewTab;
