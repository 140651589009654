import { ReactElement, ReactNode, useState } from 'react';
import Table from '../../../../molecules/Table';
import PartnerDropdown from '../../../../molecules/PartnerDropdown';
import { Button } from '../../../../atoms/Button';
import {
  BuildingOffice2Icon,
  CalendarIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { DropdownWithBorder } from '../../../../atoms/Dropdown';
import { AllEnum } from '../../../../../utility/calendarContext';
import {
  gql,
  IndexUsersForContactsScreenQuery,
  UserProfile,
  UserRole,
  UserUserProfile,
} from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import AvatarStack from '../../../../atoms/AvatarStack';
import Pagination, { RowsPerPage } from '../../../../molecules/Pagination';
import AddUserModal from '../../../../molecules/Modals/AddUser';
import { profileUtility } from '../../../../../utility/profile';
import OrganisationDropdown from '../../../../molecules/OrganisationDropdown';

const GET_USERS = gql(`
  query IndexUsersForContactsScreen ($filters: IndexUsersFilterInput!, $pagination: PaginationInput) {
	indexUsers (filters: $filters, pagination: $pagination) {
		items {
			uuid
			firstName
      lastName
			avatarSrc
      lastLogin
      profile {
        __typename 
        ... on UserUserProfile {
          role
          organisation {
            name
          }
        }
      }
		}
    pagination {
      lastPage
    }
	}
}
`);

type UserWithUserProfileType =
  IndexUsersForContactsScreenQuery['indexUsers']['items'][0] & {
    profile: UserUserProfile;
  };

const UserTab = (): ReactElement => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [users, setUsers] = useState<Array<UserWithUserProfileType>>([]);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [organisationUuid, setOrganisationUuid] = useState('all');
  const [role, setRole] = useState<UserRole | AllEnum.all>(AllEnum.all);

  const { loading } = useQuery(GET_USERS, {
    variables: {
      filters: {
        userProfile: UserProfile.user,
        userUserProfileRole: role && role !== AllEnum.all ? role : undefined,
        organisationUuid: organisationUuid === 'all' ? undefined : organisationUuid,
      },
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
    onCompleted: (data) => {
      setUsers(
        data.indexUsers.items.filter(
          (i): i is UserWithUserProfileType =>
            i.profile.__typename === 'UserUserProfile'
        )
      );
      setTotalPages(data.indexUsers.pagination.lastPage);
    },
  });

  return (
    <>
      <Table
        title="Users"
        loading={loading}
        toolbar={
          <div className="flex justify-center space-x-2">
            {/* CHANGE PARTNER DROPDOWN */}
            <OrganisationDropdown
              all
              organisationUuid={organisationUuid}
              setOrganisationUuid={setOrganisationUuid}
              buttonClassname="h-11 border-px !border-grey-500 !w-60 whitespace-nowrap !mb-0"
              hideLabel
              respectButtonWidth={false}
              hideLeadIcon
            />
            <DropdownWithBorder<AllEnum | UserRole>
              buttonText={
                profileUtility.roleOptions.find(({ value }) => value === role)
                  ?.name
              }
              options={profileUtility.roleOptions}
              buttonClassname="!w-60 justify-between whitespace-nowrap"
              onOptionSelect={(opt) => setRole(opt.value)}
            />
            <Button
              bText="Add"
              Icon={<PlusIcon className="text-white size-6" />}
              onClick={() => setShowAddUserModal(true)}
              reverse
            />
          </div>
        }
        columns={[
          {
            width: 22,
            heading: 'name',
          },
          {
            width: 22,
            heading: 'organisation',
          },
          {
            width: 22,
            heading: 'role',
          },
          {
            width: 22,
            heading: 'last login',
          },
          {
            width: 12,
          },
        ]}
        widthType="pc"
        rows={
          users.map((u) => ({
            uuid: u.uuid,
            cells: [
              {
                width: 22,
                content: (
                  <div className="flex items-center space-x-2">
                    <AvatarStack
                      avatars={[
                        {
                          firstName: u.firstName,
                          lastName: u.lastName,
                          avatarSrc: u.avatarSrc ?? undefined,
                        },
                      ]}
                    />
                    <span className="text-body-small">
                      {u.firstName} {u.lastName}
                    </span>
                  </div>
                ),
              },
              {
                width: 22,
                content: (
                  <div className="flex items-center space-x-2">
                    <BuildingOffice2Icon className="size-5 text-grey-400" />
                    <span className="text-body-small">{u.profile.organisation.name}</span>
                  </div>
                ),
              },
              {
                width: 22,
                content: (
                  <div className="flex items-center space-x-2">
                    {profileUtility.roleIcon[u.profile['role']]}
                    <span className="text-body-small">
                      {
                        profileUtility.roleOptions.find(
                          ({ value }) => value === u.profile['role']
                        )?.name
                      }
                    </span>
                  </div>
                ),
              },
              {
                width: 22,
                content: (
                  <div className="flex items-center space-x-2">
                    <CalendarIcon className="size-5 text-grey-400" />
                    <span className="text-body-small">
                      {u.lastLogin ?? '-'}
                    </span>
                  </div>
                ),
              },
              {
                width: 12,
                content: (
                  <div className="flex justify-end w-full">
                    <Button
                      href={`/contacts/${u.uuid}`}
                      bText="View"
                      bStyle="outline"
                      className="h-9"
                    />
                  </div>
                ),
              },
            ],
          })) ?? []
        }
      />
      <div className="border-t border-grey-500/40">
        <div className="rounded-b-lg overflow-hidden">
          <Pagination
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(r) => {
              setPage(1);
              setTotalPages(undefined);
              setRowsPerPage(r);
            }}
            totalPages={totalPages}
          />
        </div>
      </div>
      <AddUserModal
        open={showAddUserModal}
        onClose={() => setShowAddUserModal(false)}
      />
    </>
  );
};
export default UserTab;
