import { ReactElement, useState } from 'react';
import { Button } from '../../../../atoms/Button';
import EditContractorAvailability from '../../../../molecules/Modals/EditContractorAvailability';

interface Props {
  availability: string;
  contractorProfileUuid: string;
}
const AvailabilityTab = ({ availability, contractorProfileUuid }: Props): ReactElement => {
  const [showEdit, setShowEdit] = useState(false);
  const [a, setA] = useState(availability)

  return (
    <>
      <div className="bg-white space-y-5 py-5 flex-grow overflow-hidden flex flex-col rounded-b-lg">
        <div className="flex px-5 items-center">
          <div className="flex items-center flex-grow">
            <h2 className="text-h2 font-bold font-nunito mr-5">Availability</h2>
          </div>
          <Button
            onClick={() => setShowEdit(true)}
            bStyle="outline"
            bText="Edit"
          />
        </div>
        <ul className="px-5 space-y-3 list-disc ml-5">
          {a.includes('1') && <li>Monday</li>}
          {a.includes('2') && <li>Tuesday</li>}
          {a.includes('3') && <li>Wednesday</li>}
          {a.includes('4') && <li>Thursday</li>}
          {a.includes('5') && <li>Friday</li>}
          {a.includes('6') && <li>Saturday</li>}
          {a.includes('7') && <li>Sunday</li>}
        </ul>
      </div>
      <EditContractorAvailability
        open={showEdit}
        availability={availability}
        contractorProfileUuid={contractorProfileUuid}
        onClose={(success, availability) => {
          if (success) {
            setA(availability)
          }
          setShowEdit(false)
        }}  
      />
    </>
  );
};
export default AvailabilityTab;
