import { z } from 'zod';

const ZodAddress = z.object({
  data: z.object({
    attributes: z.object({
      location: z.object({
        lat: z.number(),
        lon: z.number(),
      }),
    }),
  }),
});

export const addressUtility = {
  getLatLon: async (
    postcode: string
  ): Promise<{ lat: number; lon: number } | null> => {
    const rsp = await fetch(
      `https://findthatpostcode.uk/postcodes/${postcode}`
    );

    if (!rsp.ok || rsp.status === 404) return null;

    const data = ZodAddress.parse(await rsp.json());

    return data.data.attributes.location;
  },
};
