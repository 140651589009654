import { ReactElement, useEffect, useState } from 'react';
import {
  gql,
  ReadSchemeQuery,
  SchemeStatus,
  SchemeType,
} from '@monorepo/graphql';
import { Button } from '../../atoms/Button';
import {
  ArrowLeftIcon,
  BuildingOffice2Icon,
  CalendarIcon,
  DocumentCheckIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline';
import { Link, useRouter } from '@tanstack/react-router';
import TwoLineText from '../../atoms/TwoLineText';
import ToolBoxSmall from '../../../assets/icons/ToolBoxSmall.svg';
import { format } from 'date-fns';
import { CircleIcon } from '../../icons/Circle';
import { styleUtility } from '../../../utility/styleUtility';
import UserDropdown from '../../molecules/UserDropown';
import { useMutation, useQuery } from '@apollo/client';
import { notify } from '../../../utility/notify';
import { RowsPerPage, TablePagination } from '../../molecules/Pagination';
import Table from '../../molecules/Table';
import AvatarStack from '../../atoms/AvatarStack';
import { jobsUtility } from '../../../utility/jobs';
import Tag from '../../atoms/Tag';
import DifficultyIndicator from '../../atoms/DifficultyIndactor';
import UpsertScheme from '../../molecules/Modals/UpsertScheme';

interface Props {
  data: ReadSchemeQuery['readScheme'];
}

const UPDATE_SCHEME = gql(`
  mutation UpdateScheme ($input: UpdateSchemeInput!) {
    updateScheme (input: $input) {
      uuid
    }
  }  
`);

const JOBS_QUERY = gql(`
  query IndexJobsForSchemePage ($filters: IndexJobsFilterInput, $pagination: PaginationInput) {
    indexJobs(filters: $filters, pagination: $pagination) {
      items {
        uuid
        type 
        status
        difficulty
        customer {
          firstName
          lastName
        }
        address {
          postcode
        }
        startDate
      }
      pagination {
        lastPage
      }
    }
  }
  `);

const Scheme = ({ data }: Props): ReactElement => {
  const [scheme, setScheme] = useState(data);
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [showEditSchemeModal, setShowEditSchemeModal] = useState(false);
  const router = useRouter();

  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);

  const [mutate] = useMutation(UPDATE_SCHEME, {
    onCompleted: () => notify.success('Updated scheme.'),
    onError: (err) => notify.error(err.message),
  });

  const { data: jobs, loading } = useQuery(JOBS_QUERY, {
    variables: {
      pagination: {
        perPage: rowsPerPage,
        page,
      },
      filters: {
        schemeUuid: data.uuid,
      },
    },
  });

  return (
    <>
      <div className="flex w-full flex-col p-5 overflow-hidden h-full">
        <div className="flex items-center mb-5">
          <Button
            onClick={() => router.history.back()}
            bStyle="clean-dark"
            Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
          />
          <div className="px-2.5 flex-grow">
            <p className="font-semibold">Scheme #{scheme.uuid}</p>
          </div>
          <Button
            className="mr-3"
            onClick={() => setShowAuditTrail(true)}
            bText="Audit trail"
            bSize="sm"
            bStyle="light"
          />
        </div>
        <div className="bg-white rounded p-5 mb-5 flex flex-col">
          <div className="flex justify-between items-center mb-5">
            <div className="flex items-center">
              <RectangleStackIcon className="text-primary size-11 mr-2" />
              <h1 className="font-nunito text-h1 font-bold">{scheme.name}</h1>
            </div>
            <Button
              bStyle="outline"
              bText="Edit"
              onClick={() => setShowEditSchemeModal(true)}
            />
          </div>
          <div className="grid-cols-3 grid gap-5">
            <TwoLineText
              label="Partner"
              Icon={<BuildingOffice2Icon className="size-5 text-grey-500" />}
              text={scheme.partnerName}
            />
            <TwoLineText
              label="Type"
              Icon={
                scheme.schemeType === SchemeType.fullService ? (
                  <DocumentCheckIcon className="size-5 text-grey-400" />
                ) : (
                  <img src={ToolBoxSmall} alt="Toolbox" />
                )
              }
              text={
                scheme.schemeType === SchemeType.fullService
                  ? 'Full service'
                  : 'Installation'
              }
            />
            <TwoLineText
              label="Dates"
              Icon={<CalendarIcon className="size-5 text-grey-500" />}
              text={`${format(scheme.startDate, 'd MMMM')} - ${
                scheme.formattedEndDate
              }`}
            />
            <TwoLineText
              label="Staus"
              Icon={
                <CircleIcon
                  multiplier={4}
                  colour={
                    scheme.status === SchemeStatus.current
                      ? styleUtility.colours.primary['DEFAULT']
                      : scheme.status === SchemeStatus.upcoming
                      ? styleUtility.colours.amber['DEFAULT']
                      : styleUtility.colours.red['DEFAULT']
                  }
                />
              }
              text={styleUtility.capitalise(scheme.status)}
            />

            <TwoLineText
              label="Operator"
              text={
                <div>
                  <UserDropdown
                    simple
                    partnerUuid={scheme.partnerUuid}
                    userUuid={scheme.operatorUuid ?? undefined}
                    setUserUuid={(_, profileUuid) => {
                      setScheme((sch) => ({
                        ...sch,
                        operatorUuid: profileUuid,
                      }));
                      mutate({
                        variables: {
                          input: {
                            uuid: scheme.uuid,
                            operatorUuid: profileUuid,
                          },
                        },
                      });
                    }}
                  />
                </div>
              }
            />

            <TwoLineText
              label="Pre auditor"
              text={
                <div>
                  <UserDropdown
                    simple
                    partnerUuid={scheme.partnerUuid}
                    userUuid={scheme.preAuditorUuid ?? undefined}
                    setUserUuid={(_, profileUuid) => {
                      setScheme((sch) => ({
                        ...sch,
                        preAuditorUuid: profileUuid,
                      }));
                      mutate({
                        variables: {
                          input: {
                            uuid: scheme.uuid,
                            preAuditorUuid: profileUuid,
                          },
                        },
                      });
                    }}
                  />
                </div>
              }
            />

            <TwoLineText
              label="General auditor"
              text={
                <div>
                  <UserDropdown
                    simple
                    partnerUuid={scheme.partnerUuid}
                    userUuid={scheme.postAuditorUuid ?? undefined}
                    setUserUuid={(_, profileUuid) => {
                      setScheme((sch) => ({
                        ...sch,
                        postAuditorUuid: profileUuid,
                      }));
                      mutate({
                        variables: {
                          input: {
                            uuid: scheme.uuid,
                            postAuditorUuid: profileUuid,
                          },
                        },
                      });
                    }}
                  />
                </div>
              }
            />

            <TwoLineText
              label="Electrical auditor"
              text={
                <div>
                  <UserDropdown
                    simple
                    partnerUuid={scheme.partnerUuid}
                    userUuid={scheme.electricalAuditorUuid ?? undefined}
                    setUserUuid={(_, profileUuid) => {
                      setScheme((sch) => ({
                        ...sch,
                        electricalAuditorUuid: profileUuid,
                      }));
                      mutate({
                        variables: {
                          input: {
                            uuid: scheme.uuid,
                            electricalAuditorUuid: profileUuid,
                          },
                        },
                      });
                    }}
                  />
                </div>
              }
            />

            <TwoLineText
              label="Roofing Auditor"
              text={
                <div>
                  <UserDropdown
                    simple
                    partnerUuid={scheme.partnerUuid}
                    userUuid={scheme.preAuditorUuid ?? undefined}
                    setUserUuid={(_, profileUuid) => {
                      setScheme((sch) => ({
                        ...sch,
                        roofingAuditorUuid: profileUuid,
                      }));
                      mutate({
                        variables: {
                          input: {
                            uuid: scheme.uuid,
                            roofingAuditorUuid: profileUuid,
                          },
                        },
                      });
                    }}
                  />
                </div>
              }
            />
          </div>
        </div>
        <div className="flex h-full flex-col rounded-lg overflow-hidden">
          <Table
            title="Jobs"
            loading={loading}
            columns={[
              {
                width: 15,
                heading: 'customer',
              },
              {
                width: 15,
                heading: 'postcode',
              },
              {
                width: 15,
                heading: 'type',
              },
              {
                width: 15,
                heading: 'state',
              },
              {
                width: 15,
                heading: 'difficulty',
              },
              {
                width: 15,
                heading: 'date',
              },
              {
                width: 10,
              },
            ]}
            widthType="pc"
            rows={
              jobs?.indexJobs.items.map((i) => ({
                uuid: '1',
                cells: [
                  {
                    width: 15,
                    content: (
                      <>
                        <AvatarStack
                          height="h-9"
                          width="w-9"
                          avatars={[
                            {
                              firstName: i.customer.firstName,
                              lastName: i.customer.lastName,
                            },
                          ]}
                        />
                        <Link to="/contacts/$uuid" params={{ uuid: '' }}>
                          <span className="underline text-body-small block ml-2">
                            {i.customer.firstName} {i.customer.lastName}
                          </span>
                        </Link>
                      </>
                    ),
                  },
                  {
                    width: 15,
                    content: 'B12 4RT',
                  },
                  {
                    width: 15,
                    content: (
                      <>
                        {jobsUtility.jobTypeIconMap[i.type]}
                        <span className="text-body-small block ml-2">
                          {styleUtility.capitalise(i.type)}
                        </span>
                      </>
                    ),
                  },
                  {
                    width: 15,
                    content: <Tag colour="notSigned" text="Not signed" />,
                  },
                  {
                    width: 15,
                    content: (
                      <>
                        <DifficultyIndicator difficulty={i.difficulty} />
                        <span className="text-body-small block ml-2">
                          {styleUtility.capitalise(i.difficulty)}
                        </span>
                      </>
                    ),
                  },
                  {
                    width: 15,
                    content: i.startDate ? (
                      <>
                        <CalendarIcon className="size-5 text-grey-400" />
                        <span className="text-body-small block ml-2">
                          {format(new Date(i.startDate), 'do MMMM yyy')}
                        </span>
                      </>
                    ) : (
                      <></>
                    ),
                  },
                  {
                    width: 10,
                    content: (
                      <div className="flex justify-end w-full">
                        <Button
                          href={`/job/${i.uuid}`}
                          bText="View"
                          bStyle="light"
                          className="h-9 text-button-small"
                        />
                      </div>
                    ),
                  },
                ],
              })) ?? []
            }
          />
          <TablePagination
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            totalPages={jobs?.indexJobs.pagination.lastPage}
          />
        </div>
      </div>
      <UpsertScheme
        uuid={scheme.uuid}
        name={scheme.name}
        partnerName={scheme.partnerName}
        partnerUuid={scheme.partnerUuid}
        operatorUuid={scheme.operatorUuid ?? undefined}
        preAuditorUuid={scheme.preAuditorUuid ?? undefined}
        postAuditorUuid={scheme.postAuditorUuid ?? undefined}
        electricalAuditorUuid={scheme.electricalAuditorUuid ?? undefined}
        roofingAuditorUuid={scheme.roofingAuditorUuid ?? undefined}
        open={showEditSchemeModal}
        onClose={() => setShowEditSchemeModal(false)}
      />
    </>
  );
};
export default Scheme;
