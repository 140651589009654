import { createFileRoute } from '@tanstack/react-router';
import { ReactNode } from 'react';
import Loader from '../../../components/icons/Loader';

const UserUserProfileSingle = (): ReactNode => {
  const data = Route.useLoaderData();
  return <>{data}</>;
};

export const Route = createFileRoute('/_authenticated/tickets/$uuid')({
  component: UserUserProfileSingle,
  pendingComponent: () => (
    <div className="flex items-center h-full justify-center">
      <Loader />
    </div>
  ),
  loader: ({ params }) => params.uuid,
});
