import { Field, Label, Radio, RadioGroup } from '@headlessui/react';

type Option<T> = {
  name: string;
  value: T;
  itemClassname?: string;
  disabled?: boolean;
};

interface Props<T> {
  title?: string;
  options: Option<T>[];
  onSelectedOption: (option: T) => void;
  selectedOption?: T;
}

function RadioSelector<T = string>({
  title,
  options,
  onSelectedOption,
  selectedOption,
}: Props<T>) {
  return (
    <div className="flex flex-col !-mb-6">
      {title && (
        <span className="mb-2 text-input-label block font-semibold">
          {title}
        </span>
      )}
      <RadioGroup
        value={selectedOption}
        onChange={onSelectedOption}
        className="flex my-2.5 items-center flex-wrap"
      >
        {options.map((option) => (
          <Field key={option.name} className="flex mr-6 mb-6 space-x-3 items-center">
            <Radio
              disabled={option.disabled}
              value={option.value}
              className="group flex size-6 items-center justify-center rounded-full border-2 bg-white data-[disabled]:[&:not([data-checked])]:bg-grey-900 data-[disabled]:[&:not([data-checked])]:border-grey-700 data-[disabled]:data-[checked]:border-primary-300  data-[checked]:border-primary cursor-pointer"
            >
              <span className="invisible size-3 rounded-full group-data-[disabled]:group-data-[checked]:bg-primary-300 bg-primary group-data-[checked]:visible" />
            </Radio>
            <Label className="data-[disabled]:opacity-50 cursor-pointer">
              {option.name}
            </Label>
          </Field>
        ))}
      </RadioGroup>
    </div>
  );
}

export default RadioSelector;
