import { gql } from '@monorepo/graphql';

const SLOT_FRAGMENT = gql(`
  fragment Slot on Slot {
    uuid
    isElectricianAvailable
    isRooferAvailable
    isInstallationSelected
    isRemedialSelected 
    isBatterySelected
    schemeName
    job {
      uuid
      status
      type 
      atRisk
      contractors {
        firstName
        lastName 
        avatarSrc
      }
      difficulty
      displayInstallationDate
      customer {
        firstName
        lastName
        line1
        city
        postcode
        schemeName
      }
    }
  }
`);

export const slotsUtility = {
  queries: {
    SLOT_FRAGMENT,
    GET_SLOTS: gql(`
      query IndexSlots($input: IndexSlotsInput!) {
        indexSlots (input: $input) {
          items {
            ...Slot
          }      
        }
      },
    `),
  },
};
