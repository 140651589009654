import { ReactElement, useState } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import Table from '../../Table';
import { CalendarIcon } from '@heroicons/react/24/outline';
import AvatarStack from '../../../atoms/AvatarStack';
import { gql, TargetType } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { RowsPerPage, TablePagination } from '../../Pagination';

type Props = {
  userUuid?: string;
  targetType?: TargetType;
  targetUuid?: string;
} & ModalWrapperPropsExtends;

const GET_AUDIT_ENTRIES = gql(`
  query GetAuditEntriesForModal($filters: IndexAuditEntriesInput!, $pagination: PaginationInput!) {
    indexAuditEntries(filters: $filters, pagination: $pagination) {
      items {
        uuid
        title
        createdAt
        targetType
        auditEntryUser {
          firstName
          lastName
          avatarSrc
          userType
        }
      }
      pagination {
        lastPage
      }
    }
  }
  `);

const AuditModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <AuditModalChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const AuditModalChild = ({
  onClose,
  targetType,
  targetUuid,
  userUuid,
}: Omit<Props, 'open'>): ReactElement => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.ten);
  const [totalPages, setTotalPages] = useState(1);

  const { data, loading } = useQuery(GET_AUDIT_ENTRIES, {
    variables: {
      filters: {
        targetType,
        targetUuid,
        userUuid,
      },
      pagination: {
        page,
        perPage: rowsPerPage,
      },
    },
    onCompleted: (data) => {
      setTotalPages(data.indexAuditEntries?.pagination.lastPage ?? 1);
    },
  });

  return (
    <Modal
      loading={loading}
      onClose={onClose}
      title="Audit trail"
      closeText="Close"
      hideTopHr
    >
      <Table
        columns={[
          {
            heading: 'user',
            width: 200,
          },
          {
            heading: 'action',
            width: 296,
          },
          {
            heading: 'date',
            width: 240,
          },
        ]}
        widthType="fixed"
        rows={
          data?.indexAuditEntries?.items.map((d) => ({
            uuid: d.uuid,
            cells: [
              {
                content: d.auditEntryUser ? (
                  <div className="flex items-center space-x-2">
                    <AvatarStack
                      avatars={[
                        {
                          firstName: d.auditEntryUser.firstName,
                          lastName: d.auditEntryUser.lastName,
                        },
                      ]}
                      width="w-8"
                      height="h-8"
                    />
                    <span className="text-body-small">
                      {d.auditEntryUser.firstName} {d.auditEntryUser.lastName}
                    </span>
                  </div>
                ) : (
                  '-'
                ),
                width: 200,
              },
              { content: d.title, width: 296 },
              {
                content: (
                  <div className="flex items-center space-x-2">
                    <CalendarIcon className="h-5 text-grey-400" />
                    <span className="text-body-small">
                      {format(d.createdAt, 'do MMM yyyy @ H:mm')}
                    </span>
                  </div>
                ),
                width: 240,
              },
            ],
          })) ?? []
        }
      />
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </Modal>
  );
};
export default AuditModal;
