import { useForm } from '@tanstack/react-form';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { InstallationIcon } from '../../../icons/Installation';
import {
  Battery50Icon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';
import { styleUtility } from '../../../../utility/styleUtility';
import SchemeDropdown from '../../SchemeDropdown';
import { usePartners } from '../../../../utility/partnerContext';
import { format } from 'date-fns';
import CheckboxCard from '../../../atoms/CheckboxCard';
import { useMutation } from '@apollo/client';
import { gql } from '@monorepo/graphql';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';

type Props = {
  uuid?: string;
  date: Date;
  isInstallationSelected: boolean;
  isRemedialSelected: boolean;
  isBatterySelected: boolean;
  isInstallationAvailable: boolean;
  isRemedialAvailable: boolean;
  isBatteryAvailable: boolean;
} & ModalWrapperPropsExtends

const UPDATE_SLOT = gql(`
  mutation UpdateSlot($input: UpdateSlotInput!) {
    updateSlot(input: $input) {
      uuid
      isInstallationSelected
      isRemedialSelected 
      isBatterySelected
    }
  }  
`);

export const ConfigureSlotModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper dialogPanelClassname="w-140" open={open} onClose={onClose}>
    <ConfigureSlotModalChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const ConfigureSlotModalChild = ({
  onClose,
  date,
  uuid,
  isInstallationSelected,
  isRemedialSelected,
  isBatterySelected,
  isInstallationAvailable,
  isRemedialAvailable,
  isBatteryAvailable,
}: Omit<Props, 'open'>) => {
  const [updateSlot, { error, loading }] = useMutation(UPDATE_SLOT);

  const { selectedPartner } = usePartners();

  const form = useForm<{
    schemeUuid?: string;
    selected: {
      isInstallationSelected: boolean;
      isRemedialSelected: boolean;
      isBatterySelected: boolean;
    };
  }>({
    onSubmit: (vals) => {
      if (!uuid) return;
      updateSlot({
        variables: {
          input: {
            uuid,
            schemeUuid: vals.value.schemeUuid,
            ...vals.value.selected,
          },
        },
      }).then(() => {
        notify.success('Saved slot.');
        onClose(true);
      });
    },
    defaultValues: {
      selected: {
        isInstallationSelected,
        isRemedialSelected,
        isBatterySelected,
      },
    },
  });

  return (
    <Modal
      title="Configure Slot"
      onClose={onClose}
      confirmText="Save config"
      loading={loading}
      confirmCallback={form.handleSubmit}
    >
      <div className="p-5 max-w-150 w-full">
        <div className="flex mb-5">
          <div className="basis-1/2">
            <span className="text-input-label font-semibold">Partner</span>
            <div className="flex space-x-2 mt-2.5">
              <BuildingOffice2Icon className="size-5 text-grey-400" />
              <p>{selectedPartner?.name}</p>
            </div>
          </div>
          <div>
            <span className="text-input-label font-semibold">Date</span>
            <div className="flex space-x-2 mt-2.5">
              <CalendarDaysIcon className="size-5 text-grey-400" />
              <p>{format(date, 'EEEE do MMMM yyyy')}</p>
            </div>
          </div>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="mb-5">
            <div className="mb-5 w-full">
              <span className="mb-2 text-input-label block font-semibold">
                Slot Type
              </span>
              <form.Field
                name="selected"
                validators={{
                  onSubmit: ({ value }) =>
                    !value.isBatterySelected &&
                    !value.isInstallationSelected &&
                    !value.isRemedialSelected
                      ? 'Please select a job type'
                      : undefined,
                }}
                children={({ state, handleChange }) => (
                  <>
                    <div className="flex space-x-2">
                      <CheckboxCard
                        disabled={!isInstallationAvailable}
                        Icon={
                          <InstallationIcon
                            multiplier={1.1}
                            colour={`${
                              isInstallationAvailable
                                ? styleUtility.colours.primary['DEFAULT']
                                : styleUtility.colours['text-disabled']
                            }${isInstallationAvailable ? '' : '80'}`}
                          />
                        }
                        title="Installation"
                        checked={state.value.isInstallationSelected}
                        setChecked={(checked) =>
                          handleChange((f) => ({
                            ...f,
                            isBatterySelected: false,
                            isRemedialSelected: false,
                            isInstallationSelected: checked,
                          }))
                        }
                        explainer="First time visit"
                      />
                      <CheckboxCard
                        disabled={!isRemedialAvailable}
                        Icon={
                          <WrenchScrewdriverIcon
                            className={`${
                              isRemedialAvailable
                                ? 'text-primary'
                                : 'text-text-disabled/50'
                            } size-8`}
                          />
                        }
                        title="Remedial"
                        checked={state.value.isRemedialSelected}
                        setChecked={(checked) =>
                          handleChange((f) => ({
                            ...f,
                            isInstallationSelected: false,
                            isRemedialSelected: checked,
                          }))
                        }
                        explainer="Upgrade or repair"
                      />
                      <CheckboxCard
                        disabled={!isBatteryAvailable}
                        Icon={
                          <Battery50Icon
                            className={`${
                              isBatteryAvailable
                                ? 'text-primary'
                                : 'text-text-disabled/50'
                            } size-8`}
                          />
                        }
                        title="Battery"
                        checked={state.value.isBatterySelected}
                        setChecked={(checked) =>
                          handleChange((f) => ({
                            ...f,
                            isInstallationSelected: false,
                            isBatterySelected: checked,
                          }))
                        }
                        explainer="Battery only"
                      />
                    </div>
                    {state.meta.errors.length > 0 && (
                      <div className="flex py-2 px-3 border-red--100 bg-red-500 border mb-5 rounded mt-2">
                        <span>{state.meta.errors.join(', ')}</span>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
          </div>

          <form.Field
            name="schemeUuid"
            children={({ state, handleChange }) => (
              <SchemeDropdown
                schemeUuid={state.value ?? ''}
                partnerUuid={selectedPartner!.uuid}
                setSchemeUuid={handleChange}
              />
            )}
          />
        </form>
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
