import {
  createRootRouteWithContext,
  Outlet,
  useLocation,
  useNavigate,
} from '@tanstack/react-router';
import { useAuth } from '../utility/authentication';
import { useEffect } from 'react';
import NotFoundScreen from '../components/organisms/NotFoundScreen';

const openRoutes = ['/login', '/reset-password'];
const skippedRoutes = ['/feedback'];

const RootComponent = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();
  useEffect(() => {
    if (!skippedRoutes.includes(location.pathname)) {
      if (user) {
        if (openRoutes.includes(location.pathname)) {
          navigate({
            to: '/',
          });
        }
      } else {
        if (!openRoutes.includes(location.pathname)) {
          navigate({
            to: '/login',
          });
        }
      }
    }
  }, [user, navigate, location]);

  return <Outlet />;
};

export const Route = createRootRouteWithContext()({
  component: RootComponent,
  notFoundComponent: () => <NotFoundScreen isRoot />,
});
