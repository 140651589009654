import { CSSProperties, ReactElement, ReactNode, useEffect } from 'react';
import Pagination from '../Pagination';
import Loader from '../../icons/Loader';
import EmptyState from '../EmptyState';

type Columns = Array<{
  heading?: string;
  className?: string;
  width: number;
}>;

type Rows = Array<{
  uuid: string;
  cells: Array<{
    content: ReactNode;
    width: number;
  }>;
}>;

type WidthTypes = 'fixed' | 'pc';

interface Props {
  title?: string;
  columns: Columns;
  widthType: WidthTypes;
  rows: Rows;
  toolbar?: ReactElement;
  loading?: boolean;
  containerClass?: string;
}

const getStyles = (widthType: WidthTypes, width: number): CSSProperties => {
  if (widthType === 'pc')
    return {
      width: `${width}%`,
      flexGrow: 1,
    };
  return {
    width: `${width}px`,
    minWidth: `${width}px`,
    maxWidth: `${width}px`,
  };
};

function Table({
  title,
  columns,
  widthType,
  rows,
  toolbar,
  loading,
  containerClass = '',
}: Props): ReactElement {
  return (
    <div
      className={`bg-white overflow-hidden flex-grow flex flex-col ${containerClass}`}
    >
      {title && (
        <div className="flex p-5 items-center border-b border-grey-700">
          <div className="flex items-center flex-grow">
            <h2 className="text-h2 font-bold font-nunito mr-5">{title}</h2>
            {loading && <Loader multiplier={0.5} />}
          </div>
          {toolbar}
        </div>
      )}
      <div className="flex border-b border-grey-700">
        {columns.map((c, i) => (
          <div
            key={`${c.heading}-${i}`}
            style={getStyles(widthType, c.width)}
            className={`flex items-center p-5 font-bold uppercase text-grey-400 ${c.className}`}
          >
            <span className="text-button-small">{c.heading}</span>
          </div>
        ))}
      </div>
      <div className="flex flex-col border-grey-700 overflow-hidden flex-grow">
        <div className="overflow-scroll flex-grow">
          {rows.length ? (
            rows.map(
              (r) =>
                r.uuid && (
                  <div
                    key={r.uuid}
                    className="border-b last:border-b-0 border-grey-700 flex items-center w-full"
                  >
                    {r.cells.map((c, i) => (
                      <div
                        key={i}
                        style={getStyles(widthType, c.width)}
                        className="flex items-center px-5 py-3"
                      >
                        {typeof c.content === 'string' ? (
                          <span className="text-button-small">{c.content}</span>
                        ) : (
                          c.content
                        )}
                      </div>
                    ))}
                  </div>
                )
            )
          ) : !loading ? (
            <EmptyState
              title="No data found"
              description="No data was found in this table."
            />
          ) : (
            <div className="h-full py-5 flex-grow flex items-center justify-center">
              <Loader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Table;
