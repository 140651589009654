import { ReactElement, useState } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input } from '../../../atoms/Input';
import { addressUtility } from '../../../../utility/address';
import { gql } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';

const UPDATE_ADDRESS = gql(`
    mutation UpdateAddress ($input: UpdateAddressInput!) {
      updateAddress (input: $input) {
        uuid
      }
    }
`);

type Address = {
  line1: string;
  line2?: string;
  city?: string;
  county?: string;
  postcode: string;
};

type Props = {
  uuid: string;
  open: boolean;
  latitude?: number;
  longitude?: number;
  onClose: (success: boolean, data?: Address) => void;
} & Address &
  Omit<ModalWrapperPropsExtends, 'onClose'>;

const EditAddress = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper dialogPanelClassname="w-120" open={open} onClose={onClose}>
    <EditAddressChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const EditAddressChild = ({
  uuid,
  line1,
  line2 = '',
  city = '',
  county = '',
  postcode,
  onClose,
  latitude,
  longitude,
}: Omit<Props, 'open'>): ReactElement => {
  const [update, { loading, error }] = useMutation(UPDATE_ADDRESS);
  const [fetchingPostcode, setFetchingPostcode] = useState(false);

  const [coordinates, setCoordinates] = useState<
    | {
        lat: number;
        lon: number;
      }
    | undefined
  >(
    latitude && longitude
      ? {
          lat: latitude,
          lon: longitude,
        }
      : undefined
  );

  const addressForm = useForm({
    onSubmit: ({ value }) => {
      if (!coordinates || fetchingPostcode) return;
      update({
        variables: {
          input: {
            uuid,
            latitude: coordinates.lat,
            longitude: coordinates.lon,
            ...value,
          },
        },
        onCompleted: () => {
          notify.success('Succesfully updated address');
          onClose(true, value);
        },
      });
    },
    defaultValues: {
      line1,
      line2,
      city,
      county,
      postcode,
    },
  });

  return (
    <Modal
      title="Edit address"
      confirmCallback={addressForm.handleSubmit}
      confirmText="Save changes"
      onClose={(success) => onClose(success)}
      loading={loading}
    >
      <div className="p-5">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <addressForm.Field
            name="line1"
            preserveValue
            validators={{
              onSubmit: ({ value }) =>
                !value ? 'Please add the first line of the address' : undefined,
            }}
            children={({ state, handleChange }) => (
              <Input
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Address line one"
                error={state.meta.errors.join(', ')}
                className="w-80"
              />
            )}
          />

          <addressForm.Field
            name="line2"
            preserveValue
            children={({ state, handleChange }) => (
              <Input
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="Address line two (optional)"
                error={state.meta.errors.join(', ')}
                className="w-80"
              />
            )}
          />

          <addressForm.Field
            name="city"
            preserveValue
            children={({ state, handleChange }) => (
              <Input
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="City (optional)"
                error={state.meta.errors.join(', ')}
                className="w-80"
              />
            )}
          />

          <addressForm.Field
            name="county"
            preserveValue
            children={({ state, handleChange }) => (
              <Input
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                label="County (optional)"
                error={state.meta.errors.join(', ')}
                className="w-80"
              />
            )}
          />

          <addressForm.Field
            name="postcode"
            preserveValue
            validators={{
              onChangeAsync: async ({ value }) => {
                if (coordinates) return undefined;
                if (!value.length) return 'Please add a valid postcode';
                setFetchingPostcode(true);
                const rsp = await addressUtility.getLatLon(value);
                setFetchingPostcode(false);
                if (!rsp) return 'Invalid postcode.';
                setCoordinates(rsp);
                return undefined;
              },
              onChangeAsyncDebounceMs: 500,
            }}
            children={({ state, handleChange, handleBlur }) => (
              <Input
                value={state.value}
                onBlur={handleBlur}
                onChange={(e) => {
                  if (coordinates) setCoordinates(undefined);
                  handleChange(e.target.value);
                }}
                label="Postcode"
                error={state.meta.errors.join(', ')}
                className="w-80"
                loading={fetchingPostcode}
                success={coordinates ? 'Location details saved.' : undefined}
              />
            )}
          />
        </form>
        {error && (
          <div className="mt-5">
            <Alert alertType="error" text={error.message} />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default EditAddress;
