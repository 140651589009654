import { ReactElement, useState } from 'react';
import { gql } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import Table from '../../../../../molecules/Table';
import AvatarStack from '../../../../../atoms/AvatarStack';
import { Link } from '@tanstack/react-router';
import Tag from '../../../../../atoms/Tag';
import { jobsUtility } from '../../../../../../utility/jobs';
import { Button } from '../../../../../atoms/Button';
import { TablePagination } from '../../../../../molecules/Pagination';

const INDEX_AUDIT_PENDING_JOBS = gql(`
  query IndexAuditPendingJobsForSection ($pagination: PaginationInput, $myAssignment: Boolean!) {
    auditPendingJobs (pagination: $pagination, myAssignment: $myAssignment) {
      items {
        uuid 
        status 
        customer {
          userUuid
          firstName
          lastName
          avatarSrc
        }
        address {
          postcode
        }
      }
      pagination {
        lastPage
      }
    }
  }  
`);

const AuditPending = ({
  myAssignment
}: {
  myAssignment: boolean
}): ReactElement => {
  const [page, setPage] = useState(1);
  const { data, loading } = useQuery(INDEX_AUDIT_PENDING_JOBS, {
    variables: {
      pagination: {
        perPage: 15,
        page,
      },
      myAssignment
    },
  });

  return (
    <>
      <Table
        loading={loading}
        widthType="pc"
        columns={[
          {
            heading: 'customer',
            width: 40,
          },
          {
            heading: 'postcode',
            width: 25,
          },
          {
            heading: 'state',
            width: 20,
          },
          {
            width: 15,
          },
        ]}
        rows={
          data?.auditPendingJobs.items.map((j) => ({
            uuid: j.uuid,
            cells: [
              {
                content: (
                  <Link
                    className="flex items-center"
                    to="/contacts/$uuid"
                    params={{ uuid: j.customer.userUuid }}
                  >
                    <AvatarStack
                      width="w-9"
                      height="h-9"
                      avatars={[
                        {
                          firstName: j.customer.firstName,
                          lastName: j.customer.lastName,
                          avatarSrc: j.customer.avatarSrc,
                        },
                      ]}
                    />
                    <span className="text-sm underline ml-2">
                      {j.customer.firstName} {j.customer.lastName}
                    </span>
                  </Link>
                ),
                width: 40,
              },
              {
                content: j.address?.postcode ?? '-',
                width: 25,
              },
              {
                content: (
                  <Tag
                    colour={j.status}
                    text={jobsUtility.jobStatusNiceMap[j.status]}
                  />
                ),
                width: 20,
              },
              {
                content: (
                  <div className="flex justify-end w-full">
                    <Button
                      bStyle="light"
                      className="h-9"
                      bText="View"
                      href={`/job/${j.uuid}`}
                    />
                  </div>
                ),
                width: 15,
              },
            ],
          })) ?? []
        }
      />
      <TablePagination
        page={page}
        setPage={setPage}
        totalPages={data?.auditPendingJobs.pagination.lastPage}
      />
    </>
  );
};
export default AuditPending;
