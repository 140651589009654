import { ReactElement } from 'react';
import { Modal, ModalWrapper } from '../Base';
import { useForm } from '@tanstack/react-form';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import { styleUtility } from '../../../../utility/styleUtility';
import { gql } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';

type Props = {
  open: boolean;
  availability: string;
  contractorProfileUuid: string;
  onClose: (success: boolean, availability: string) => void;
};

const UPDATE_USER_CONTRACTOR_AVAILABILITY = gql(`
  mutation UpdateUserContractorProfileAvailability($input: UpdateUserContractorProfileInput!) {
    updateUserContractorProfile(input: $input) {
      uuid
      availability
    }
  }
`);

const EditContractorAvailability = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper
    dialogPanelClassname="w-120"
    open={open}
    onClose={() => onClose(false, rest.availability)}
  >
    <EditContractorAvailabilityChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const EditContractorAvailabilityChild = ({
  onClose,
  contractorProfileUuid,
  availability,
}: Omit<Props, 'open'>): ReactElement => {
  const [mutate, { loading, error }] = useMutation(
    UPDATE_USER_CONTRACTOR_AVAILABILITY
  );

  const professionalForm = useForm<{
    availability: {
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
      sunday: boolean;
    };
  }>({
    onSubmit: ({ value }) => {
      const avail = Object.keys(value.availability).reduce(
        (prev, curr, index) =>
          value.availability[curr as keyof typeof value.availability]
            ? `${prev}${index + 1}`
            : prev,
        ''
      );
      mutate({
        variables: {
          input: {
            uuid: contractorProfileUuid,
            availability: avail,
          },
        },
        onCompleted: () => {
          notify.success('Succesfully updated contractor');
          onClose(true, avail);
        },
      });
    },
    defaultValues: {
      availability: {
        monday: availability.includes('1'),
        tuesday: availability.includes('2'),
        wednesday: availability.includes('3'),
        thursday: availability.includes('4'),
        friday: availability.includes('5'),
        saturday: availability.includes('6'),
        sunday: availability.includes('7'),
      },
    },
  });

  return (
    <Modal
      title="Edit availability"
      onClose={(success) => onClose(success, availability)}
      confirmCallback={professionalForm.handleSubmit}
      confirmText="Save changes"
      loading={loading}
    >
      <div className="p-5 space-y-5">
        <div>
          <span className="font-semibold text-input-label block mb-2 text-text-normal">
            Select working days
          </span>
          <professionalForm.Field
            name="availability"
            preserveValue
            children={({ state, handleChange }) =>
              Object.keys(state.value).map((k) => (
                <div className="py-2.5">
                  <SimpleCheckbox
                    label={styleUtility.capitalise(k)}
                    checked={state.value[k as keyof typeof state.value]}
                    setChecked={(checked) =>
                      handleChange((f) => ({
                        ...f,
                        [k]: checked,
                      }))
                    }
                  />
                </div>
              ))
            }
          />
        </div>
        {error ? (
          <Alert text={error.message} alertType="error" />
        ) : (
          <Alert
            alertType="warning"
            text="These changes will take effect 6 weeks from now."
          />
        )}
      </div>
    </Modal>
  );
};
export default EditContractorAvailability;
