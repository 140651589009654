import { ReactElement } from 'react';
import CalendarDay from '../CalendarDay';
import { addDays, format, isSameDay, startOfWeek } from 'date-fns';
import { CalendarPeriod } from '../../../utility/calendarContext';
import { useQuery } from '@apollo/client';
import { gql } from '@monorepo/graphql';
import Loader from '../../icons/Loader';
import { styleUtility } from '../../../utility/styleUtility';
import { usePartners } from '../../../utility/partnerContext';

interface Props {
  period: CalendarPeriod;
  selectedDate: Date;
}

const days: Record<CalendarPeriod, number> = {
  week: 7,
  day: 1,
  weekdays: 5,
};

const GET_SLOT_COUNT = gql(`
    query SlotCount($input: SlotCountInput!) {
      slotCount(input: $input)
    }
`);

const FreeDays = ({
  date,
  partnerUuid,
}: {
  date: Date;
  partnerUuid: string;
}) => {
  const { data, loading } = useQuery(GET_SLOT_COUNT, {
    variables: {
      input: {
        date,
        partnerUuid,
      },
    },
    context: {
      isBatched: true,
    },
  });
  return loading ? (
    <Loader
      multiplier={0.3}
      className="mt-2"
      colour={styleUtility.colours['text-low-priority']}
    />
  ) : (
    <p className="text-body-small text-text-low-priority">
      {data?.slotCount ?? '-'}
    </p>
  );
};

const CalendarDays = ({ period, selectedDate }: Props): ReactElement => {
  const { selectedPartner } = usePartners();

  if (period === 'day') {
    return <CalendarDay date={selectedDate} />;
  }

  const start = startOfWeek(selectedDate, { weekStartsOn: 1 });

  return (
    <div className="flex bg-white flex-col w-full h-full overflow-hidden">
      <div className="flex">
        {Array.from(new Array(days[period])).map((_, i) => (
          <div
            key={i}
            className="flex items-center flex-grow justify-center h-fit py-3 border-y border-grey-700 flex-col"
          >
            <div
              className={`mb-2.5 h-10 w-10 shrink-0 rounded-full flex items-center justify-center ${
                isSameDay(addDays(start, i), new Date())
                  ? 'bg-primary-600 text-primary'
                  : ''
              }`}
            >
              <h3 className="font-nunito leading-none text-h3 font-bold">
                {format(addDays(start, i), 'd')}
              </h3>
            </div>
            <p className="text-body-small font-semibold">
              {format(addDays(start, i), 'EEEE')}
            </p>
            {selectedPartner && (
              <FreeDays
                partnerUuid={selectedPartner.uuid}
                date={addDays(start, i)}
              />
            )}
          </div>
        ))}
      </div>
      <div className="flex overflow-scroll grow">
        {Array.from(new Array(days[period])).map((_, i) => (
          <CalendarDay key={i} date={addDays(start, i)} />
        ))}
      </div>
    </div>
  );
};
export default CalendarDays;
