import { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import Tabs from '../../Tabs';
import Alert from '../../../atoms/Alerts';
import { FormApi, useForm } from '@tanstack/react-form';
import RadioSelector from '../../../atoms/RadioSelector';
import { Input } from '../../../atoms/Input';
import {
  ArrowDownTrayIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../../../atoms/Button';
import ExtrasTable, { Extra } from '../../ExtrasTable';
import SimpleModal from '../Simple';
import { useJobContext } from '../../../organisms/Job';
import {
  AuditType,
  gql,
  IndexJobAuditsQuery,
  ResponsibleContractorType,
  UserRole,
} from '@monorepo/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { useAuth } from '../../../../utility/authentication';
import { profileUtility } from '../../../../utility/profile';

const yesOrNoOptions = [
  {
    name: 'Yes (pass audit)',
    value: true,
  },
  {
    name: 'No (fail audit)',
    value: false,
  },
];

type Audit = IndexJobAuditsQuery['indexAuditsForJob'][0];

interface GeneralTabProps {
  audit: Audit;
  isAuthorised: boolean;
  form: FormApi<GeneralFormProps, undefined>;
}

interface GeneralFormProps {
  pass: boolean;
  notes: string;
}

interface NonStandardFormType {
  pass: boolean;
  notes: string;
  extras: Extra[];
}

const UPDATE_JOB_AUDIT = gql(`
  mutation UpdatePostJobAudit ($input: UpdateJobAuditInput!) {
    updateJobAudit (input: $input) {
      uuid
      notes
      hasPassed
      auditType 
      submittedAt
      auditor {
        uuid
        firstName
        lastName
        avatarSrc
      }
    }
  }  
`);

const GeneralTab = ({ audit, form, isAuthorised }: GeneralTabProps) => {
  const { setAudits } = useJobContext();
  const [canEdit, setCanEdit] = useState(!audit.submittedAt);

  useEffect(() => {
    if (canEdit && audit.submittedAt) {
      setAudits((a) =>
        a.map((aud) => {
          if (aud.auditType === AuditType.postGeneral) {
            return {
              ...aud,
              submittedAt: null,
            };
          }
          return aud;
        })
      );
    }
  }, [canEdit, setAudits, audit]);

  return (
    <form className="p-5 space-y-5" onSubmit={() => form.handleSubmit()}>
      {audit?.submittedAt ? (
        isAuthorised &&
        !canEdit && (
          <Alert
            alertType={audit.hasPassed ? 'success' : 'error'}
            text={
              <div className="flex flex-col items-start">
                <span className="text-body-small break-words">
                  {audit.hasPassed
                    ? 'This audit has already been submitted as “passed”. To edit it, click the button below.'
                    : 'This audit has already been submitted as “failed”. To edit it, click the button below.'}
                </span>
                <button
                  onClick={() => setCanEdit(true)}
                  type="button"
                  className="underline text-body-small mt-5 font-bold font-nunito"
                >
                  Edit audit
                </button>
              </div>
            }
          />
        )
      ) : (
        <div className="mb-5">
          <Alert
            alertType="info"
            text="Please review all notes and the general state of the job."
          />
        </div>
      )}

      <form.Field
        name="pass"
        children={({ state, handleChange }) => (
          <RadioSelector
            title="Is the job complete?"
            options={yesOrNoOptions.map((o) => ({
              ...o,
              disabled: !canEdit,
            }))}
            onSelectedOption={(value) => handleChange(value)}
            selectedOption={state.value}
          />
        )}
      />
      <form.Field
        name="notes"
        children={({ state, handleChange }) => (
          <Input
            disabled={!canEdit}
            label="Auditor notes (optional)"
            value={state.value}
            onChange={(e) => handleChange(e.target.value)}
            type="textarea"
          />
        )}
      />
    </form>
  );
};

const RoofingAudit = ({
  form,
  isAuthorised,
  audit,
}: {
  form: FormApi<NonStandardFormType & { solarPanelsInstalled: number }>;
  isAuthorised: boolean;
  audit: Audit;
}) => {
  const { setAudits, job } = useJobContext();
  const [canEdit, setCanEdit] = useState(!audit.submittedAt);
  const { extras: extrasField } = form.useStore((values) => values.values);

  useEffect(() => {
    if (canEdit && audit.submittedAt) {
      setAudits((a) =>
        a.map((aud) => {
          if (aud.auditType === AuditType.postRoofing) {
            return {
              ...aud,
              submittedAt: null,
            };
          }
          return aud;
        })
      );
    }
  }, [canEdit, setAudits, audit]);

  return (
    <div>
      <div className="p-5">
        {audit?.submittedAt ? (
          isAuthorised &&
          !canEdit && (
            <div className="mb-5">
              <Alert
                alertType={audit.hasPassed ? 'success' : 'error'}
                text={
                  <div className="flex flex-col items-start">
                    <span className="text-body-small break-words">
                      {audit.hasPassed
                        ? 'This audit has already been submitted as “passed”. To edit it, click the button below.'
                        : 'This audit has already been submitted as “failed”. To edit it, click the button below.'}
                    </span>
                    <button
                      onClick={() => setCanEdit(true)}
                      type="button"
                      className="underline text-body-small mt-5 font-bold font-nunito"
                    >
                      Edit audit
                    </button>
                  </div>
                }
              />
            </div>
          )
        ) : (
          <div className="mb-5">
            <Alert
              alertType="info"
              text="Please review all notes and the roofing state of the job."
            />
          </div>
        )}
        <h3 className="font-bold text-h3 font-nunito">Handovers</h3>
        <div className="flex mt-4">
          <Button
            bStyle="light"
            bText="Roofing"
            Icon={<ArrowDownTrayIcon className="size-6" />}
            reverse
          />
        </div>
      </div>
      <form className="space-y-5">
        <div className="px-5">
          <h3 className="font-bold text-h3 font-nunito mb-2 mt-5">
            Audit Form
          </h3>
        </div>
        <div className="px-5">
          <form.Field
            name="pass"
            children={({ state, handleChange }) => (
              <RadioSelector
                title="Is the job complete?"
                options={yesOrNoOptions.map((o) => ({
                  ...o,
                  disabled: !canEdit,
                }))}
                onSelectedOption={(value) => handleChange(value)}
                selectedOption={state.value}
              />
            )}
          />
        </div>
        <div className="px-5">
          <form.Field
            name="notes"
            children={({ state, handleChange }) => (
              <Input
                label="Auditor notes (optional)"
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                type="textarea"
                disabled={!canEdit}
              />
            )}
          />
        </div>

        {extrasField.length > 0 && (
          <div>
            <div className="px-5 mt-10">
              <h3 className="font-bold text-h3 font-nunito">
                Roofing extras installed
              </h3>
            </div>
            <ExtrasTable
              customerProfileUuid={job.customer.uuid}
              form={
                form as unknown as FormApi<
                  {
                    extras: Extra[];
                  },
                  undefined
                >
              }
              disabled={!canEdit}
            />
          </div>
        )}
      </form>
    </div>
  );
};

const ElectricalAudit = ({
  form,
  isAuthorised,
  audit,
}: {
  form: FormApi<NonStandardFormType>;
  isAuthorised: boolean;
  audit: Audit;
}) => {
  const { setAudits, job } = useJobContext();
  const [canEdit, setCanEdit] = useState(!audit.submittedAt);
  const { extras: extrasField } = form.useStore((values) => values.values);

  useEffect(() => {
    if (canEdit && audit.submittedAt) {
      setAudits((a) =>
        a.map((aud) => {
          if (aud.auditType === AuditType.postElectrical) {
            return {
              ...aud,
              submittedAt: null,
            };
          }
          return aud;
        })
      );
    }
  }, [canEdit, setAudits, audit]);

  return (
    <div>
      <div className="p-5">
        {audit?.submittedAt ? (
          isAuthorised &&
          !canEdit && (
            <div className="mb-5">
              <Alert
                alertType={audit.hasPassed ? 'success' : 'error'}
                text={
                  <div className="flex flex-col items-start">
                    <span className="text-body-small break-words">
                      {audit.hasPassed
                        ? 'This audit has already been submitted as “passed”. To edit it, click the button below.'
                        : 'This audit has already been submitted as “failed”. To edit it, click the button below.'}
                    </span>
                    <button
                      onClick={() => setCanEdit(true)}
                      type="button"
                      className="underline text-body-small mt-5 font-bold font-nunito"
                    >
                      Edit audit
                    </button>
                  </div>
                }
              />
            </div>
          )
        ) : (
          <div className="mb-5">
            <Alert
              alertType="info"
              text="Please review all notes and the electrical state of the job."
            />
          </div>
        )}
        <h3 className="font-bold text-h3 font-nunito">Handovers</h3>
        <div className="flex mt-4">
          <Button
            className="mr-2"
            bStyle="light"
            bText="Electrical"
            Icon={<ArrowDownTrayIcon className="size-6" />}
            reverse
          />
        </div>
      </div>
      <form className="space-y-5">
        <div className="px-5">
          <h3 className="font-bold text-h3 font-nunito mb-2 mt-5">
            Audit Form
          </h3>
        </div>
        <div className="px-5">
          <form.Field
            name="pass"
            children={({ state, handleChange }) => (
              <RadioSelector
                title="Is the job complete?"
                options={yesOrNoOptions.map((o) => ({
                  ...o,
                  disabled: !canEdit,
                }))}
                onSelectedOption={(value) => handleChange(value)}
                selectedOption={state.value}
              />
            )}
          />
        </div>
        <div className="px-5">
          <form.Field
            name="notes"
            children={({ state, handleChange }) => (
              <Input
                label="Auditor notes (optional)"
                value={state.value}
                onChange={(e) => handleChange(e.target.value)}
                type="textarea"
                disabled={!canEdit}
              />
            )}
          />
        </div>

        {extrasField.length > 0 && (
          <div>
            <div className="px-5 mt-10">
              <h3 className="font-bold text-h3 font-nunito">
                Electrical extras installed
              </h3>
            </div>
            <ExtrasTable
              customerProfileUuid={job.customer.uuid}
              form={
                form as unknown as FormApi<
                  {
                    extras: Extra[];
                  },
                  undefined
                >
              }
              disabled={!canEdit}
            />
          </div>
        )}
      </form>
    </div>
  );
};

const INDEX_JOB_PRODUCTS = gql(`
  query IndexJobProductsForPostAudit ($uuid: String!) {
    indexProductsForJob (uuid: $uuid) {
      uuid
      product {
        name
        responsibleContractorType
      }
      quantity
      isInstalled
      price
      image {
        uuid
        key
        name
        src
        size
        mimeType
      }
    }
  }  
`);

enum TabType {
  roofing = 'roofing',
  electrical = 'electrical',
  general = 'general',
}

const PostAuditModal = ({
  open,
  onClose,
}: ModalWrapperPropsExtends): ReactElement => (
  <ModalWrapper open={open} onClose={onClose}>
    <PostAuditModalChild onClose={onClose} />
  </ModalWrapper>
);

const PostAuditModalChild = ({ onClose }: ModalPropsExtends): ReactElement => {
  const { user } = useAuth();
  profileUtility.assertProfile(user, 'UserUserProfile');
  const [showSuccess, setShowSuccess] = useState(false);
  const { audits, job, setAudits, setJob } = useJobContext();

  const generalAudit = useMemo(
    () => audits.find(({ auditType }) => auditType === AuditType.postGeneral),
    [audits]
  );
  const electricalAudit = useMemo(
    () =>
      audits.find(({ auditType }) => auditType === AuditType.postElectrical),
    [audits]
  );
  const roofingAudit = useMemo(
    () => audits.find(({ auditType }) => auditType === AuditType.postRoofing),
    [audits]
  );

  // ideally we would rely on job context but something in the handling of this makes Tanstack form shit the bed
  const extras = useQuery(INDEX_JOB_PRODUCTS, {
    variables: {
      uuid: job.uuid,
    },
    context: {
      isBatched: true,
    },
  });

  const [updateJobAudit, { loading, error }] = useMutation(UPDATE_JOB_AUDIT);
  const generalForm = useForm<GeneralFormProps>({
    onSubmit: ({ value }) => {
      if (!generalAudit) return;
      updateJobAudit({
        variables: {
          input: {
            uuid: generalAudit.uuid,
            hasPassed: value.pass,
            notes: value.notes,
          },
        },
        onCompleted: (d) => {
          if (d.updateJobAudit) {
            setAudits((aud) =>
              aud.map((a) => {
                if (a.auditType === AuditType.postGeneral) {
                  const { __typename, ...attributes } = d.updateJobAudit;
                  return {
                    ...a,
                    ...attributes,
                  };
                }
                return a;
              })
            );
          }
          setShowSuccess(true);
          generalForm.reset();
        },
      });
    },
    defaultValues: {
      pass: true,
      notes: '',
    },
  });

  const products: Extra[] = useMemo(
    () =>
      extras.data?.indexProductsForJob.map((p) => ({
        uuid: p.uuid,
        isInstalled: p.isInstalled,
        price: p.price,
        quantity: p.quantity,
        product: {
          name: p.product.name,
          responsibleContractorType: p.product.responsibleContractorType,
        },
        image: p.image
          ? {
              uuid: p.image.uuid,
              name: p.image.name,
              src: p.image.src,
              mimeType: p.image.mimeType,
            }
          : undefined,
      })) ?? [],
    [extras]
  );

  const electricalForm = useForm<NonStandardFormType>({
    onSubmit: ({ value }) => {
      if (!electricalAudit) return;
      updateJobAudit({
        variables: {
          input: {
            uuid: electricalAudit.uuid,
            hasPassed: value.pass,
            notes: value.notes,
            extras: value.extras.map((e) => ({
              uuid: e.uuid,
              isInstalled: e.isInstalled,
              price: e.price,
              imageUuid: e.image?.uuid,
            })),
          },
        },
        onCompleted: async (d) => {
          if (d.updateJobAudit) {
            setAudits((aud) =>
              aud.map((a) => {
                if (a.auditType === AuditType.postElectrical) {
                  const { __typename, ...attributes } = d.updateJobAudit;
                  return {
                    ...a,
                    ...attributes,
                  };
                }
                return a;
              })
            );
          }
          setShowSuccess(true);
        },
      });
    },
    defaultValues: {
      pass: !!electricalAudit?.hasPassed,
      notes: electricalAudit?.notes ?? '',
      extras: products.filter(
        ({ product }) =>
          product.responsibleContractorType ===
          ResponsibleContractorType.electrician
      ),
    },
  });

  const roofingForm = useForm<
    NonStandardFormType & { solarPanelsInstalled: number }
  >({
    onSubmit: ({ value }) => {
      if (!roofingAudit) return;
      updateJobAudit({
        variables: {
          input: {
            uuid: roofingAudit.uuid,
            hasPassed: value.pass,
            notes: value.notes,
            numberOfSolarPanels:
              value.solarPanelsInstalled === job.solarPanelCount
                ? undefined
                : value.solarPanelsInstalled,
            extras: value.extras.map((e) => ({
              uuid: e.uuid,
              isInstalled: e.isInstalled,
              price: e.price,
              imageUuid: e.image?.uuid,
            })),
          },
        },
        onCompleted: async (d) => {
          if (d.updateJobAudit) {
            setAudits((aud) =>
              aud.map((a) => {
                if (a.auditType === AuditType.postRoofing) {
                  const { __typename, ...attributes } = d.updateJobAudit;
                  return {
                    ...a,
                    ...attributes,
                  };
                }
                return a;
              })
            );
            setJob((j) => ({
              ...j,
              revisedSolarPanelCount:
                value.solarPanelsInstalled === job.solarPanelCount
                  ? null
                  : value.solarPanelsInstalled,
            }));
          }
          setShowSuccess(true);
        },
      });
    },
    defaultValues: {
      pass: true,
      notes: roofingAudit?.notes ?? '',
      solarPanelsInstalled: job.revisedSolarPanelCount ?? job.solarPanelCount,
      extras: products.filter(
        ({ product }) =>
          product.responsibleContractorType === ResponsibleContractorType.roofer
      ),
    },
  });

  const handleConfirm = () => {
    if (!generalAudit?.submittedAt) {
      generalForm.handleSubmit();
    } else {
      if (tabType === TabType.electrical) {
        electricalForm.handleSubmit();
      } else {
        roofingForm.handleSubmit();
      }
    }
  };

  const isAuthorisedGeneral = useMemo(
    () =>
      user.profile.role === UserRole.superAdministrator ||
      generalAudit?.auditor?.uuid === user.profile.uuid,
    [user, generalAudit]
  );

  const isAuthorisedElectric = useMemo(
    () =>
      user.profile.role === UserRole.superAdministrator ||
      generalAudit?.auditor?.uuid === user.profile.uuid,
    [user, generalAudit]
  );

  const isAuthorisedRoofing = useMemo(
    () =>
      user.profile.role === UserRole.superAdministrator ||
      generalAudit?.auditor?.uuid === user.profile.uuid,
    [user, generalAudit]
  );

  const [tabType, setTabType] = useState(TabType.general);

  return (
    <>
      <Modal
        onClose={onClose}
        confirmText={
          (tabType === TabType.general &&
            isAuthorisedGeneral &&
            !generalAudit?.submittedAt) ||
          (tabType === TabType.electrical &&
            isAuthorisedElectric &&
            !electricalAudit?.submittedAt) ||
          (tabType === TabType.roofing &&
            isAuthorisedRoofing &&
            !roofingAudit?.submittedAt)
            ? 'Submit audit'
            : undefined
        }
        confirmCallback={handleConfirm}
        title="Post job audit"
        hideTopHr
        loading={loading}
      >
        <div className="w-150">
          <Tabs
            ignoreSearch
            onSelectCallback={(t) => setTabType(t.value)}
            size="sm"
            padding={20}
            tabs={[
              {
                name: (
                  <div className="flex items-center group-data-[status=active]:text-primary text-text-low-priority">
                    {generalAudit?.submittedAt && (
                      <CheckCircleIcon className="size-4 mr-2" />
                    )}
                    <span className="text-body-small">General</span>
                  </div>
                ),
                value: TabType.general,
                component: generalAudit ? (
                  <GeneralTab
                    isAuthorised={isAuthorisedGeneral}
                    form={generalForm}
                    audit={generalAudit}
                  />
                ) : (
                  <>Something went wrong</>
                ),
              },
              {
                name: (
                  <div className="flex items-center group-data-[status=active]:text-primary group-disabled:text-text-disabled text-text-low-priority">
                    {electricalAudit?.submittedAt && (
                      <CheckCircleIcon className="size-4 mr-2" />
                    )}
                    <span className="text-body-small">Electrical</span>
                  </div>
                ),
                value: TabType.electrical,
                disabled: !generalAudit?.submittedAt,
                component: electricalAudit ? (
                  <ElectricalAudit
                    audit={electricalAudit}
                    isAuthorised={isAuthorisedElectric}
                    form={electricalForm}
                  />
                ) : (
                  <>Something went wrong.</>
                ),
              },
              {
                name: (
                  <div className="flex items-center group-data-[status=active]:text-primary group-disabled:text-text-disabled text-text-low-priority">
                    {roofingAudit?.submittedAt && (
                      <CheckCircleIcon className="size-4 mr-2" />
                    )}
                    <span className="text-body-small">Roofing</span>
                  </div>
                ),
                value: TabType.roofing,
                disabled: !generalAudit?.submittedAt,
                component: roofingAudit ? (
                  <RoofingAudit
                    form={roofingForm}
                    isAuthorised={isAuthorisedRoofing}
                    audit={roofingAudit}
                  />
                ) : (
                  <>Something went wrong</>
                ),
              },
            ]}
          />
          {error && (
            <div className="mx-5 mb-5">
              <Alert alertType="error" text={error.message} />
            </div>
          )}
        </div>
      </Modal>
      <SimpleModal
        text={`You have successfully submitted a general audit for job “${
          job.customer.firstName
        } ${job.customer.lastName}, ${job.type}, ${
          job.displayDate
        }”. Audit status - ${generalAudit?.hasPassed ? 'Passed' : 'Failed'}.`}
        title="Audit submitted"
        onConfirm={() => {
          setShowSuccess(false);
          onClose(true);
        }}
        onConfirmText="OK"
        open={showSuccess}
        icon="success"
        onClose={function (): void {
          throw new Error('Function not implemented.');
        }}
      />
    </>
  );
};
export default PostAuditModal;
