import { createFileRoute } from '@tanstack/react-router';
import { ReactNode } from 'react';
import Loader from '../../../components/icons/Loader';
import UserUserProfile from '../../../components/organisms/UserUserProfile';
import { usersUtility } from '../../../utility/users';
import { profileUtility } from '../../../utility/profile';
import UserContractorProfile from '../../../components/organisms/UserContractorProfile';
import UserCustomerProfile from '../../../components/organisms/UserCustomerProfile';

const UserProfileSingle = (): ReactNode => {
  const data = Route.useLoaderData();
  if (profileUtility.assertProfileSafe(data, 'UserUserProfile')) {
    return <UserUserProfile data={data} />
  }
  if (profileUtility.assertProfileSafe(data, 'UserContractorProfile')) {
    return <UserContractorProfile data={data} />
  }
  if (profileUtility.assertProfileSafe(data, 'UserCustomerProfile')) {
    return <UserCustomerProfile data={data} />
  }
};

export const Route = createFileRoute('/_authenticated/contacts/$uuid')({
  component: UserProfileSingle,
  pendingComponent: () => (
    <div className="flex items-center h-full justify-center">
      <Loader />
    </div>
  ),
  loader: ({ params }) => usersUtility.fetch({ uuid: params.uuid }),
});
