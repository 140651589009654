import {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { Button } from '../../atoms/Button';
import {
  ArrowLeftIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  CalendarIcon,
  ChevronDownIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  PencilSquareIcon,
  PhoneIcon,
  RectangleStackIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import AvatarStack from '../../atoms/AvatarStack';
import { Link, useRouter } from '@tanstack/react-router';
import Dropdown from '../../atoms/Dropdown';
import Tag from '../../atoms/Tag';
import UserDropdown from '../../molecules/UserDropown';
import EmptyAvatarStack from '../../atoms/EmptyAvatarStack';
import TwoLineText from '../../atoms/TwoLineText';
import DifficultyIndicator from '../../atoms/DifficultyIndactor';
import Tabs from '../../molecules/Tabs';
import { SurveyTab } from './Tabs/Survey';
import { jobsUtility } from '../../../utility/jobs';
import { JobTab } from './Tabs/Job';
import SimpleModal from '../../molecules/Modals/Simple';
import RescheduleJob from '../../molecules/Modals/RescheduleJob';
import TasksTab from './Tabs/Tasks';
import SkillsTab from './Tabs/Skills';
import AuditModal from '../../molecules/Modals/Audit';
import {
  gql,
  IndexJobAuditsQuery,
  IndexSkillsForJobsQuery,
  ReadJobQuery,
  TargetType,
  JobStatus,
} from '@monorepo/graphql';
import { styleUtility } from '../../../utility/styleUtility';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import Loader from '../../icons/Loader';
import { notify } from '../../../utility/notify';
import AuditSelector from './AuditSelector';
import AuditorsModal from '../../molecules/Modals/Auditors';
import ContractorPanel from './ContractorPanel';
import RoofTab from './Tabs/Roofs';
import CommentsTab from './Tabs/Comments';

interface Props {
  jobData: ReadJobQuery['readJob'];
}

enum JobMenuOption {
  reschedule = 'reschedule',
  cancel = 'cancel',
}

const JobContext = createContext<IJobContext>({} as IJobContext);

export const useJobContext = () => {
  const context = useContext(JobContext);
  if (!context) {
    throw new Error('useJobContext must be used within a job context.');
  }
  return context;
};

const INDEX_JOB_AUDITS = gql(`
  query IndexJobAudits ($uuid: String!) {
    indexAuditsForJob (uuid: $uuid) {
      uuid
      notes
      hasPassed
      auditType 
      submittedAt
      auditor {
        uuid
        firstName
        lastName
        avatarSrc
      }
    }
  }  
`);

const INDEX_SKILLS = gql(`
  query IndexSkillsForJobs ($filters: IndexSkillsForJobsFilterInput!) {
    indexSkillsForJobs (filters: $filters) {
      uuid
      name
      levelRequired
      trade
    }
  }  
`);

const UPDATE_JOB = gql(`
  mutation UpdateJob ($input: UpdateJobInput!) {
    updateJob(input: $input) {
      status
    }
  }  
`);

type Audit = IndexJobAuditsQuery['indexAuditsForJob'][0];
type Auditor = NonNullable<Audit['auditor']>;
type Skill = IndexSkillsForJobsQuery['indexSkillsForJobs'][0];

interface IJobContext {
  job: ReadJobQuery['readJob'];
  setJob: Dispatch<SetStateAction<ReadJobQuery['readJob']>>;
  audits: Audit[];
  setAudits: Dispatch<SetStateAction<Audit[]>>;
  skills: Skill[];
  setSkills: Dispatch<SetStateAction<Skill[]>>;
  auditError?: ApolloError;
  skillsError?: ApolloError;
  skillsLoading: boolean;
}

const Job = ({ jobData }: Props): ReactElement => {
  const [job, setJob] = useState(jobData);
  const [operatorUuid, setOperatorUuid] = useState<string>(
    job.operatorUuid ?? ''
  );
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [auditors, setAuditors] = useState<Auditor[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [showAuditorsModal, setShowAuditorsModal] = useState(false);

  const router = useRouter();

  const [jobMenuSelection, setJobMenuSelection] = useState<JobMenuOption>();
  const [audits, setAudits] = useState<Audit[]>([]);

  const {
    refetch: fetchAudits,
    loading,
    error: auditError,
  } = useQuery(INDEX_JOB_AUDITS, {
    variables: {
      uuid: job.uuid,
    },
    notifyOnNetworkStatusChange: true,
    context: {
      isBatched: true,
    },
    onCompleted: (data) => {
      if (data.indexAuditsForJob) {
        const auditors = data.indexAuditsForJob
          .map(({ auditor }) => auditor)
          .filter((a): a is NonNullable<typeof a> => !!a);
        setAuditors(auditors);
        setAudits(data.indexAuditsForJob);
      }
    },
    onError: (msg) => `Unable to fetch audits: ${notify.error(msg.message)}`,
  });

  const [saveJob, { loading: savingJob }] = useMutation(UPDATE_JOB, {
    onCompleted: () => notify.success('Updated job.'),
  });

  const { error: skillsError, loading: skillsLoading } = useQuery(
    INDEX_SKILLS,
    {
      variables: {
        filters: {
          jobUuid: job.uuid,
        },
      },
      onError: (msg) => `Unable to fetch audits: ${notify.error(msg.message)}`,
      onCompleted: (data) => {
        if (data.indexSkillsForJobs) {
          setSkills(data.indexSkillsForJobs);
        }
      },
    }
  );

  return (
    <JobContext.Provider
      value={{
        job,
        setJob,
        audits,
        setAudits,
        skills,
        setSkills,
        auditError,
        skillsError,
        skillsLoading,
      }}
    >
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col w-full">
          <div className="flex items-center p-4">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <p className="font-semibold">Job #{job.uuid}</p>
            </div>
            <Button
              className="mr-3"
              onClick={() => setShowAuditTrail(true)}
              bText="Audit trail"
              bSize="sm"
              bStyle="light"
            />
            <Dropdown
              buttonClassname="bg-white h-11 px-2.5 rounded border border-grey-500"
              ButtonIcon={<EllipsisVerticalIcon className="size-6" />}
              onOptionSelect={(opt) => setJobMenuSelection(opt.value)}
              options={[
                {
                  value: JobMenuOption.reschedule,
                  name: 'Reschedule',
                  Icon: <CalendarDaysIcon className="size-5 " />,
                },
                {
                  value: JobMenuOption.cancel,
                  name: 'Cancel Job',
                  itemClassname: 'text-red',
                  Icon: <XCircleIcon className="size-5 text-red" />,
                },
              ]}
            />
          </div>
          <div className="flex flex-col overflow-hidden">
            <div className="overflow-scroll px-4">
              <div className="bg-white rounded-lg">
                <div className="p-5 flex justify-start">
                  <AvatarStack
                    height="h-12"
                    width="w-12"
                    avatars={[
                      {
                        firstName: job.customer.firstName,
                        lastName: job.customer.lastName,
                      },
                    ]}
                  />
                  <div className="space-y-2.5 px-5 flex-grow">
                    <Link
                      to="/contacts/$uuid"
                      params={{ uuid: job.customer.userUuid }}
                    >
                      <h1 className="text-h1-small font-bold font-nunito underline">
                        {job.customer.firstName} {job.customer.lastName}
                      </h1>
                    </Link>
                    {job.address && (
                      <h4 className="font-nunito text-h4">
                        {[
                          job.address.line1,
                          job.address.line2,
                          job.address.city,
                          job.address.postcode,
                        ]
                          .filter((a) => a)
                          .join(', ')}
                      </h4>
                    )}
                  </div>
                  <div>
                    <Dropdown
                      anchor="bottom end"
                      selected={jobsUtility.statusOptions.find(
                        ({ value }) => value === job.status
                      )}
                      buttonClassname="flex items-center space-x-2 p-2"
                      options={jobsUtility.statusOptions}
                      onOptionSelect={(opt) => {
                        setJob({
                          ...job,
                          status: opt.value,
                        });
                        saveJob({
                          variables: {
                            input: {
                              uuid: job.uuid,
                              status: opt.value,
                            },
                          },
                        });
                      }}
                      ButtonLeadIcon={
                        <Tag
                          size="lg"
                          colour={job.status}
                          text={jobsUtility.jobStatusNiceMap[job.status]}
                        />
                      }
                      ButtonIcon={<ChevronDownIcon className="size-5" />}
                    />
                  </div>
                </div>
                <hr className="border-none w-full h-px bg-grey-700" />
                <div className="flex items-center p-5 space-x-12">
                  <div className="flex flex-col flex-grow items-center w-1/4">
                    <UserDropdown
                      stacked
                      userUuid={operatorUuid}
                      setUserUuid={(_, profileUuid) => {
                        setOperatorUuid(profileUuid);
                        saveJob({
                          variables: {
                            input: {
                              uuid: job.uuid,
                              operatorUuid: profileUuid,
                            },
                          },
                        });
                      }}
                      partnerUuid={job.partner.uuid}
                    />
                    <span className="text-body-small text-text-low-priority">
                      Operator
                    </span>
                  </div>
                  <div className="flex flex-col flex-grow justify-center items-center w-1/4">
                    <button
                      disabled={loading}
                      className="outline-none flex flex-col items-center"
                      onClick={() => setShowAuditorsModal(true)}
                    >
                      {loading ? (
                        <div className="mb-5">
                          <Loader multiplier={0.5} />
                        </div>
                      ) : (
                        <>
                          <EmptyAvatarStack avatars={auditors} />
                          <div className="flex items-center mt-1.5">
                            <span className="text-body-small">
                              {auditors.length
                                ? 'Multiple users'
                                : '-- Select --'}
                            </span>
                            <ChevronDownIcon className="size-2.5 text-text-normal ml-1" />
                          </div>
                        </>
                      )}
                      <span className="text-body-small text-text-low-priority">
                        Auditors
                      </span>
                    </button>
                  </div>
                  <div className="flex flex-col flex-grow items-center w-1/4">
                    {loading ? <Loader multiplier={0.5} /> : <AuditSelector />}
                  </div>
                  {/* <div className="flex flex-col items-center w-1/4">
                    <LifebuoyIcon className="text-grey-400 size-9" />
                    <span className="text-body-small mt-1.5">Ticket #4456</span>
                    <Link
                      to="/tickets/$uuid"
                      params={{ uuid: '' }}
                      className="text-body-small text-primary underline"
                    >
                      View Ticket
                    </Link>
                  </div> */}
                </div>
                <hr className="border-none w-full h-px bg-grey-700" />
                <div className="grid grid-cols-2 gap-5 p-5">
                  <div className="flex-grow">
                    <TwoLineText
                      label="Difficulty"
                      text={styleUtility.capitalise(job.difficulty)}
                      Icon={<DifficultyIndicator difficulty={job.difficulty} />}
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Scheme"
                      text={job.schemeName}
                      Icon={
                        <RectangleStackIcon className="size-5 text-grey-400" />
                      }
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Partner"
                      text={job.partner.name}
                      Icon={
                        <BuildingOffice2Icon className="size-5 text-grey-400" />
                      }
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Customer email"
                      text={job.customer.email}
                      Icon={<EnvelopeIcon className="size-5 text-grey-400" />}
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Type"
                      text={styleUtility.capitalise(job.type)}
                      Icon={jobsUtility.jobTypeIconMap[job.type]}
                    />
                  </div>
                  <div className="basis-1/2">
                    <TwoLineText
                      label="Customer phone"
                      text={job.customer.phoneNumber}
                      Icon={<PhoneIcon className="size-5 text-grey-400" />}
                    />
                  </div>
                </div>
              </div>
              <div className="my-5">
                <Tabs
                  tabs={[
                    {
                      name: styleUtility.capitalise(job.type),
                      value: 'job',
                      component: <JobTab />,
                    },
                    {
                      name: 'Survey',
                      value: 'survey',
                      component: <SurveyTab />,
                    },
                    {
                      name: 'Tasks',
                      value: 'tasks',
                      component: <TasksTab />,
                    },
                    {
                      name: 'Comments',
                      value: 'comments',
                      component: <CommentsTab />
                    },
                    // {
                    //   name: 'Timeline',
                    //   value: 'timeline',
                    //   component: <TimelineTab />,
                    // },
                    {
                      name: 'Roofs',
                      value: 'roofs',
                      component: <RoofTab />,
                    },
                    {
                      name: 'Skills',
                      value: 'skills',
                      component: <SkillsTab />,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col p-5 bg-white h-full w-full max-w-90">
          <h3 className="font-nunito text-h3 font-bold mb-5">Assignments</h3>
          <div className="flex gap-2">
            <div className="flex-grow">
              <TwoLineText
                label="Installation date"
                text={job.displayDate}
                Icon={<CalendarIcon className="size-5 text-grey-400" />}
              />
            </div>
            <Button
              className="!border-none"
              Icon={<PencilSquareIcon className="size-6" />}
              bStyle="light"
              onClick={() => setJobMenuSelection(JobMenuOption.reschedule)}
            />
          </div>
          <hr className="border-none w-full h-px bg-grey-700 my-5" />
          <div className="flex-grow flex flex-col">
            <ContractorPanel />
          </div>
        </div>
      </div>
      <SimpleModal
        text="If you can this job you will have to contact the customer to rebook it."
        title="Cancel Job"
        loading={savingJob}
        onConfirm={() => {
          saveJob({
            variables: {
              input: {
                uuid: job.uuid,
                status: JobStatus.cancelled,
              },
            },
            onCompleted: () => {
              setJob((j) => ({
                ...j,
                status: JobStatus.cancelled,
              }));
              setJobMenuSelection(undefined);
            },
          });
        }}
        icon="critical"
        onConfirmText="Confirm Cancellation"
        open={jobMenuSelection === JobMenuOption.cancel}
        onClose={() => setJobMenuSelection(undefined)}
      />

      <RescheduleJob
        open={jobMenuSelection === JobMenuOption.reschedule}
        onClose={() => setJobMenuSelection(undefined)}
      />

      <AuditModal
        targetType={TargetType.job}
        targetUuid={job.uuid}
        open={showAuditTrail}
        onClose={() => setShowAuditTrail(false)}
      />

      <AuditorsModal
        open={showAuditorsModal}
        onClose={(success) => {
          if (success) {
            fetchAudits({
              uuid: job.uuid,
            });
          }
          setShowAuditorsModal(false);
        }}
      />
    </JobContext.Provider>
  );
};
export default Job;
