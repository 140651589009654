import { useForm } from '@tanstack/react-form';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import { gql, SettingKind } from '@monorepo/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import Loader from '../../../icons/Loader';

const INDEX_SETTINGS = gql(`
  query IndexSettingsForNotificationSettings ($filter: IndexSettingsFilter!) {
    indexSettings (filter: $filter) {
      items {
        uuid
        userUuid
        key
        value
        kind
        description
      }
    }
  }  
`);

const UPDATE_SETTING = gql(`
  mutation UpdateSetting ($input: UpdateSettingInput!) {
    updateSetting (input: $input) {
      uuid
    }
  }  
`);

const NotificationSettings = (): ReactElement => {
  const { data, loading } = useQuery(INDEX_SETTINGS, {
    variables: {
      filter: {
        kind: [
          SettingKind.notificationBehaviour,
          SettingKind.notificationSubject,
        ],
      },
    },
    onError: (err) =>
      notify.error(`Unable to fetch notifications \n ${err.message}`),
  });

  const [update] = useMutation(UPDATE_SETTING, {
    onError: (err) =>
      notify.error(`Unable to fetch notifications \n ${err.message}`),
    onCompleted: () => notify.success('Successfully saved setting.'),
  });

  const [options, setOptions] = useState(data?.indexSettings.items ?? []);

  useEffect(() => {
    if (data?.indexSettings) {
      setOptions(data.indexSettings.items);
    }
  }, [data]);

  return (
    <div className="p-5">
      <h3 className="text-h3 font-bold font-nunito mb-4">Settings</h3>
      {loading ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="flex-grow flex flex-col">
          <div className="mb-5 flex flex-col">
            <span className="text-input-label font-semibold mb-2 block">
              Notify me about
            </span>

            {options
              .filter(({ kind }) => kind === SettingKind.notificationSubject)
              .map((s) => (
                <div className="my-3" key={s.uuid}>
                  <SimpleCheckbox
                    checked={s.value === 'true'}
                    setChecked={(checked) =>
                      setOptions((opts) =>
                        opts.map((o) => {
                          if (o.uuid === s.uuid) {
                            const value = checked ? 'true' : 'false';
                            update({
                              variables: {
                                input: {
                                  uuid: o.uuid,
                                  value,
                                },
                              },
                            });
                            return {
                              ...o,
                              value,
                            };
                          }
                          return o;
                        })
                      )
                    }
                    label={s.description}
                  />
                </div>
              ))}
          </div>
          <div className="mb-5 flex flex-col">
            <span className="text-input-label font-semibold mb-2">
              Behaviour
            </span>

            {options
              .filter(({ kind }) => kind === SettingKind.notificationBehaviour)
              .map((s) => (
                <div className="my-3" key={s.uuid}>
                  <SimpleCheckbox
                    checked={s.value === 'true'}
                    setChecked={(checked) =>
                      setOptions((opts) =>
                        opts.map((o) => {
                          if (o.uuid === s.uuid) {
                            const value = checked ? 'true' : 'false';
                            update({
                              variables: {
                                input: {
                                  uuid: o.uuid,
                                  value,
                                },
                              },
                            });
                            return {
                              ...o,
                              value,
                            };
                          }
                          return o;
                        })
                      )
                    }
                    label={s.description}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default NotificationSettings;
