import z from 'zod'
import ResetPassword from '../../components/organisms/ResetPassword';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_unauthenticated/reset-password')({
  component: ResetPassword,
  validateSearch: z.object({
    token: z.string().optional(),
    email: z.string().optional()
  })
});
