import {
  useContext,
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { usersUtility } from '../utility/users';
import { MeQuery } from '@monorepo/graphql';

export interface AuthContext {
  user?: MeQuery['user'];
  setUser: Dispatch<SetStateAction<MeQuery['user'] | undefined>>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContext | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const { user: userRsp } = usersUtility.useRefresh();

  const [user, setUser] = useState(userRsp);

  const logout = useCallback(async () => {
    localStorage.clear();
    sessionStorage.clear();
    setUser(undefined);
  }, []);

  useEffect(() => {
    if (userRsp) {
      setUser(userRsp);
    }
  }, [userRsp]);

  return (
    <AuthContext.Provider value={{ user, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
