import { ReactElement, useState } from 'react';
import AvatarStack from '../../../atoms/AvatarStack';
import { Link } from '@tanstack/react-router';
import { Button } from '../../../atoms/Button';
import {
  CalendarIcon,
  CheckCircleIcon,
  MapPinIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import AssignmentModal from '../../../molecules/Modals/Assignment';
import { gql } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import { useJobContext } from '..';
import Loader from '../../../icons/Loader';
import Alert from '../../../atoms/Alerts';
import EmptyState from '../../../molecules/EmptyState';
import { client } from '../../../../main';

const INDEX_JOB_CONTRACTORS = gql(`
  query IndexJobContractors ($uuid: String!) {
    indexContractorsForJob (uuid: $uuid) {
      uuid
      displayDateRequired
      isElectrician
      isRoofer
      distance
      user {
        uuid
        firstName
        lastName
        avatarSrc
      }
    }
  }
`);

const ContractorPanel = (): ReactElement => {
  const { job } = useJobContext();

  const { data, error } = useQuery(INDEX_JOB_CONTRACTORS, {
    variables: {
      uuid: job.uuid,
    },
  });

  const [showAssignmentModal, setShowAssignmentModal] = useState(false);

  return (
    <>
      <div className="flex-grow">
        <span className="text-body-small text-text-low-priority mb-2 block">
          Contractors
        </span>
        {!data ? (
          <div className="flex justify-center items-center">
            {error ? (
              <Alert alertType="error" text={error.message} />
            ) : (
              <Loader />
            )}
          </div>
        ) : data.indexContractorsForJob.length === 0 ? (
          <EmptyState
            title="No contractors"
            description="Click “Assign contractors” to start assigning them to this job"
          />
        ) : (
          data.indexContractorsForJob.map((c) => (
            <div
              key={c.uuid}
              className="border border-grey-400/40 rounded-md overflow-hidden mb-3"
            >
              <div className="p-2 flex items-center">
                <AvatarStack
                  height="h-12"
                  width="w-12"
                  avatars={[
                    {
                      firstName: c.user.firstName,
                      lastName: c.user.lastName,
                    },
                  ]}
                />
                <div className="space-y-2.5 px-5 flex-grow">
                  <Link to="/contacts/$uuid" params={{ uuid: c.user.uuid }}>
                    <p className="font-semibold underline">
                      {c.user.firstName} {c.user.lastName}
                    </p>
                  </Link>
                  <span className="text-body-small text-text-low-priority">
                    Trade:
                    {c.isElectrician && c.isRoofer
                      ? ' Electrician, roofer '
                      : c.isElectrician
                      ? ' Electrician'
                      : ' Roofer'}
                  </span>
                </div>
              </div>
              <div className="p-2 bg-background-secondary flex items-center border-b border-grey-400/40">
                <div className="flex items-center flex-grow">
                  <MapPinIcon className="size-5 text-grey-400 mr-1" />
                  <span className="text-body-small">
                    {c.distance.toLocaleString('en-GB', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    miles
                  </span>
                </div>
              </div>
              <div className="p-2 bg-background-secondary flex items-center border-b border-grey-400/40">
                <div className="flex items-center flex-grow">
                  <CalendarIcon className="size-5 text-grey-400 mr-1" />
                  <span className="text-body-small">
                    {c.displayDateRequired}
                  </span>
                </div>
              </div>
              <div className="p-2 bg-background-secondary flex items-center">
                <CheckCircleIcon className="size-5 text-grey-400 mr-1" />
                <span className="text-body-small">
                  {c.isElectrician && c.isRoofer
                    ? 'Electrician, roofer '
                    : c.isElectrician
                    ? 'Electrician'
                    : 'Roofer'}
                </span>
              </div>
            </div>
          ))
        )}
      </div>
      <Button
        className="w-full flex items-center justify-center"
        bText="Assign contractors"
        onClick={() => setShowAssignmentModal(true)}
      />

      <AssignmentModal
        open={showAssignmentModal}
        onClose={(success) => {
          if (success) {
            client.graphqlClient().refetchQueries({
              include: [INDEX_JOB_CONTRACTORS],
            });
          }
          setShowAssignmentModal(false);
        }}
      />
    </>
  );
};
export default ContractorPanel;
