import { ReactElement, useEffect, useState } from 'react';
import logoSrc from './../../../assets/images/logo.svg';
import { useForm } from '@tanstack/react-form';
import { Input } from '../../atoms/Input';
import Star from '../../icons/Star';
import { Button } from '../../atoms/Button';
import { gql } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import Alert from '../../atoms/Alerts';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

interface Props {
  token: string;
  tokenUuid: string;
}

const SAVE_FEEDBACK = gql(`
  mutation SaveFeedback ($input: SaveJobFeedback!) {
    saveFeedback (input: $input)
  }  
`);

const Feedback = ({ token, tokenUuid }: Props): ReactElement => {
  const [save, { data, loading, error }] = useMutation(SAVE_FEEDBACK);

  const form = useForm({
    onSubmit: ({ value }) => {
      save({
        variables: {
          input: {
            jobFeedbackTokenUuid: tokenUuid,
            notes: value.notes,
            rating: value.rating,
            token: token,
          },
        },
      });
    },
    defaultValues: {
      rating: -1,
      notes: '',
    },
  });

  const [hoverIndex, setHoverIndex] = useState(-1);
  
  return (
    <div className="h-full justify-center flex overflow-hidden">
      <div className="bg-white flex flex-col py-10 px-20 overflow-scroll">
        <img src={logoSrc} alt="Infinity Logo" className="mb-5 h-14" />
        <div className="w-140">
          {data?.saveFeedback ? (
            <div className="flex mt-20 flex-col items-center justify-center space-y-5">
              <div className="h-20 w-20 flex items-center bg-primary justify-center rounded-full">
                <CheckCircleIcon className="text-white size-11" />
              </div>
              <h2 className="text-h2 font-bold font-nunito">
                Feedback Submitted
              </h2>
              <p>Thanks, you can close this page now</p>
            </div>
          ) : (
            <>
              <h1 className="text-h1-small mx-auto font-bold font-nunito mb-5">
                Customer Feedback Form
              </h1>
              <form
                className="w-full"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  form.handleSubmit();
                }}
              >
                <div className="flex flex-col mb-5">
                  <span className="mb-1 font-semibold text-input-label text-text-normal">
                    Rate your experience
                  </span>
                  <span className="mb-2 text-text-low-priority text-body-small">
                    1 start - terrible, 5 stars - amazing
                  </span>

                  <form.Field
                    name="rating"
                    validators={{
                      onSubmit: ({ value }) =>
                        value === -1
                          ? 'Please select a star rating.'
                          : undefined,
                    }}
                    children={({ state, handleChange }) => (
                      <>
                        <div
                          className="flex space-x-2"
                          onMouseLeave={() => setHoverIndex(-1)}
                        >
                          {Array.from(new Array(5)).map((_, i) => (
                            <button
                              type="button"
                              onClick={() => handleChange(i)}
                              onMouseEnter={() => setHoverIndex(i)}
                            >
                              <Star
                                filled={
                                  i <= hoverIndex ||
                                  (hoverIndex === -1 && i <= state.value)
                                }
                              />
                            </button>
                          ))}
                        </div>
                        {!!state.meta.errors.length && (
                          <div className="mt-5">
                            <Alert
                              alertType="error"
                              text={state.meta.errors.join(', ')}
                            />
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                <form.Field
                  name="notes"
                  validators={{
                    onSubmit: ({ value }) =>
                      value.length ? undefined : 'Please enter feedback.',
                  }}
                  children={({ state, handleChange }) => (
                    <Input
                      label="Feedback"
                      type="textarea"
                      max={1000}
                      onChange={(e) => handleChange(e.target.value)}
                      value={state.value}
                      error={state.meta.errors.join(', ')}
                    />
                  )}
                />

                {error && (
                  <div className="my-5">
                    <Alert alertType="error" text={error.message} />
                  </div>
                )}

                <Button
                  bText="Submit Feedback"
                  type="submit"
                  loading={loading}
                />
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Feedback;
