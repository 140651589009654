import { ReactElement, useMemo } from 'react';

interface Props {
  progress: number;
}
const ProgressBar = ({ progress = 0 }: Props): ReactElement => {
  return (
    <div className="h-1 bg-grey-800 rounded w-full relative">
      <div
        className={`h-1 rounded-full w-0 absolute left-0 transition-all duration-1000  bg-${
          progress === 101 ? 'red' : progress === 100 ? 'primary' : 'tertiary'
        }`}
        style={{ width: `${Math.min(100, progress)}%` }}
      />
    </div>
  );
};
export default ProgressBar;
