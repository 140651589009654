import { ReactElement, useEffect, useMemo, useState } from 'react';
import Dropdown from '../../atoms/Dropdown';
import ExpandMore from '../../../assets/icons/ExpandMore.svg';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import {
  BuildingOffice2Icon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline';
import { gql } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import { Partner as PartnerContextType } from '../../../utility/partnerContext';

const GET_PARTNERS = gql(`
  query IndexPartners($filters: IndexPartnersFilterInput!) {
    indexPartners(filters: $filters) {
      items {
        name
        uuid
      }
    }
  }
  `);

interface Props {
  partnerUuid?: string;
  setPartnerUuid: (uuid: string) => void;
  setSelectedPartner?: (partner: PartnerContextType) => void;
  error?: string;
  isOptional?: boolean;
  hideLabel?: boolean;
  buttonClassname?: string;
  respectButtonWidth?: boolean;
  all?: boolean;
  hideLeadIcon?: boolean;
  partnerName?: string;
}
const PartnerDropdown = ({
  partnerUuid,
  setPartnerUuid,
  setSelectedPartner,
  isOptional,
  hideLabel,
  error,
  buttonClassname = '',
  respectButtonWidth = true,
  all = false,
  hideLeadIcon = false,
  partnerName,
}: Props): ReactElement => {
  const { data, refetch } = useQuery(GET_PARTNERS, {
    variables: {
      filters: {},
    },
    onCompleted: (data) => {
      if (!partnerUuid && setSelectedPartner) {
        setPartnerUuid(data.indexPartners?.items[0].uuid);
      }
    },
  });

  const partnerOptions = useMemo(() => {
    return [
      ...(all
        ? [
            {
              value: 'all',
              name: 'All partners',
            },
          ]
        : []),
      ...(data?.indexPartners.items.map(({ uuid, name }) => ({
        value: uuid,
        name,
      })) ?? []),
    ];
  }, [all, data]);

  const selected = useMemo(
    () => partnerOptions.find(({ value }) => value === partnerUuid),
    [partnerUuid, partnerOptions]
  );

  useEffect(() => {
    if (partnerUuid && data?.indexPartners && setSelectedPartner) {
      const partners = data.indexPartners.items;
      const partner =
        partners.find(({ uuid }) => uuid === partnerUuid) ?? partners[0];
      localStorage.setItem('__ir_partner_uuid__', partner.uuid);
      setSelectedPartner(partner);
    }
  }, [partnerUuid, setSelectedPartner, data]);

  return (
    <Dropdown
      options={partnerOptions}
      respectButtonWidth={respectButtonWidth}
      innerButtonFullWidth={true}
      buttonText={selected?.name ?? partnerName ?? '-- Select --'}
      buttonClassname={`${buttonClassname} py-2.5 px-3 border rounded flex items-center w-580 w-full mb-6 focus-within:outline-primary border-grey-500 focus-within:outline focus-within:outline-2 h-11`}
      selected={selected}
      ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
      onOptionSelect={(opt) => setPartnerUuid(opt.value)}
      label={
        hideLabel ? undefined : `Partner ${isOptional ? '(optional)' : ''}`
      }
      ButtonLeadIcon={
        !hideLeadIcon ? (
          <BuildingOffice2Icon className="size-6 mr-2" />
        ) : undefined
      }
      textClassname="flex-grow text-start truncate"
      handlesSearch
      searchFunction={async (search) => {
        if (search) {
          await refetch({ filters: { term: search } });
        }
      }}
      error={error}
    />
  );
};
export default PartnerDropdown;
