import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { DayPicker, getDefaultClassNames } from 'react-day-picker';
import { styleUtility } from '../../../utility/styleUtility';
import { format } from 'date-fns';

interface Props {
  selectedDate?: Date;
  setSelectedDate: (dt: Date) => void;
  containerType?: string;
  showLabel?: boolean;
  buttonClassname?: string;
  beforeDisabledDate?: Date;
}
const DateSelector = ({
  selectedDate,
  setSelectedDate,
  containerType,
  showLabel = false,
  buttonClassname = 'w-full',
  beforeDisabledDate,
}: Props): ReactElement => {
  const defaultClassNames = getDefaultClassNames();

  return (
    <Popover className="relative">
      <PopoverButton
        className={`bg-white h-11 rounded flex items-center justify-between  border-grey-500 border p-3 hover:!bg-grey-900/5 disabled:text-text-disabled/50 ${buttonClassname} ${
          containerType && 'border-white'
        }`}
      >
        {showLabel && (
          <span>
            {selectedDate
              ? format(selectedDate, 'dd MMMM yyyy')
              : '-- Select Date --'}
          </span>
        )}
        <CalendarIcon className="size-6" />
      </PopoverButton>
      <PopoverPanel
        anchor="bottom"
        className="bg-white shadow-regular rounded-lg"
      >
        {({ close }) => (
          <DayPicker
            mode="single"
            selected={selectedDate}
            startMonth={selectedDate}
            onSelect={(dt) => {
              setSelectedDate(dt ?? new Date());
              close();
            }}
            required={false}
            weekStartsOn={1}
            disabled={{ before: beforeDisabledDate ?? new Date() }}
            classNames={{
              chevron: `${defaultClassNames.chevron} !fill-text-normal`,
              button_next: `flex items-center justify-center h-11 px-4.5 w-12 hover:bg-grey-900`,
              button_previous: `flex items-center justify-center h-11 w-12 px-4.5 hover:bg-grey-900`,
              selected: `!bg-primary !text-white`,
              today: `bg-grey-900 rounded`,
              caption_label: `font-nunito text-h2`,
              nav: `mt-3 mr-2.5 ${defaultClassNames.nav}`,
              month_caption: `flex items-center px-5 py-5 border-b border-grey-700`,
              month_grid: `${defaultClassNames.month_grid} m-5`,
            }}
            components={{
              Chevron: ({ orientation }) =>
                orientation === 'left' ? (
                  <ChevronLeftIcon
                    stroke={styleUtility.colours['text-normal']}
                    className="size-6"
                  />
                ) : (
                  <ChevronRightIcon
                    stroke={styleUtility.colours['text-normal']}
                    className="size-6"
                  />
                ),
            }}
          />
        )}
      </PopoverPanel>
    </Popover>
  );
};
export default DateSelector;
