import { ReactElement, useState } from 'react';
import {
  IndexSlotsQuery,
  getFragmentData,
  FragmentType,
  gql,
} from '@monorepo/graphql';
import { slotsUtility } from '../../../../utility/slots';
import { Link } from '@tanstack/react-router';
import {
  CalendarPeriod,
  useCalendarContext,
} from '../../../../utility/calendarContext';
import Tag from '../../../atoms/Tag';
import {
  Battery0Icon,
  CalendarIcon,
  Cog6ToothIcon,
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  LockClosedIcon,
  PlusCircleIcon,
  RectangleStackIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { jobsUtility } from '../../../../utility/jobs';
import AvatarStack from '../../../atoms/AvatarStack';
import TwoLineText from '../../../atoms/TwoLineText';
import { styleUtility } from '../../../../utility/styleUtility';
import DifficultyIndicator from '../../../atoms/DifficultyIndactor';
import Dropdown from '../../../atoms/Dropdown';
import ArrowForward from '../../../../assets/icons/ArrowForward.svg';
import RemedialDark from '../../../../assets/icons/RemedialDark.svg';
import InstallationDark from '../../../../assets/icons/InstallationDark.svg';
import { AddJobModal } from '../../Modals/AddJob';
import { ConfigureSlotModal } from '../../Modals/Configure';
import { ReassignModal } from '../../Modals/ReassignJob';
import SimpleModal from '../../Modals/Simple';
import { format, isAfter, subDays } from 'date-fns';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';

interface Props {
  slot: FragmentType<typeof slotsUtility.queries.SLOT_FRAGMENT>;
  date: Date;
  doRefetch: () => void;
}

enum ModalType {
  addJob = 'addJob',
  configureSlot = 'configureSlot',
  reassign = 'reassign',
  deleteSlot = 'deleteSlot',
}

const DELETE_SLOT = gql(`
  mutation UpdateSlotForDeletion($uuid: String!) {
    deleteSlot(uuid: $uuid)
  }  
`);

const CalendarDayJob = ({ slot, date, doRefetch }: Props): ReactElement => {
  const s = getFragmentData(slotsUtility.queries.SLOT_FRAGMENT, slot);
  const [modalParams, setModalParams] = useState<{
    slot: IndexSlotsQuery['indexSlots']['items'][0];
    type: ModalType;
  }>();
  const { selectedPeriod } = useCalendarContext();
  const [deleteSlot] = useMutation(DELETE_SLOT, {
    context: {
      isBatched: false,
    },
  });

  return (
    <>
      {s.job ? (
        <Link
          to="/job/$uuid"
          params={{
            uuid: s.job.uuid,
          }}
          key={s.uuid}
          className="flex border-t first:border-t-0 border-grey-700 bg-white h-[164px]"
        >
          <div
            className={`flex flex-grow flex-col space-y-3 ${
              selectedPeriod === CalendarPeriod.day ? 'p-5' : 'p-3'
            }`}
          >
            <div className="flex items-center justify-between">
              <Tag
                text={jobsUtility.jobStatusNiceMap[s.job.status]}
                colour={s.job.status}
              />
              {s.job.atRisk && (
                <ExclamationCircleIcon className="text-red size-6" />
              )}
            </div>
            <div className="body-small">
              <p className="font-semibold">
                {s.job.customer.firstName} {s.job.customer.lastName}
              </p>
              <span className="block mt-1 text-text-low-priority">
                {[
                  s.job.customer.line1,
                  s.job.customer.city,
                  s.job.customer.postcode,
                ]
                  .filter((s) => s)
                  .join(', ')}
              </span>
            </div>
            <div className="flex items-center justify-between">
              {
                jobsUtility.slotIconComponentMap[
                  s.job.type as keyof typeof jobsUtility.slotIconComponentMap
                ]
              }
              <AvatarStack avatars={s.job.contractors} />
            </div>
          </div>
          {selectedPeriod === CalendarPeriod.day && (
            <>
              <div className="flex flex-col justify-between border-l border-r border-grey-700 flex-grow my-5 px-5">
                <TwoLineText
                  label="Difficulty"
                  text={styleUtility.capitalise(s.job.difficulty)}
                  Icon={<DifficultyIndicator difficulty={s.job.difficulty} />}
                />
                <TwoLineText
                  label="Installation Date"
                  text={s.job.displayInstallationDate}
                  Icon={<CalendarIcon className="size-5 text-grey-400" />}
                />
              </div>
              {s.job.customer.schemeName && (
                <div className="flex flex-col justify-between flex-grow my-5 px-5">
                  <TwoLineText
                    label="Scheme"
                    text={s.job.customer.schemeName}
                    Icon={
                      <RectangleStackIcon className="size-5 text-grey-500" />
                    }
                  />
                </div>
              )}
            </>
          )}
        </Link>
      ) : (
        <div
          key={s.uuid}
          className="h-[164px] flex flex-col border-t first:border-t-0 border-grey-700 bg-grey-900 p-3"
        >
          <div className="flex mb-5 justify-end">
            {isAfter(date, subDays(new Date(), 1)) ? (
              <Dropdown
                options={[
                  {
                    name: 'Add job',
                    value: ModalType.addJob,
                    Icon: <PlusCircleIcon className="size-6" />,
                  },
                  {
                    name: 'Configure slot',
                    value: ModalType.configureSlot,
                    Icon: <Cog6ToothIcon className="size-6" />,
                  },
                  {
                    name: 'Reassign',
                    value: ModalType.reassign,
                    Icon: <img src={ArrowForward} alt="arrow-forward" />,
                  },
                  {
                    name: 'Delete slot',
                    value: ModalType.deleteSlot,
                    itemClassname: 'text-red',
                    Icon: <TrashIcon className="size-6 text-red" />,
                  },
                ]}
                respectButtonWidth={false}
                ButtonIcon={<EllipsisVerticalIcon className="size-6" />}
                onOptionSelect={(opt) =>
                  setModalParams({ type: opt.value, slot: s })
                }
                buttonClassname="h-fit"
              />
            ) : (
              <LockClosedIcon className="size-5 mt-1 text-text-disabled/30" />
            )}
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex items-center">
              {s.isInstallationSelected ? (
                <>
                  <img
                    alt="Installation"
                    src={InstallationDark}
                    className="h-5 w-auto mr-2"
                  />
                  <span className="text-body-small text-text-low-priority">
                    Installation
                  </span>
                </>
              ) : (
                <div className="space-y-2">
                  {s.isRemedialSelected && (
                    <div className="flex items-center">
                      <img
                        alt="Remedial"
                        src={RemedialDark}
                        className="h-5 w-auto mr-2"
                      />
                      <span className="text-body-small text-text-low-priority">
                        Remedial
                      </span>
                    </div>
                  )}
                  {s.isBatterySelected && (
                    <div className="flex items-center">
                      <Battery0Icon className="size-5 text-text-low-priority mr-2" />
                      <span className="text-body-small text-text-low-priority">
                        Battery
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
            {s.schemeName && (
              <div className="flex items-center">
                <RectangleStackIcon className="size-5 mr-2 text-text-low-priority" />
                <span className="text-body-small text-text-low-priority">
                  Scheme Name
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      <AddJobModal
        open={modalParams?.type === ModalType.addJob}
        date={date}
        onClose={() => setModalParams(undefined)}
        isBatteryAvailable={!!modalParams?.slot.isBatterySelected}
        isRemedialAvailable={!!modalParams?.slot.isRemedialSelected}
        isInstallationAvailable={!!modalParams?.slot.isInstallationSelected}
        isRooferAvailable={!!modalParams?.slot.isRooferAvailable}
        isElectricianAvailable={!!modalParams?.slot.isElectricianAvailable}
      />

      <ConfigureSlotModal
        open={modalParams?.type === ModalType.configureSlot}
        onClose={() => setModalParams(undefined)}
        date={date}
        uuid={modalParams?.slot.uuid}
        isInstallationSelected={!!modalParams?.slot.isInstallationSelected}
        isBatterySelected={!!modalParams?.slot.isBatterySelected}
        isRemedialSelected={!!modalParams?.slot.isRemedialSelected}
        isInstallationAvailable={
          !!modalParams?.slot.isElectricianAvailable &&
          !!modalParams?.slot.isRooferAvailable
        }
        isRemedialAvailable={
          !!modalParams?.slot.isElectricianAvailable ||
          !!modalParams?.slot.isRooferAvailable
        }
        isBatteryAvailable={!!modalParams?.slot.isElectricianAvailable}
      />

      <ReassignModal
        open={modalParams?.type === ModalType.reassign}
        uuid={modalParams?.slot.uuid}
        onClose={() => {
          setModalParams(undefined);
          doRefetch();
        }}
        date={date}
      />

      <SimpleModal
        icon="warning"
        onConfirmText="Delete"
        title="Delete slot?"
        onConfirm={() => {
          if (modalParams?.slot) {
            notify.promise({
              promise: deleteSlot({
                variables: {
                  uuid: modalParams?.slot.uuid,
                },
              }),
              success: 'Deleted slot',
              pending: 'Deleting slot',
              error: 'Unable to delete slot',
            });
          }
          setModalParams(undefined);
          doRefetch();
        }}
        dialogPanelClassname="w-108"
        open={modalParams?.type === ModalType.deleteSlot}
        onClose={() => setModalParams(undefined)}
        text={`You will only delete this slot on ${format(
          date,
          'Mo MMMM yyyy'
        )}. This action  is irreversible. If  you want to edit daily slots for a partner,  you can do this on  the “Partner” page.`}
      />
    </>
  );
};
export default CalendarDayJob;
