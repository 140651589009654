import { gql } from "@monorepo/graphql";


export const partnerUtility = {
  READ_PARTNER: gql(`
    query ReadPartner($uuid: String!) {
      readPartner(uuid: $uuid) {
        uuid
        name
        telephone
        website
        email 
        sla {
          uuid
          name
        }
      }
    }  
  `)
}