import { ReactElement } from 'react';
import NoActionIllustraton from '../../../assets/illustrations/NoAction.svg'

const NoAction = (): ReactElement => (
  <div className="flex flex-col items-center w-full h-full justify-center">
    <img src={NoActionIllustraton} alt="Nothing here" className="mb-4" />
    <h3 className="text-h3 font-semibold font-nunito mb-2">
      No action required
    </h3>
    <span className="text-sm">Check back later for new actionable items</span>
  </div>
);
export default NoAction;
