import { ReactElement } from 'react';
import Tabs from '../../../molecules/Tabs';
import { gql } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import Loader from '../../../icons/Loader';
import JobsToday from './Tabs/JobsToday';
import BlockedJobs from './Tabs/BlockedJobs';
import UnassignedJobs from './Tabs/UnassignedJobs';
import AuditPending from './Tabs/AuditPending';
import NoAction from '../../../atoms/NoAction';

interface Props {
  myAssignment: boolean;
}

const JOB_WIDGET_COUNTS = gql(`
  query JobsWidgetCounts ($myAssignment: Boolean!) {
    todaysJobCount (myAssignment: $myAssignment)
    blockedJobCount (myAssignment: $myAssignment)
    unassignedJobCount (myAssignment: $myAssignment)
    auditPendingJobCount (myAssignment: $myAssignment)
  }
`);

const JobsWidget = ({ myAssignment }: Props): ReactElement => {
  const { data, loading } = useQuery(JOB_WIDGET_COUNTS, {
    variables: {
      myAssignment,
    },
  });

  return (
    <div className="bg-white rounded-lg flex flex-col overflow-hidden">
      <div className="p-5">
        <h2 className="text-h2 font-bold font-nunito">Jobs</h2>
      </div>
      {loading ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : !data?.todaysJobCount &&
        !data?.blockedJobCount &&
        !data?.unassignedJobCount &&
        !data?.auditPendingJobCount ? (
        <NoAction />
      ) : (
        <Tabs
          ignoreSearch
          tabs={[
            ...(data?.todaysJobCount
              ? [
                  {
                    name: (
                      <div className="p-3 flex flex-col items-center">
                        <h1 className="font-bold font-nunito text-display text-primary">
                          {data.todaysJobCount}
                        </h1>
                        <p>Jobs today</p>
                      </div>
                    ),
                    value: 'today',
                    component: <JobsToday myAssignment={myAssignment} />,
                  },
                ]
              : []),
            ...(data?.blockedJobCount
              ? [
                  {
                    name: (
                      <div className="p-3 flex flex-col items-center">
                        <h1 className="font-bold font-nunito text-display text-primary">
                          {data.blockedJobCount}
                        </h1>
                        <p>Blocked jobs</p>
                      </div>
                    ),
                    value: 'blocked',
                    component: <BlockedJobs myAssignment={myAssignment} />,
                  },
                ]
              : []),
            ...(data?.unassignedJobCount
              ? [
                  {
                    name: (
                      <div className="p-3 flex flex-col items-center">
                        <h1 className="font-bold font-nunito text-display text-primary">
                          {data.unassignedJobCount}
                        </h1>
                        <p>Unassigned jobs</p>
                      </div>
                    ),
                    value: 'unassigned',
                    component: <UnassignedJobs myAssignment={myAssignment} />,
                  },
                ]
              : []),
            ...(data?.auditPendingJobCount
              ? [
                  {
                    name: (
                      <div className="p-3 flex flex-col items-center">
                        <h1 className="font-bold font-nunito text-display text-primary">
                          {data.auditPendingJobCount}
                        </h1>
                        <p>Audit pending</p>
                      </div>
                    ),
                    value: 'audit',
                    component: <AuditPending myAssignment={myAssignment} />,
                  },
                ]
              : []),
          ]}
        />
      )}
    </div>
  );
};
export default JobsWidget;
