import { createFileRoute } from '@tanstack/react-router';
import Feedback from '../components/organisms/Feedback';
import { z } from 'zod';
import ErrorScreen from '../components/organisms/ErrorScreen';

const ZodSearchParams = z.object({
  token: z.string(),
  tokenUuid: z.string()
});

const FeedbackRoute = () => {
    const { token, tokenUuid } = Route.useSearch()
    return <Feedback token={token} tokenUuid={tokenUuid} />
}

export const Route = createFileRoute('/feedback')({
  validateSearch: ZodSearchParams,
  component: FeedbackRoute,
  errorComponent: () => <ErrorScreen error={new Error('Token required')} />,
});
