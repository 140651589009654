import { ReactElement } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input, NumberInput } from '../../../atoms/Input';
import { AuditEntryType, gql, TargetType } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import Alert from '../../../atoms/Alerts';

type Props = {
  uuid?: string;
  call?: {
    uuid?: string;
    title?: string;
    notes: string;
    targetUuid: string;
  };
} & ModalWrapperPropsExtends;

const UPSERT_CALL = gql(`
  mutation UpsertAuditEntryCall ($input: UpsertAuditEntryInput!) {
    upsertAuditEntry (input: $input) {
      uuid
    }
  }  
`);

const UpsertCall = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper dialogPanelClassname="w-140" onClose={onClose} open={open}>
    <UpsertCallChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const UpsertCallChild = ({
  onClose,
  call,
}: Omit<Props, 'open'>): ReactElement => {
  const [upsert, { loading, error }] = useMutation(UPSERT_CALL);

  const form = useForm<{
    duration: string;
    notes: string;
  }>({
    onSubmit: ({ value }) => {
      if (!call) return;
      upsert({
        variables: {
          input: {
            uuid: call.uuid,
            title: call.title ? call.title : `Call - ${value.duration} minutes`,
            notes: value.notes,
            targetType: TargetType.user,
            targetUuid: call.targetUuid,
            entryType: AuditEntryType.userCustomerProfileCall,
          },
        },
        onCompleted: () => {
          notify.success('Succesfully logged call');
          onClose(true);
        },
      });
    },
    defaultValues: {
      duration: '',
      notes: call?.notes ?? '',
    },
  });

  return (
    <Modal
      loading={loading}
      title="Log call"
      onClose={onClose}
      confirmText="Log call"
      confirmCallback={form.handleSubmit}
    >
      <div className="p-5 space-y-5">
        {!call?.uuid && (
          <form.Field
            name="duration"
            validators={{
              onBlur: (val) =>
                val.value.length ? undefined : 'Please add a duration.',
            }}
            children={({ state, handleChange, handleBlur }) => (
              <NumberInput
                onBlur={handleBlur}
                value={state.value}
                label="Duration in minutes"
                onChange={(e) => handleChange(e.target.value)}
              />
            )}
          />
        )}
        <form.Field
          name="notes"
          validators={{
            onBlur: (val) =>
              val.value.length ? undefined : 'Please add some notes.',
          }}
          children={({ state, handleChange, handleBlur }) => (
            <Input
              onBlur={handleBlur}
              max={500}
              value={state.value}
              label="Call description"
              type="textarea"
              onChange={(e) => handleChange(e.target.value)}
            />
          )}
        />
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
export default UpsertCall;
