import { ReactElement, useState } from 'react';
import Table from '../../../../molecules/Table';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import { Button } from '../../../../atoms/Button';
import { AuditEntryType, gql, TargetType } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import { CalendarIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { CircleIcon } from '../../../../icons/Circle';
import AvatarStack from '../../../../atoms/AvatarStack';
import { notify } from '../../../../../utility/notify';
import UpsertCall from '../../../../molecules/Modals/UpsertCall';
import { client } from '../../../../../main';

interface Props {
  userUuid: string;
}

const INDEX_CALLS = gql(`
  query IndexAuditEntriesForCalls ($filters: IndexAuditEntriesInput!, $pagination: PaginationInput) {
    indexAuditEntries (filters: $filters, pagination: $pagination) {
      items {
        uuid
        title 
        notes
        auditEntryUser {
          firstName
          lastName
          avatarSrc
        }
        createdAt 
      }
      pagination {
        lastPage
      }
    }
  }
`);

const CallsTab = ({ userUuid }: Props): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.ten);
  const [page, setPage] = useState(1);
  const [call, setCall] = useState<{
    uuid?: string;
    title?: string;
    notes: string;
    targetUuid: string;
  }>();

  const { data, loading } = useQuery(INDEX_CALLS, {
    variables: {
      filters: {
        targetType: TargetType.user,
        targetUuid: userUuid,
        entryType: AuditEntryType.userCustomerProfileCall,
      },
      pagination: {
        page,
        perPage: rowsPerPage,
      },
    },
    onError: (err) => notify.error(err.message),
  });

  return (
    <>
      <Table
        loading={loading}
        title="Calls"
        columns={[
          {
            heading: 'Date',
            width: 20,
          },
          {
            heading: 'call by',
            width: 20,
          },
          {
            heading: 'notes',
            width: 50,
          },
          {
            width: 10,
          },
        ]}
        widthType="pc"
        toolbar={
          <Button
            onClick={() =>
              setCall({
                notes: '',
                targetUuid: userUuid,
              })
            }
            bStyle="outline"
            bText="Log call"
          />
        }
        rows={
          data?.indexAuditEntries.items.map((i) => ({
            uuid: i.uuid,
            cells: [
              {
                content: (
                  <div className="flex items-center space-x-2">
                    <CalendarIcon className="h-5 text-grey-400" />
                    <span>{format(new Date(i.createdAt), 'd MMMM yyyy')}</span>
                    <CircleIcon />
                    <span>{format(new Date(i.createdAt), 'H:mm')}</span>
                  </div>
                ),
                width: 20,
              },
              {
                content: (
                  <div className="flex items-center space-x-2">
                    {i.auditEntryUser ? (
                      <>
                        <AvatarStack
                          avatars={[
                            {
                              firstName: i.auditEntryUser.firstName,
                              lastName: i.auditEntryUser.lastName,
                              avatarSrc: i.auditEntryUser.avatarSrc,
                            },
                          ]}
                          width="w-9"
                          height="h-9"
                        />
                        <span>
                          {i.auditEntryUser.firstName}{' '}
                          {i.auditEntryUser.lastName}
                        </span>
                      </>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                ),
                width: 20,
              },
              {
                content: (
                  <div>
                    <p className="font-semibold text-sm mb-2">{i.title}</p>
                    <p className="text-sm">{i.notes}</p>
                  </div>
                ),
                width: 50,
              },
              {
                content: (
                  <div className="flex w-full justify-end">
                    <Button
                      className="h-9 w-9 justify-center !p-0"
                      bStyle="light"
                      onClick={() =>
                        setCall({
                          uuid: i.uuid,
                          title: i.title,
                          notes: i.notes ?? '',
                          targetUuid: userUuid,
                        })
                      }
                      Icon={
                        <PencilSquareIcon className="size-5 text-text-normal" />
                      }
                    />
                  </div>
                ),
                width: 10,
              },
            ],
          })) ?? []
        }
      />
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={data?.indexAuditEntries?.pagination.lastPage}
      />
      <UpsertCall
        call={call}
        open={!!call}
        onClose={(success) => {
          if (success) {
            client.graphqlClient().refetchQueries({
              include: [INDEX_CALLS],
            });
          }
          setCall(undefined);
        }}
      />
    </>
  );
};
export default CallsTab;
