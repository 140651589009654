import { ReactElement, useEffect, useMemo, useState } from 'react';
import Dropdown from '../../atoms/Dropdown';
import ExpandMore from '../../../assets/icons/ExpandMore.svg';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import {
  BuildingOffice2Icon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline';
import { gql } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';

const GET_ORGANISATIONS = gql(`
  query IndexOrganisations($filters: IndexOrganisationsFilterInput!) {
    indexOrganisations(filters: $filters) {
      items {
        name
        uuid
      }
    }
  }
  `);

interface Props {
  organisationUuid?: string;
  setOrganisationUuid: (uuid: string) => void;
  error?: string;
  isOptional?: boolean;
  hideLabel?: boolean;
  buttonClassname?: string;
  respectButtonWidth?: boolean;
  all?: boolean;
  hideLeadIcon?: boolean;
  organisationName?: string;
  light?: boolean;
}
const OrganisationDropdown = ({
  organisationUuid,
  setOrganisationUuid,
  isOptional,
  hideLabel,
  error,
  buttonClassname = '',
  respectButtonWidth = true,
  all = false,
  hideLeadIcon = false,
  organisationName,
  light
}: Props): ReactElement => {
  const { data, refetch } = useQuery(GET_ORGANISATIONS, {
    variables: {
      filters: {},
    }
  });

  const organisationOptions = useMemo(() => {
    return [
      ...(all
        ? [
            {
              value: 'all',
              name: 'All organisations',
            },
          ]
        : []),
      ...(data?.indexOrganisations.items.map(({ uuid, name }) => ({
        value: uuid,
        name,
      })) ?? []),
    ];
  }, [all, data]);

  const selected = useMemo(
    () => organisationOptions.find(({ value }) => value === organisationUuid),
    [organisationUuid, organisationOptions]
  );

  return (
    <Dropdown
      options={organisationOptions}
      respectButtonWidth={respectButtonWidth}
      innerButtonFullWidth={true}
      buttonText={selected?.name ?? organisationName ?? '-- Select --'}
      buttonClassname={`${buttonClassname} py-2.5 px-3 border rounded flex items-center w-580 w-full ${
        hideLabel
          ? 'mb-4 border-white'
          : 'mb-6 focus-within:outline-primary border-grey-500 focus-within:outline focus-within:outline-2'
      }`}
      selected={selected}
      ButtonIcon={
        <img src={light ? ExpandMore : ExpandMoreDark} alt="Drop down" />
      }
      onOptionSelect={(opt) => setOrganisationUuid(opt.value)}
      label={
        hideLabel ? undefined : `Organisation ${isOptional ? '(optional)' : ''}`
      }
      ButtonLeadIcon={
        !hideLeadIcon ? (
          <BuildingOffice2Icon className="size-6 mr-2" />
        ) : undefined
      }
      textClassname="flex-grow text-start truncate"
      handlesSearch
      searchFunction={async (search) => {
        if (search) {
          await refetch({ filters: { term: search } });
        }
      }}
      error={error}
    />
  );
};
export default OrganisationDropdown;
