import { ReactElement, useMemo, useState } from 'react';
import Dropdown from '../../atoms/Dropdown';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import ExpandMoreDisabled from '../../../assets/icons/ExpandMoreDisabled.svg';
import { gql, IndexSkillsQuery } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';

const GET_SKILLS = gql(`
  query IndexSkills($filters: IndexSkillsFilterInput!) {
    indexSkills(filters: $filters) {
      items {
        name
        uuid
      }
    }
  }
`);

const READ_SKILL = gql(`
  query ReadSkillForDropdown($uuid: String!) {
    readSkill (uuid: $uuid) {
      name
      uuid
    }
  }  
`);

type Skill = IndexSkillsQuery['indexSkills']['items'][0];

interface Props {
  skillUuid?: string;
  setSkill: (skill: Skill) => void;
  error?: string;
  isOptional?: boolean;
  showLabel?: boolean;
  className?: string;
  errorClassname?: string;
  disabled?: boolean;
}
const SkillDropdown = ({
  skillUuid,
  setSkill,
  isOptional,
  error,
  showLabel,
  className,
  errorClassname,
  disabled
}: Props): ReactElement => {
  const { data, refetch } = useQuery(GET_SKILLS, {
    context: {
      isBatched: true,
    },
  });
  const [activeSkill, setActiveSkill] = useState<Skill>();

  const skillOptions = useMemo(() => {
    return (
      data?.indexSkills.items.map(({ uuid, name }) => ({
        value: uuid,
        name,
      })) ?? []
    );
  }, [data]);

  const selected = useMemo(
    () => skillOptions.find(({ value }) => value === skillUuid),
    [skillUuid, skillOptions]
  );

  useQuery(READ_SKILL, {
    variables: {
      uuid: skillUuid!,
    },
    context: {
      isBatched: true,
    },
    onCompleted: (data) => setActiveSkill(data.readSkill),
    skip: !!((!skillUuid && skillUuid !== '-') || selected),
  });

  return (
    <Dropdown
      disabled={disabled}
      options={skillOptions}
      respectButtonWidth={true}
      innerButtonFullWidth={true}
      buttonText={selected?.name ?? activeSkill?.name ?? '-- Select --'}
      buttonClassname={`py-2.5 px-3 border border-grey-500 rounded flex items-center focus-within:outline-primary focus-within:outline focus-within:outline-2 w-580 w-full ${className}`}
      selected={selected}
      ButtonIcon={<img src={disabled ? ExpandMoreDisabled : ExpandMoreDark} alt="Drop down" />}
      onOptionSelect={(opt) =>
        setSkill({
          name: opt.name,
          uuid: opt.value,
        })
      }
      label={showLabel ? `Skill ${isOptional ? '(optional)' : ''}` : undefined}
      textClassname="flex-grow text-start"
      handlesSearch
      searchFunction={async (search) => {
        await refetch({
          filters: {
            term: search,
          },
        });
      }}
      error={error}
      errorClassname={errorClassname}
    />
  );
};
export default SkillDropdown;
