import { ReactElement, useState } from 'react';
import Table from '../../../../molecules/Table';
import { Button } from '../../../../atoms/Button';
import { RooferIcon } from '../../../../icons/Roofer';
import { styleUtility } from '../../../../../utility/styleUtility';
import {
  AcademicCapIcon,
  BoltIcon,
  BookOpenIcon,
  StarIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import SkillsModal from '../../../../molecules/Modals/ContractorProfileSkiilsModal';
import { SkillLevel, ContractorTrade, gql } from '@monorepo/graphql';
import EmptyState from '../../../../molecules/EmptyState';
import { useQuery } from '@apollo/client';
import { RowsPerPage } from '../../../../molecules/Pagination';
import PartnerSkillsModal from '../../../../molecules/Modals/PartnerSkillsModal';

const INDEX_SKILLS = gql(`
  query IndexSkillsForPartner ($partnerUuid: String!, $pagination: PaginationInput) {
    indexSkillsForPartners (partnerUuid: $partnerUuid, pagination: $pagination) {
      items {
        uuid
        name
        levelRequired
        trade
      }
      pagination {
        lastPage
      }
    }
  }  
`);

type Props = {
  uuid: string;
};

const SkillsTab = ({ uuid }: Props): ReactElement => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [showModal, setShowModal] = useState(false);
  const { data, loading } = useQuery(INDEX_SKILLS, {
    variables: {
      partnerUuid: uuid,
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
  });

  return (
    <>
      <div className="rounded-b-lg overflow-hidden">
        <Table
          loading={loading}
          widthType="pc"
          columns={[
            {
              width: 30,
              heading: 'trade',
            },
            {
              width: 30,
              heading: 'skill',
            },
            {
              width: 40,
              heading: 'min level',
            },
          ]}
          rows={
            data?.indexSkillsForPartners.items.map((s) => ({
              uuid: s.uuid,
              cells: [
                {
                  content:
                    s.trade === ContractorTrade.roofer ? (
                      <>
                        <RooferIcon
                          className="shrink-0"
                          colour={styleUtility.colours.grey['400']}
                        />
                        <span className="text-body-small block ml-2">
                          Roofer
                        </span>
                      </>
                    ) : (
                      <>
                        <BoltIcon className="size-5 text-grey-400" />
                        <span className="text-body-small block ml-2">
                          Electrician
                        </span>
                      </>
                    ),
                  width: 30,
                },
                {
                  content: s.name,
                  width: 30,
                },
                {
                  content:
                    s.levelRequired === SkillLevel.inTraining ? (
                      <>
                        <BookOpenIcon className="size-5 text-grey-400" />
                        <span className="text-body-small block ml-2">
                          In training
                        </span>
                      </>
                    ) : s.levelRequired === SkillLevel.partQualified ? (
                      <>
                        <AcademicCapIcon className="size-5 text-grey-400" />
                        <span className="text-body-small block ml-2">
                          Part qualified
                        </span>
                      </>
                    ) : (
                      <>
                        <StarIcon className="size-5 text-grey-400" />
                        <span className="text-body-small block ml-2">
                          Fully qualified
                        </span>
                      </>
                    ),
                  width: 40,
                },
                // {
                //   content: (
                //     <Button
                //       className="h-9 w-9 !p-0 justify-center"
                //       bStyle="light"
                //       onClick={() => {
                //         // handle
                //       }}
                //       Icon={<TrashIcon className="size-5 text-red" />}
                //     />
                //   ),
                //   width: 10,
                // },
              ],
            })) ?? []
          }
          title="Required skills"
          toolbar={
            <Button
              onClick={() => setShowModal(true)}
              bText="Edit skills"
              bStyle="outline"
            />
          }
        />
      </div>
      <PartnerSkillsModal
        partnerUuid={uuid}
        skills={data?.indexSkillsForPartners.items ?? []}
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};
export default SkillsTab;
