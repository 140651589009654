import { ReactElement } from 'react';
import { Tooltip } from 'react-tooltip';
import { styleUtility } from '../../../utility/styleUtility';

export type Avatar = {
  firstName: string;
  lastName?: string;
  avatarSrc?: string | null;
};

interface Props {
  avatars: Avatar[];
  height?: string;
  width?: string;
  heading?: boolean;
}

const adjust = (color: string, amount: number) =>
  '#' +
  color
    .replace(/^#/, '')
    .replace(/../g, (color) =>
      (
        '0' +
        Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
      ).substr(-2)
    );

const AvatarStack = ({
  avatars,
  height = 'h-7',
  width = 'w-7',
  heading,
}: Props): ReactElement => {
  const tooltipPrefix = Math.random();
  return (
    <div className="flex -space-x-3.5">
      {avatars.map((c, i) => (
        <div
          data-tooltip-id={`${tooltipPrefix}-${c.firstName}`}
          data-tooltip-content={`${c.firstName} ${c.lastName ?? ''}`}
          className="relative"
          key={`${tooltipPrefix}-${i}`}
        >
          <Tooltip id={`${tooltipPrefix}-${c.firstName}`} />
          {c.avatarSrc ? (
            <img
              src={c.avatarSrc}
              className={`${height} ${width} border border-white object-cover rounded-full`}
              alt={c.firstName}
            />
          ) : (
            <div
              className={`rounded-full overflow-hidden ${height} ${width} flex items-center justify-center border`}
              style={{
                backgroundColor: adjust(
                  styleUtility.stringToColour(c.firstName),
                  200
                ),
                borderColor: styleUtility.stringToColour(c.firstName),
                color: styleUtility.stringToColour(c.firstName),
              }}
            >
              {heading ? (
                <span className="text-h1 font-nunito font-semibold">
                  {c.firstName.slice(0, 1).toUpperCase()}
                </span>
              ) : (
                <span className="text-body-small leading-none font-semibold">
                  {c.firstName.slice(0, 1).toUpperCase()}
                </span>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default AvatarStack;
