import { ReactElement, ReactNode } from 'react';

interface Props {
  label: string;
  text: ReactNode;
  Icon?: ReactNode;
}
const TwoLineText = ({ label, text, Icon }: Props): ReactElement => (
  <div>
    <span className="text-body-small text-text-low-priority mb-2 block">
      {label}
    </span>
    <div className="flex items-center">
      {Icon}
      {typeof text === 'string' ? (
        <span className={`text-body ${Icon ? 'ml-2' : ''}`}>{text}</span>
      ) : (
        text
      )}
    </div>
  </div>
);
export default TwoLineText;
