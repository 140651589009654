import {
  createContext,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { IndexPartnersQuery } from '@monorepo/graphql';

interface IPartnerContext {
  selectedPartner?: Partner;
  setSelectedPartner: (partner: Partner) => void;
}

const PartnerContext = createContext<IPartnerContext>({} as IPartnerContext);

export type Partner = IndexPartnersQuery['indexPartners']['items'][0];

export function PartnerProvider({ children }: { children: ReactNode }) {
  const [selectedPartner, setSelectedPartner] = useState<Partner>();

  return (
    <PartnerContext.Provider
      value={{
        selectedPartner,
        setSelectedPartner,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
}

export function usePartners() {
  const context = useContext(PartnerContext);
  if (!context) {
    throw new Error('useParters must be used within an Partner Provider');
  }
  return context;
}
