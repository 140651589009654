import { ReactElement } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { gql, UserRole } from '@monorepo/graphql';
import { Input } from '../../../atoms/Input';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import PartnerDropdown from '../../PartnerDropdown';
import { DropdownWithBorder } from '../../../atoms/Dropdown';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import SchemeDropdown from '../../SchemeDropdown';

type InitalState = {
  uuid: string;
  userUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  partnerUuid: string;
  schemeUuid?: string;
  phone: string;
};

type Props = {
  customer: InitalState;
  partnerName?: string;
} & ModalWrapperPropsExtends;

const UPDATE_USER_CUSTOMER_PROFILE = gql(`
    mutation UpdateUserCustomerProfile($input: UpdateUserCustomerProfileInput!) {
      updateUserCustomerProfile(input: $input) {
        userUuid
      }
    }
  `);

const EditCustomerModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper onClose={onClose} open={open}>
    <EditCustomerModalChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const EditCustomerModalChild = ({
  onClose,
  customer,
  partnerName,
}: Omit<Props, 'open'>): ReactElement => {
  const [amendUser, { error, loading }] = useMutation(
    UPDATE_USER_CUSTOMER_PROFILE
  );

  const form = useForm({
    onSubmit: ({ value }) => {
      const attributes = {
        userUuid: value.userUuid,
        email: value.email,
        firstName: value.firstName,
        lastName: value.lastName,
        partnerUuid: value.partnerUuid,
        schemeUuid: value.schemeUuid,
        phone: value.phone,
      };
      amendUser({
        variables: {
          input: {
            uuid: customer.uuid,
            ...attributes,
          },
        },
      }).then(() => {
        onClose(true);
      });
    },
    defaultValues: customer,
  });

  const formValues = form.useStore((state) => state.values);

  return (
    <Modal
      title={`${customer ? 'Edit' : 'Add'} customer`}
      onClose={onClose}
      confirmText={`${customer ? 'Save' : 'Add'} customer`}
      confirmCallback={() => form.handleSubmit()}
      loading={loading}
    >
      <div className="p-5 max-w-150 overflow-hidden">
        {error?.message && (
          <div className="mb-5">
            <Alert alertType="error" text={error.message} />
          </div>
        )}
        <form.Field
          name="firstName"
          validators={{
            onSubmit: ({ value }) =>
              !value ? 'Please add a first name' : undefined,
          }}
          children={({ state, handleChange }) => (
            <Input
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="First name"
              error={state.meta.errors.join(', ')}
              className="w-80"
            />
          )}
        />
        <form.Field
          name="lastName"
          validators={{
            onSubmit: ({ value }) =>
              !value ? 'Please add a last name' : undefined,
          }}
          children={({ state, handleChange }) => (
            <Input
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="Last name"
              error={state.meta.errors.join(', ')}
              className="w-80"
            />
          )}
        />
        <form.Field
          name="email"
          validators={{
            onSubmit: ({ value }) =>
              !value ? 'Please add an email' : undefined,
          }}
          children={({ state, handleChange }) => (
            <Input
              type="email"
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="Email"
              error={state.meta.errors.join(', ')}
              className="w-140"
              Icon={<EnvelopeIcon className="size-6" />}
            />
          )}
        />
        <form.Field
          name="phone"
          children={({ state, handleChange }) => (
            <Input
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="Phone"
              Icon={<PhoneIcon className="size-6" />}
              className="w-80"
            />
          )}
        />
        <form.Field
          name="partnerUuid"
          validators={{
            onSubmit: ({ value }) =>
              !value ? 'Please select a partner' : undefined,
          }}
          preserveValue
          children={({ state, handleChange }) => (
            <PartnerDropdown
              error={state.meta.errors.join(', ')}
              partnerName={partnerName}
              partnerUuid={state.value}
              setPartnerUuid={handleChange}
            />
          )}
        />
        <form.Field
          name="schemeUuid"
          preserveValue
          validators={{
            onSubmit: ({ value, fieldApi }) =>
              !value && fieldApi.form.getFieldValue('partnerUuid')
                ? 'Please select a scheme'
                : undefined,
          }}
          children={({ state, handleChange }) =>
            formValues.partnerUuid && (
              <SchemeDropdown
                error={state.meta.errors.join(', ')}
                partnerUuid={formValues.partnerUuid}
                schemeUuid={state.value ?? ''}
                setSchemeUuid={handleChange}
              />
            )
          }
        />
      </div>
    </Modal>
  );
};
export default EditCustomerModal;
