import { createFileRoute } from '@tanstack/react-router';
import { ReactNode } from 'react';
import Loader from '../../../components/icons/Loader';
import { client } from '../../../main';
import { gql } from '@monorepo/graphql';
import NotFoundScreen from '../../../components/organisms/NotFoundScreen';
import Scheme from '../../../components/organisms/Scheme';

const READ_SCHEME = gql(`
  query ReadScheme($uuid: String!) {
    readScheme(uuid: $uuid) {
      uuid
      name
      partnerUuid
      startDate
      endDate 
      schemeType
      formattedStartDate
      formattedEndDate 
      status
      partnerName
      operatorUuid
      preAuditorUuid
      postAuditorUuid
      electricalAuditorUuid
      roofingAuditorUuid
    }
  }  
`);

const JobSingle = (): ReactNode => {
  const data = Route.useLoaderData();
  if (!data.data) return <NotFoundScreen />;
  return <Scheme data={data.data.readScheme} />;
};

export const Route = createFileRoute('/_authenticated/schemes/$uuid')({
  component: JobSingle,
  pendingComponent: () => (
    <div className="flex items-center h-full justify-center">
      <Loader />
    </div>
  ),
  loader: ({ params }) =>
    client.graphqlClient().query({
      query: READ_SCHEME,
      variables: {
        uuid: params.uuid,
      },
    }),
});
