import { createFileRoute } from '@tanstack/react-router';
import { ReactNode } from 'react';
import Loader from '../../../components/icons/Loader';
import { gql } from '@monorepo/graphql';
import { client } from '../../../main';
import NotFoundScreen from '../../../components/organisms/NotFoundScreen';
import Task from '../../../components/organisms/Task';

const READ_TASK = gql(`
  query ReadTask ($uuid: String!) {
    readTask (uuid: $uuid) {
      uuid
      title
      description
      status
      dueDate
      createdAt
      assignee {
        uuid
        avatarSrc
        firstName
        lastName
      }
      creator {
        uuid
        firstName
        lastName
      }
      customer {
        uuid
        firstName
        lastName
        partnerUuid
      }
      job {
        uuid
        type
        displayName
      }
      files { 
        uuid
        name
        src
        type
      }
    }
  }
`);

const UserUserProfileSingle = (): ReactNode => {
  const data = Route.useLoaderData();
  if (!data.data) return <NotFoundScreen />;
  return <Task task={data.data.readTask} />;
};

export const Route = createFileRoute('/_authenticated/tasks/$uuid')({
  component: UserUserProfileSingle,
  pendingComponent: () => (
    <div className="flex items-center h-full justify-center">
      <Loader />
    </div>
  ),
  loader: ({ params }) =>
    client.graphqlClient().query({
      query: READ_TASK,
      variables: {
        uuid: params.uuid,
      },
    }),
});
