import { ReactElement, useMemo, useState } from 'react';
import Dropdown from '../../atoms/Dropdown';
import ExpandMoreDark from '../../../assets/icons/ExpandMoreDark.svg';
import {
  ChevronDownIcon,
  PlusIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { gql, IndexUsersForSearchQuery, UserProfile } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import AvatarStack from '../../atoms/AvatarStack';
import EmptyAvatarStack from '../../atoms/EmptyAvatarStack';

const GET_USERS = gql(`
  query IndexUsersForSearch ($filters: IndexUsersFilterInput!) {
	indexUsers (filters: $filters) {
		items {
			firstName
      lastName
			avatarSrc
			uuid
      profile {
        ... on UserContractorProfile {
          uuid
        }
        ... on UserCustomerProfile {
          uuid
        }
        ... on UserUserProfile {
          uuid
        }
      }
		}
	}
}
`);

const GET_ACTIVE_USER = gql(`
  query ActiveUserForSearch ($input: ReadUserInput!) {
	readUser (input: $input) {
		firstName
    lastName
    avatarSrc
    uuid
	}
}
`);

interface Props {
  userUuid: string | undefined;
  setUserUuid: (uuid: string, profileUuid: string) => void;
  error?: string;
  stacked?: boolean;
  userProfile?: UserProfile;
  simple?: boolean;
  label?: string;
  partnerUuid?: string;
  helperText?: string;
  hideIcon?: boolean;
  disabled?: boolean;
  hideMargin?: boolean;
  hideLabel?: boolean;
}
const UserDropdown = ({
  userUuid,
  setUserUuid,
  error,
  stacked = false,
  userProfile = UserProfile.user,
  simple = false,
  label = 'User',
  partnerUuid,
  helperText,
  hideIcon,
  disabled,
  hideMargin,
  hideLabel,
}: Props): ReactElement => {
  const [user, setUser] =
    useState<IndexUsersForSearchQuery['indexUsers']['items'][0]>();

  const { data, refetch } = useQuery(GET_USERS, {
    variables: {
      filters: {
        userProfile,
        partnerUuid:
          userProfile === UserProfile.customer ? partnerUuid : undefined,
      },
    },
    context: {
      isBatched: true,
    },
  });

  const userOptions = useMemo(() => {
    return (
      data?.indexUsers.items.map(({ uuid, firstName, lastName }) => ({
        value: uuid,
        name: `${firstName} ${lastName}`,
      })) ?? []
    );
  }, [data]);

  const selected = useMemo(() => {
    const option = userOptions.find(({ value }) => value === userUuid);
    if (option) return option;
    if (user) {
      return {
        value: user.uuid,
        name: `${user.firstName} ${user.lastName}`,
      };
    }
    return undefined;
  }, [userUuid, userOptions, user]);

  const { loading } = useQuery(GET_ACTIVE_USER, {
    variables: {
      input: {
        uuid: userUuid ?? '',
        userProfile,
      },
    },
    skip: !!(!userUuid || selected),
    context: {
      isBatched: true,
    },
    onCompleted: (u) => {
      if (u.readUser) {
        setUser({
          ...u.readUser,
          profile: {
            uuid: u.readUser.uuid,
          },
        });
      }
    },
  });

  return stacked ? (
    <Dropdown
      disabled={disabled}
      isFetchingSelected={loading}
      error={error}
      options={userOptions}
      innerButtonFullWidth={true}
      buttonClassname="flex flex-col items-center space-y-1.5"
      selected={selected}
      onOptionSelect={(opt) => {
        const user = data?.indexUsers.items.find(
          ({ uuid }) => uuid === opt.value
        );
        if (!user) return;
        setUserUuid(opt.value, user.profile.uuid);
        setUser(user);
      }}
      textClassname="flex-grow text-start"
      handlesSearch
      searchFunction={async (search) => {
        if (search.length) {
          await refetch({
            filters: {
              term: search,
              userProfile,
              partnerUuid,
            },
          });
        }
      }}
    >
      <EmptyAvatarStack
        avatars={
          user
            ? [
                {
                  firstName: user.firstName,
                  lastName: user.lastName,
                  avatarSrc: user.avatarSrc ?? undefined,
                },
              ]
            : []
        }
      />
      <div className="flex items-center">
        <span className="text-body-small">
          {selected?.name ?? '-- Select --'}
        </span>
        <ChevronDownIcon className="size-2.5 text-text-normal ml-1" />
      </div>
    </Dropdown>
  ) : simple ? (
    <Dropdown
      disabled={disabled}
      error={error}
      options={userOptions}
      buttonText={selected?.name ?? '-- Select --'}
      buttonClassname="flex space-x-2 text-body-small items-center w-580 w-full"
      selected={selected}
      ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
      onOptionSelect={(opt) => {
        const user = data?.indexUsers.items.find(
          ({ uuid }) => uuid === opt.value
        );
        if (!user) return;
        setUserUuid(opt.value, user.profile.uuid);
        setUser(user);
      }}
      ButtonLeadIcon={
        <EmptyAvatarStack
          width="w-9"
          height="h-9"
          avatars={
            user
              ? [
                  {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    avatarSrc: user.avatarSrc ?? undefined,
                  },
                ]
              : []
          }
        />
      }
      textClassname="flex-grow text-start"
      handlesSearch
      searchFunction={async (search) => {
        if (search.length) {
          await refetch({
            filters: {
              term: search,
              userProfile,
              partnerUuid,
            },
          });
        }
      }}
    />
  ) : (
    <Dropdown
      disabled={disabled}
      error={error}
      options={userOptions}
      respectButtonWidth={true}
      innerButtonFullWidth={true}
      buttonText={selected?.name ?? '-- Select --'}
      buttonClassname={`py-2.5 px-3 border border-grey-500 rounded flex items-center focus-within:outline-primary focus-within:outline focus-within:outline-2 w-580 h-11  w-full ${
        hideMargin ? '' : 'mb-5'
      }`}
      selected={selected}
      ButtonIcon={<img src={ExpandMoreDark} alt="Drop down" />}
      onOptionSelect={(opt) => {
        const user = data?.indexUsers.items.find(
          ({ uuid }) => uuid === opt.value
        );
        if (!user) return;
        setUserUuid(opt.value, user.profile.uuid);
        setUser(user);
      }}
      label={hideLabel ? undefined : label}
      ButtonLeadIcon={
        !hideIcon ? <UserIcon className="size-6 mr-2" /> : undefined
      }
      textClassname="flex-grow text-start"
      handlesSearch
      helperText={helperText}
      searchFunction={async (search) => {
        if (search.length) {
          await refetch({
            filters: {
              term: search,
              userProfile,
              partnerUuid,
            },
          });
        }
      }}
    />
  );
};
export default UserDropdown;
