import { ReactElement, useState } from 'react';
import {
  Modal,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { useForm } from '@tanstack/react-form';
import { LocalFile } from '../../../../utility/files';
import { Input } from '../../../atoms/Input';
import {
  DocumentTextIcon,
  PhotoIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';
import UploadModal from '../UploadModal';
import { FileTargetType, gql, UpsertTaskInAddTaskMutation, UserProfile } from '@monorepo/graphql';
import UserDropdown from '../../UserDropown';
import JobDropdown from '../../JobDropdown';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';
import DateSelector from '../../../atoms/DateSelector';
import { addDays } from 'date-fns';

type Props = {
  uuid?: string;
  title?: string;
  description?: string;
  customerProfileUuid?: string;
  jobUuid?: string;
  partnerUuid?: string;
  operatorUuid?: string;
  files?: LocalFile[];
  dueDate?: Date;
  onClose: (success: boolean, task?: UpsertTaskInAddTaskMutation['upsertTask']) => void;
} & Omit<ModalWrapperPropsExtends, 'onClose'>;

const UPSERT_TASK = gql(`
  mutation UpsertTaskInAddTask ($input: TaskUpsertInput!) {
    upsertTask(input: $input) {
      uuid
      title
      description
      status
      dueDate
      createdAt
      assignee {
        uuid
        avatarSrc
        firstName
        lastName
      }
      creator {
        uuid
        firstName
        lastName
      }
      customer {
        uuid
        firstName
        lastName
        partnerUuid
      }
      job {
        uuid
        type
        displayName
      }
      files { 
        uuid
        name
        src
        type
      }
    }
  }  
`);

const AddTask = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper open={open} onClose={onClose} dialogPanelClassname="w-150">
    <AddTaskChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const AddTaskChild = ({
  onClose,
  title = '',
  description = '',
  uuid,
  customerProfileUuid,
  operatorUuid,
  jobUuid,
  partnerUuid,
  dueDate = addDays(new Date(), 7),
  files = []
}: Omit<Props, 'open'>): ReactElement => {
  const [showUploadModal, setShowUploadModal] = useState(false);

  const [upsertTask, { loading, error }] = useMutation(UPSERT_TASK);

  const form = useForm<{
    uuid: string;
    title: string;
    description: string;
    files: LocalFile[];
    operatorUuid?: string;
    jobUuid?: string;
    date?: Date;
    customerProfileUuid?: string;
  }>({
    onSubmit: (values) => {
      upsertTask({
        variables: {
          input: {
            uuid: values.value.uuid,
            customerProfileUuid: values.value.customerProfileUuid,
            title: values.value.title,
            description: values.value.description,
            operatorUuid: values.value.operatorUuid,
            jobUuid: values.value.jobUuid,
            fileUuids: values.value.files.map(({ uuid }) => uuid),
            dueDate: values.value.date,
          },
        },
        refetchQueries: ['IndexTasks'],
        onCompleted: (d) => {
          notify.success('Task successfully created');
          onClose(true, d.upsertTask);
        },
      });
    },
    defaultValues: {
      uuid: uuid ?? uuidv4(),
      title,
      description,
      files,
      jobUuid,
      operatorUuid,
      customerProfileUuid,
      date: dueDate
    },
  });

  const customerProfileUuidField = form.useStore(
    (state) => state.values.customerProfileUuid
  );

  return (
    <>
      <Modal
        title={`${uuid ? 'Save' : 'Create'} task`}
        confirmText={`${uuid ? 'Save' : 'Create'} task`}
        onClose={onClose}
        confirmCallback={() => form.handleSubmit()}
        loading={loading}
        onFiles={() => setShowUploadModal(true)}
        onFilesDisabled={!customerProfileUuidField}
      >
        <div className="p-5">
          <form.Field
            name="title"
            validators={{
              onBlur: (val) =>
                val.value.length === 0 ? 'Please enter a title' : undefined,
            }}
            children={(subfield) => (
              <Input
                error={subfield.state.meta.errors.join(', ')}
                onBlur={subfield.handleBlur}
                label="Title"
                onChange={(e) => subfield.handleChange(e.target.value)}
                value={subfield.state.value}
              />
            )}
          />

          <form.Field
            name="description"
            children={(subfield) => (
              <Input
                max={500}
                label="Description"
                type="textarea"
                onChange={(e) => subfield.handleChange(e.target.value)}
                value={subfield.state.value}
              />
            )}
          />

          <form.Field
            name="date"
            children={(subfield) => (
              <>
                <div className="mb-2">
                  <span className="font-semibold text-input-label text-text-normal">
                    Due date
                  </span>
                </div>
                <DateSelector
                  showLabel
                  selectedDate={subfield.state.value}
                  setSelectedDate={subfield.handleChange}
                />
              </>
            )}
          />

          <div className="mt-5">
            <form.Field
              name="operatorUuid"
              children={(subfield) => (
                <UserDropdown
                  hideIcon
                  label="Assignee"
                  userUuid={subfield.state.value}
                  setUserUuid={(_, profileUuid) =>
                    subfield.handleChange(profileUuid)
                  }
                />
              )}
            />
          </div>
          <div className="mt-5">
            <form.Field
              name="customerProfileUuid"
              children={(subfield) => (
                <UserDropdown
                  hideIcon
                  userProfile={UserProfile.customer}
                  label="Customer"
                  partnerUuid={partnerUuid}
                  userUuid={subfield.state.value}
                  setUserUuid={(_, profileUuid) =>
                    subfield.handleChange(profileUuid)
                  }
                />
              )}
            />
          </div>

          <form.Field name="files">
            {(subfield) =>
              customerProfileUuidField && subfield.state.value.length ? (
                <>
                  <div className="mb-2 mt-5">
                    <span className="font-semibold text-input-label text-text-normal">
                      Files
                    </span>
                  </div>
                  <div
                    className={`flex shrink-0 flex-wrap ${
                      subfield.state.value.length ? '!-mb-2' : ''
                    }`}
                  >
                    {subfield.state.value.map((f) => (
                      <div className="p-2 flex items-center mr-2 mb-2 bg-grey-900 border-grey-500 border rounded fit-content w-fit space-x-2">
                        {f.type.includes('image') ? (
                          <PhotoIcon className="size-5" />
                        ) : (
                          <DocumentTextIcon className="size-5" />
                        )}
                        <span className="text-body-small">{f.name}</span>
                        <button
                          onClick={() =>
                            subfield.handleChange(
                              subfield.state.value?.filter(
                                (fi) => fi.uuid !== f.uuid
                              )
                            )
                          }
                          type="button"
                        >
                          <XMarkIcon className="size-5" />
                        </button>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )
            }
          </form.Field>

          {customerProfileUuidField && (
            <div className="mt-5">
              <form.Field
                name="jobUuid"
                children={(subfield) => (
                  <JobDropdown
                    customerProfileUuid={customerProfileUuidField}
                    jobUuid={subfield.state.value}
                    setJobUuid={(jobUuid) => subfield.handleChange(jobUuid)}
                  />
                )}
              />
            </div>
          )}
          {error && (
            <div className="mt-5">
              <Alert alertType="error" text={error.message} />
            </div>
          )}
        </div>
      </Modal>
      {customerProfileUuidField && (
        <UploadModal
          targetType={FileTargetType.task}
          targetUuid={form.getFieldValue('uuid')}
          customerProfileUuid={customerProfileUuidField}
          skipCommit={!uuid}
          open={showUploadModal}
          onClose={(_, files) => {
            if (files) {
              form.setFieldValue(
                'files',
                [
                  ...(files.map((f) => ({
                    name: f.file.name,
                    type: f.file.type,
                    src: f.src,
                    uuid: f.uuid,
                  })) ?? []),
                  ...form.getFieldValue('files'),
                ],
                {
                  touch: true,
                }
              );
            }
            setShowUploadModal(false);
          }}
        />
      )}
    </>
  );
};
export default AddTask;
