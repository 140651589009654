import { format } from 'date-fns';
import { ReactElement, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import Loader from '../../icons/Loader';
import { usePartners } from '../../../utility/partnerContext';
import { slotsUtility } from '../../../utility/slots';
import CalendarDayJob from './CalendarDayJob';
import { AllEnum, useCalendarContext } from '../../../utility/calendarContext';
import { Option } from '../../atoms/Dropdown';
import { JobDifficulty, JobStatus, JobType } from '@monorepo/graphql';
import EmptyState from '../EmptyState';

interface Props {
  date: Date;
}

const CalendarDay = ({ date }: Props): ReactElement => {
  const { selectedPartner } = usePartners();
  const { filters, selectedPeriod } = useCalendarContext();

  const filterVars = useMemo(() => {
    const statuses = filters.states.filter(
      (opt): opt is Option<JobStatus> => opt.value !== AllEnum.all
    );
    const difficulties = filters.difficulty.filter(
      (opt): opt is Option<JobDifficulty> => opt.value !== AllEnum.all
    );
    const jobTypes = filters.jobType.filter(
      (opt): opt is Option<JobType> => opt.value !== AllEnum.all
    );
    const schemes = filters.schemes.filter(
      (opt): opt is Option<string> => opt.value !== AllEnum.all
    );
    const contractors = filters.contractors.filter(
      (opt): opt is Option<string> => opt.value !== AllEnum.all
    );
    return {
      status: statuses.length ? statuses.map(({ value }) => value) : undefined,
      difficulty: difficulties.length
        ? difficulties.map(({ value }) => value)
        : undefined,
      jobType: jobTypes.length ? jobTypes.map(({ value }) => value) : undefined,
      schemes: schemes.length ? schemes.map(({ value }) => value) : undefined,
      contractors: contractors.length
        ? contractors.map(({ value }) => value)
        : undefined,
    };
  }, [filters]);

  const { data, loading, refetch } = useQuery(slotsUtility.queries.GET_SLOTS, {
    variables: {
      input: {
        filter: {
          date,
          partnerUuid: selectedPartner?.uuid ?? '',
          ...filterVars,
        },
      },
    },
    context: {
      isBatched: true,
    },
    skip: !selectedPartner,
  });

  return (
    <div className="flex-grow w-full bg-white">
      {loading ? (
        <div className="bg-white h-full flex-grow flex items-center justify-center">
          <Loader />
        </div>
      ) : data?.indexSlots.items.length ? (
        <div className="pt-px pb-10">
          <div className="outline outline-1 -mt-1 outline-grey-700">
            {data?.indexSlots.items.map((s, i) => (
              <CalendarDayJob
                doRefetch={refetch}
                key={i}
                slot={s}
                date={date}
              />
            ))}
          </div>
        </div>
      ) : selectedPeriod === 'day' && (
        <EmptyState
          title="No jobs"
          description="There are not jobs booked on this day."
        />
      )}
    </div>
  );
};
export default CalendarDay;
