import { ReactElement, useState } from 'react';
import { Input } from '../../atoms/Input';
import { useForm } from '@tanstack/react-form';
import { Button } from '../../atoms/Button';
import { getRouteApi, useRouter } from '@tanstack/react-router';
import {
  CheckCircleIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';
import { usersUtility } from '../../../utility/users';
import { useLazyQuery } from '@apollo/client';
import Alert from '../../atoms/Alerts';

const routeApi = getRouteApi('/_unauthenticated/reset-password');
const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d@$!"%*#?&]{8,}$/;

const ResetPassword = (): ReactElement => {
  const search = routeApi.useSearch();

  const isComplete = search.email && search.token;

  const [hasSucceeded, setHasSucceeded] = useState(false);

  const [completeResetPassword, { loading, error }] = useLazyQuery(
    usersUtility.queries.CompleteResetPasswordGql
  );
  const [
    resetPassword,
    { loading: resetPasswordLoading, error: resetPasswordError },
  ] = useLazyQuery(usersUtility.queries.ResetPasswordGql);

  const router = useRouter();

  const form = useForm<{
    email: string;
    password: string;
    confirmPassword: string;
  }>({
    onSubmit: async ({ value }) => {
      if (search.token && search.email) {
        completeResetPassword({
          variables: {
            input: {
              email: search.email,
              token: search.token,
              password: value.confirmPassword,
            },
          },
          onCompleted: () => {
            setHasSucceeded(true);
            setTimeout(
              () =>
                router.navigate({
                  to: '/login',
                }),
              5000
            );
          },
        });
      } else if (value.email) {
        resetPassword({
          variables: {
            input: {
              email: value.email,
            },
          },
          onCompleted: () => setHasSucceeded(true),
        });
      }
    },
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  return hasSucceeded ? (
    <div className="flex items-center w-[430px] flex-col">
      <div
        className={`h-[92px] w-[92px] rounded-full mb-5 flex items-center justify-center ${
          isComplete ? 'bg-primary' : 'bg-tertiary-100'
        }`}
      >
        {isComplete ? (
          <CheckCircleIcon className="text-white size-11" />
        ) : (
          <InformationCircleIcon className="text-white size-11" />
        )}
      </div>
      <h2 className="text-h2 font-bold font-nunito mb-2">
        {isComplete ? 'Password Changed' : 'Check your email'}
      </h2>
      <p className="font-normal text-body">
        {isComplete
          ? 'Forwarding to login page in 5...'
          : 'We have emailed you a password reset link'}
      </p>
    </div>
  ) : (
    <form
      className="w-[430px]"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
    >
      <h1 className="text-h1-small font-nunito mb-4">
        {isComplete ? 'Create new password' : 'Reset password'}
      </h1>
      {isComplete ? (
        <>
          <form.Field
            name="password"
            validators={{
              onBlur: ({ value }) => {
                if (value.length < 8)
                  return 'You must include at least 8 characters';
                if (!value.match(/[A-Z]/))
                  return 'You must include at least one uppercase character';
                if (!value.match(/[\d]/))
                  return 'You must include at least one number';
                if (!value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/))
                  return 'You must include at least special character';

                return undefined;
              },
            }}
            children={({ state, handleChange, handleBlur }) => (
              <Input
                value={state.value ?? ''}
                onChange={(e) => handleChange(e.target.value)}
                label="New password"
                helperText="Min 8 characters, one special character, one uppercase letter"
                Icon={<LockClosedIcon className="size-6" />}
                type="password"
                onBlur={handleBlur}
                required
                error={state.meta.errors.join(', ')}
                success={
                  state.value.match(passwordRegex)
                    ? 'Password matches requirements'
                    : undefined
                }
              />
            )}
          />
          <form.Field
            name="confirmPassword"
            validators={{
              onBlur: ({ value, fieldApi }) =>
                value === fieldApi.form.getFieldValue('password')
                  ? undefined
                  : 'Please ensure passwords match',
            }}
            children={({ state, handleChange, handleBlur, form }) => (
              <Input
                value={state.value ?? ''}
                onChange={(e) => handleChange(e.target.value)}
                label="Confirm new password"
                Icon={<LockClosedIcon className="size-6" />}
                type="password"
                required
                onBlur={handleBlur}
                error={state.meta.errors.join(', ')}
                success={
                  state.value === form.getFieldValue('password') &&
                  state.value.length
                    ? 'Password match'
                    : undefined
                }
              />
            )}
          />
        </>
      ) : (
        <form.Field
          name="email"
          children={({ state, handleChange }) => (
            <Input
              value={state.value ?? ''}
              onChange={(e) => handleChange(e.target.value)}
              label="Your email"
              Icon={<EnvelopeIcon className="size-6" />}
              required
            />
          )}
        />
      )}
      {error && (
        <div className="mt-4">
          <Alert alertType='error' text={error.message} />
        </div>
      )}
      {resetPasswordError && (
        <div className="mt-4">
          <Alert alertType='error' text={resetPasswordError.message} />
        </div>
      )}
      <Button
        className="w-full mt-8 flex items-center justify-center"
        loading={loading || resetPasswordLoading}
        type="submit"
        bText={isComplete ? 'Reset password' : 'Send instructions'}
      />
    </form>
  );
};
export default ResetPassword;
