import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import {
  XMarkIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  ArrowLeftIcon,
  PaperClipIcon,
} from '@heroicons/react/24/outline';
import { ElementType, ReactNode, useEffect, useMemo, useState } from 'react';
import { Button } from '../../../atoms/Button';
import Loader from '../../../icons/Loader';

type Icon = 'warning' | 'critical' | 'success';

interface ModalWrapperProps {
  onClose: (success: boolean) => void;
  open: boolean;
  children: ReactNode;
  dialogPanelClassname?: string;
  as?: ElementType;
}

export type ModalWrapperPropsExtends = {
  open: boolean;
  icon?: Icon;
  onClose: (success: boolean) => void;
  dialogPanelClassname?: string;
};

export type ModalPropsExtends = Pick<
  ModalWrapperPropsExtends,
  'icon' | 'onClose'
>;

export function ModalWrapper({
  open,
  onClose,
  children,
  dialogPanelClassname,
  as,
}: ModalWrapperProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    } else {
      setTimeout(() => setIsOpen(false), 250);
    }
  }, [open]);

  return (
    <Dialog
      transition
      className={`fixed inset-0 z-20 flex w-screen items-center justify-center p-1 transition duration-150 data-[closed]:opacity-0`}
      open={open}
      onClose={() => onClose(false)}
    >
      <DialogBackdrop transition className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel
          as={as}
          transition
          className={`flex flex-col shadow-lg overflow-hidden max-h-4/5 bg-white rounded-xl transition duration-150 translate-y-0 ease-out data-[closed]:translate-y-full ${dialogPanelClassname}`}
        >
          {isOpen && children}
        </DialogPanel>
      </div>
    </Dialog>
  );
}

interface ModalProps {
  title: string;
  onClose: (success: boolean) => void;
  children: ReactNode;
  icon?: Icon;
  iconClassname?: string;
  confirmText?: string;
  confirmCallback?: () => void;
  hideTopHr?: boolean;
  closeText?: string;
  hideCloseText?: boolean;
  scroll?: boolean;
  loading?: boolean;
  onBack?: () => void;
  onFiles?: () => void;
  confirmDisabled?: boolean;
  onFilesDisabled?: boolean;
}

export function Modal({
  onClose,
  onBack,
  title,
  children,
  icon,
  confirmText,
  confirmCallback,
  hideTopHr,
  closeText = 'Cancel',
  hideCloseText,
  scroll = true,
  loading,
  confirmDisabled,
  onFiles,
  onFilesDisabled,
}: ModalProps) {
  return (
    <>
      <div className="flex items-center justify-between p-5">
        <div className="flex items-center space-x-5">
          {icon === 'warning' && (
            <div className="p-3 bg-amber-100 rounded-full">
              <ExclamationTriangleIcon className="size-6 rounded-full text-white" />
            </div>
          )}
          {icon === 'critical' && (
            <div className="p-3 bg-red-100 rounded-full">
              <ExclamationCircleIcon className="size-6 rounded-full text-white" />
            </div>
          )}
          {icon === 'success' && (
            <div className="p-3 bg-primary rounded-full">
              <CheckCircleIcon className="size-6 rounded-full text-white" />
            </div>
          )}
          <h2 className="font-nunito text-2xl font-bold">{title}</h2>
        </div>
        <button onClick={() => onClose(false)}>
          <XMarkIcon className="size-6 text-normal" />
        </button>
      </div>
      {!icon && !hideTopHr && (
        <hr className="border-none shrink-0 h-px w-full bg-grey-700" />
      )}
      <div
        className={
          scroll ? 'overflow-scroll' : 'flex overflow-hidden flex-grow'
        }
      >
        {children}
      </div>
      {!icon && <hr className="border-none shrink-0 h-px w-full bg-grey-700" />}
      <div className="flex items-center p-5 h-21 justify-between">
        {loading ? (
          <div className="flex-grow flex justify-end">
            <Loader multiplier={0.6} />
          </div>
        ) : (
          <>
            <div className="flex-grow">
              {onBack && (
                <Button
                  bStyle="outline"
                  bText="Back"
                  Icon={<ArrowLeftIcon className="size-6" />}
                  onClick={onBack}
                />
              )}
              {onFiles && (
                <Button
                  bStyle="light"
                  bText="Attach files"
                  disabled={onFilesDisabled}
                  onClick={onFiles}
                  Icon={<PaperClipIcon className="size-6" />}
                />
              )}
            </div>
            <div className="flex items-center space-x-3">
              {!hideCloseText && (
                <Button
                  bStyle="clean"
                  bText={closeText}
                  onClick={() => onClose(false)}
                />
              )}
              {confirmText && (
                <Button
                  bText={confirmText}
                  disabled={confirmDisabled}
                  className="min-w-30 justify-center"
                  onClick={confirmCallback}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
