import { ReactElement } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { useJobContext } from '../../../organisms/Job';
import { useForm } from '@tanstack/react-form';
import { AuditType, gql } from '@monorepo/graphql';
import UserDropdown from '../../UserDropown';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';

const UpdateJobAuditors = gql(`
  mutation UpdateJobAuditors($input: UpdateJobInput!) {
    updateJob (input: $input) {
      uuid
    }
  }  
`);

const AuditorsModal = ({ open, onClose }: ModalWrapperPropsExtends) => (
  <ModalWrapper dialogPanelClassname="w-140" open={open} onClose={onClose}>
    <AuditorsModalChild onClose={onClose} />
  </ModalWrapper>
);

const AuditorsModalChild = ({ onClose }: ModalPropsExtends): ReactElement => {
  const { job, audits } = useJobContext();

  const [updateJob, { loading, error }] = useMutation(UpdateJobAuditors);

  const form = useForm({
    onSubmit: (values) => {
      updateJob({
        variables: {
          input: {
            uuid: job.uuid,
            auditorUuids: values.value,
          },
        },
        onCompleted: () => {
          notify.success('Successfully saved job.');
          onClose(true);
        },
      });
    },
    defaultValues: {
      [AuditType.postGeneral]:
        audits.find(({ auditType }) => auditType === AuditType.postGeneral)
          ?.auditor?.uuid ?? '',
      [AuditType.postElectrical]:
        audits.find(({ auditType }) => auditType === AuditType.postElectrical)
          ?.auditor?.uuid ?? '',
      [AuditType.postRoofing]:
        audits.find(({ auditType }) => auditType === AuditType.postRoofing)
          ?.auditor?.uuid ?? '',
      [AuditType.pre]:
        audits.find(({ auditType }) => auditType === AuditType.pre)?.auditor
          ?.uuid ?? '',
    },
  });

  return (
    <Modal
      title="Edit auditors"
      onClose={onClose}
      loading={loading}
      confirmCallback={form.handleSubmit}
      confirmText="Save changes"
    >
      <div className="p-5">
        <form.Field
          name={AuditType.pre}
          children={(field) => (
            <UserDropdown
              label="Auditor"
              helperText="Pre job"
              userUuid={field.state.value}
              setUserUuid={(_, profileUuid) => field.handleChange(profileUuid)}
            />
          )}
        />
        <form.Field
          name={AuditType.postGeneral}
          children={(field) => (
            <UserDropdown
              label="General Auditor"
              helperText="Post job"
              userUuid={field.state.value}
              setUserUuid={(_, profileUuid) => field.handleChange(profileUuid)}
            />
          )}
        />
        <form.Field
          name={AuditType.postElectrical}
          children={(field) => (
            <UserDropdown
              label="Electrical Auditor"
              helperText="Post job"
              userUuid={field.state.value}
              setUserUuid={(_, profileUuid) => field.handleChange(profileUuid)}
            />
          )}
        />
        <form.Field
          name={AuditType.postRoofing}
          children={(field) => (
            <UserDropdown
              label="Roofing Auditor"
              helperText="Post job"
              userUuid={field.state.value}
              setUserUuid={(_, profileUuid) => field.handleChange(profileUuid)}
            />
          )}
        />
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
export default AuditorsModal;
