import { ReactElement } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input } from '../../../atoms/Input';
import NumberInput from '../../../atoms/NumberInput';
import { gql } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';

type Props = {
  sla?: {
    uuid: string;
    name: string;
    scaffoldingSetUpThreshold: number;
    scaffoldingStrikeThreshold: number;
  };
} & ModalWrapperPropsExtends;

const UPSERT_SLA = gql(`
  mutation UpsertSLA ($input: UpsertSLAInput!) {
    upsertSLA (input: $input) {
      uuid
    }
  }
`);

const UpsertSla = ({
  open,
  onClose,
  ...rest
}: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <UpsertSlaChild onClose={onClose} {...rest} />
  </ModalWrapper>
)

const UpsertSlaChild = ({ onClose, sla }: Omit<Props, 'open'>): ReactElement => {
  const [upsert, { loading, error }] = useMutation(UPSERT_SLA);

  const form = useForm({
    onSubmit: ({ value }) => {
      upsert({
        variables: {
          input: {
            uuid: sla?.uuid,
            name: value.name,
            scaffoldingUpDays: value.scaffoldingSetUpThreshold,
            scaffoldingDownDays: value.scaffoldingStrikeThreshold,
          },
        },
        onCompleted: () => {
          notify.success('Successfully saved SLA');
          onClose(true);
        },
      });
    },
    defaultValues: {
      name: sla?.name ?? '',
      scaffoldingSetUpThreshold: sla?.scaffoldingSetUpThreshold ?? 1,
      scaffoldingStrikeThreshold: sla?.scaffoldingStrikeThreshold ?? 1,
    },
  });

  return (
    <Modal
      confirmCallback={form.handleSubmit}
      confirmText={sla ? 'Save SLA' : 'Add SLA'}
      title={sla ? 'Edit SLA' : 'Add SLA'}
      onClose={onClose}
      loading={loading}
    >
      <div className="p-5">
        <form.Field
          name="name"
          validators={{
            onBlur: ({ value }) =>
              value.length ? undefined : 'Please enter a name.',
          }}
          children={({ state, handleChange }) => (
            <Input
              error={state.meta.errors.join(', ')}
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="Name"
            />
          )}
        />
        <div className="mb-5">
          <form.Field
            name="scaffoldingSetUpThreshold"
            children={({ state, handleChange }) => (
              <NumberInput
                max={20}
                count={state.value}
                setCount={handleChange}
                title="Scaffolding up (days)"
                helperText="Number of days within which scaffolding must be put up after booking a job"
              />
            )}
          />
        </div>
        <form.Field
          name="scaffoldingStrikeThreshold"
          children={({ state, handleChange }) => (
            <NumberInput
              helperText="Number of days within which scaffolding must be taken down after a job"
              max={20}
              count={state.value}
              setCount={handleChange}
              title="Scaffolding down (days)"
            />
          )}
        />
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
export default UpsertSla;
