import { ReactElement } from 'react';
import { Modal, ModalWrapper, ModalWrapperPropsExtends } from '../Base';
import { useForm } from '@tanstack/react-form';
import { Input } from '../../../atoms/Input';
import { gql } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';

type Props = {
  skill?: {
    name: string;
    uuid?: string;
  };
} & ModalWrapperPropsExtends;

const UpsertSkill = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper as="form" open={open} onClose={onClose}>
    <UpsertSkillChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const UPSERT_SKILL = gql(`
  mutation UpsertSkill ($input: UpsertSkillInput!) {
    upsertSkill(input: $input) {
      uuid
    }
  }  
`);

const UpsertSkillChild = ({
  onClose,
  skill,
}: Omit<Props, 'open'>): ReactElement => {
  const [upsert, { loading, error }] = useMutation(UPSERT_SKILL);

  const form = useForm({
    defaultValues: {
      name: skill?.name ?? '',
    },
    onSubmit: ({ value }) => {
      upsert({
        variables: {
          input: {
            uuid: skill?.uuid,
            name: value.name,
          },
        },
        onCompleted: () => {
          notify.success('Successfully saved skill.')
          onClose(true)
        }
      });
    },
  });

  return (
    <Modal
      title={skill?.uuid ? 'Edit skill' : 'Add skill'}
      onClose={onClose}
      confirmText="Save skill"
      confirmCallback={form.handleSubmit}
      loading={loading}
    >
      <div className="p-5 w-140">
        <form.Field
          name="name"
          children={({ state, handleChange }) => (
            <Input
              value={state.value}
              required
              label="Name"
              onChange={(e) => handleChange(e.target.value)}
            />
          )}
        />
        {error && (
          <div className="mt-5">
            <Alert alertType="error" text={error.message} />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default UpsertSkill;
