import { ReactElement, useState } from 'react';
import Table from '../../../../molecules/Table';
import { Button } from '../../../../atoms/Button';
import { RooferIcon } from '../../../../icons/Roofer';
import { styleUtility } from '../../../../../utility/styleUtility';
import {
  AcademicCapIcon,
  BoltIcon,
  BookOpenIcon,
  StarIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import SkillsModal from '../../../../molecules/Modals/ContractorProfileSkiilsModal';
import { SkillLevel, ContractorTrade } from '@monorepo/graphql';
import { useJobContext } from '../..';
import EmptyState from '../../../../molecules/EmptyState';

const SkillsTab = (): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const { skills, skillsLoading } = useJobContext();

  return (
    <>
      <div className="rounded-b-lg overflow-hidden">
        <Table
          loading={skillsLoading}
          widthType="pc"
          columns={[
            {
              width: 30,
              heading: 'trade',
            },
            {
              width: 30,
              heading: 'skill',
            },
            {
              width: 40,
              heading: 'min level',
            },
          ]}
          rows={skills.map((s) => ({
            uuid: s.uuid,
            cells: [
              {
                content:
                  s.trade === ContractorTrade.roofer ? (
                    <>
                      <RooferIcon
                        className="shrink-0"
                        colour={styleUtility.colours.grey['400']}
                      />
                      <span className="text-body-small block ml-2">Roofer</span>
                    </>
                  ) : (
                    <>
                      <BoltIcon className="size-5 text-grey-400" />
                      <span className="text-body-small block ml-2">
                        Electrician
                      </span>
                    </>
                  ),
                width: 30,
              },
              {
                content: s.name,
                width: 30,
              },
              {
                content:
                  s.levelRequired === SkillLevel.inTraining ? (
                    <>
                      <BookOpenIcon className="size-5 text-grey-400" />
                      <span className="text-body-small block ml-2">
                        In training
                      </span>
                    </>
                  ) : s.levelRequired === SkillLevel.partQualified ? (
                    <>
                      <AcademicCapIcon className="size-5 text-grey-400" />
                      <span className="text-body-small block ml-2">
                        Part qualified
                      </span>
                    </>
                  ) : (
                    <>
                      <StarIcon className="size-5 text-grey-400" />
                      <span className="text-body-small block ml-2">
                        Fully qualified
                      </span>
                    </>
                  ),
                width: 40,
              },
              // {
              //   content: (
              //     <Button
              //       className="h-9 w-9 !p-0 justify-center"
              //       bStyle="light"
              //       onClick={() => {
              //         // handle
              //       }}
              //       Icon={<TrashIcon className="size-5 text-red" />}
              //     />
              //   ),
              //   width: 10,
              // },
            ],
          }))}
          title="Required skills"
          // toolbar={
          //   <Button
          //     onClick={() => setShowModal(true)}
          //     bText="Edit skills"
          //     bStyle="outline"
          //   />
          // }
        />
      </div>
      {/* <SkillsModal
        skills={[
          {
            uuid: '1',
            name: '',
            trade: ContractorTrade.electrician,
            levelRequired: SkillLevel.fullyQualified,
          },
        ]}
        open={showModal}
        onClose={() => setShowModal(false)}
      /> */}
    </>
  );
};
export default SkillsTab;
