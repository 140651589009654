import { ReactElement, useState } from 'react';
import {
  gql,
  ReadUserQuery,
  TargetType,
  UserProfile,
  UserProfileSource,
  UserProfileStatus,
} from '@monorepo/graphql';
import { Button } from '../../atoms/Button';
import { useRouter } from '@tanstack/react-router';
import {
  ArrowLeftIcon,
  BoltIcon,
  BuildingOffice2Icon,
  CalendarIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  MapPinIcon,
  PhoneIcon,
  PlusCircleIcon,
  PowerIcon,
  RectangleStackIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import SimpleModal from '../../molecules/Modals/Simple';
import { notify } from '../../../utility/notify';
import { usersUtility } from '../../../utility/users';
import { profileUtility } from '../../../utility/profile';
import { useMutation } from '@apollo/client';
import Tag from '../../atoms/Tag';
import AvatarStack from '../../atoms/AvatarStack';
import TwoLineText from '../../atoms/TwoLineText';
import { format } from 'date-fns';
import { contractorUtility } from '../../../utility/contractors';
import { RooferIcon } from '../../icons/Roofer';
import { styleUtility } from '../../../utility/styleUtility';
import Tabs from '../../molecules/Tabs';
import JobsTab from './Tabs/Jobs';
import CommentsTab from './Tabs/Comments';
import TasksTab from './Tabs/Tasks';
import TicketsTab from './Tabs/Tickets';
import AuditModal from '../../molecules/Modals/Audit';
import AddUserWithProfile from '../../molecules/Modals/AddUserWithProfile';
import AddUserModal from '../../molecules/Modals/AddUser';
import EditCustomerModal from '../../molecules/Modals/EditCustomer';
import { jobsUtility } from '../../../utility/jobs';
import EditAddress from '../../molecules/Modals/EditAddress';
import FilesTab from './Tabs/Files';
import InvoicesTab from './Tabs/Invoices';
import CallsTab from './Tabs/Calls';

interface Props {
  data: ReadUserQuery['readUser'] & {
    profile: {
      __typename: 'UserCustomerProfile';
    };
  };
}

const UPDATE_USER_CONTRACTOR_PROFILE = gql(`
  mutation UpdateUserCustomerProfileStatus($input: UpdateUserCustomerProfileInput!) {
    updateUserCustomerProfile(input: $input) {
      userUuid
    }
  }
`);

const UserCustomerProfile = ({ data }: Props): ReactElement => {
  const router = useRouter();

  const [user, setUser] = useState(data);

  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
  const [showAddJobModal, setShowAddJobModal] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState(false);

  return (
    <>
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col flex-grow p-5">
          <div className="flex items-center mb-5">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <p className="font-semibold">Customer #{user.uuid}</p>
            </div>
            <Button
              className="mr-3"
              onClick={() => setShowAuditTrail(true)}
              bText="Audit trail"
              bSize="sm"
              bStyle="light"
            />
          </div>
          <div className="bg-white rounded p-5 flex flex-col mb-5">
            <div className="flex justify-between items-center">
              <div className="flex-grow">
                {user.profile.jobStatus && (
                  <Tag
                    colour={user.profile.jobStatus}
                    text={jobsUtility.jobStatusNiceMap[user.profile.jobStatus]}
                  />
                )}
              </div>
              <div className="space-x-2.5 flex">
                <Button
                  bStyle="outline"
                  bText="Edit"
                  onClick={() => setShowEditCustomerModal(true)}
                />
                <Button
                  bText="Add a job"
                  onClick={() => setShowAddJobModal(true)}
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex items-center flex-col w-1/3 p-5">
                <AvatarStack
                  heading
                  height="h-20"
                  width="w-20"
                  avatars={[
                    {
                      firstName: user.firstName,
                      lastName: user.lastName,
                      avatarSrc: user.avatarSrc ?? undefined,
                    },
                  ]}
                />
                <h1 className="text-h1-small font-nunito font-bold mt-4 mb-2">
                  {user.firstName} {user.lastName}
                </h1>
                <div className="flex items-start">
                  <MapPinIcon className="size-5 text-grey-400" />
                  <div className="ml-2">
                    <span>{usersUtility.formatAddress(user.address)} •</span>{' '}
                    <button
                      onClick={() => setShowEditAddress(true)}
                      className="text-primary underline font-semibold"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
              <hr className="border-none w-px h-full bg-grey-700" />
              <div className="grid grid-cols-2 gap-5 p-5 flex-grow">
                <div className="basis-1/2">
                  <TwoLineText
                    label="Email"
                    text={user.email}
                    Icon={<EnvelopeIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Phone"
                    text={user.phoneNumber ?? '-'}
                    Icon={<PhoneIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Partner"
                    text={user.profile.partner.name}
                    Icon={
                      <BuildingOffice2Icon className="size-5 text-grey-400" />
                    }
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Scheme"
                    text={user.schemeName ?? ''}
                    Icon={
                      <RectangleStackIcon className="size-5 text-grey-400" />
                    }
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Added"
                    text={
                      user.profile.source === UserProfileSource.api
                        ? 'API'
                        : user.profile.source === UserProfileSource.csv
                        ? 'Via CSV import'
                        : 'Direct'
                    }
                    Icon={<PlusCircleIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Customer Created"
                    text={format(
                      new Date(parseInt(user.createdAt)),
                      'do MMMM yyyy'
                    )}
                    Icon={<CalendarIcon className="size-5 text-grey-400" />}
                  />
                </div>
              </div>
            </div>
          </div>
          <Tabs
            tabs={[
              {
                name: 'Jobs',
                value: 'jobs',
                component: <JobsTab customerProfileUuid={user.profile.uuid} />,
              },
              {
                name: 'Tasks',
                value: 'tasks',
                component: (
                  <TasksTab
                    partnerUuid={user.profile.partner.uuid}
                    customerProfileUuid={user.profile.uuid}
                  />
                ),
              },
              // {
              //   name: 'Support Tickets',
              //   value: 'support',
              //   component: <TicketsTab userProfileUuid={user.profile.uuid} />,
              // },
              {
                name: 'Comments',
                value: 'comments',
                component: (
                  <CommentsTab customerProfileUuid={user.profile.uuid} />
                ),
              },
              {
                name: 'Files',
                value: 'files',
                component: (
                  <FilesTab
                    customerProfileUuid={user.profile.uuid}
                    userUuid={user.uuid}
                  />
                ),
              },
              {
                name: 'Calls',
                value: 'calls',
                component: <CallsTab userUuid={user.uuid} />,
              },
              {
                name: 'Invoices',
                value: 'invoices',
                component: (
                  <InvoicesTab customerProfileUuid={user.profile.uuid} />
                ),
              },
            ]}
          />
        </div>
      </div>
      <AuditModal
        targetUuid={user.uuid}
        targetType={TargetType.user}
        open={showAuditTrail}
        onClose={() => setShowAuditTrail(false)}
      />
      {user.address && (
        <EditAddress
          uuid={user.address.uuid}
          open={showEditAddress}
          onClose={(_, data) => {
            if (data) {
              setUser((u) => ({
                ...u,
                address: u.address
                  ? {
                      ...u.address,
                      ...data,
                    }
                  : null,
              }));
            }
            setShowEditAddress(false);
          }}
          line1={user.address.line1}
          postcode={user.address.postcode}
          line2={user.address.line2 ?? undefined}
          city={user.address.city ?? undefined}
          county={user.address.county ?? undefined}
          latitude={user.address.latitude ?? undefined}
          longitude={user.address.longitude ?? undefined}
        />
      )}
      <EditCustomerModal
        open={showEditCustomerModal}
        onClose={(fromConfirm) => {
          setShowEditCustomerModal(false);
          if (fromConfirm) {
            notify.success('Saved user');
            usersUtility.fetch({ uuid: user.uuid }).then((d) => {
              if (profileUtility.assertProfileSafe(d, 'UserCustomerProfile')) {
                setUser(d);
              }
            });
          }
        }}
        customer={{
          ...user,
          uuid: user.profile.uuid,
          userUuid: user.uuid,
          partnerUuid: user.profile.partner.uuid,
          schemeUuid: user.profile.schemeUuid ?? '',
          phone: user.phoneNumber ?? '',
        }}
        partnerName={user.profile.partner.name}
      />
    </>
  );
};

export default UserCustomerProfile;
