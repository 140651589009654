import { useForm } from '@tanstack/react-form';
import { ReactElement, useState } from 'react';
import { CommentTargetType, FileTargetType, gql } from '@monorepo/graphql';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@apollo/client';
import { notify } from '../../../../utility/notify';
import UploadModal from '../../Modals/UploadModal';
import { Input } from '../../../atoms/Input';
import {
  DocumentTextIcon,
  PaperClipIcon,
  PhotoIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Alert from '../../../atoms/Alerts';
import { Button } from '../../../atoms/Button';

type LocalFile = {
  uuid: string;
  name: string;
  type: string;
  src?: string;
};

type FormType = {
  uuid: string;
  text: string;
  files: Array<LocalFile>;
};

interface Props {
  customerProfileUuid?: string;
  jobUuid?: string;
  targetType: CommentTargetType;
  targetUuid: string;
  comment?: FormType;
  onSaveCallback?: () => void;
}

const UPSERT_COMMENT = gql(`
  mutation UpsertComment ($input: UpsertCommentInput!) {
    upsertComment(input: $input) {
      ...Comment
    }
  }  
`);

const Upsert = ({
  customerProfileUuid,
  jobUuid,
  targetType,
  targetUuid,
  comment,
  onSaveCallback,
}: Props): ReactElement => {
  const form = useForm<FormType>({
    onSubmit: (values) => {
      upsert({
        variables: {
          input: {
            uuid: values.value.uuid,
            text: values.value.text,
            targetType,
            targetUuid,
            jobUuid,
            fileUuids: values.value.files.map(({ uuid }) => uuid),
          },
        },
      });
    },
    defaultValues: {
      text: comment?.text ?? '',
      uuid: comment?.uuid ?? uuidv4(),
      files: comment?.files ?? [],
    },
  });

  const [showUploadModal, setShowUploadModal] = useState(false);

  const [upsert, { error: createError, loading: saving }] = useMutation(
    UPSERT_COMMENT,
    {
      onCompleted: () => {
        form.reset();
        notify.success(`Succesfully ${comment ? 'saved' : 'created'} comment`);
        onSaveCallback?.();
      },
      refetchQueries: comment ? [] : ['IndexComments'],
    }
  );

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          form.handleSubmit();
        }}
        className="py-5 space-y-5"
      >
        {!comment && (
          <h2 className="text-h3 font-bold font-nunito mb-2">Add comment</h2>
        )}
        <form.Field
          name="text"
          validators={{
            onBlur: ({ value }) => (!value ? 'Please enter a value' : null),
          }}
        >
          {(subfield) => (
            <Input
              onBlur={subfield.handleBlur}
              value={subfield.state.value}
              type="textarea"
              onChange={(e) => subfield.handleChange(e.target.value)}
              className="w-full"
              max={500}
              error={subfield.state.meta.errors.join(', ')}
            />
          )}
        </form.Field>
        <form.Field name="files">
          {(subfield) => (
            <div
              className={`flex shrink-0 flex-wrap ${
                subfield.state.value.length ? '!-mb-2' : ''
              }`}
            >
              {subfield.state.value.map((f) => (
                <div className="p-2 flex items-center mr-2 mb-2 bg-grey-900 border-grey-500 border rounded fit-content w-fit space-x-2">
                  {f.type.includes('image') ? (
                    <PhotoIcon className="size-5" />
                  ) : (
                    <DocumentTextIcon className="size-5" />
                  )}
                  <span className="text-body-small">{f.name}</span>
                  <button
                    onClick={() =>
                      subfield.handleChange(
                        subfield.state.value?.filter((fi) => fi.uuid !== f.uuid)
                      )
                    }
                    type="button"
                  >
                    <XMarkIcon className="size-5" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </form.Field>
        {createError && <Alert text={createError.message} alertType="error" />}
        <div className="flex justify-end space-x-3 items-center">
          {customerProfileUuid && (
            <Button
              bStyle="light"
              bText="Attach files"
              onClick={() => setShowUploadModal(true)}
              Icon={<PaperClipIcon className="size-5" />}
              className={comment ? 'h-9 text-sm' : ''}
            />
          )}
          <Button
            className={comment ? 'h-9 text-sm' : ''}
            loading={saving}
            type="submit"
            bText={`${comment ? 'Save' : 'Add'} comment`}
          />
        </div>
      </form>
      {customerProfileUuid && (
        <UploadModal
          targetType={FileTargetType.comment}
          targetUuid={form.getFieldValue('uuid')}
          customerProfileUuid={customerProfileUuid}
          skipCommit={!comment}
          open={showUploadModal}
          onClose={(_, files) => {
            if (files) {
              form.setFieldValue(
                'files',
                [
                  ...(files.map((f) => ({
                    name: f.file.name,
                    type: f.file.type,
                    src: f.src,
                    uuid: f.uuid,
                  })) ?? []),
                  ...form.getFieldValue('files'),
                ],
                {
                  touch: true,
                }
              );
            }
            setShowUploadModal(false);
          }}
        />
      )}
    </>
  );
};
export default Upsert;
