import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { ProtectedApp } from './AppShell';
import { ApolloProvider } from '@apollo/client';
import env from './environment';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import 'react-toastify/dist/ReactToastify.css';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import 'react-day-picker/style.css';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { SentryLink } from 'apollo-link-sentry';
import { onError } from '@apollo/client/link/error';
import { notify } from './utility/notify';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createFragmentRegistry } from '@apollo/client/cache';
import { slotsUtility } from './utility/slots';
import { commentsUtility } from './utility/comments';
import { invoicesUtiltiy } from './utility/invoices';
import { notificationsUtiltiy } from './utility/notifications';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

declare module '@apollo/client' {
  interface DefaultContext {
    isBatched?: boolean; 
  }
}

let graphqlClient: ApolloClient<NormalizedCacheObject> | null = null;

const httpLink = (uri: string) =>
  ApolloLink.split(
    (op) => op.getContext().isBatched ?? false,
    new BatchHttpLink({
      uri,
      batchInterval: 20,
    }),
    new HttpLink({
      uri,
    })
  );

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.extensions?.code) {
        case 'FORBIDDEN':
          notify.error(err.message);
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = '/login';
          break;
      }
    }
  }
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: `Bearer ${sessionStorage.getItem('__cognito_token__')}`,
  },
}));

export const client = {
  graphqlClient: (
    { apiUrl }: { apiUrl: string } | undefined = { apiUrl: env.apiUrl }
  ) => {
    if (!graphqlClient) {
      graphqlClient = new ApolloClient({
        uri: apiUrl,
        cache: new InMemoryCache({
          fragments: createFragmentRegistry(
            slotsUtility.queries.SLOT_FRAGMENT,
            commentsUtility.queries.COMMENT_FRAGMENT,
            invoicesUtiltiy.queries.INVOICE_FRAGMENT,
            notificationsUtiltiy.queries.NOTIFICATION_FRAGMENT
          ),
          typePolicies: {
            Slot: {
              keyFields: ['uuid'],
            },
            JobAudit: {
              keyFields: ['uuid'],
            },
            Comment: {
              keyFields: ['uuid'],
            },
            Scheme: {
              keyFields: ['uuid'],
            },
            Invoice: {
              keyFields: ['uuid'],
            },
            Partner: {
              keyFields: ['uuid'],
            },
            Notification: {
              keyFields: ['uuid'],
            },
          },
        }),
        credentials: 'include',
        link: ApolloLink.from([
          new SentryLink(),
          errorLink,
          authLink,
          httpLink(apiUrl),
        ]),
      });
    }
    return graphqlClient;
  },
  handleError: (message: string) => {
    const [msg] = message.split(':');
    throw new Error(msg);
  },
};

Sentry.init({
  dsn: 'https://07b3a779c329f8f961410bd284294945@o4507663915483136.ingest.de.sentry.io/4507663917776976',
  environment: env.sentry.environment,
  beforeBreadcrumb: excludeGraphQLFetch,
});

root.render(
  <StrictMode>
    <ToastContainer />
    <ApolloProvider
      client={client.graphqlClient({
        apiUrl: env.apiUrl,
      })}
    >
      <ProtectedApp />
    </ApolloProvider>
  </StrictMode>
);
