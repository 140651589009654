import { ReactElement, useState } from 'react';
import FileViewer from '../../../../molecules/FileViewer';
import CommentThread from '../../../../molecules/CommentThread';
import Table from '../../../../molecules/Table';
import {
  ArrowDownTrayIcon,
  BoltIcon,
  CalendarIcon,
  CheckCircleIcon,
  PlusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../../../../atoms/Button';
import ExtrasModal from '../../../../molecules/Modals/Extras';
import { useJobContext } from '../..';
import { FileTargetType, gql } from '@monorepo/graphql';
import { styleUtility } from '../../../../../utility/styleUtility';
import { useQuery } from '@apollo/client';
import { currency } from '../../../../../utility/currency';
import { JobHandoverType } from '@prisma/client';
import { RooferIcon } from '../../../../icons/Roofer';

const INDEX_JOB_PRODUCTS = gql(`
  query IndexProductsForJobTab ($uuid: String!) {
    indexProductsForJob (uuid: $uuid) {
      uuid
      product {
        name
        responsibleContractorType
      }
      quantity
      isInstalled
      price
      isBillable
      image {
        uuid
        key
        name
        src
        size
        mimeType
      }
    }
  }  
`);

const INDEX_JOB_HANDOVERS = gql(`
  query IndexJobHandovers ($uuid: String!) {
    indexHandoversForJob (uuid: $uuid) {
      uuid
      type
      displayDate
      src
    }
  }  
`);

export const JobTab = (): ReactElement => {
  const [showExtrasModal, setShowExtrasModal] = useState(false);
  const { job } = useJobContext();

  const { data } = useQuery(INDEX_JOB_PRODUCTS, {
    variables: {
      uuid: job.uuid,
    },
    context: {
      isBatched: true,
    },
  });

  const { data: jobHandoverData } = useQuery(INDEX_JOB_HANDOVERS, {
    variables: {
      uuid: job.uuid,
    },
    context: {
      isBatched: true,
    },
  });

  const jobTypeTitle = styleUtility.capitalise(job.type);

  return (
    <div className="rounded-b-lg overflow-hidden">
      <FileViewer
        customerProfileUuid={job.customer.uuid}
        targetType={FileTargetType.job}
        targetUuid={job.uuid}
        title={jobTypeTitle}
      />
      <Table
        widthType="pc"
        title="Extras"
        // toolbar={
        //   <Button
        //     bText="Edit"
        //     bStyle="outline"
        //     onClick={() => setShowExtrasModal(true)}
        //   />
        // }
        columns={[
          {
            width: 40,
            heading: 'extra',
          },
          {
            width: 40,
            heading: 'status',
          },
          {
            width: 10,
            heading: 'quantity',
            className: 'justify-end',
          },
          {
            width: 10,
            heading: 'cost',
            className: 'justify-end',
          },
        ]}
        rows={
          data?.indexProductsForJob.map((p) => ({
            uuid: p.uuid,
            cells: [
              {
                width: 40,
                content: (
                  <div className="flex items-center">
                    <PlusCircleIcon className="size-5 text-grey-400 mr-2" />
                    <span className="text-body-small">{p.product.name}</span>
                  </div>
                ),
              },
              {
                width: 40,
                content: !p.isBillable ? (
                  '-'
                ) : p.isInstalled ? (
                  <div className="flex items-center">
                    <CheckCircleIcon className="size-5 text-primary mr-2" />
                    <span className="text-body-small">Installed</span>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <XCircleIcon className="size-5 text-red mr-2" />
                    <span className="text-body-small">Not installed</span>
                  </div>
                ),
              },
              {
                width: 10,
                content: (
                  <div className="w-full flex justify-end">
                    <span className="text-body-small">{p.quantity}</span>
                  </div>
                ),
              },
              {
                width: 10,
                content: (
                  <div className="w-full flex justify-end">
                    <span className="text-body-small text-tertiary font-semibold">
                      £{p.price}
                    </span>
                  </div>
                ),
              },
            ],
          })) ?? []
        }
      />
      <div className="p-5 bg-white flex items-center justify-end space-x-6">
        <span className="font-bold text-body-small">Total</span>
        <span className="font-bold text-body-small text-tertiary">
          {currency.add(
            data?.indexProductsForJob.map(
              ({ quantity, price }) => quantity * parseFloat(price)
            ) ?? []
          )}
        </span>
      </div>

      <Table
        widthType="pc"
        rows={
          jobHandoverData?.indexHandoversForJob.map((h) => ({
            uuid: h.uuid,
            cells: [
              {
                content:
                  h.type === JobHandoverType.electrical ? (
                    <div className="flex items-center">
                      <BoltIcon className="size-5 text-grey-400 mr-2" />
                      <span className="text-body-small">Electrical</span>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <RooferIcon
                        multiplier={0.83}
                        className="mr-2"
                        colour={styleUtility.colours.grey['400']}
                      />
                      <span className="text-body-small">Roofing</span>
                    </div>
                  ),
                width: 45,
              },
              {
                content: (
                  <div className="flex items-center">
                    <CalendarIcon className="size-5 text-grey-400 mr-2" />
                    <span className="text-body-small">{h.displayDate}</span>
                  </div>
                ),
                width: 45,
              },
              {
                width: 10,
                content: (
                  <div className="flex items-center justify-end">
                    <Button
                      Icon={<ArrowDownTrayIcon className="size-5" />}
                      bStyle="light"
                      href={h.src}
                    />
                  </div>
                ),
              },
            ],
          })) ?? []
        }
        columns={[
          {
            heading: 'Type',
            width: 45,
          },
          {
            heading: 'Date added',
            width: 45,
          },
          {
            width: 10,
          },
        ]}
        title="Handovers"
      />
      {/* <ExtrasModal
        open={showExtrasModal}
        onClose={() => setShowExtrasModal(false)}
        extras={products}
      /> */}
    </div>
  );
};
