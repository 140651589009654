import { ReactElement, useState } from 'react';
import Table from '../../../../molecules/Table';
import { DropdownWithBorder } from '../../../../atoms/Dropdown';
import { AllEnum } from '../../../../../utility/calendarContext';
import { gql, JobDifficulty, JobStatus } from '@monorepo/graphql';
import { jobsUtility } from '../../../../../utility/jobs';
import AvatarStack from '../../../../atoms/AvatarStack';
import { Link } from '@tanstack/react-router';
import Tag from '../../../../atoms/Tag';
import DifficultyIndicator from '../../../../atoms/DifficultyIndactor';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../../atoms/Button';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import { useQuery } from '@apollo/client';
import { styleUtility } from '../../../../../utility/styleUtility';
import { format } from 'date-fns';

interface Props {
  contractorProfileUuid: string;
}

const difficultyOptions = [
  {
    name: 'All Difficulties',
    value: AllEnum.all,
  },
  ...jobsUtility.difficultyStatusOptions,
];
const jobStatusOptions = [
  {
    name: 'All Statuses',
    value: AllEnum.all,
  },
  ...jobsUtility.statusOptions,
];

const JOBS_QUERY = gql(`
  query IndexJobsForUserContractorProfile($filters: IndexJobsFilterInput, $pagination: PaginationInput) {
    indexJobs(filters: $filters, pagination: $pagination) {
      items {
        uuid
        type 
        status
        difficulty
        customer {
          firstName
          lastName
        }
        address {
          postcode
        }
        startDate
      }
      pagination {
        lastPage
      }
    }
  }
  `);

const JobsTab = ({ contractorProfileUuid }: Props): ReactElement => {
  const [difficulty, setDifficulty] = useState<AllEnum | JobDifficulty>(
    AllEnum.all
  );
  const [status, setStatus] = useState<AllEnum | JobStatus>(AllEnum.all);

  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { data, loading } = useQuery(JOBS_QUERY, {
    variables: {
      pagination: {
        perPage: rowsPerPage,
        page,
      },
      filters: {
        contractorProfileUuid,
        difficulty: difficulty === AllEnum.all ? undefined : difficulty,
        status: status === AllEnum.all ? undefined : status,
      },
    },
    onCompleted: (data) => setTotalPages(data?.indexJobs.pagination.lastPage),
  });

  return (
    <>
      <Table
        title="Assigned Jobs"
        loading={loading}
        toolbar={
          <div className="flex justify-center space-x-2">
            <DropdownWithBorder<AllEnum | JobStatus>
              buttonText={
                jobStatusOptions.find(({ value }) => value === status)?.name
              }
              options={jobStatusOptions}
              buttonClassname="!w-60 justify-between whitespace-nowrap"
              onOptionSelect={(opt) => setStatus(opt.value)}
              respectButtonWidth
            />
            <DropdownWithBorder<AllEnum | JobDifficulty>
              buttonText={
                difficultyOptions.find(({ value }) => value === difficulty)
                  ?.name
              }
              options={difficultyOptions}
              buttonClassname="!w-60 justify-between whitespace-nowrap"
              onOptionSelect={(opt) => setDifficulty(opt.value)}
              respectButtonWidth
            />
          </div>
        }
        columns={[
          {
            width: 15,
            heading: 'customer',
          },
          {
            width: 15,
            heading: 'postcode',
          },
          {
            width: 15,
            heading: 'type',
          },
          {
            width: 15,
            heading: 'state',
          },
          {
            width: 15,
            heading: 'difficulty',
          },
          {
            width: 15,
            heading: 'date',
          },
          {
            width: 10,
          },
        ]}
        widthType="pc"
        rows={
          data?.indexJobs.items.map((i) => ({
            uuid: '1',
            cells: [
              {
                width: 15,
                content: (
                  <>
                    <AvatarStack
                      height="h-9"
                      width="w-9"
                      avatars={[
                        {
                          firstName: i.customer.firstName,
                          lastName: i.customer.lastName,
                        },
                      ]}
                    />
                    <Link to="/contacts/$uuid" params={{ uuid: '' }}>
                      <span className="underline text-body-small block ml-2">
                        {i.customer.firstName} {i.customer.lastName}
                      </span>
                    </Link>
                  </>
                ),
              },
              {
                width: 15,
                content: 'B12 4RT',
              },
              {
                width: 15,
                content: (
                  <>
                    {jobsUtility.jobTypeIconMap[i.type]}
                    <span className="text-body-small block ml-2">
                      {styleUtility.capitalise(i.type)}
                    </span>
                  </>
                ),
              },
              {
                width: 15,
                content: <Tag colour="notSigned" text="Not signed" />,
              },
              {
                width: 15,
                content: (
                  <>
                    <DifficultyIndicator difficulty={i.difficulty} />
                    <span className="text-body-small block ml-2">
                      {styleUtility.capitalise(i.difficulty)}
                    </span>
                  </>
                ),
              },
              {
                width: 15,
                content: i.startDate ? (
                  <>
                    <CalendarIcon className="size-5 text-grey-400" />
                    <span className="text-body-small block ml-2">
                      {format(new Date(i.startDate), 'do MMMM yyy')}
                    </span>
                  </>
                ) : (
                  <></>
                ),
              },
              {
                width: 10,
                content: (
                  <div className="flex justify-end w-full">
                    <Button
                      href={`/job/${i.uuid}`}
                      bText="View job"
                      bStyle="light"
                      className="h-9 text-button-small"
                    />
                  </div>
                ),
              },
            ],
          })) ?? []
        }
      />
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
      />
    </>
  );
};
export default JobsTab;
