import { ReactElement } from 'react';
import { DropdownWithBorder, Option } from '../../atoms/Dropdown';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Input } from '../../atoms/Input';
import { Button } from '../../atoms/Button';

export type PaginationProps = Props;

interface Props {
  rowsPerPage?: RowsPerPage;
  setRowsPerPage?: (rows: RowsPerPage) => void;
  totalPages?: number;
  page: number;
  setPage: (page: number) => void;
  removePadding?: boolean;
}

export enum RowsPerPage {
  ten = 10,
  fifteen = 15,
  twenty = 20,
  twentyfive = 25,
}

const pageOptions: Option<RowsPerPage>[] = [
  {
    name: String(RowsPerPage.ten),
    value: RowsPerPage.ten,
  },
  {
    name: String(RowsPerPage.fifteen),
    value: RowsPerPage.fifteen,
  },
  {
    name: String(RowsPerPage.twenty),
    value: RowsPerPage.twenty,
  },
  {
    name: String(RowsPerPage.twentyfive),
    value: RowsPerPage.twentyfive,
  },
];

export const TablePagination = (props: Props) => (
  <div className="border-t border-grey-500/40">
    <div className="rounded-b-lg overflow-hidden">
      <Pagination {...props} />
    </div>
  </div>
);

const Pagination = ({
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  totalPages,
  removePadding,
}: Props): ReactElement => {
  return (
    <div
      className={`bg-white flex items-center justify-between ${
        removePadding ? '' : 'p-5'
      }`}
    >
      <div className="flex items-center space-x-3">
        {rowsPerPage && setRowsPerPage && (
          <>
            <span className="text-body-small">Rows per page</span>
            <DropdownWithBorder
              options={pageOptions}
              selected={pageOptions.find(({ value }) => value === rowsPerPage)}
              onOptionSelect={(opt) => setRowsPerPage(opt.value)}
              buttonText={String(
                pageOptions.find(({ value }) => value === rowsPerPage)?.value ??
                  ''
              )}
              respectButtonWidth
              height="h-9"
            />
          </>
        )}
      </div>
      {totalPages !== undefined && (
        <div className="flex items-center space-x-3">
          <Button
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
            bStyle="clean-dark"
            className="!p-2 !h-9"
            Icon={<ChevronLeftIcon className="size-5" />}
          />
          {totalPages === 1 ? (
            <span>1</span>
          ) : (
            <Input
              type="number"
              className="w-11 !mb-0 !h-9"
              inputClassname="text-center !h-9 leading-none"
              value={page}
              onChange={(e) =>
                setPage(
                  e.target.value.length
                    ? Number(e.target.value) === 0
                      ? 1
                      : Number(e.target.value) > totalPages
                      ? totalPages
                      : Number(e.target.value)
                    : NaN
                )
              }
            />
          )}
          <span>of</span>
          <span>{totalPages}</span>
          <Button
            bStyle="clean-dark"
            className="!p-2 !h-9"
            disabled={page === totalPages}
            Icon={<ChevronRightIcon className="size-5" />}
            onClick={() => setPage(page + 1)}
          />
        </div>
      )}
    </div>
  );
};
export default Pagination;
