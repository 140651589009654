type DeepNonNullable<T> = {
  [P in keyof T]: T[P] extends object ? DeepNonNullable<T[P]> : NonNullable<T[P]>;
};

const env = {
  apiUrl: import.meta.env.VITE_API_URL,
  version: import.meta.env.VITE_APP_VERSION,
  sentry: {
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT
  },
  google: {
    mapsEmbedKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY
  }
}

export default env as DeepNonNullable<typeof env>