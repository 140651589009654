import {
  AuditType,
  JobDifficulty,
  JobStatus,
  JobType,
  RoofTile,
  ScaffoldingLocation,
  ScaffoldingLocationArea,
} from '@monorepo/graphql';
import { Option } from '../components/atoms/Dropdown';
import { ReactNode } from 'react';
import ToolBoxSmall from '../assets/icons/ToolBoxSmall.svg';
import {
  Battery0Icon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';

import Installation from '../assets/icons/Installation.svg';
import Remedial from '../assets/icons/Remedial.svg';

const jobStatusNiceMap: Record<JobStatus, string> = {
  [JobStatus.notSigned]: 'Not Signed',
  [JobStatus.dnoG99]: 'DNO 99 Outstanding',
  [JobStatus.preAudit]: 'Pre Audit',
  [JobStatus.ready]: 'Ready',
  [JobStatus.scaffoldingUp]: 'Scaffolding Up',
  [JobStatus.postAuditGeneral]: 'Post Audit',
  [JobStatus.postAuditElectricalAndRoofing]: 'Post Audit',
  [JobStatus.dnoG98]: 'DNO 98 Outstanding',
  [JobStatus.feedback]: 'Feedback',
  [JobStatus.completed]: 'Completed',
  [JobStatus.rescheduled]: 'Rescheduled',
  [JobStatus.incomplete]: 'Incomplete',
  [JobStatus.cancelled]: 'Cancelled',
};

const roofTileNiceMap: Record<RoofTile, string> = {
  [RoofTile.flat]: 'Flat',
  [RoofTile.concrete]: 'Concrete',
  [RoofTile.concreteFlat]: 'Concrete flat tile',
  [RoofTile.flatPlain]: 'Flat plain',
  [RoofTile.metal]: 'Metal',
  [RoofTile.slate]: 'Slate',
  [RoofTile.unspecified]: 'Unknown',
};

const slotIconComponentMap = {
  installation: <img alt="installation" src={Installation} />,
  remedial: <img alt="remedial" src={Remedial} />,
  battery: <Battery0Icon className="size-7 text-grey-500" />,
};

const scaffoldingLocationNiceMap: Record<ScaffoldingLocation, string> = {
  [ScaffoldingLocation.garage]: 'Garage',
  [ScaffoldingLocation.mainProperty]: 'Main Property',
  [ScaffoldingLocation.other]: 'Other',
  [ScaffoldingLocation.outbuilding]: 'Outbuilding',
};

const scaffoldingLocationAreaNiceMap: Record<ScaffoldingLocationArea, string> =
  {
    [ScaffoldingLocationArea.back]: 'Back',
    [ScaffoldingLocationArea.front]: 'Front',
    [ScaffoldingLocationArea.leftSide]: 'Left side',
    [ScaffoldingLocationArea.other]: 'Other',
    [ScaffoldingLocationArea.rightSide]: 'Right side',
  };

const statusOptions: Option<JobStatus>[] = Object.keys(JobStatus)
  .sort((a, b) =>
    Object.keys(jobStatusNiceMap).indexOf(a) >
    Object.keys(jobStatusNiceMap).indexOf(b)
      ? 1
      : -1
  )
  .map((s) => ({
    name: jobStatusNiceMap[s as keyof typeof JobStatus],
    value: s as JobStatus,
  }));

const jobTypeIconMap: Record<JobType, ReactNode> = {
  [JobType.installation]: <img src={ToolBoxSmall} alt="Toolbox" />,
  [JobType.remedial]: (
    <WrenchScrewdriverIcon className="size-5 text-grey-400" />
  ),
  [JobType.battery]: <Battery0Icon className="size-5 text-grey-400" />,
};

const difficultyStatusOptions: Option<JobDifficulty>[] = [
  {
    name: 'Easy',
    value: JobDifficulty.easy,
  },
  {
    name: 'Normal',
    value: JobDifficulty.normal,
  },
  {
    name: 'Difficult',
    value: JobDifficulty.difficult,
  },
];

const jobStatusAuditTypeMap: Record<JobStatus, AuditType> = {
  [JobStatus.notSigned]: AuditType.pre,
  [JobStatus.dnoG99]: AuditType.pre,
  [JobStatus.preAudit]: AuditType.pre,
  [JobStatus.ready]: AuditType.pre,
  [JobStatus.scaffoldingUp]: AuditType.pre,
  [JobStatus.postAuditGeneral]: AuditType.postGeneral,
  [JobStatus.postAuditElectricalAndRoofing]: AuditType.postGeneral,
  [JobStatus.dnoG98]: AuditType.postGeneral,
  [JobStatus.feedback]: AuditType.postGeneral,
  [JobStatus.completed]: AuditType.postGeneral,
  [JobStatus.rescheduled]: AuditType.postGeneral,
  [JobStatus.cancelled]: AuditType.postGeneral,
  [JobStatus.incomplete]: AuditType.postGeneral,
};

export const jobsUtility = {
  jobStatusNiceMap,
  statusOptions,
  difficultyStatusOptions,
  scaffoldingLocationAreaNiceMap,
  scaffoldingLocationNiceMap,
  roofTileNiceMap,
  jobTypeIconMap,
  slotIconComponentMap,
  jobStatusAuditTypeMap
};
