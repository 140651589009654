import { createFileRoute } from '@tanstack/react-router'
import Job from '../../../components/organisms/Job'
import { ReactNode, Suspense } from 'react'
import Loader from '../../../components/icons/Loader'
import { client } from '../../../main'
import { gql } from '@monorepo/graphql'
import NotFoundScreen from '../../../components/organisms/NotFoundScreen'

const READ_JOB = gql(`
  query ReadJob($uuid: String!) {
    readJob(uuid: $uuid) {
      uuid
      difficulty
      schemeName
      type
      status
      displayDate
      operatorUuid
      solarPanelCount
      revisedSolarPanelCount
      duration
      dates
      partner {
        uuid
        name
      }
      customer {
        uuid
        userUuid
        email
        firstName
        lastName
        phoneNumber
      }
      address {
        line1
        line2
        city
        postcode
      }
      survey {
        uuid
      }
    }
  }  
`)

const JobSingle = (): ReactNode => {
  const data = Route.useLoaderData()
  if (!data.data) return <NotFoundScreen />
  return <Job jobData={data.data.readJob} />
}

export const Route = createFileRoute('/_authenticated/job/$uuid')({
  component: JobSingle,
  pendingComponent: () => (
    <div className="flex items-center h-full justify-center">
      <Loader />
    </div>
  ),
  loader: ({ params }) =>
    client.graphqlClient().query({
      query: READ_JOB,
      variables: {
        uuid: params.uuid,
      },
    }),
})
