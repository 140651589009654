import { ReactElement, useState } from 'react';
import { gql, UserProfile } from '@monorepo/graphql';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import { onError } from '@apollo/client/link/error';
import { notify } from '../../../../../utility/notify';
import { useQuery } from '@apollo/client';
import Table from '../../../../molecules/Table';
import { Button } from '../../../../atoms/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import AddUserWithProfile from '../../../../molecules/Modals/AddUserWithProfile';
import AvatarStack from '../../../../atoms/AvatarStack';

interface Props {
  uuid: string;
  name: string;
}

const GET_USERS = gql(`
  query IndexUsersForPartnersScreen ($filters: IndexUsersFilterInput, $pagination: PaginationInput) {
	indexUsers (filters: $filters, pagination: $pagination) {
		items {
			uuid
      schemeName
			firstName
      lastName
			avatarSrc
      lastLogin
      address {
        postcode
      }
      profile {
        __typename 
        ... on UserCustomerProfile {
          uuid
          partner {
            name
          }
        }
      }
		}
    pagination {
      lastPage
    }
	}
}
`);

const CustomersTab = ({ uuid, name }: Props): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);

  const { data, loading } = useQuery(GET_USERS, {
    variables: {
      filters: {
        userProfile: UserProfile.customer,
        partnerUuid: uuid,
      },
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
    onError: (err) => notify.error(err.message),
  });

  return (
    <>
      <Table
        toolbar={
          <Button
            bStyle="outline"
            bText='Add'
            reverse
            Icon={<PlusIcon className="size-6 text-primary" />}
            className="justify-center"
            onClick={() => setShowAddCustomerModal(true)}
          />
        }
        loading={loading}
        columns={[
          {
            width: 33,
            heading: 'name',
          },
          {
            width: 33,
            heading: 'Postcode',
          },
          {
            width: 22,
            heading: 'Scheme',
          },
          {
            width: 12,
          },
        ]}
        widthType="pc"
        rows={
          data?.indexUsers.items.map((u) => ({
            uuid: u.uuid,
            cells: [
              {
                width: 33,
                content: (
                  <div className="flex items-center space-x-2">
                    <AvatarStack
                      avatars={[
                        {
                          firstName: u.firstName,
                          lastName: u.lastName,
                          avatarSrc: u.avatarSrc ?? undefined,
                        },
                      ]}
                    />
                    <span className="text-body-small">
                      {u.firstName} {u.lastName}
                    </span>
                  </div>
                ),
              },
              {
                width: 33,
                content: u.address?.postcode,
              },
              {
                width: 22,
                content: u.schemeName,
              },
              {
                width: 12,
                content: (
                  <div className="flex justify-end w-full">
                    <Button
                      href={`/contacts/${u.uuid}`}
                      bText="View"
                      bStyle="light"
                      className="h-9"
                    />
                  </div>
                ),
              },
            ],
          })) ?? []
        }
        title="Customers"
      />
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={data?.indexUsers.pagination.lastPage}
      />
      <AddUserWithProfile
        profileType="customer"
        partnerName={name}
        partnerUuid={uuid}
        open={showAddCustomerModal}
        onClose={() => setShowAddCustomerModal(false)}
      />
    </>
  );
};
export default CustomersTab;
