import { ReactElement, useState } from 'react';
import Table from '../../../../molecules/Table';
import { RowsPerPage, TablePagination } from '../../../../molecules/Pagination';
import { Button } from '../../../../atoms/Button';
import { getFragmentData, gql, InvoiceStatus } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import { notify } from '../../../../../utility/notify';
import { invoicesUtiltiy } from '../../../../../utility/invoices';
import UpsertInvoice from '../../../../molecules/Modals/UpsertInvoice';
import {
  ArrowTopRightOnSquareIcon,
  CalendarIcon,
  CurrencyPoundIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { client } from '../../../../../main';
import { styleUtility } from '../../../../../utility/styleUtility';
import { currency } from '../../../../../utility/currency';
import { format } from 'date-fns';
import Tag from '../../../../atoms/Tag';

interface Props {
  customerProfileUuid: string;
}

const INDEX_INVOICES = gql(`
  query IndexInvoicesForCustomer ($customerProfileUuid: String!, $pagination: PaginationInput) {
    indexInvoicesForCustomer (customerProfileUuid: $customerProfileUuid, pagination: $pagination) {
      items {
        ... Invoice
      }
      pagination {
        lastPage
      }
    }
  }
`);

const InvoicesTab = ({ customerProfileUuid }: Props): ReactElement => {
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);

  const [invoice, setInvoice] = useState<{
    uuid: string;
    jobUuid?: string;
    dueDate?: Date;
    xeroReference?: string;
    xeroLink?: string;
    notes?: string;
    amount?: string;
    status?: InvoiceStatus;
  }>();

  const { data, loading } = useQuery(INDEX_INVOICES, {
    variables: {
      customerProfileUuid,
      pagination: {
        perPage: rowsPerPage,
        page,
      },
    },
    onError: (e) => notify.error(e.message),
  });
  const [showUpsertInvoiceModal, setShowUpsertInvoiceModal] = useState(false);

  return (
    <>
      <Table
        title="Invoices"
        loading={loading}
        toolbar={
          <Button
            bText="Add Invoice"
            bStyle="outline"
            onClick={() => setShowUpsertInvoiceModal(true)}
          />
        }
        columns={[
          {
            heading: 'invoice id',
            width: 18,
          },
          {
            heading: 'status',
            width: 18,
          },
          {
            heading: 'raised',
            width: 18,
          },
          {
            heading: 'due',
            width: 18,
          },
          {
            heading: 'value',
            width: 18,
          },
          {
            width: 10,
          },
        ]}
        widthType="pc"
        rows={
          data?.indexInvoicesForCustomer.items
            .map((inv) =>
              getFragmentData(invoicesUtiltiy.queries.INVOICE_FRAGMENT, inv)
            )
            .map((inv) => ({
              uuid: inv.uuid,
              cells: [
                {
                  content: (
                    <div className="flex items-center space-x-2">
                      <CurrencyPoundIcon className="size-5 text-grey-400" />
                      <span className="text-sm">{inv.xeroReference}</span>
                    </div>
                  ),
                  width: 18,
                },
                {
                  content: (
                    <Tag
                      colour={inv.status}
                      text={styleUtility.capitalise(inv.status)}
                    />
                  ),
                  width: 18,
                },
                {
                  content: (
                    <div className="flex items-center space-x-2">
                      <CalendarIcon className="size-5 text-grey-400" />
                      <span className="text-sm">
                        {format(inv.createdAt, 'd MMMM yyyy')}
                      </span>
                    </div>
                  ),
                  width: 18,
                },
                {
                  content: (
                    <div className="flex items-center space-x-2">
                      <CalendarIcon className="size-5 text-grey-400" />
                      <span className="text-sm">
                        {format(inv.dueDate, 'd MMMM yyyy')}
                      </span>
                    </div>
                  ),
                  width: 18,
                },
                {
                  content: (
                    <span className="font-semibold text-tertiary">
                      {currency.format(inv.amount)}
                    </span>
                  ),
                  width: 18,
                },
                {
                  content: (
                    <div className="flex w-full space-x-2 justify-end">
                      <Button
                        bText="Edit"
                        bStyle="light"
                        className='h-9'
                        onClick={() => {
                          setInvoice({
                            uuid: inv.uuid,
                            dueDate: new Date(inv.dueDate),
                            notes: inv.notes ?? undefined,
                            xeroLink: inv.xeroLink,
                            xeroReference: inv.xeroReference,
                            amount: String(inv.amount),
                            status: inv.status,
                          });
                          setShowUpsertInvoiceModal(true);
                        }}
                      />
                      <Button
                        bText="Open"
                        bStyle="light"
                        className='h-9'
                        reverse
                        href={inv.xeroLink}
                        Icon={
                          <ArrowTopRightOnSquareIcon className="size-5 mr-1" />
                        }
                      />
                    </div>
                  ),
                  width: 10,
                },
              ],
            })) ?? []
        }
      />
      <TablePagination
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
        totalPages={data?.indexInvoicesForCustomer.pagination.lastPage}
      />
      <UpsertInvoice
        invoice={invoice}
        customerProfileUuid={customerProfileUuid}
        open={showUpsertInvoiceModal}
        onClose={() => {
          client.graphqlClient().refetchQueries({
            include: [INDEX_INVOICES],
          });
          setShowUpsertInvoiceModal(false);
        }}
      />
    </>
  );
};
export default InvoicesTab;
