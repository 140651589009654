import { useRouter } from '@tanstack/react-router';
import { ReactElement, useState } from 'react';
import { Button } from '../../../atoms/Button';
import {
  ArrowLeftIcon,
  ClipboardDocumentListIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import Table from '../../../molecules/Table';
import { RowsPerPage, TablePagination } from '../../../molecules/Pagination';
import { gql } from '@monorepo/graphql';
import { useMutation, useQuery } from '@apollo/client';
import UpsertSla from '../../../molecules/Modals/UpsertSla';
import { client } from '../../../../main';
import SimpleModal from '../../../molecules/Modals/Simple';
import { notify } from '../../../../utility/notify';

const INDEX_SLAS = gql(`
  query IndexSLAs ($pagination: PaginationInput) {
    indexSLAs (pagination: $pagination) {
      items {
        name
        uuid
        partnerCount
        scaffoldingSetUpThreshold
        scaffoldingStrikeThreshold
      }
    }
  }
`);

const DELETE_SLA = gql(`
  mutation DeleteSLA ($uuid: String!) {
    deleteSLA (uuid: $uuid)
  }
`);

const SLAs = (): ReactElement => {
  const router = useRouter();

  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage.twenty);
  const [page, setPage] = useState(1);

  const [showUpsertSLA, setShowUpsertSLA] = useState(false);
  const [slaToDelete, setSLAToDelete] = useState<string>();

  const [deleteSLA, { loading: deleteLoading }] = useMutation(DELETE_SLA, {
    onCompleted: () => {
      notify.success('Deleted SLA')
      client.graphqlClient().refetchQueries({
        include: [
          INDEX_SLAS
        ]
      })
      setSLAToDelete(undefined)
    },
    onError: (err) => notify.error(`Unable to delete SLA \n ${err.message}`),
  });

  const [sla, setSla] = useState<{
    uuid: string;
    name: string;
    scaffoldingSetUpThreshold: number;
    scaffoldingStrikeThreshold: number;
  }>();

  const { data, loading } = useQuery(INDEX_SLAS);

  return (
    <>
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col flex-grow p-5">
          <div className="flex items-center mb-5">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <h1 className="text-h1 font-nunito font-bold">SLAs</h1>
            </div>
            <Button
              reverse
              Icon={<PlusIcon className="size-6 text-white" />}
              onClick={() => setShowUpsertSLA(true)}
              bText="Add"
            />
          </div>
          <Table
            loading={loading}
            widthType="pc"
            rows={
              data?.indexSLAs.items.map((sla) => ({
                uuid: sla.uuid,
                cells: [
                  {
                    content: (
                      <div className="flex items-center space-x-2">
                        <ClipboardDocumentListIcon className="size-5 text-grey-400" />
                        <span className="text-sm">{sla.name}</span>
                      </div>
                    ),
                    width: 30,
                  },
                  {
                    content: sla.partnerCount,
                    width: 15,
                  },
                  {
                    content: sla.scaffoldingSetUpThreshold,
                    width: 25,
                  },
                  {
                    content: sla.scaffoldingStrikeThreshold,
                    width: 25,
                  },
                  {
                    content: (
                      <div className="flex space-x-2 justify-end w-full">
                        <Button
                          bStyle="light"
                          className="w-9 h-9 justify-center !p-0"
                          Icon={<TrashIcon className="text-red size-5" />}
                          onClick={() => setSLAToDelete(sla.uuid)}
                        />
                        <Button
                          bStyle="light"
                          className="w-9 h-9 justify-center !p-0"
                          Icon={
                            <PencilSquareIcon
                              onClick={() => {
                                setSla(sla);
                                setShowUpsertSLA(true);
                              }}
                              className="size-5"
                            />
                          }
                        />
                      </div>
                    ),
                    width: 10,
                  },
                ],
              })) ?? []
            }
            columns={[
              {
                heading: 'name',
                width: 30,
              },
              {
                heading: 'partners',
                width: 15,
              },
              {
                heading: 'scaffolding up (days)',
                width: 25,
              },
              {
                heading: 'scaffodlding down (days)',
                width: 25,
              },
              {
                width: 10,
              },
            ]}
          />
          <TablePagination
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
      <UpsertSla
        open={showUpsertSLA}
        onClose={(success) => {
          if (success) {
            client.graphqlClient().refetchQueries({
              include: [INDEX_SLAS],
            });
          }
          setShowUpsertSLA(false);
          setSla(undefined);
        }}
        sla={sla}
      />
      <SimpleModal
        text="Are you sure you want to delete this SLA? Partners that have the SLA active will need to be changed manually."
        title="Delete SLA"
        onConfirm={() => {
          if (slaToDelete) {
            deleteSLA({
              variables: {
                uuid: slaToDelete,
              },
            });
          }
        }}
        onConfirmText="Delete"
        icon='critical'
        open={!!slaToDelete}
        onClose={() => setSLAToDelete(undefined)}
      />
    </>
  );
};
export default SLAs;
