import { ReactElement, useEffect, useState } from 'react';
import logoSrc from '../../../assets/images/logo-light.svg';
import { Link } from '@tanstack/react-router';
import {
  ViewColumnsIcon,
  ChartPieIcon,
  CalendarIcon,
  ClipboardIcon,
  LifebuoyIcon,
  UsersIcon,
  RectangleStackIcon,
  BuildingOffice2Icon,
  Cog8ToothIcon,
} from '@heroicons/react/24/outline';
import {
  ViewColumnsIcon as ViewColumnsIconSolid,
  ChartPieIcon as ChartPieIconSolid,
  CalendarIcon as CalendarIconSolid,
  ClipboardIcon as ClipboardIconSolid,
  LifebuoyIcon as LifebuoyIconSolid,
  UsersIcon as UsersIconSolid,
  RectangleStackIcon as RectangleStackIconSolid,
  BuildingOffice2Icon as BuildingOffice2IconSolid,
  Cog8ToothIcon as Cog8ToothIconSolid,
} from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import env from '../../../environment';
import PartnerDropdown from '../PartnerDropdown';

interface Props {
  display: boolean;
}

const Navigation = ({ display }: Props): ReactElement => (
  <div
    className={`flex h-full flex-col bg-gradient-to-t from-from-regal-blue py-6 to-to-regal-blue w-full px-3 transition-all transform ${
      display
        ? 'translate-x-0 max-w-[240px]'
        : '-translate-x-full max-w-0 overflow-hidden !px-0'
    }`}
  >
    <img src={logoSrc} alt="Small logo" className="mx-6 mb-6" />
    <nav className="flex flex-grow">
      <ul className="mt-2.5 space-y-1 w-full">
        <li className="relative w-full">
          <Link
            to="/"
            activeProps={{
              className: 'group !text-primary-200 bg-black/30',
            }}
            className="whitespace-nowrap rounded text-white transition-all duration-150  flex items-center px-2 py-2.5 hover:bg-black/30"
          >
            <ViewColumnsIcon className="group-data-[status=active]:hidden size-6 flex-shrink-0" />
            <ViewColumnsIconSolid className="group-data-[status=active]:block hidden size-6 flex-shrink-0" />
            <span className="ml-2 font-semibold text-white">Dashboard</span>
          </Link>
        </li>

        <li className="relative w-full">
          <Link
            to="/calendar"
            activeProps={{
              className: 'group !text-primary-200 bg-black/30',
            }}
            className="whitespace-nowrap rounded text-white transition-all duration-150  flex items-center px-2 py-2.5 hover:bg-black/30"
          >
            <CalendarIcon className="group-data-[status=active]:hidden size-6 flex-shrink-0" />
            <CalendarIconSolid className="group-data-[status=active]:block hidden size-6 flex-shrink-0" />
            <span className="ml-2 font-semibold text-white">Calendar</span>
          </Link>
        </li>

        <li className="relative w-full">
          <Link
            to="/tasks"
            activeProps={{
              className: 'group !text-primary-200 bg-black/30',
            }}
            className="whitespace-nowrap rounded text-white transition-all duration-150  flex items-center px-2 py-2.5 hover:bg-black/30"
          >
            <ClipboardIcon className="group-data-[status=active]:hidden size-6 flex-shrink-0" />
            <ClipboardIconSolid className="group-data-[status=active]:block hidden size-6 flex-shrink-0" />
            <span className="ml-2 font-semibold text-white">Tasks</span>
          </Link>
        </li>

        <li className="relative w-full">
          <Link
            to="/support-tickets"
            activeProps={{
              className: 'group !text-primary-200 bg-black/30',
            }}
            className="whitespace-nowrap rounded text-white transition-all duration-150  flex items-center px-2 py-2.5 hover:bg-black/30"
          >
            <LifebuoyIcon className="group-data-[status=active]:hidden size-6 flex-shrink-0" />
            <LifebuoyIconSolid className="group-data-[status=active]:block hidden size-6 flex-shrink-0" />
            <span className="ml-2 font-semibold text-white">
              Support Tickets
            </span>
          </Link>
        </li>
      </ul>
    </nav>
    <div className="flex flex-col mb-5">
      <div className="my-4">
        <span className="text-body-small text-white/80 font-bold font-nunito">
          ADMINISTRATION
        </span>
      </div>
      <nav className="flex flex-grow">
        <ul className="space-y-1 w-full">
          <li className="relative w-full">
            <Link
              to="/business-intelligence"
              activeProps={{
                className: 'group !text-primary-200 bg-black/30',
              }}
              className="whitespace-nowrap rounded text-white transition-all duration-150 flex items-center px-2 py-2.5 hover:bg-black/30"
            >
              <ChartPieIcon className="group-data-[status=active]:hidden size-6 flex-shrink-0" />
              <ChartPieIconSolid className="group-data-[status=active]:block hidden size-6 flex-shrink-0" />
              <span className="ml-2 font-semibold text-white">
                Business Intelligence
              </span>
            </Link>
          </li>

          <li className="relative w-full">
            <Link
              to="/partners"
              activeProps={{
                className: 'group !text-primary-200 bg-black/30',
              }}
              className="whitespace-nowrap rounded text-white transition-all duration-150  flex items-center px-2 py-2.5 hover:bg-black/30"
            >
              <BuildingOffice2Icon className="group-data-[status=active]:hidden size-6 flex-shrink-0" />
              <BuildingOffice2IconSolid className="group-data-[status=active]:block hidden size-6 flex-shrink-0" />
              <span className="ml-2 font-semibold text-white">Partners</span>
            </Link>
          </li>
          <li className="relative w-full">
            <Link
              to="/contacts"
              activeProps={{
                className: 'group !text-primary-200 bg-black/30',
              }}
              className="whitespace-nowrap rounded text-white transition-all duration-150  flex items-center px-2 py-2.5 hover:bg-black/30"
            >
              <UsersIcon className="group-data-[status=active]:hidden size-6 flex-shrink-0" />
              <UsersIconSolid className="group-data-[status=active]:block hidden size-6 flex-shrink-0" />
              <span className="ml-2 font-semibold text-white">Contacts</span>
            </Link>
          </li>
          {/* <li className="relative w-full">
              <Link
                to="/schemes"
                activeProps={{
                  className: 'group !text-primary-200 bg-black/30',
                }}
                className="whitespace-nowrap rounded text-white transition-all duration-150  flex items-center px-2 py-2.5 hover:bg-black/30"
              >
                <RectangleStackIcon className="group-data-[status=active]:hidden size-6 flex-shrink-0" />
                <RectangleStackIconSolid className="group-data-[status=active]:block hidden size-6 flex-shrink-0" />
                <span className="ml-2 font-semibold text-white">Schemes</span>
              </Link>
            </li> */}
          <li className="relative w-full">
            <Link
              to="/configuration"
              activeProps={{
                className: 'group !text-primary-200 bg-black/30',
              }}
              className="whitespace-nowrap rounded text-white transition-all duration-150  flex items-center px-2 py-2.5 hover:bg-black/30"
            >
              <Cog8ToothIcon className="group-data-[status=active]:hidden size-6 flex-shrink-0" />
              <Cog8ToothIconSolid className="group-data-[status=active]:block hidden size-6 flex-shrink-0" />
              <span className="ml-2 font-semibold text-white">
                Configuration
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
    <hr className="h-px border-none w-full mb-6 bg-white/40" />
    <span className="text-body-small text-white/90">
      Last login: {format(new Date(), "do MMMM yyyy 'at' HH:mm")}
    </span>
    <span className="text-body-small text-white/90 mt-2 block">
      Version: v{env.version}
    </span>
  </div>
);
export default Navigation;
