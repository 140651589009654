import { RouterProvider, createRouter } from '@tanstack/react-router';
import { Suspense } from 'react';
import { routeTree } from './routeTree.gen';
import { AuthContext } from './utility/authentication';
import Loader from './components/icons/Loader';
import { CatchBoundary, redirect } from '@tanstack/react-router';
import { notify } from './utility/notify';
import { AuthProvider } from './utility/authentication';

const router = createRouter({
  routeTree,
  context: {
    auth: undefined as unknown as AuthContext,
  },
  defaultPendingMs: 0
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export const ProtectedApp = () => (
  <CatchBoundary
    getResetKey={() => router.state?.resolvedLocation.state?.key ?? 'reset'}
    onCatch={(error) => {
      notify.error(error.message);
    }}
  >
    <Suspense
      fallback={
        <div className="h-screen w-screen flex items-center justify-center">
          <Loader />
        </div>
      }
    >
      <AuthProvider>
        <RouterProvider
          defaultPreload="intent"
          context={{ auth: null }}
          router={router}
        />
      </AuthProvider>
    </Suspense>
  </CatchBoundary>
);
