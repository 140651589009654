import { Field, Label, Radio, RadioGroup } from '@headlessui/react';
import { Fragment, ReactElement, ReactNode, useState } from 'react';
import Alert from '../Alerts';

type Option<T> = {
  value: T;
  disabled?: boolean;
  Icon?: ReactNode;
  title?: string;
  explainer?: string;
};

interface Props<T> {
  options: Option<T>[];
  selectedOption?: Option<T>;
  setSelectedOption: (opt: Option<T>) => void;
  label: string;
  type?: 'simple' | 'card';
  error?: string;
}

function RadioCardSelector<T>({
  options,
  label,
  type = 'card',
  selectedOption,
  setSelectedOption,
  error,
}: Props<T>): ReactElement {
  return (
    <>
      <span className="mb-2 text-input-label block font-semibold">{label}</span>
      <RadioGroup
        value={selectedOption}
        onChange={setSelectedOption}
        className={`flex items-center ${
          type === 'simple' ? 'flex-wrap space-y-2' : 'space-x-2'
        }`}
      >
        {options.map((option) => (
          <Field key={option.title} className="w-full">
            <Radio
              as="div"
              value={option}
              className={`group p-3 flex outline outline-1 [&:not([data-disabled])]:cursor-pointer hover:[&:not([data-disabled])]:bg-primary-600 -outline-offset-1 outline-grey-500 rounded hover:[&:not([data-disabled])]:outline-primary hover:[&:not([data-disabled])]:outline-2 data-[checked]:outline-primary data-[checked]:outline-2 data-[checked]:bg-primary-600 data-[disabled]:text-text-disabled/50
                ${
                  type === 'card'
                    ? 'flex-col'
                    : 'items-center w-full flex-row-reverse justify-between'
                }
                ${error ? 'outline-red--100 ' : ''}`}
              disabled={option.disabled}
            >
              <div className="flex items-start justify-between">
                {type === 'card' && option.Icon}
                <div className="group flex size-6 items-center border-grey-500 group-data-[disabled]:border-grey-700 justify-center rounded-full border-2 bg-white group-data-[checked]:border-primary group-hover:[&:not([group-data-[disabled]])]:border-primary group-data-[disabled]:bg-grey-900">
                  <span className="invisible size-3 rounded-full bg-primary group-data-[checked]:visible" />
                </div>
              </div>
              <p
                className={`text-start w-full text-body block ${
                  type === 'card' ? 'mb-1 mt-2 font-semibold' : ''
                }`}
              >
                {option.title}
              </p>
              {type === 'simple' && option.Icon}
              <span className="text-body-small">{option.explainer}</span>
            </Radio>
          </Field>
        ))}
      </RadioGroup>
      {error && (
        <div className="mt-2">
          <Alert alertType="error" text={error} />
        </div>
      )}
    </>
  );
}
export default RadioCardSelector;
