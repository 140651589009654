import { ReactElement, useMemo, useState } from 'react';
import { useJobContext } from '..';
import PreAuditModal from '../../../molecules/Modals/PreAudit';
import PostAuditModal from '../../../molecules/Modals/PostAudit';
import { AuditType, UserRole } from '@monorepo/graphql';
import { jobsUtility } from '../../../../utility/jobs';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { useAuth } from '../../../../utility/authentication';
import { profileUtility } from '../../../../utility/profile';

const AuditSelector = (): ReactElement => {
  const { user } = useAuth();

  profileUtility.assertProfile(user, 'UserUserProfile');

  const [showAudit, setShowAudit] = useState<AuditType | null>(null);

  const { audits, job, auditError } = useJobContext();

  const activeAudits = useMemo(
    () =>
      audits.filter(({ auditType }) =>
        jobsUtility.jobStatusAuditTypeMap[job.status] === AuditType.pre
          ? auditType === AuditType.pre
          : [
              AuditType.postRoofing,
              AuditType.postElectrical,
              AuditType.postGeneral,
            ].includes(auditType)
      ),
    [audits, job.status]
  );

  const awaitingSubmission = useMemo(
    () => activeAudits?.some(({ submittedAt }) => !submittedAt),
    [activeAudits]
  );

  const hasFailed = useMemo(
    () => activeAudits?.some(({ hasPassed }) => !hasPassed),
    [activeAudits]
  );

  const canSubmit = useMemo(
    () =>
      user.profile.role === UserRole.superAdministrator
        ? true
        : activeAudits.some(
            ({ auditor }) => auditor?.uuid === user.profile.uuid
          ) || !awaitingSubmission,
    [activeAudits, awaitingSubmission, user]
  );

  if (auditError) {
    return (
      <>
        <ExclamationCircleIcon className="text-red size-9" />
        <span className="text-body-small mt-1.5 text-red">
          {auditError.message}
        </span>
      </>
    );
  }

  return (
    <>
      {awaitingSubmission ? (
        <>
          <ExclamationTriangleIcon className="text-amber size-9" />
          <span className="text-body-small mt-1.5">Audit required</span>
        </>
      ) : !hasFailed ? (
        <>
          <CheckCircleIcon className="text-primary size-9" />
          <span className="text-body-small mt-1.5">Audit passed</span>
        </>
      ) : (
        <>
          <ExclamationCircleIcon className="text-red size-9" />
          <span className="text-body-small mt-1.5">Audit failed</span>
        </>
      )}

      {canSubmit && (
        <button
          onClick={() =>
            setShowAudit(jobsUtility.jobStatusAuditTypeMap[job.status])
          }
          className="text-body-small text-primary underline"
        >
          {!awaitingSubmission ? 'View form' : 'Start'}
        </button>
      )}

      <PreAuditModal
        open={showAudit === AuditType.pre}
        onClose={() => setShowAudit(null)}
      />

      <PostAuditModal
        open={showAudit === AuditType.postGeneral}
        onClose={() => setShowAudit(null)}
      />
    </>
  );
};
export default AuditSelector;
