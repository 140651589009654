import { ChevronRightIcon, ClipboardIcon, LightBulbIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { ReactElement } from 'react';
import { Button } from '../../atoms/Button';
import { Link } from '@tanstack/react-router';

const Configuration = (): ReactElement => (
  <>
    <div className="flex items-center p-5">
      <h1 className="text-h1-small font-bold font-nunito mr-5">Configure</h1>
    </div>
    <div className="flex flex-wrap p-5 -m-5">
      <Link
        to="/configuration/skills"
        className="rounded-md p-5 bg-white m-5 flex space-x-4 w-1/3 max-w-84"
      >
        <div className="flex items-center justify-center shrink-0 bg-primary-600 h-16 w-16 rounded-full">
          <LightBulbIcon className="size-6 text-primary" />
        </div>
        <div className="flex flex-col">
          <h2 className="font-nunito font-bold text-h2 mb-2">Skills</h2>
          <span className="text-sm">
          Create, edit and delete contractor skills
          </span>
        </div>
        <div className="flex items-center">
          <ChevronRightIcon className="size-5 text-grey-400" />
        </div>
      </Link>
      <Link
        to="/configuration/slas"
        className="rounded-md p-5 bg-white m-5 flex space-x-4 w-1/3 max-w-84"
      >
        <div className="flex items-center justify-center shrink-0 bg-primary-600 h-16 w-16 rounded-full">
          <ClipboardIcon className="size-6 text-primary" />
        </div>
        <div className="flex flex-col">
          <h2 className="font-nunito font-bold text-h2 mb-2">SLAs</h2>
          <span className="text-sm">
            Manage service license agreements for partners
          </span>
        </div>
        <div className="flex items-center">
          <ChevronRightIcon className="size-5 text-grey-400" />
        </div>
      </Link>
      <Link
        to="/configuration/organisations"
        className="rounded-md p-5 bg-white m-5 flex space-x-4 w-1/3 max-w-84"
      >
        <div className="flex items-center justify-center shrink-0 bg-primary-600 h-16 w-16 rounded-full">
          <UserGroupIcon className="size-6 text-primary" />
        </div>
        <div className="flex flex-col">
          <h2 className="font-nunito font-bold text-h2 mb-2">Organisations</h2>
          <span className="text-sm">
            Create & update organisations
          </span>
        </div>
        <div className="flex items-center">
          <ChevronRightIcon className="size-5 text-grey-400" />
        </div>
      </Link>
      
    </div>
  </>
);
export default Configuration;
