import { ReactElement } from 'react';
import {
  Modal,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { useForm } from '@tanstack/react-form';
import SkillsTable, { SkillRow } from '../../SkillsTable';
import { gql } from '@monorepo/graphql';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';

type Props = {
  skills: SkillRow[];
  partnerUuid: string;
} & ModalWrapperPropsExtends;

const UPDATE_PARTNER_SKILLS = gql(`
  mutation UpdatePartnerSkills ($input: UpdatePartnerInput!) {
    updatePartner (input: $input) {
      uuid
    }
  }
`);

const PartnerSkillsModal = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper dialogPanelClassname="min-w-140" open={open} onClose={onClose}>
    <PartnerSkillsModalChild {...rest} onClose={onClose} />
  </ModalWrapper>
);

const PartnerSkillsModalChild = ({
  onClose,
  skills,
  partnerUuid,
}: Omit<Props, 'open'>): ReactElement => {
  const [update, { loading, error }] = useMutation(UPDATE_PARTNER_SKILLS, {
    onCompleted: () => {
      notify.success('Succesfully saved skills.');
      onClose(true);
    },
    refetchQueries: ['IndexSkillsForPartner'],
  });

  const form = useForm({
    defaultValues: {
      skills,
    },
    onSubmit: ({ value }) => {
      update({
        variables: {
          input: {
            uuid: partnerUuid,
            skills: value.skills.map((sk) => ({
              skillUuid: sk.uuid,
              levelRequired: sk.levelRequired,
              trade: sk.trade,
            })),
          },
        },
      });
    },
  });

  const skillValue = form.useField({
    name: 'skills',
  });

  return (
    <Modal
      loading={loading}
      title="Partner's skills"
      onClose={onClose}
      confirmText="Save changes"
      confirmCallback={() => {
        form.handleSubmit();
      }}
      hideTopHr={skillValue.getValue().length > 0}
    >
      <div className={skillValue.getValue().length ? 'px-5' : 'p-5'}>
        <SkillsTable wrapped form={form} />
        {error && <Alert alertType="error" text={error.message} />}
      </div>
    </Modal>
  );
};
export default PartnerSkillsModal;
