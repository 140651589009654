import { Link } from '@tanstack/react-router';
import { ReactElement } from 'react';
import Tag from '../../../../atoms/Tag';
import {
  BuildingOffice2Icon,
  CalendarIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline';
import { jobsUtility } from '../../../../../utility/jobs';
import AvatarStack from '../../../../atoms/AvatarStack';
import TwoLineText from '../../../../atoms/TwoLineText';
import DifficultyIndicator from '../../../../atoms/DifficultyIndactor';
import { Button } from '../../../../atoms/Button';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { gql } from '@monorepo/graphql';
import { useQuery } from '@apollo/client';
import Loader from '../../../../icons/Loader';
import { styleUtility } from '../../../../../utility/styleUtility';

const JOBS_QUERY = gql(`
  query IndexJobsForUserCustomerProfile($filters: IndexJobsFilterInput) {
    indexJobs(filters: $filters) {
      items {
        uuid
        type 
        status
        difficulty
        customer {
          firstName
          lastName
        }
        displayDate
        partner {
          name
        }
        schemeName
        address {
          line1
          city
          postcode
        }
        startDate
        contractors {
          firstName
          lastName
          avatarSrc
        }
      }
    }
  }
  `);

interface Props {
  customerProfileUuid: string;
}
const JobsTab = ({ customerProfileUuid }: Props): ReactElement => {
  const { data, loading } = useQuery(JOBS_QUERY, {
    variables: {
      filters: {
        customerProfileUuid,
      },
    },
  });

  return (
    <div className="bg-white overflow-hidden h-full flex flex-col rounded-b-lg">
      <div className="flex p-5 items-center border-grey-700">
        <div className="flex items-center flex-grow">
          <h2 className="text-h2 font-bold font-nunito mr-5">Jobs</h2>
        </div>
      </div>
      {loading ? (
        <div className="flex items-center h-full py-5 justify-center">
          <Loader />
        </div>
      ) : (
        <div className="overflow-scroll flex flex-col">
          {data?.indexJobs.items.map((j) => (
            <Link
              to="/job/$uuid"
              params={{
                uuid: j.uuid,
              }}
              key={j.uuid}
              className="border-t relative flex border-grey-700 bg-white"
            >
              <div className="flex basis-1/3 flex-col space-y-3 p-5">
                <div className="flex items-center justify-between">
                  <Tag
                    text={jobsUtility.jobStatusNiceMap[j.status]}
                    colour={j.status}
                  />
                  {/* <ExclamationCircleIcon className="text-red size-6" /> */}
                </div>
                <div className="body-small">
                  <p className="font-semibold">
                    {styleUtility.capitalise(j.type)}
                  </p>
                  <span className="block mt-1 text-text-low-priority">
                    {[j.address?.line1, j.address?.city, j.address?.postcode]
                      .filter((i) => !!i)
                      .join(', ')}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  {
                    jobsUtility.slotIconComponentMap[
                      j.type as keyof typeof jobsUtility.slotIconComponentMap
                    ]
                  }
                  <AvatarStack avatars={j.contractors ?? []} />
                </div>
              </div>
              <div className="flex flex-col basis-1/3 justify-between border-l border-r border-grey-700 flex-grow my-5 px-5">
                <TwoLineText
                  label="Difficulty"
                  text={j.difficulty}
                  Icon={<DifficultyIndicator difficulty={j.difficulty} />}
                />
                <TwoLineText
                  label="Installation Date"
                  text={j.displayDate}
                  Icon={<CalendarIcon className="size-5 text-grey-400" />}
                />
              </div>
              <div className="flex flex-col basis-1/3 justify-between flex-grow my-5 px-5">
                <TwoLineText
                  label="Partner"
                  text={j.partner.name}
                  Icon={
                    <BuildingOffice2Icon className="size-5 text-grey-500" />
                  }
                />
                <TwoLineText
                  label="Scheme"
                  text={j.schemeName}
                  Icon={<RectangleStackIcon className="size-5 text-grey-500" />}
                />
              </div>
              <div className="flex items-end absolute right-0.5 bottom-0.5 p-5">
                <Button href={`/job/${j.uuid}`} bStyle="outline" bText="View job" />
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
export default JobsTab;
