import { ReactElement } from 'react';
import {
  Modal,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import { gql, InvoiceStatus } from '@monorepo/graphql';
import { useForm } from '@tanstack/react-form';
import { addDays } from 'date-fns';
import JobDropdown from '../../JobDropdown';
import DateSelector from '../../../atoms/DateSelector';
import { Input, NumberInput } from '../../../atoms/Input';
import { useMutation } from '@apollo/client';
import Alert from '../../../atoms/Alerts';
import { notify } from '../../../../utility/notify';
import { DropdownWithBorder } from '../../../atoms/Dropdown';
import { styleUtility } from '../../../../utility/styleUtility';

type Invoice = {
  uuid: string;
  jobUuid?: string;
  dueDate?: Date;
  xeroReference?: string;
  xeroLink?: string;
  notes?: string;
  amount?: string;
  status?: InvoiceStatus;
};

type Props = {
  invoice?: Invoice;
  customerProfileUuid: string;
} & ModalWrapperPropsExtends;

const CREATE_INVOICE = gql(`  
  mutation CreateInvoice ($input: InvoiceCreateInput!) {
    createInvoice (input: $input) {
      ... Invoice 
    }
  }
`);

const UPDATE_INVOICE = gql(`  
  mutation UpdateInvoice ($input: InvoiceUpdateInput!) {
    updateInvoice (input: $input) {
      ... Invoice 
    }
  }
`);

const statusOptions = Object.values([
  InvoiceStatus.created,
  InvoiceStatus.sent,
  InvoiceStatus.overdue,
  InvoiceStatus.paid,
  InvoiceStatus.voided,
]).map((v) => ({
  name: styleUtility.capitalise(v),
  value: v as InvoiceStatus,
}));

const UpsertInvoice = ({ open, onClose, ...rest }: Props) => (
  <ModalWrapper open={open} onClose={onClose}>
    <UpsertInvoiceChild onClose={onClose} {...rest} />
  </ModalWrapper>
);

const UpsertInvoiceChild = ({
  onClose,
  customerProfileUuid,
  invoice,
}: Omit<Props, 'open'>): ReactElement => {
  const [create, { loading, error }] = useMutation(CREATE_INVOICE);
  const [update, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_INVOICE);

  const form = useForm({
    onSubmit: ({ value }) => {
      if (!invoice) {
        create({
          variables: {
            input: {
              amount: parseFloat(value.amount),
              status: value.status,
              dueDate: value.dueDate,
              xeroReference: value.xeroReference,
              xeroLink: value.xeroLink,
              notes: value.notes,
              jobUuid: value.jobUuid,
              customerProfileUuid,
            },
          },
          onCompleted: () => {
            notify.success('Successfully created invoice.');
            onClose(true);
          },
        });
      } else {
        update({
          variables: {
            input: {
              uuid: invoice.uuid,
              amount: parseFloat(value.amount),
              status: value.status,
              dueDate: value.dueDate,
              xeroReference: value.xeroReference,
              xeroLink: value.xeroLink,
            },
          },
          onCompleted: () => {
            notify.success('Successfully updated invoice.');
            onClose(true);
          },
        });
      }
    },
    defaultValues: {
      jobUuid: invoice?.jobUuid ?? '',
      dueDate: invoice?.dueDate ?? addDays(new Date(), 30),
      xeroReference: invoice?.xeroReference ?? '',
      xeroLink: invoice?.xeroLink ?? '',
      notes: invoice?.notes ?? '',
      status: invoice?.status ?? InvoiceStatus.created,
      amount: invoice?.amount ?? '',
    },
  });

  return (
    <Modal
      loading={loading || updateLoading}
      onClose={onClose}
      confirmCallback={form.handleSubmit}
      confirmText={invoice ? 'Save invoice' : 'Add invoice'}
      title={invoice ? 'Edit invoice' : 'Add Invoice'}
    >
      <div className="p-5">
        <div className="mb-5">
          <form.Field
            name="status"
            children={({ state, handleChange }) => (
              <DropdownWithBorder
                label="Status"
                buttonText={styleUtility.capitalise(state.value)}
                buttonClassname="w-full justify-between"
                options={statusOptions}
                selected={statusOptions.find((v) => v.value === state.value)}
                onOptionSelect={(v) => handleChange(v.value)}
              />
            )}
          />
        </div>

        {!invoice && (
          <div className="w-140 mb-5">
            <form.Field
              name="jobUuid"
              validators={{
                onSubmit: ({ value }) =>
                  value.length
                    ? undefined
                    : 'Please select a job to attach the invoice too.',
              }}
              children={({ state, handleChange }) => (
                <JobDropdown
                  ignorePartnerUuid
                  error={state.meta.errors.join(', ')}
                  jobUuid={state.value}
                  customerProfileUuid={customerProfileUuid}
                  setJobUuid={handleChange}
                />
              )}
            />
          </div>
        )}

        <div className="mb-5">
          <span className={`text-input-label font-semibold block mb-2`}>
            Due date
          </span>

          <form.Field
            name="dueDate"
            children={({ state, handleChange }) => (
              <DateSelector
                showLabel
                selectedDate={state.value}
                setSelectedDate={handleChange}
              />
            )}
          />
        </div>

        <form.Field
          name="xeroReference"
          validators={{
            onBlur: ({ value }) =>
              value.length ? undefined : 'Please add a Xero reference',
          }}
          children={({ state, handleChange, handleBlur }) => (
            <Input
              error={state.meta.errors.join(', ')}
              value={state.value}
              onBlur={handleBlur}
              onChange={(e) => handleChange(e.target.value)}
              label="Xero Reference"
            />
          )}
        />
        <form.Field
          name="xeroLink"
          validators={{
            onBlur: ({ value }) =>
              value.length ? undefined : 'Please add a Xero link',
          }}
          children={({ state, handleChange, handleBlur }) => (
            <Input
              error={state.meta.errors.join(', ')}
              value={state.value}
              onBlur={handleBlur}
              onChange={(e) => handleChange(e.target.value)}
              label="Xero Link"
            />
          )}
        />

        <form.Field
          name="amount"
          validators={{
            onBlur: ({ value }) =>
              value.length ? undefined : 'Please enter an amount',
          }}
          children={({ state, handleChange, handleBlur }) => (
            <NumberInput
              error={state.meta.errors.join(', ')}
              helperText="Pound sterling"
              onBlur={handleBlur}
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="Invoice Amount"
            />
          )}
        />

        <form.Field
          name="notes"
          children={({ state, handleChange }) => (
            <Input
              type="textarea"
              className="w-140"
              max={500}
              value={state.value}
              onChange={(e) => handleChange(e.target.value)}
              label="Notes (optional)"
            />
          )}
        />
        {error && <Alert alertType="error" text={error.message} />}
        {updateError && <Alert alertType="error" text={updateError.message} />}
      </div>
    </Modal>
  );
};
export default UpsertInvoice;
