import { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalPropsExtends,
  ModalWrapper,
  ModalWrapperPropsExtends,
} from '../Base';
import {
  ContractorTrade,
  gql,
  IndexContractorsForAssignmentsQuery,
  IndexPotentialContractorsForJobQuery,
  UserProfile,
} from '@monorepo/graphql';
import { useMutation, useQuery } from '@apollo/client';
import RadioSelector from '../../../atoms/RadioSelector';
import { DropdownWithBorder } from '../../../atoms/Dropdown';
import { Input } from '../../../atoms/Input';
import {
  ArrowRightIcon,
  CalendarIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import { useDebounce } from 'use-debounce';
import AvatarStack from '../../../atoms/AvatarStack';
import { Link } from '@tanstack/react-router';
import { Button } from '../../../atoms/Button';
import TwoLineText from '../../../atoms/TwoLineText';
import Loader from '../../../icons/Loader';
import { useJobContext } from '../../../organisms/Job';
import EmptyState from '../../EmptyState';
import AssignedContractor from './AssignedContractor';
import Alert from '../../../atoms/Alerts';
import { SimpleCheckbox } from '../../../atoms/CheckboxCard';
import { client } from '../../../../main';
import { notify } from '../../../../utility/notify';

const INDEX_CONTRACTORS_FOR_JOB = gql(`
  query IndexContractorsForAssignments ($uuid: String!) {
    indexContractorsForJob (uuid: $uuid) {
      uuid
      isElectrician
      isRoofer 
      distance
      canBeElectrician
      canBeRoofer
      user {
        uuid
        firstName
        lastName
        avatarSrc
      }
      datesRequired
      contractorProfileUuid
    }
  }
`);

const INDEX_POTENTIAL_CONTRACTORS_FOR_JOB = gql(`
  query IndexPotentialContractorsForJob ($filters: IndexPotentialContractorsForJobFilter!) {
    indexPotentialContractorsForJob (filters: $filters) {
      userUuid
      contractorProfileUuid
      firstName
      lastName
      avatarSrc
      isElectrician
      isRoofer
      canBeElectrician
      canBeRoofer
      distance
    }
  }
`);

const ATTACH_JOB_CONTRACTORS = gql(`
  mutation AttachJobContractors ($input: AttachJobContractorsInput!) {
    attachJobContractors (input: $input) 
  }  
`);

const AssignmentModal = ({ open, onClose }: ModalWrapperPropsExtends) => (
  <ModalWrapper open={open} onClose={onClose}>
    <AssignmentModalChild onClose={onClose} />
  </ModalWrapper>
);

type AssignedContractors =
  IndexContractorsForAssignmentsQuery['indexContractorsForJob'];
type PotentialContractors =
  IndexPotentialContractorsForJobQuery['indexPotentialContractorsForJob'];

const AssignmentModalChild = ({ onClose }: ModalPropsExtends): ReactElement => {
  const [isElectrician, setIsElectrician] = useState(true);
  const [isRoofer, setIsRoofer] = useState(true);

  const [search, setSearch] = useState('');
  const { job } = useJobContext();
  const [attach, { loading: attachLoading }] = useMutation(
    ATTACH_JOB_CONTRACTORS,
    {
      onError: (err) =>
        notify.error(`Unable to attach contractors to job \n ${err.message}`),
      onCompleted: () => {
        notify.success('Saved contractors.');
        onClose(true);
      },
    }
  );

  const [searchDebounced] = useDebounce(search, 500);

  const { loading } = useQuery(INDEX_POTENTIAL_CONTRACTORS_FOR_JOB, {
    variables: {
      filters: {
        jobUuid: job.uuid,
        isElectrician,
        isRoofer,
        term: searchDebounced,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      const contractors = [...(d?.indexPotentialContractorsForJob ?? [])];
      setPotentialContractors(
        contractors
          .filter(
            (c) =>
              !assignedContractors.some(
                (a) => a.contractorProfileUuid === c.contractorProfileUuid
              )
          )
          .sort((a, b) => {
            if (a.distance > b.distance) return 1;
            if (a.distance === b.distance) return 0;
            return -1;
          }) ?? []
      );
    },
  });

  const { loading: assignedContractorsLoading, error } = useQuery(
    INDEX_CONTRACTORS_FOR_JOB,
    {
      variables: {
        uuid: job.uuid,
      },
      onCompleted: (d) =>
        setAssignedContractors(d?.indexContractorsForJob ?? []),
    }
  );

  const [assignedContractors, setAssignedContractors] =
    useState<AssignedContractors>([]);
  const [potentialContractors, setPotentialContractors] =
    useState<PotentialContractors>([]);

  return (
    <Modal
      scroll={false}
      title="Assign Contractors"
      onClose={onClose}
      confirmText="Save"
      loading={attachLoading}
      confirmCallback={() =>
        attach({
          variables: {
            input: {
              jobUuid: job.uuid,
              contractors: assignedContractors.map((c) => ({
                contractorProfileUuid: c.contractorProfileUuid,
                isElectrician: c.isElectrician,
                isRoofer: c.isRoofer,
                isPrimary: true,
                canBeElectrician: c.canBeElectrician,
                canBeRoofer: c.canBeRoofer,
                datesRequired: c.datesRequired.map((d) => new Date(d)),
              })),
            },
          },
        })
      }
    >
      <div className="flex">
        <div className="flex flex-col w-110">
          <div className="p-5 space-y-5">
            <h3 className="text-h3 font-nunito font-bold">Find contractors</h3>
            <div className="flex space-x-5">
              <SimpleCheckbox
                checked={isElectrician}
                setChecked={setIsElectrician}
                label="Electricians?"
              />
              <SimpleCheckbox
                checked={isRoofer}
                setChecked={setIsRoofer}
                label="Roofers?"
              />
            </div>
            <Input
              value={search}
              className="!mb-0 w-100 h-11"
              Icon={<MagnifyingGlassIcon className="size-6" />}
              onChange={(e) => setSearch(e.target.value)}
            />
            {!!search.length && (
              <Alert
                alertType="warning"
                text="By searching for a contractor directly you are circumnavigating skill and distance checks."
              />
            )}
          </div>
          <div className="flex overflow-hidden">
            <div className="overflow-scroll flex items-center flex-col space-y-2 w-full px-5">
              {loading && <Loader />}
              {potentialContractors.length
                ? potentialContractors.map((u) => (
                    <div
                      key={u.userUuid}
                      className="border border-grey-400/40 shrink-0 w-full rounded-md overflow-hidden"
                    >
                      <div className="p-2 flex items-center">
                        <AvatarStack
                          height="h-9"
                          width="w-9"
                          avatars={[
                            {
                              firstName: u.firstName,
                              lastName: u.lastName,
                              avatarSrc: u.avatarSrc ?? undefined,
                            },
                          ]}
                        />
                        <div className="space-y-2.5 px-5 flex-grow">
                          <Link
                            to="/contacts/$uuid"
                            params={{
                              uuid: u.userUuid,
                            }}
                          >
                            <p className="font-semibold underline">
                              {u.firstName} {u.lastName}
                            </p>
                          </Link>
                          <span className="text-body-small text-text-low-priority">
                            Trade:{' '}
                            {[
                              u.isElectrician ? 'Electrician' : undefined,
                              u.isRoofer ? 'Roofer' : undefined,
                            ]
                              .filter((s) => s)
                              .join(', ')}
                          </span>
                        </div>
                      </div>
                      <div className="p-2 bg-background-secondary flex items-center">
                        <div className="flex items-center flex-grow">
                          <MapPinIcon className="size-5 text-grey-400 mr-1" />
                          <span className="text-body-small">
                            {u.distance.toLocaleString('en-GB', {
                              maximumFractionDigits: 2,
                            })}{' '}
                            miles
                          </span>
                        </div>
                        <Button
                          className="justify-center"
                          reverse
                          onClick={() => {
                            setAssignedContractors((as) => [
                              {
                                uuid: u.contractorProfileUuid,
                                contractorProfileUuid: u.contractorProfileUuid,
                                isElectrician: u.isElectrician,
                                isRoofer: u.isRoofer,
                                canBeElectrician: u.canBeElectrician,
                                canBeRoofer: u.canBeRoofer,
                                user: {
                                  uuid: u.userUuid,
                                  firstName: u.firstName,
                                  lastName: u.lastName,
                                  avatarSrc: u.avatarSrc,
                                },
                                datesRequired: job.dates,
                                distance: u.distance,
                              },
                              ...as,
                            ]);
                            setPotentialContractors((pc) =>
                              pc.filter(
                                ({ contractorProfileUuid }) =>
                                  contractorProfileUuid !==
                                  u.contractorProfileUuid
                              )
                            );
                          }}
                          bText="Assign"
                          Icon={<ArrowRightIcon className="size-5" />}
                        />
                      </div>
                    </div>
                  ))
                : !loading && (
                    <EmptyState
                      title="No contractors available"
                      description="Uh oh, no contractors are available for this job."
                    />
                  )}
            </div>
          </div>
        </div>
        <hr className="h-full shrink-0 w-px border-none bg-grey-700" />
        <div className="flex flex-col w-110">
          <div className="space-y-5 p-5">
            <TwoLineText
              label="Job Address"
              text={
                job.address
                  ? [
                      job.address.line1,
                      job.address.line2,
                      job.address.city,
                      job.address.postcode,
                    ]
                      .filter((a) => a)
                      .join(', ')
                  : ''
              }
              Icon={<MapPinIcon className="h-5 text-grey-400" />}
            />
            <TwoLineText
              label="Installation date"
              text={job.displayDate}
              Icon={<CalendarIcon className="h-5 text-grey-400" />}
            />
            <h3 className="text-h3 font-nunito font-bold">
              Assigned Contractors
            </h3>
          </div>
          <div className="flex px-5 justify-center w-full h-full overflow-hidden mb-5">
            {error ? (
              <Alert alertType="error" text={error.message} />
            ) : !assignedContractors.length ? (
              assignedContractorsLoading ? (
                <Loader />
              ) : (
                <div className="py-5 flex items-center justify-center">
                  <div className="max-w-90">
                    <EmptyState
                      title="No contractors"
                      description="Start by finding contractors on the left and assigning them"
                    />
                  </div>
                </div>
              )
            ) : (
              <div className="overflow-scroll space-y-2 w-full">
                {assignedContractors.map((u) => (
                  <AssignedContractor
                    updateContractor={(attributes) =>
                      setAssignedContractors((as) =>
                        as.map((a) => {
                          if (
                            a.contractorProfileUuid === u.contractorProfileUuid
                          )
                            return {
                              ...a,
                              ...attributes,
                            };
                          return a;
                        })
                      )
                    }
                    key={u.uuid}
                    contractor={u}
                    onDelete={(contractor) => {
                      setAssignedContractors((as) =>
                        as.filter(
                          ({ contractorProfileUuid }) =>
                            contractorProfileUuid !==
                            contractor.contractorProfileUuid
                        )
                      );
                      client.graphqlClient().refetchQueries({
                        include: [INDEX_POTENTIAL_CONTRACTORS_FOR_JOB],
                      });
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default AssignmentModal;
