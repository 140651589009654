import { ReactElement, useEffect, useState } from 'react';
import { SimpleCheckbox } from '../../atoms/CheckboxCard';
import { Button } from '../../atoms/Button';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import JobsWidget from './JobsWidget';
import TasksWidget from './TasksWidget';

const Dashboard = (): ReactElement => {
  const [myAssignments, setMyAssignments] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('allAssignments')) {
      setMyAssignments(false)
    }
  }, [])

  return (
    <div className="flex w-full overflow-hidden h-full">
      <div className="flex flex-col flex-grow p-5">
        <div className="flex items-center mb-5">
          <div className="px-2.5 flex-grow">
            <h1 className="text-h1 font-nunito font-bold">Dashboard</h1>
          </div>
          <SimpleCheckbox
            checked={myAssignments}
            setChecked={checked => {
              if (!checked) {
                localStorage.setItem('allAssignments', 'true')
              } else {
                localStorage.removeItem('allAssignments')
              }
              setMyAssignments(checked)
            }}
            label="Show only my assignments"
          />
          <Button
            bText="Configure"
            bStyle="light"
            className="ml-3"
            Icon={<Cog6ToothIcon className="size-6" />}
          />
        </div>
        <div className="grid 2xl:grid-cols-2 h-full grid-cols-1 gap-5 overflow-hidden">
          <JobsWidget myAssignment={myAssignments} />
          <TasksWidget  myAssignment={myAssignments} />
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
