import { createFileRoute } from '@tanstack/react-router'
import { ReactNode } from 'react'
import Loader from '../../../components/icons/Loader'
import { client } from '../../../main'
import Partner from '../../../components/organisms/Partner'
import { partnerUtility } from '../../../utility/partners'

const JobSingle = (): ReactNode => {
  const { uuid } = Route.useParams()
  return <Partner uuid={uuid} />
}

export const Route = createFileRoute('/_authenticated/partners/$uuid')({
  component: JobSingle,
  pendingComponent: () => (
    <div className="flex items-center h-full justify-center">
      <Loader />
    </div>
  ),
  loader: ({ params }) =>
    client.graphqlClient().query({
      query: partnerUtility.READ_PARTNER,
      variables: {
        uuid: params.uuid,
      },
    }),
})
