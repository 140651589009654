import { Option } from '../components/atoms/Dropdown';
import { TicketStatus } from '@monorepo/graphql';

const ticketStatusNiceMap: Record<TicketStatus, string> = {
  [TicketStatus.assigned]: 'Assigned',
  [TicketStatus.resolved]: 'Resolved',
  [TicketStatus.unassigned]: 'Unassigned',
  [TicketStatus.unableToResolve]: 'Unable to resolve',
};

const ticketStatusOptions: Option<TicketStatus>[] = Object.keys(TicketStatus)
  .sort((a, b) =>
    Object.keys(ticketStatusNiceMap).indexOf(a) >
    Object.keys(ticketStatusNiceMap).indexOf(b)
      ? 1
      : -1
  )
  .map((s) => ({
    name: ticketStatusNiceMap[s as TicketStatus],
    value: s as TicketStatus,
  }));

export const ticketsUtility = {
  ticketStatusOptions,
  ticketStatusNiceMap,
};
