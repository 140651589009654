import { ReactElement, useEffect, useState } from 'react';
import {
  gql,
  IndexAllTasksForSectionQuery,
  TaskStatus,
} from '@monorepo/graphql';
import { useMutation, useQuery } from '@apollo/client';
import Table from '../../../../../molecules/Table';
import {
  CheckCircleIcon,
  ClipboardIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { tasksUtility } from '../../../../../../utility/tasks';
import { Button } from '../../../../../atoms/Button';
import Tag from '../../../../../atoms/Tag';
import { TablePagination } from '../../../../../molecules/Pagination';
import UserDropdown from '../../../../../molecules/UserDropown';
import { notify } from '../../../../../../utility/notify';
import { format, isAfter, subDays } from 'date-fns';
import { Tooltip } from 'react-tooltip';

const INDEX_ALL_TASKS = gql(`
  query IndexAllTasksForSection ($pagination: PaginationInput, $myAssignment: Boolean!) {
    allTasks (pagination: $pagination, myAssignment: $myAssignment) {
      items {
        uuid 
        title
        status
        dueDate
        assignee {
          uuid
          firstName
          lastName
          avatarSrc
        }
      }
      pagination {
        lastPage
      }
    }
  }  
`);

const SAVE_TASK = gql(`
  mutation UpsertTaskOnDashboard ($input: TaskUpsertInput!) {
    upsertTask(input: $input) {
      uuid
    }
  }  
`);

const AllTasks = ({
  myAssignment
}: {
  myAssignment: boolean; 
}): ReactElement => {
  const [page, setPage] = useState(1);
  const { data, loading } = useQuery(INDEX_ALL_TASKS, {
    variables: {
      pagination: {
        perPage: 15,
        page,
      },
      myAssignment
    },
  });

  const [tasks, setTasks] = useState<
    IndexAllTasksForSectionQuery['allTasks']['items']
  >(data?.allTasks.items ?? []);

  const [saveTask] = useMutation(SAVE_TASK, {
    onError: (err) => notify.error(`Unable to save task ${err.message}`),
    onCompleted: () => notify.success(`Successfully saved task`),
  });

  useEffect(() => {
    setTasks(data?.allTasks.items ?? []);
  }, [data]);

  return (
    <>
      <Table
        loading={loading}
        widthType="pc"
        columns={[
          {
            heading: 'title',
            width: 40,
          },
          {
            heading: 'assignee',
            width: 30,
          },
          {
            heading: 'status',
            width: 15,
          },
          {
            width: 15,
          },
        ]}
        rows={
          tasks.map((t) => ({
            uuid: t.uuid,
            cells: [
              {
                content: (
                  <>
                    <div
                      data-tooltip-id={t.uuid}
                      data-tooltip-content={
                        t.dueDate ? format(t.dueDate, 'do MMMM yyyy') : '-'
                      }
                    >
                      {t.dueDate && <Tooltip id={t.uuid} />}
                      {t.dueDate ? (
                        isAfter(new Date(), t.dueDate) ? (
                          <ExclamationCircleIcon className="size-5 mr-2 rounded-full text-red-100" />
                        ) : isAfter(new Date(), subDays(t.dueDate, 7)) ? (
                          <ExclamationCircleIcon className="size-5 mr-2 rounded-full text-amber-100" />
                        ) : (
                          <ClipboardIcon className="text-grey-400 size-5 mr-2" />
                        )
                      ) : (
                        <ClipboardIcon className="text-grey-400 size-5 mr-2" />
                      )}
                    </div>
                    <span className="text-sm truncate">{t.title}</span>
                  </>
                ),
                width: 40,
              },
              {
                content: (
                  <div>
                    <UserDropdown
                      simple
                      userUuid={t.assignee?.uuid}
                      setUserUuid={(_, profileUuid) =>
                        setTasks((ts) =>
                          ts.map((task) => {
                            if (t.uuid === task.uuid) {
                              saveTask({
                                variables: {
                                  input: {
                                    uuid: task.uuid,
                                    title: task.title,
                                    status: TaskStatus.todo,
                                    operatorUuid: profileUuid,
                                  },
                                },
                              });
                              return {
                                ...task,
                                status: TaskStatus.todo,
                                assignee: {
                                  firstName: '',
                                  lastName: '',
                                  uuid: profileUuid,
                                  avatarSrc: null,
                                },
                              };
                            }
                            return task;
                          })
                        )
                      }
                    />
                  </div>
                ),
                width: 30,
              },
              {
                content: (
                  <Tag
                    colour={t.status}
                    text={tasksUtility.taskStatusNiceMap[t.status]}
                  />
                ),
                width: 15,
              },
              {
                content: (
                  <div className="flex justify-end w-full">
                    <Button
                      bStyle="light"
                      className="h-9"
                      bText="View"
                      href={`/tasks/${t.uuid}`}
                    />
                  </div>
                ),
                width: 15,
              },
            ],
          })) ?? []
        }
      />
      <TablePagination
        page={page}
        setPage={setPage}
        totalPages={data?.allTasks.pagination.lastPage}
      />
    </>
  );
};
export default AllTasks;
