import { z } from 'zod';
import Calendar from '../../components/organisms/Calendar';
import { createFileRoute } from '@tanstack/react-router';
import { CalendarPeriod } from '../../utility/calendarContext';
import { PartnerProvider } from '../../utility/partnerContext';

const CalendarComponent = () => {
  const { period, date } = Route.useSearch();
  return (
    <PartnerProvider>
      <Calendar
        period={period ?? CalendarPeriod.weekdays}
        selectedDate={date ?? new Date().toISOString()}
      />
    </PartnerProvider>
  );
};

export const Route = createFileRoute('/_authenticated/calendar/')({
  validateSearch: z.object({
    period: z.nativeEnum(CalendarPeriod).optional(),
    date: z.string().optional(),
  }),
  component: CalendarComponent,
});