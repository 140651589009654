import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  FileTargetType,
  gql,
  ReadUserQuery,
  TargetType,
  UserProfile,
  UserProfileStatus,
} from '@monorepo/graphql';
import { Button } from '../../atoms/Button';
import {
  ArrowLeftIcon,
  BuildingOffice2Icon,
  CalendarIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  FolderIcon,
  LockClosedIcon,
  PhoneIcon,
  PhotoIcon,
  PowerIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';
import { useRouter } from '@tanstack/react-router';
import AuditModal from '../../molecules/Modals/Audit';
import Tag from '../../atoms/Tag';
import AvatarStack from '../../atoms/AvatarStack';
import { profileUtility } from '../../../utility/profile';
import { styleUtility } from '../../../utility/styleUtility';
import TwoLineText from '../../atoms/TwoLineText';
import { format } from 'date-fns';
import Tabs from '../../molecules/Tabs';
import Jobs from './Tabs/jobs';
import AddUserModal from '../../molecules/Modals/AddUser';
import { usersUtility } from '../../../utility/users';
import Tasks from './Tabs/tasks';
import Tickets from './Tabs/tickets';
import { from, useLazyQuery, useMutation } from '@apollo/client';
import SimpleModal from '../../molecules/Modals/Simple';
import { notify } from '../../../utility/notify';
import { useAuth } from '../../../utility/authentication';
import Dropdown from '../../atoms/Dropdown';
import { filesUtility } from '../../../utility/files';
import axios from 'axios';

interface Props {
  data: ReadUserQuery['readUser'] & {
    profile: {
      __typename: 'UserUserProfile';
    };
  };
}

const UPDATE_USER_USER_PROFILE = gql(`
  mutation UpdateUserUserProfileStatus($input: UpdateUserUserProfileInput!) {
    updateUserUserProfile(input: $input) {
      userUuid
    }
  }
`);

const PUT_SIGNED_URL = gql(`
  mutation PutSignedAvatarPhotoUrlForProfile ($input: UploadAvatarUrlInput!) {
    putSignedAvatarPhotoUrl (input: $input) {
      url
      key
    }
  }
`);

const CREATE_AVATAR_FILE = gql(`
  mutation CreateAvatarFileForProfile ($input: CreateAvatarFileInput!) {
    createAvatarFile (input: $input) {
      uuid
      src
    }
  }  
`);

const UserUserProfile = ({ data }: Props): ReactElement => {
  const [showAuditTrail, setShowAuditTrail] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showResetPasswordEmail, setShowResetPasswordEmail] = useState(false);
  const [user, setUser] = useState(data);
  const router = useRouter();
  const [amendUser, { error, loading }] = useMutation(UPDATE_USER_USER_PROFILE);
  const [resetPassword, { loading: loadingResetPassword }] = useLazyQuery(
    usersUtility.queries.ResetPasswordGql,
    {
      onError: (err) =>
        notify.error(`Unable to reset password \n ${err.message}`),
    }
  );
  const userIsActive = user.profile.status === UserProfileStatus.active;
  const { user: authenticatedUser } = useAuth();

  const [signedAvatarUrl] = useMutation(PUT_SIGNED_URL);
  const [createAvatarFile] = useMutation(CREATE_AVATAR_FILE);

  const isMyProfile = authenticatedUser!.uuid === user.uuid;

  const onUpload = useCallback(
    async (file: File) => {
      const { data } = await signedAvatarUrl({
        variables: {
          input: {
            contentDisposition: `inline; filename=${file.name}`,
          },
        },
      });

      if (!data) {
        notify.error('Unable to upload file');
        return;
      }

      const { key, url } = data.putSignedAvatarPhotoUrl;

      await axios
        .put(url, file, {
          headers: {
            'Content-Disposition': `inline; filename=${file.name}`,
            'Content-Type': file.type,
          },
        })
        .catch((e) => {
          throw new Error('Unable to upload');
        });

      const { data: createFile } = await createAvatarFile({
        variables: {
          input: {
            key,
            name: file.name,
            mimeType: file.type,
            size: file.size,
          },
        },
      });

      if (!createFile) {
        notify.error('Unable to upload file');
        return;
      }

      const { src, uuid } = createFile.createAvatarFile;

      amendUser({
        variables: {
          input: {
            uuid: user.profile.uuid,
            avatarUuid: uuid,
          },
        },
      })
        .then(() => {
          notify.success('Saved user');
          setUser((u) => ({
            ...u,
            avatarSrc: src,
          }));
        })
        .catch((e) => notify.error(`Unable to save user.`));
    },
    [amendUser, createAvatarFile, signedAvatarUrl, user.profile.uuid]
  );

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const files = (e as ChangeEvent<HTMLInputElement>).target.files;
      if (!files || files?.length === 0) return;
      await onUpload(files[0]);
    } catch (e) {
      throw new Error((e as Error).message);
    }
  };

  return (
    <>
      <div className="flex w-full overflow-hidden h-full">
        <div className="flex flex-col flex-grow p-5">
          <div className="flex items-center mb-5">
            <Button
              onClick={() => router.history.back()}
              bStyle="clean-dark"
              Icon={<ArrowLeftIcon className="text-text-normal size-6" />}
            />
            <div className="px-2.5 flex-grow">
              <p className="font-semibold">
                {isMyProfile
                  ? `My Profile (User #${user.uuid})`
                  : `User #${user.uuid}`}
              </p>
            </div>
            <Button
              className="mr-3"
              onClick={() => setShowAuditTrail(true)}
              bText="Audit trail"
              bSize="sm"
              bStyle="light"
            />
            <Dropdown
              buttonClassname="bg-white h-11 w-11 items-center justify-center flex rounded border border-grey-500"
              ButtonIcon={<EllipsisVerticalIcon className="size-6" />}
              onOptionSelect={(opt) => {
                switch (opt.value) {
                  case 'password':
                    setShowResetPasswordEmail(true);
                    break;
                  case 'activation':
                    setShowStatusModal(true);
                    break;
                  case '2fa':
                    break;
                }
              }}
              options={[
                {
                  name: 'Reset password',
                  value: 'password',
                  Icon: <LockClosedIcon className="size-5" />,
                },
                ...(isMyProfile
                  ? [
                      {
                        name: 'Configure 2FA',
                        value: '2fa',
                        Icon: <ShieldCheckIcon className="size-5" />,
                      },
                    ]
                  : []),
                {
                  name:
                    user.profile.status === UserProfileStatus.active
                      ? 'Deactivate'
                      : 'Activate',
                  value: 'activation',
                  Icon:
                    user.profile.status === UserProfileStatus.active ? (
                      <ExclamationCircleIcon className="size-5 text-red" />
                    ) : (
                      <PowerIcon className="size-5" />
                    ),
                },
              ]}
            />
          </div>
          <div className="bg-white rounded p-5 flex flex-col mb-5">
            <div className="flex justify-between items-center">
              <Tag
                size="lg"
                text={userIsActive ? 'Active' : 'Inactive'}
                colour={userIsActive ? 'completed' : 'notSigned'}
              />
              <Button
                bStyle="outline"
                bText="Edit"
                onClick={() => setShowAddUserModal(true)}
              />
            </div>
            <div className="flex">
              <div className="flex items-center flex-col w-1/3 p-5">
                <div className="relative">
                  <AvatarStack
                    heading
                    height="h-20"
                    width="w-20"
                    avatars={[
                      {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        avatarSrc: user.avatarSrc ?? undefined,
                      },
                    ]}
                  />
                  {isMyProfile && (
                    <label
                      htmlFor="file-upload"
                      className="flex cursor-pointer absolute h-9 w-9 justify-center top-1/2 bg-white -right-5 items-center rounded-full border border-grey-400"
                      style={{
                        borderColor: user.avatarSrc
                          ? undefined
                          : styleUtility.stringToColour(user.firstName),
                      }}
                    >
                      <PhotoIcon className="size-5" />
                      <input
                        onChange={onChange}
                        type="file"
                        multiple
                        accept="image/jpg,image/jpeg,image/png"
                        className="hidden"
                        id="file-upload"
                      />
                    </label>
                  )}
                </div>
                <h1 className="text-h1-small font-nunito font-bold mt-4 mb-2">
                  {user.firstName} {user.lastName}
                </h1>
                <div className="flex items-center">
                  {profileUtility.roleIcon[user.profile.role]}
                  <p className="ml-2">
                    {profileUtility.roleNiceMap[user.profile.role]}
                  </p>
                </div>
              </div>
              <hr className="border-none w-px h-full bg-grey-700" />
              <div className="grid grid-cols-2 gap-5 p-5 flex-grow">
                <div className="basis-1/2">
                  <TwoLineText
                    label="Email"
                    text={user.email}
                    Icon={<EnvelopeIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Phone"
                    text={user.phoneNumber ?? '-'}
                    Icon={<PhoneIcon className="size-5 text-grey-400" />}
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="Orgnisation"
                    text={user.profile.organisation.name}
                    Icon={
                      <BuildingOffice2Icon className="size-5 text-grey-400" />
                    }
                  />
                </div>
                <div className="basis-1/2">
                  <TwoLineText
                    label="User created"
                    text={format(
                      new Date(parseInt(user.createdAt)),
                      'do MMMM yyyy'
                    )}
                    Icon={<CalendarIcon className="size-5 text-grey-400" />}
                  />
                </div>
              </div>
            </div>
          </div>
          <Tabs
            tabs={[
              {
                name: 'Jobs',
                value: 'jobs',
                component: <Jobs userUuid={user.uuid} />,
              },
              {
                name: 'Tasks',
                value: 'tasks',
                component: <Tasks userProfileUuid={user.profile.uuid} />,
              },
              {
                name: 'Tickets',
                value: 'tickets',
                component: <Tickets userProfileUuid={user.profile.uuid} />,
              },
            ]}
          />
        </div>
      </div>
      <AuditModal
        targetUuid={user.uuid}
        targetType={TargetType.user}
        open={showAuditTrail}
        onClose={() => setShowAuditTrail(false)}
      />
      <AddUserModal
        open={showAddUserModal}
        onClose={(fromConfirm) => {
          setShowAddUserModal(false);
          if (fromConfirm) {
            usersUtility.fetch({ uuid: user.uuid }).then((d) => {
              if (profileUtility.assertProfileSafe(d, 'UserUserProfile')) {
                setUser(d);
              }
            });
          }
        }}
        user={{
          ...user,
          uuid: user.profile.uuid,
          organisationUuid: user.profile.organisation.uuid,
          phone: user.phoneNumber ?? '',
          role: user.profile.role,
        }}
        organisationName={user.profile.organisation.name}
      />
      <SimpleModal
        icon="warning"
        text="Are you sure you want to update the users access?"
        title={`${userIsActive ? 'Deactivate' : 'Activate'} user`}
        onConfirm={() => {
          amendUser({
            variables: {
              input: {
                uuid: user.profile.uuid,
                status: userIsActive
                  ? UserProfileStatus.inactive
                  : UserProfileStatus.active,
              },
            },
          })
            .then(() => {
              notify.success('Saved user');
              usersUtility.fetch({ uuid: user.uuid }).then((d) => {
                if (profileUtility.assertProfileSafe(d, 'UserUserProfile')) {
                  setUser(d);
                }
              });
            })
            .catch((e) => notify.error(`Unable to save user.`));
          setShowStatusModal(false);
        }}
        onConfirmText={`${userIsActive ? 'Deactivate' : 'Activate'} user`}
        open={showStatusModal}
        onClose={() => setShowStatusModal(false)}
      />
      <SimpleModal
        icon="warning"
        text="Reseting a users password will send them an email with a link to reset, they will still be able to log with the old password until they change it."
        title="Send password reset email"
        onConfirm={() => {
          resetPassword({
            variables: {
              input: {
                email: user.email,
              },
            },
          }).then(() => {
            notify.success('Sucessfully reset passed');
            setShowResetPasswordEmail(false);
          });
        }}
        onConfirmText="Confirm reset"
        loading={loadingResetPassword}
        open={showResetPasswordEmail}
        onClose={() => setShowResetPasswordEmail(false)}
      />
      <SimpleModal
        icon="warning"
        text="Are you sure you want to update the users access?"
        title={`${userIsActive ? 'Deactivate' : 'Activate'} user`}
        onConfirm={() => {
          amendUser({
            variables: {
              input: {
                uuid: user.profile.uuid,
                status: userIsActive
                  ? UserProfileStatus.inactive
                  : UserProfileStatus.active,
              },
            },
          })
            .then(() => {
              notify.success('Saved user');
              usersUtility.fetch({ uuid: user.uuid }).then((d) => {
                if (profileUtility.assertProfileSafe(d, 'UserUserProfile')) {
                  setUser(d);
                }
              });
            })
            .catch((e) => notify.error(`Unable to save user.`));
          setShowStatusModal(false);
        }}
        onConfirmText={`${userIsActive ? 'Deactivate' : 'Activate'} user`}
        open={showStatusModal}
        onClose={() => setShowStatusModal(false)}
      />
    </>
  );
};
export default UserUserProfile;
